/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type HeaderDescription_school$ref: FragmentReference;
declare export opaque type HeaderDescription_school$fragmentType: HeaderDescription_school$ref;
export type HeaderDescription_school = {|
  +name: ?string,
  +city: ?string,
  +state: ?string,
  +legacyId: ?number,
  +$refType: HeaderDescription_school$ref,
|};
export type HeaderDescription_school$data = HeaderDescription_school;
export type HeaderDescription_school$key = {
  +$data?: HeaderDescription_school$data,
  +$fragmentRefs: HeaderDescription_school$ref,
};
*/


const node/*: ReaderFragment*/ = {
  "kind": "Fragment",
  "name": "HeaderDescription_school",
  "type": "School",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "name",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "city",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "state",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "legacyId",
      "args": null,
      "storageKey": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '61e7351219dc0fd555d20e263e7f8153';
module.exports = node;
