/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type RatingDistributionChart_ratingsDistribution$ref: FragmentReference;
declare export opaque type RatingDistributionChart_ratingsDistribution$fragmentType: RatingDistributionChart_ratingsDistribution$ref;
export type RatingDistributionChart_ratingsDistribution = {|
  +r1: ?number,
  +r2: ?number,
  +r3: ?number,
  +r4: ?number,
  +r5: ?number,
  +$refType: RatingDistributionChart_ratingsDistribution$ref,
|};
export type RatingDistributionChart_ratingsDistribution$data = RatingDistributionChart_ratingsDistribution;
export type RatingDistributionChart_ratingsDistribution$key = {
  +$data?: RatingDistributionChart_ratingsDistribution$data,
  +$fragmentRefs: RatingDistributionChart_ratingsDistribution$ref,
};
*/


const node/*: ReaderFragment*/ = {
  "kind": "Fragment",
  "name": "RatingDistributionChart_ratingsDistribution",
  "type": "ratingsDistribution",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "r1",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "r2",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "r3",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "r4",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "r5",
      "args": null,
      "storageKey": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '1e35875406e3470b3740959213bd7f48';
module.exports = node;
