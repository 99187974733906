import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { createFragmentContainer, graphql } from 'react-relay'

import Aside from '@StyledComponents/Aside'
import TeacherTypes from '@types/Teacher.types'
import ProfessorNoteTypes from '@types/ProfessorNote.types'
import Comments from '../Comments'
import ProfessorNoteHeader from './ProfessorNoteHeader'
import ProfessorNoteFooter from './ProfessorNoteFooter'

export const StyledAside = styled(Aside)`
  margin-bottom: 32px;
`

export const ProfessorNote = ({ note, teacher, setReplyModeActive }) => {
  return (
    <StyledAside>
      <ProfessorNoteHeader note={note} teacher={teacher} />
      <Comments content={note.comment} />
      <ProfessorNoteFooter
        note={note}
        teacher={teacher}
        onEditClicked={() => setReplyModeActive(true)}
      />
    </StyledAside>
  )
}

ProfessorNote.propTypes = {
  note: ProfessorNoteTypes.isRequired,
  teacher: TeacherTypes.isRequired,
  setReplyModeActive: PropTypes.func.isRequired
}

export default createFragmentContainer(ProfessorNote, {
  teacher: graphql`
    fragment ProfessorNote_teacher on Teacher {
      ...ProfessorNoteHeader_teacher
      ...ProfessorNoteFooter_teacher
    }
  `,
  note: graphql`
    fragment ProfessorNote_note on TeacherNotes {
      comment
      ...ProfessorNoteHeader_note
      ...ProfessorNoteFooter_note
    }
  `
})
