/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type SchoolRatingFooter_rating$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type SchoolRating_rating$ref: FragmentReference;
declare export opaque type SchoolRating_rating$fragmentType: SchoolRating_rating$ref;
export type SchoolRating_rating = {|
  +clubsRating: ?number,
  +comment: ?any,
  +date: ?string,
  +facilitiesRating: ?string,
  +foodRating: ?number,
  +happinessRating: ?number,
  +internetRating: ?number,
  +locationRating: ?number,
  +opportunitiesRating: ?number,
  +reputationRating: ?number,
  +safetyRating: ?number,
  +socialRating: ?number,
  +legacyId: ?number,
  +flagStatus: ?string,
  +createdByUser: ?boolean,
  +$fragmentRefs: SchoolRatingFooter_rating$ref,
  +$refType: SchoolRating_rating$ref,
|};
export type SchoolRating_rating$data = SchoolRating_rating;
export type SchoolRating_rating$key = {
  +$data?: SchoolRating_rating$data,
  +$fragmentRefs: SchoolRating_rating$ref,
};
*/


const node/*: ReaderFragment*/ = {
  "kind": "Fragment",
  "name": "SchoolRating_rating",
  "type": "SchoolRating",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "clubsRating",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "comment",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "date",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "facilitiesRating",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "foodRating",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "happinessRating",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "internetRating",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "locationRating",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "opportunitiesRating",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "reputationRating",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "safetyRating",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "socialRating",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "legacyId",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "flagStatus",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "createdByUser",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "FragmentSpread",
      "name": "SchoolRatingFooter_rating",
      "args": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = 'b61e1f568714ded5c12c8b24e2304a6f';
module.exports = node;
