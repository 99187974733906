import React from 'react'
import styled from 'styled-components'
import Link from '@components/Link'
import Button from '@components/Button'
import useGlobalContext from '@hooks/useGlobalContext'
import PropTypes from 'prop-types'
import MODALS from '@enums/MODALS'

export const StyledButton = styled(Button)`
  background: none;
  border: 0;
  font-size: inherit;
  margin: 0;
  outline: none;
  padding: 0;
  &:hover {
    cursor: pointer;
  }
`

// Export empty styled component so that it is style-able
export const StyledLink = styled(Link)``

/**
 * Temporary reusable component that can be toggled on and off with our feature flag
 *
 * Returns a Link if the feature flag isn't enabled
 *
 * Returns a Button if the feature flag is enabled
 *
 * Maybe we could repurpose this somehow after we ditch the feature flag?
 */
const ModalButtonLink = ({ trackingLabel, children, modal, className }) => {
  const { setActiveModal } = useGlobalContext()

  return (
    <StyledButton
      onClick={() => {
        setActiveModal(modal)
      }}
      trackingLabel={trackingLabel}
      role="button"
      type="button"
      data-testid="modal-button-link"
      className={className}
    >
      {children}
    </StyledButton>
  )
}

ModalButtonLink.propTypes = {
  trackingLabel: PropTypes.string,
  children: PropTypes.node,
  modal: PropTypes.oneOf(Object.values(MODALS)),
  className: PropTypes.string
}

export default ModalButtonLink
