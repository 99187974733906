/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useContext } from 'react'
import PropTypes from 'prop-types'
import styled, { css, ThemeContext } from 'styled-components'
import LABEL_STYLE, { LabelStyleTypes } from '@enums/LABEL_STYLE'
import Alert from '@assets/images/js/Alert'
import Eye from '@assets/images/js/Eye'
import EyeSlash from '@assets/images/js/EyeSlash'
import InputWrapper from './InputWrapper'

// NOTE: Not intended to be implemented directly. Use TextInput, which returns
// this component along with FormContext, instead unless you need a field absent
// of a form (for display purposes, for example)

const passwordTextCss = css`
  font-family: ${props => props.theme.fontFamily.avenirHeavy};
  letter-spacing: 4px;
`
export const leftLabelTextCss = css`
  background-color: white;
  max-width: ${({ theme }) => theme.mainColumnWidth.form};
`
const readOnlyTextCss = css`
  border: 0px none;
  cursor: default;
  :focus {
    outline: none;
  }
`

export const TextFieldStyles = css`
  border: solid 1px ${({ theme, hasError }) => (hasError ? theme.color.error : theme.color.gray4)};
  border-radius: 3px;
  color: inherit;
  font-family: ${props => props.theme.fontFamily.helvetica};
  font-size: ${props => props.theme.fontSize.medium};
  height: 40px;
  outline: none;
  padding: ${props =>
    `${props.theme.padding.textFieldV}px ${props.theme.padding.textFieldH / 2}px`};
  width: 100%;

  ${({ type }) => type === 'password' && passwordTextCss}
  ${({ labelStyle }) => labelStyle === LABEL_STYLE.LEFT && leftLabelTextCss}
  ${({ disabled }) => disabled && readOnlyTextCss}

  &:focus {
    border: 1px solid ${({ theme, hasError }) =>
      hasError ? theme.color.error : theme.color.gray8};
  }

  ::placeholder {
    color: ${props => props.theme.color.default};
  }
`

export const StyledTextField = styled.input`
  ${TextFieldStyles}
`

export const InputContainer = styled.div`
  display: flex;
  width: 100%;
`

const getButtonVerticalPosition = (isPassword, labelStyle) => {
  let bot = `${6}px`

  if (isPassword && labelStyle === LABEL_STYLE.LEFT) {
    bot = `${22}px`
  } else if (labelStyle === LABEL_STYLE.FLOATING) {
    bot = `${8}px`
  } else if (labelStyle === LABEL_STYLE.LEFT) {
    bot = 'unset'
  }

  return css`
    bottom: ${bot};
  `
}

export const InputIconButton = styled.button`
  background: none;
  border: 0;
  cursor: pointer;
  position: absolute;
  right: 5px;

  ${({ isPassword, labelStyle }) => getButtonVerticalPosition(isPassword, labelStyle)}
`

const getInputIconButtonSvg = (hasError, isPassword, isPasswordShown, theme) => {
  if (isPassword) {
    return isPasswordShown ? (
      <EyeSlash bgColor={theme.color.black} />
    ) : (
      <Eye bgColor={theme.color.black} />
    )
  }
  if (hasError) {
    return <Alert bgColor={theme.color.error} />
  }
  return null
}

export const TextField = ({
  onChange = () => {},
  value,
  isPassword,
  name,
  className,
  label = null,
  disabled,
  placeholder,
  hasError = false,
  labelStyle = LABEL_STYLE.FLOATING,
  ...props
}) => {
  const [isFocused, setFocus] = useState(false)
  const [isPasswordShown, setIsPasswordShown] = useState(false)
  const theme = useContext(ThemeContext)

  const inputIconButtonSvg =
    !disabled && getInputIconButtonSvg(hasError, isPassword, isPasswordShown, theme)

  return (
    <InputWrapper
      hasValue={value?.length > 0}
      isFocused={isFocused}
      setFocus={setFocus}
      name={name}
      label={label}
      labelStyle={labelStyle}
      className={className}
    >
      <InputContainer inputIconButtonSvg={!!inputIconButtonSvg}>
        <StyledTextField
          id={name}
          name={name}
          value={value}
          placeholder={placeholder}
          labelStyle={labelStyle}
          type={isPassword && !isPasswordShown ? 'password' : 'text'}
          onFocus={() => setFocus(true)}
          onBlur={() => setFocus(false)}
          onChange={onChange}
          disabled={disabled}
          hasError={hasError}
          {...props}
        />
        {inputIconButtonSvg && (
          <InputIconButton
            disabled={!isPassword}
            onClick={() => {
              setIsPasswordShown(!isPasswordShown)
            }}
            type="button"
            role="button"
            isPassword={isPassword}
            labelStyle={labelStyle}
          >
            {inputIconButtonSvg}
          </InputIconButton>
        )}
      </InputContainer>
    </InputWrapper>
  )
}

TextField.propTypes = {
  label: PropTypes.string,
  labelStyle: LabelStyleTypes,
  isPassword: PropTypes.bool,
  disabled: PropTypes.bool,
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  hasError: PropTypes.bool
}

export default TextField
