/**
 * @flow
 * @relayHash 07410af5eb5174757bbcdc5e2bc62b51
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type FlagReportingBox_rating$ref = any;
export type FlagTeacherRatingPageQueryVariables = {|
  id: string,
  ratingId?: ?string,
|};
export type FlagTeacherRatingPageQueryResponse = {|
  +teacher: ?{|
    +firstName?: ?string,
    +lastName?: ?string,
    +id?: ?string,
    +legacyId?: ?number,
    +school?: ?{|
      +id: ?string
    |},
  |},
  +rating: ?{|
    +id?: ?string,
    +$fragmentRefs: FlagReportingBox_rating$ref,
  |},
|};
export type FlagTeacherRatingPageQuery = {|
  variables: FlagTeacherRatingPageQueryVariables,
  response: FlagTeacherRatingPageQueryResponse,
|};
*/


/*
query FlagTeacherRatingPageQuery(
  $id: ID!
  $ratingId: ID
) {
  teacher: node(id: $id) {
    __typename
    ... on Teacher {
      firstName
      lastName
      id
      legacyId
      school {
        id
      }
    }
    id
  }
  rating: node(id: $ratingId) {
    __typename
    ... on Rating {
      id
      ...FlagReportingBox_rating
    }
    id
  }
}

fragment FlagReportingBox_rating on Rating {
  comment
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "id",
    "type": "ID!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "ratingId",
    "type": "ID",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "firstName",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "lastName",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "legacyId",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "school",
  "storageKey": null,
  "args": null,
  "concreteType": "School",
  "plural": false,
  "selections": [
    (v4/*: any*/)
  ]
},
v7 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "ratingId"
  }
],
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "__typename",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "FlagTeacherRatingPageQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": "teacher",
        "name": "node",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "type": "Teacher",
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/)
            ]
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": "rating",
        "name": "node",
        "storageKey": null,
        "args": (v7/*: any*/),
        "concreteType": null,
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "type": "Rating",
            "selections": [
              (v4/*: any*/),
              {
                "kind": "FragmentSpread",
                "name": "FlagReportingBox_rating",
                "args": null
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "FlagTeacherRatingPageQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": "teacher",
        "name": "node",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "plural": false,
        "selections": [
          (v8/*: any*/),
          (v4/*: any*/),
          {
            "kind": "InlineFragment",
            "type": "Teacher",
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/)
            ]
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": "rating",
        "name": "node",
        "storageKey": null,
        "args": (v7/*: any*/),
        "concreteType": null,
        "plural": false,
        "selections": [
          (v8/*: any*/),
          (v4/*: any*/),
          {
            "kind": "InlineFragment",
            "type": "Rating",
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "comment",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "FlagTeacherRatingPageQuery",
    "id": null,
    "text": "query FlagTeacherRatingPageQuery(\n  $id: ID!\n  $ratingId: ID\n) {\n  teacher: node(id: $id) {\n    __typename\n    ... on Teacher {\n      firstName\n      lastName\n      id\n      legacyId\n      school {\n        id\n      }\n    }\n    id\n  }\n  rating: node(id: $ratingId) {\n    __typename\n    ... on Rating {\n      id\n      ...FlagReportingBox_rating\n    }\n    id\n  }\n}\n\nfragment FlagReportingBox_rating on Rating {\n  comment\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '0e29ca03c356b128409b91ee6631046b';
module.exports = node;
