const calculateOverallGrade = ratingList => {
  const grades = ratingList.map(rating => rating.score)
  const initialVal = 0
  const addedTotal = grades.reduce(
    (gradePrevious, gradeCurrent) => gradePrevious + gradeCurrent,
    initialVal
  )
  const overallGrade = addedTotal / 10
  const roundedGrade = overallGrade.toFixed(1)
  const stringGrade = roundedGrade.toString()
  return stringGrade
}

export default calculateOverallGrade
