/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type ProfessorNoteHeader_note$ref: FragmentReference;
declare export opaque type ProfessorNoteHeader_note$fragmentType: ProfessorNoteHeader_note$ref;
export type ProfessorNoteHeader_note = {|
  +createdAt: ?string,
  +updatedAt: ?string,
  +$refType: ProfessorNoteHeader_note$ref,
|};
export type ProfessorNoteHeader_note$data = ProfessorNoteHeader_note;
export type ProfessorNoteHeader_note$key = {
  +$data?: ProfessorNoteHeader_note$data,
  +$fragmentRefs: ProfessorNoteHeader_note$ref,
};
*/


const node/*: ReaderFragment*/ = {
  "kind": "Fragment",
  "name": "ProfessorNoteHeader_note",
  "type": "TeacherNotes",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "createdAt",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "updatedAt",
      "args": null,
      "storageKey": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = 'bb5d879a47e3e516a5ad2f4697f83e48';
module.exports = node;
