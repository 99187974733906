/**
 * @flow
 * @relayHash 7b517c89f0340ca4e260617a4b9e3d15
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type OauthButtonUrlQueryVariables = {||};
export type OauthButtonUrlQueryResponse = {|
  +oauthURL: ?string
|};
export type OauthButtonUrlQuery = {|
  variables: OauthButtonUrlQueryVariables,
  response: OauthButtonUrlQueryResponse,
|};
*/


/*
query OauthButtonUrlQuery {
  oauthURL(provider: "google")
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "oauthURL",
    "args": [
      {
        "kind": "Literal",
        "name": "provider",
        "value": "google"
      }
    ],
    "storageKey": "oauthURL(provider:\"google\")"
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "OauthButtonUrlQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": (v0/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "OauthButtonUrlQuery",
    "argumentDefinitions": [],
    "selections": (v0/*: any*/)
  },
  "params": {
    "operationKind": "query",
    "name": "OauthButtonUrlQuery",
    "id": null,
    "text": "query OauthButtonUrlQuery {\n  oauthURL(provider: \"google\")\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '7ba4bac1d3e6f45deb7fd81f4f040d8d';
module.exports = node;
