import React from 'react'
import styled from 'styled-components'
import TeacherTypes from '@types/Teacher.types'
import { createFragmentContainer, graphql } from 'react-relay'
import { poppinsBolder } from '@StyledComponents/theme/typesets'

export const StyledCardName = styled.div`
  font-size: ${props => props.theme.fontSize.teacherCardName};
  font-style: normal;
  line-height: 24px;
  margin-bottom: 9px;
  text-align: left;

  ${poppinsBolder}
`

export const CardName = ({ teacher }) => {
  const { firstName, lastName } = teacher

  return (
    <StyledCardName>
      {firstName} {lastName}
    </StyledCardName>
  )
}

export default createFragmentContainer(CardName, {
  teacher: graphql`
    fragment CardName_teacher on Teacher {
      firstName
      lastName
    }
  `
})

CardName.propTypes = {
  teacher: TeacherTypes
}
