import PropTypes from 'prop-types'
import STATUS from '@enums/STATUS'

export const ProfessorNoteAttributes = {
  id: PropTypes.string,
  legacyId: PropTypes.number,
  rid: PropTypes.string,
  tid: PropTypes.string,
  createdDate: PropTypes.string, // Old and will be deprecated
  createdAt: PropTypes.string, // New and correct
  lastUpdatedDate: PropTypes.string, // Old and will be deprecated
  updatedAt: PropTypes.string, // New and correct
  comments: PropTypes.string,
  comment: PropTypes.string, // New and correct
  status: PropTypes.oneOf(Object.values(STATUS))
}

const ProfessorNoteTypes = PropTypes.shape(ProfessorNoteAttributes)

export default ProfessorNoteTypes
