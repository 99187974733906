import PropTypes from 'prop-types'

const CompareSchoolAttributes = {
  name: PropTypes.string,
  numRatings: PropTypes.number,
  avgRating: PropTypes.number,
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      score: PropTypes.number
    })
  ).isRequired
}

const CompareSchoolTypes = PropTypes.shape(CompareSchoolAttributes)

export default CompareSchoolTypes
