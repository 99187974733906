import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { createFragmentContainer, graphql } from 'react-relay'
import COMPARE_ACTIONS from '@enums/COMPARE'
import TeacherTypes from '@types/Teacher.types'
import PageWrapper from '@components/PageWrapper'
import SEOMetaInfo from '@components/SEOMetaInfo'
import AdRail from '@components/Ads/AdRail'
import { AD_TOP_COMPARE_PAGE } from '@pages/CompareSchoolsPage/CompareSchools'
import AdLeaderboard from '@components/Ads/AdLeaderboard'
import CompareProfessorsSearchResults from './CompareProfessorsSearchResults'
import CompareProfessorsHeader from './CompareProfessorsHeader'
import { cleanUrl, handleUrlChange } from './CompareProfessors.utils'

const CompareProfessorsContainer = styled.div`
  background-color: ${({ theme }) => theme.color.gray7};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-bottom: 144px;
  max-width: 860px;
  min-height: 379px;
  padding: 24px;
`

export function CompareProfessors({ variables, primaryProfessor, secondaryProfessor, loading }) {
  if (loading) {
    return <PageWrapper>Loading...</PageWrapper>
  }

  const [professors, setProfessors] = useState({
    primary: primaryProfessor,
    secondary: secondaryProfessor
  })

  useEffect(() => {
    /**
     * This is the case where we have an id in the url but the server responds that
     * the professor was not found. We need to clear that id from the url
     */
    if (variables?.primaryId && !primaryProfessor) {
      cleanUrl({ base64Id: variables.primaryId })
    }
    if (variables?.secondaryId && !secondaryProfessor) {
      cleanUrl({ base64Id: variables.secondaryId })
    }
  }, [])

  const handleOnItemSelected = selected => {
    selected.event.preventDefault()

    setProfessors({
      primary: selected.isPrimaryProfessor ? selected.item : professors.primary,
      secondary: selected.isPrimaryProfessor ? null : selected.item
    })

    handleUrlChange({
      action: selected.isPrimaryProfessor
        ? COMPARE_ACTIONS.SELECT_FIRST_PROFESSOR
        : COMPARE_ACTIONS.SELECT_SECOND_PROFESSOR,
      firstProfessor: selected.isPrimaryProfessor ? selected.item : null,
      secondProfessor: selected.isPrimaryProfessor ? null : selected.item
    })
  }

  const handleClose = column => {
    // Different columns have different close event behaviors
    if (column === 'primary') {
      handleUrlChange({
        action: COMPARE_ACTIONS.EDIT_FIRST_PROFESSOR,
        firstProfessor: professors.primary
      })
      setProfessors({
        primary: professors.secondary,
        secondary: null
      })
    } else if (column === 'secondary') {
      handleUrlChange({
        action: COMPARE_ACTIONS.EDIT_SECOND_PROFESSOR,
        secondProfessor: professors.secondary
      })
      setProfessors({
        primary: professors.primary,
        secondary: null
      })
    }
  }

  return (
    <PageWrapper>
      <SEOMetaInfo
        title="Compare Professors"
        description="See how these professors compare, and compare others"
      />
      <AdLeaderboard code="ad_lb_top_ratings" />
      <CompareProfessorsContainer>
        <CompareProfessorsHeader disabled={!(professors.primary && professors.secondary)} />
        <CompareProfessorsSearchResults
          primaryProfessor={professors.primary}
          secondaryProfessor={professors.secondary}
          handleOnItemSelected={handleOnItemSelected}
          handleClose={handleClose}
        />
      </CompareProfessorsContainer>
      <AdRail adTop={AD_TOP_COMPARE_PAGE} />
    </PageWrapper>
  )
}

CompareProfessors.propTypes = {
  variables: PropTypes.shape({
    primaryId: PropTypes.string,
    secondaryId: PropTypes.string
  }),
  primaryProfessor: TeacherTypes,
  secondaryProfessor: TeacherTypes,
  loading: PropTypes.bool
}

export default createFragmentContainer(CompareProfessors, {
  primaryProfessor: graphql`
    fragment CompareProfessors_primaryProfessor on Teacher {
      id
      legacyId
      ...CompareProfessorsColumn_teacher
    }
  `,
  secondaryProfessor: graphql`
    fragment CompareProfessors_secondaryProfessor on Teacher {
      id
      legacyId
      ...CompareProfessorsColumn_teacher
    }
  `
})
