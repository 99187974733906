/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type Thumbs_school$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type SchoolRatingFooter_school$ref: FragmentReference;
declare export opaque type SchoolRatingFooter_school$fragmentType: SchoolRatingFooter_school$ref;
export type SchoolRatingFooter_school = {|
  +id: ?string,
  +legacyId: ?number,
  +$fragmentRefs: Thumbs_school$ref,
  +$refType: SchoolRatingFooter_school$ref,
|};
export type SchoolRatingFooter_school$data = SchoolRatingFooter_school;
export type SchoolRatingFooter_school$key = {
  +$data?: SchoolRatingFooter_school$data,
  +$fragmentRefs: SchoolRatingFooter_school$ref,
};
*/


const node/*: ReaderFragment*/ = {
  "kind": "Fragment",
  "name": "SchoolRatingFooter_school",
  "type": "School",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "id",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "legacyId",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "FragmentSpread",
      "name": "Thumbs_school",
      "args": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '381ea1ab195ea135739edc3dfc3b23fc';
module.exports = node;
