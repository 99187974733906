import React, { useState } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import Link from '@components/Link'
import ROUTE from '@enums/ROUTE'
import Alert from '@assets/images/alert.svg'
import CaretDown from '@assets/images/caret-down-black.svg'
import CaretUp from '@assets/images/caret-up-black.svg'

export const StyledInfoBoxContainer = styled.div`
  background: ${({ theme }) => theme.color.gray7};
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  font-size: ${({ theme }) => theme.fontSize.medium};
  line-height: 22px;
  margin-bottom: 16px;
`
export const StyledInfoBoxTrigger = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 12px 22px 12px 10px;
`

export const StyledInfoBoxHeader = styled.div`
  display: inline-flex;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
`

export const StyledInfoBoxTitle = styled.h2`
  margin-left: 9px;
`

export const StyledInfoBox = styled.div`
  display: ${({ expanded }) => (expanded ? 'flex' : 'none')};
  flex-direction: column;
  padding: 0px 22px 22px 60px;
  text-align: left;
`

export const StyledInfoBoxList = styled.ol`
  font-size: ${({ theme }) => theme.fontSize.medium};
  list-style: disc;
  margin-bottom: 8px;
`

export const StyledLegalLink = styled(Link).attrs(props => ({ ...props, target: '_blank' }))`
  font-size: ${({ theme }) => theme.fontSize.default};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  margin-left: -6px;
  width: fit-content;
`

const InfoBoxGuidelines = ({ guidelines }) => {
  const [expanded, setExpanded] = useState(true)
  return (
    <StyledInfoBoxContainer>
      <StyledInfoBoxTrigger onClick={() => setExpanded(!expanded)}>
        <StyledInfoBoxHeader>
          <img alt="" src={Alert} />
          <StyledInfoBoxTitle>Guidelines</StyledInfoBoxTitle>
        </StyledInfoBoxHeader>
        <img alt="" src={expanded ? CaretUp : CaretDown} />
      </StyledInfoBoxTrigger>
      <StyledInfoBox expanded={expanded}>
        <StyledInfoBoxList>
          {guidelines?.map(guideline => {
            return <li key={guideline}>{guideline}</li>
          })}
        </StyledInfoBoxList>
        <StyledLegalLink to={ROUTE.LEGAL_SITE_GUIDELINES}>View all guidelines</StyledLegalLink>
      </StyledInfoBox>
    </StyledInfoBoxContainer>
  )
}

InfoBoxGuidelines.propTypes = {
  guidelines: PropTypes.arrayOf(PropTypes.string)
}

export default InfoBoxGuidelines
