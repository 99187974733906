import React from 'react'
import styled, { css } from 'styled-components'
import TeacherTypes from '@types/Teacher.types'
import { createFragmentContainer, graphql } from 'react-relay'
import {
  breakpointSmediumAndBelow,
  breakpointSmallPlusAndBelow,
  breakpointXMediumAndBelow
} from '@StyledComponents/theme/helpers/breakpoints'

export const Department = styled.div`
  line-height: 16px;
  margin-bottom: 11px;
  overflow: hidden;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
`

export const School = styled.div`
  color: ${props => props.theme.color.gray8};
  line-height: 16px;
  margin-bottom: 8px;
  overflow: hidden;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
`

export const StyledCardSchool = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: none;
  font-size: ${props => props.theme.fontSize.medium};
  width: 100%;

  ${breakpointSmediumAndBelow(
    css`
      flex-direction: column;
      align-items: flex-start;
    `
  )}
  ${breakpointXMediumAndBelow(
    css`
      max-width: 465px;
    `
  )}

  ${breakpointSmallPlusAndBelow(css`
    max-width: 327px;
  `)}
`

export const Divider = styled.span`
  margin-right: 6px;

  ${breakpointSmediumAndBelow(
    css`
      display: none;
    `
  )}
`

export const CardSchool = ({ teacher }) => {
  const { department, school } = teacher

  if (!department || !school?.name) return null

  return (
    <StyledCardSchool>
      {department && <Department>{department}</Department>}
      {school?.name && <School>{school.name}</School>}
    </StyledCardSchool>
  )
}

export default createFragmentContainer(CardSchool, {
  teacher: graphql`
    fragment CardSchool_teacher on Teacher {
      department
      school {
        name
      }
    }
  `
})

CardSchool.propTypes = {
  teacher: TeacherTypes
}
