import React, { useState, useContext } from 'react'
import PropTypes from 'prop-types'
import styled, { css, ThemeContext } from 'styled-components'
import ThemeObject from '@StyledComponents/theme'
import SEARCH_CATEGORY from '@enums/SEARCH_CATEGORY'
import Apple from '@assets/images/js/Apple'
import GraduationCap from '@assets/images/js/GraduationCap'
import CaretDown from '@assets/images/caret-down.svg'
import CaretUp from '@assets/images/caret-up.svg'
import {
  breakpointSmediumAndBelow,
  breakpointXMediumAndBelow
} from '@StyledComponents/theme/helpers/breakpoints'
import { useHeaderContext } from './HeaderContext'

const StyledToggleButton = styled.button`
  align-items: center;
  background: none;
  border: 0;
  color: white;
  cursor: pointer;
  display: flex;
  margin-right: 14px;
  outline: none;
  position: relative;

  ${breakpointSmediumAndBelow(css`
    display: ${({ isMobileInputFullWidth }) => (isMobileInputFullWidth ? 'none' : 'flex')};
    margin-right: 0;
  `)}

  svg {
    margin-right: 5px;

    ${breakpointSmediumAndBelow(css`
      margin-right: 11px;
    `)}
  }
`

const DropDownItemText = styled.div`
  font-size: ${({ theme }) => theme.fontSize.large};
  line-height: 21px;
  margin-right: 10px;
  padding-left: 11px;
`

const ToggleButtonText = styled.div`
  color: white;
  font-size: ${({ theme }) => theme.fontSize.large};
  line-height: 21px;
  padding-right: 10px;

  ${breakpointSmediumAndBelow(css`
    display: none;
  `)}
`

const StyledTeacherSchoolDropdown = styled.div`
  background: white;
  border-radius: 8px;
  box-shadow: 2px -1px 10px rgba(0, 0, 0, 0.1);
  color: black;
  display: flex;
  font-size: ${({ theme }) => theme.fontSize.medium};
  padding: 21px 55px 21px 17px;
  position: absolute;
  top: 35px;
  z-index: 2;

  &:hover {
    background: ${({ theme }) => theme.color.mainBlue};

    ${DropDownItemText} {
      color: white;
    }

    path {
      fill: white;
    }
  }
`

const IconWrapper = styled.span`
  ${breakpointXMediumAndBelow(css`
    display: none;
  `)}

  ${breakpointSmediumAndBelow(css`
    display: block;
  `)}
`

export const buttonIconConfig = {
  [SEARCH_CATEGORY.SCHOOLS]: {
    icon: function Icon(fillColor, height, width) {
      return <GraduationCap fillColor={fillColor} height={height} width={width} />
    },
    text: 'Schools',
    name: SEARCH_CATEGORY.SCHOOLS
  },
  [SEARCH_CATEGORY.TEACHERS]: {
    icon: function Icon(fillColor) {
      return <Apple fillColor={fillColor} />
    },
    text: 'Professors',
    name: SEARCH_CATEGORY.TEACHERS
  }
}

const TeacherSchoolToggleDropdown = ({ config, setSearchCategory, theme }) => {
  return (
    <StyledTeacherSchoolDropdown
      onClick={() => {
        setSearchCategory(config.name)
      }}
    >
      {config.icon(theme.color.black)} <DropDownItemText>{config.text}</DropDownItemText>
    </StyledTeacherSchoolDropdown>
  )
}

const TeacherSchoolToggleButton = ({ searchCategory, setSearchCategory }) => {
  const theme = useContext(ThemeContext)
  const { isMobileInputFullWidth } = useHeaderContext()

  const config = buttonIconConfig[searchCategory]

  const [isOpen, setIsOpen] = useState(false)

  const remainingCategory = Object.values(SEARCH_CATEGORY).find(category => {
    return category !== SEARCH_CATEGORY.ALL && category !== searchCategory
  })

  const remainingConfig = buttonIconConfig[remainingCategory]

  return (
    <StyledToggleButton
      onClick={() => setIsOpen(!isOpen)}
      onBlur={() => setIsOpen(false)}
      isMobileInputFullWidth={isMobileInputFullWidth}
      type="button"
      role="button"
      title="Header Type Toggle Button"
    >
      <IconWrapper> {config.icon(theme.color.white)}</IconWrapper>
      <ToggleButtonText>{config.text}</ToggleButtonText>
      <img src={isOpen ? CaretUp : CaretDown} alt={`Caret ${isOpen ? 'Up' : 'Down'}`} />
      {isOpen && (
        <TeacherSchoolToggleDropdown
          config={remainingConfig}
          setSearchCategory={setSearchCategory}
          theme={theme}
        />
      )}
    </StyledToggleButton>
  )
}

TeacherSchoolToggleDropdown.propTypes = {
  config: PropTypes.shape({
    icon: PropTypes.func,
    text: PropTypes.string,
    name: PropTypes.string
  }),
  setSearchCategory: PropTypes.func,
  theme: PropTypes.shape(ThemeObject)
}

TeacherSchoolToggleButton.propTypes = {
  searchCategory: PropTypes.oneOf(Object.values(SEARCH_CATEGORY)),
  setSearchCategory: PropTypes.func
}

export default TeacherSchoolToggleButton
