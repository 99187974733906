import React from 'react'
import styled, { css } from 'styled-components'
import { TextButton } from '@StyledComponents/Buttons'
import { SearchInputContainer } from '@components/Search'
import { breakpointSmediumAndBelow } from '@StyledComponents/theme/helpers/breakpoints'
import SchoolTypes from '@types/School.types'
import PropTypes from 'prop-types'
import HeaderSearchInput from './HeaderSearchInput'
import { useHeaderContext } from './HeaderContext'
import TeacherSchoolToggleButton from './TeacherSchoolToggleButton'

const MainInputWrapper = styled.div`
  align-items: center;
  display: flex;
  margin: 0 auto;
  width: 100%;

  ${breakpointSmediumAndBelow(css`
    justify-content: space-between;
  `)}
`

const NewSearchWrapper = styled.div`
  max-width: 795px;
  width: 100%;

  ${SearchInputContainer} {
    display: flex;
    justify-content: center;
    position: relative;
    width: 100%;

    ${breakpointSmediumAndBelow(css`
      max-width: unset;
    `)}
  }
`

const InputCancelButton = styled(TextButton)`
  color: ${({ theme }) => theme.color.white};
  display: none;
  font-size: ${({ theme }) => theme.fontSize.medium};
  font-weight: ${({ theme }) => theme.fontWeight.light};

  ${breakpointSmediumAndBelow(css`
    display: ${({ isMobileInputFullWidth }) => (isMobileInputFullWidth ? 'none' : 'block')};
  `)}
`

const HeaderSearchMainInput = ({ school, setSchool }) => {
  const {
    searchCategory,
    setSearchCategory,
    setIsMobileSearchOpen,
    isMobileInputFullWidth,
    isSchoolSearch
  } = useHeaderContext()

  return (
    <MainInputWrapper>
      <div>
        <TeacherSchoolToggleButton
          searchCategory={searchCategory}
          setSearchCategory={setSearchCategory}
        />
      </div>
      <NewSearchWrapper isSchoolSearch={isSchoolSearch}>
        <HeaderSearchInput
          searchCategory={searchCategory}
          setIsMobileSearchOpen={setIsMobileSearchOpen}
          school={school}
          setSchool={setSchool}
        />
      </NewSearchWrapper>
      <InputCancelButton
        onClick={() => setIsMobileSearchOpen(false)}
        isMobileInputFullWidth={isMobileInputFullWidth}
      >
        cancel
      </InputCancelButton>
    </MainInputWrapper>
  )
}

export default HeaderSearchMainInput

HeaderSearchMainInput.propTypes = {
  school: SchoolTypes,
  setSchool: PropTypes.func
}
