import React, { useRef } from 'react'
import { graphql } from 'react-relay'

import QueryWrapper from '@components/utils/QueryWrapper'
import { getRandomInt } from '@utils/random'
import FormController from './FormController'

/**
 * The Form component aims to abstract the majority of the relay work when
 * implementing a mutation based form. As long as the inputs and fields in the
 * Form folder are used, error handling and validation will be totally managed
 * by this component and it's context. Similarly it will handle input locking
 * while requests are active, and top-level mutation errors. Most of this is
 * actually handled by FormController, whereas this file is simply the query
 * wrapper
 */
const Form = props => {
  const validationID = useRef(`${Date.now()}${getRandomInt(0, 10000)}`)
  return (
    <QueryWrapper
      query={graphql`
        query FormQuery($validationId: ID!) {
          validation: node(id: $validationId) {
            id
            ...FormController_validation
          }
        }
      `}
      variables={{ validationId: btoa(`ValidationResult-${validationID.current}`) }}
      component={node => {
        const validation = node.validation === undefined ? null : node.validation
        return <FormController validation={validation} {...props} />
      }}
    />
  )
}

Form.displayName = 'Form'
Form.propTypes = FormController.propTypes

export default Form
