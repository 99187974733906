import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import useUserInfo from '@hooks/useUserInfo'
import DeleteAccountWarning from './DeleteAccountWarning'
import DeleteAccountForm from './DeleteAccountForm'

const StyledDeleteAccount = styled.div`
  margin: auto;
  max-width: 755px;
`

export default function DeleteAccount({ setFormMode }) {
  const userInfo = useUserInfo()
  if (!userInfo?.Email) return null

  return (
    <StyledDeleteAccount>
      <DeleteAccountWarning userType={userInfo.Type} />
      <DeleteAccountForm userEmail={userInfo.Email} setFormMode={setFormMode} />
    </StyledDeleteAccount>
  )
}

DeleteAccount.propTypes = {
  setFormMode: PropTypes.func
}
