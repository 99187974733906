import React from 'react'
import PropTypes from 'prop-types'

const Flag = ({ outlineFill, insideFill }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <defs>
        <path
          id="prefix__a"
          d="M3.93 10c1.417 0 2.383.276 4.371 1.072 1.762.704 2.546.928 3.629.928 1.189 0 2.094-.165 2.754-.428.095-.039.177-.075.246-.108v-9.86c-.82.253-1.814.396-3 .396-1.417 0-2.383-.276-4.371-1.072C5.797.224 5.013 0 3.93 0 2.741 0 1.836.165 1.176.428A4.094 4.094 0 00.93.536v9.86c.82-.253 1.814-.396 3-.396z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <path
          fill={outlineFill}
          fillRule="nonzero"
          d="M3 3a1 1 0 01.293-.707c.22-.22.614-.483 1.21-.721C5.407 1.21 6.564 1 8 1c1.417 0 2.383.276 4.371 1.072C14.133 2.776 14.917 3 16 3c1.189 0 2.094-.165 2.754-.428.341-.137.508-.249.539-.28C19.923 1.663 21 2.11 21 3v12a1 1 0 01-.293.707c-.22.22-.614.483-1.21.721-.903.362-2.06.572-3.497.572-1.417 0-2.383-.276-4.371-1.072C9.867 15.224 9.083 15 8 15c-1.189 0-2.094.165-2.754.428a4.09 4.09 0 00-.247.108L5 22a1 1 0 01-2 0V3zm5 0c-1.189 0-2.094.165-2.754.428A4.094 4.094 0 005 3.536v9.86C5.82 13.143 6.814 13 8 13c1.417 0 2.383.276 4.371 1.072 1.762.704 2.546.928 3.629.928 1.189 0 2.094-.165 2.754-.428.095-.039.177-.075.246-.108v-9.86c-.82.253-1.814.396-3 .396-1.417 0-2.383-.276-4.371-1.072C9.867 3.224 9.083 3 8 3z"
        />
        <g transform="translate(4.07 3)">
          <mask id="prefix__b" fill="#fff">
            <use xlinkHref="#prefix__a" />
          </mask>
          <use fill={insideFill} fillRule="nonzero" xlinkHref="#prefix__a" />
          <g fill={insideFill} mask="url(#prefix__b)">
            <path d="M0 0H64V64H0z" transform="translate(-25 -27)" />
          </g>
        </g>
      </g>
    </svg>
  )
}

Flag.propTypes = {
  outlineFill: PropTypes.string,
  insideFill: PropTypes.string
}

export default Flag
