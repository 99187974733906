import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { useSwipeable } from 'react-swipeable'
import CaretRight from '@assets/images/caret-right.svg'

const CarouselWrapper = styled.div`
  display: flex;
`

const CarouselContainer = styled.div`
  overflow: hidden;
  padding-left: 36px;
`

const InnerCarousel = styled.div`
  transition: transform 0.3s;
  white-space: nowrap;
`

const Indicators = styled.div`
  display: flex;
  justify-content: center;
`

const IndexButton = styled.button`
  background: #fff;
  border: 0;
  padding: ${({ active }) => (active ? '11px 4px' : '12px 4px;')};
  &:hover {
    cursor: pointer;
  }
`

const IndicatorCircle = styled.div`
  background-color: ${({ active }) => (active ? '#134BA5' : '#D8D8D8')};
  border-radius: 50%;
  height: ${({ active }) => (active ? '8px' : '6px')};
  padding: 0;
  width: ${({ active }) => (active ? '8px' : '6px')};
`

const RightButton = styled.button`
  background: #fff;
  border: #fff;
  &:hover {
    cursor: pointer;
  }
`

const Carousel = ({ children, className }) => {
  const [activeIndex, setActiveIndex] = useState(0)
  const [paused, setPaused] = useState(false)

  const updateIndex = newIndex => {
    let newActiveIndex
    if (newIndex < 0) {
      newActiveIndex = children.length - 1
    } else if (newIndex >= children.length) {
      newActiveIndex = 0
    } else {
      newActiveIndex = newIndex
    }
    setActiveIndex(newActiveIndex)
  }

  useEffect(() => {
    const interval = setInterval(() => {
      if (!paused) {
        updateIndex(activeIndex + 1)
      }
    }, 5000)

    return () => {
      if (interval) {
        clearInterval(interval)
      }
    }
  })

  const handlers = useSwipeable({
    onSwipedLeft: () => updateIndex(activeIndex + 1),
    onSwipedRight: () => updateIndex(activeIndex - 1)
  })

  return (
    <CarouselWrapper className={className}>
      <CarouselContainer
        {...handlers}
        onMouseEnter={() => setPaused(true)}
        onMouseLeave={() => setPaused(false)}
      >
        <InnerCarousel style={{ transform: `translateX(-${activeIndex * 100}%)` }}>
          {children.map(child => {
            return React.cloneElement(child, { width: '100%' })
          })}
        </InnerCarousel>
        <Indicators className="indicators">
          {React.Children.map(children, (child, index) => {
            return (
              <IndexButton
                type="button"
                active={index === activeIndex}
                onClick={() => {
                  updateIndex(index)
                }}
              >
                <IndicatorCircle active={index === activeIndex} />
              </IndexButton>
            )
          })}
        </Indicators>
      </CarouselContainer>
      <RightButton
        type="button"
        onClick={() => {
          updateIndex(activeIndex + 1)
        }}
      >
        <img alt="Right Arrow" src={CaretRight} />
      </RightButton>
    </CarouselWrapper>
  )
}

Carousel.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string
}

export default Carousel
