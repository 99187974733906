import PropTypes from 'prop-types'
import RatingTypes from '@types/Rating.types'
import TeacherNoteTypes from '@types/TeacherNote.types'
import { getPaginationTypes } from '@utils/types'

export const TeacherAttributes = {
  id: PropTypes.string,
  legacyId: PropTypes.number,
  lockStatus: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  department: PropTypes.string,
  school: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string
  }),
  numRatings: PropTypes.number,
  avgRating: PropTypes.number,
  avgDifficulty: PropTypes.number,
  wouldTakeAgainPercent: PropTypes.number,
  teacherRatingTags: PropTypes.arrayOf(
    PropTypes.shape({
      tagName: PropTypes.string,
      tagCount: PropTypes.number,
      legacyId: PropTypes.number
    })
  ),
  ratings: getPaginationTypes(RatingTypes),
  notes: getPaginationTypes(TeacherNoteTypes),
  emailAlerts: PropTypes.bool
}

const TeacherTypes = PropTypes.shape(TeacherAttributes)

export default TeacherTypes
