import React from 'react'
import styled from 'styled-components'
import { createPaginationContainer, graphql } from 'react-relay'
import useRelayIsLoading from '@hooks/useRelayIsLoading'
// eslint-disable-next-line import/no-extraneous-dependencies
import '@node_modules/@rmwc/circular-progress/circular-progress.css'
import RelayTypes from '@types/Relay.types'
import PaginationButton from '@StyledComponents/PaginationButton'
import SchoolTypes from '@types/School.types'
import SchoolRating from '@components/SchoolRating/SchoolRating'
import AdNoBid from '@components/Ads/NoBid/AdNoBid'

export const GPT_AD_INTERVAL = 3
export const GPT_AD_OFFSET = 0
export const SCHOOL_RATINGS_LIST_ANCHOR = 'schoolRatingsList'

const ListContainer = styled.ul`
  list-style: none;
  margin: 0 24px;
`

const displayOptions = {
  showSuperHeader: false,
  showEmotionLabel: false,
  showFooter: true,
  hideFlag: false
}

const buildSchoolRatingList = school => {
  const result = []
  school.ratings.edges.forEach((edge, i) => {
    const adUnitKey = `school-ratings-list-${i}`
    result.push(
      <SchoolRating
        key={edge.cursor}
        school={school}
        rating={edge.node}
        displayOptions={displayOptions}
      />
    )

    /**
     * Nobid Ad Unit
     */
    if (i >= GPT_AD_OFFSET && (i - GPT_AD_OFFSET) % GPT_AD_INTERVAL === 0) {
      result.push(<AdNoBid code="ad_lb_dyn_profile" key={adUnitKey} />)
    }
  })
  return result
}
export function SchoolRatingsList({ school, relay }) {
  const [isLoading, setIsLoading] = useRelayIsLoading(relay, [relay, school])

  const loadMore = () => {
    relay.loadMore(
      10, // Fetch the next 10 feed items
      error => {
        if (error) {
          console.error(error)
        }
      }
    )
    setIsLoading(true)
  }

  return (
    <React.Fragment>
      <ListContainer id={SCHOOL_RATINGS_LIST_ANCHOR}>
        {buildSchoolRatingList(school).map(item => (
          <li key={`li-${item.key}`}>{item}</li>
        ))}
      </ListContainer>

      <PaginationButton isLoading={isLoading} hasMore={relay.hasMore()} onClick={loadMore}>
        Show More
      </PaginationButton>
    </React.Fragment>
  )
}

SchoolRatingsList.propTypes = {
  school: SchoolTypes,
  relay: RelayTypes
}

export default createPaginationContainer(
  SchoolRatingsList,
  {
    school: graphql`
      fragment SchoolRatingsList_school on School
        @argumentDefinitions(
          count: { type: "Int", defaultValue: 20 }
          cursor: { type: "String", defaultValue: null }
        ) {
        id
        name
        city
        state
        country
        legacyId
        ratings(first: $count, after: $cursor) @connection(key: "SchoolRatingsList_ratings") {
          edges {
            cursor
            node {
              ...SchoolRating_rating
            }
          }
          pageInfo {
            hasNextPage
            endCursor
          }
        }
        ...SchoolRating_school
      }
    `
  },
  {
    direction: 'forward',
    getConnectionFromProps(props) {
      return props.school && props.school.ratings
    },
    getVariables(props, { count, cursor }) {
      return {
        count,
        cursor,
        id: props.school.id // Intentional node_id
      }
    },
    query: graphql`
      # Pagination query to be fetched upon calling 'loadMore'.
      # Notice that we re-use our fragment, and the shape of this query matches our fragment spec.
      query SchoolRatingsListQuery($count: Int!, $id: ID!, $cursor: String) {
        node(id: $id) {
          ... on School {
            ...SchoolRatingsList_school @arguments(count: $count, cursor: $cursor)
          }
        }
      }
    `
  }
)
