import React from 'react'
import PropTypes from 'prop-types'
import { createFragmentContainer, graphql } from 'react-relay'
import styled from 'styled-components'

import TeacherTypes from '@types/Teacher.types'
import IconButton from '@StyledComponents/IconButton'
import TRACKING_EVENT from '@enums/TRACKING_EVENT'
import ROUTE from '@enums/ROUTE'
import TeacherNoteTypes from '@types/TeacherNote.types'
import ReportFlag from '../ReportFlag'

const StyledProfessorNoteFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`

export const ProfessorNoteFooter = ({ note, teacher, onEditClicked }) => {
  return (
    <StyledProfessorNoteFooter>
      {teacher.isProfCurrentUser ? (
        <React.Fragment>
          <IconButton.Edit trackingLabel={TRACKING_EVENT.REPLY_EDIT} onClick={onEditClicked}>
            Edit
          </IconButton.Edit>
        </React.Fragment>
      ) : (
        <ReportFlag
          status={note.flagStatus}
          link={`${ROUTE.FLAG_PROFESSOR_NOTE}?rebuttalId=${note.legacyId}&tid=${teacher.legacyId}`}
          trackingLabel={TRACKING_EVENT.REPLY_FLAG}
          entityLabel="rating"
        />
      )}
    </StyledProfessorNoteFooter>
  )
}
ProfessorNoteFooter.propTypes = {
  note: TeacherNoteTypes,
  teacher: TeacherTypes,
  onEditClicked: PropTypes.func
}

export default createFragmentContainer(ProfessorNoteFooter, {
  teacher: graphql`
    fragment ProfessorNoteFooter_teacher on Teacher {
      legacyId
      isProfCurrentUser
    }
  `,
  note: graphql`
    fragment ProfessorNoteFooter_note on TeacherNotes {
      legacyId
      flagStatus
    }
  `
})
