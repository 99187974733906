import React from 'react'
import styled from 'styled-components'
import { createPaginationContainer, graphql } from 'react-relay'
import UserTypes from '@types/User.types'
import PaginationButton from '@StyledComponents/PaginationButton'
import TeacherCard from '@components/TeacherCard/TeacherCard'
import NoBookmarksGif from '@assets/images/no-bookmarks.gif'
import useRelayIsLoading from '@hooks/useRelayIsLoading'
import RelayTypes from '@types/Relay.types'
import { helveticaBoldXLarge } from '@StyledComponents/theme/typesets'

export const StyledSavedProfessorsList = styled.div``

const ProfessorsList = styled.ul`
  list-style-type: none;
`

const TeacherCardArea = styled.div`
  margin-bottom: 24px;
`

const NoBookmarksArea = styled.div`
  margin-top: 60px;
`
const NoBookmarksImg = styled.img`
  margin: 14px auto 16px;
  max-height: 188px;
`

export const NoBookmarksCopy = styled.div`
  ${helveticaBoldXLarge};
`

export function SavedProfessorsList({ user, relay }) {
  const [isLoading, setIsLoading] = useRelayIsLoading(relay, [user, relay])

  if (!user) {
    return null
  }

  if (!user.teachers || !user.teachers.edges || user.teachers.edges.length < 1) {
    return <NoBookmarks />
  }

  const loadMore = () => {
    relay.loadMore(10, error => {
      console.error('Error', error)
      setIsLoading(false)
    })
    setIsLoading(true)
  }

  return (
    <StyledSavedProfessorsList>
      <ProfessorsList>
        {user.teachers.edges.map(item => (
          <TeacherCardArea key={`tca-${item.cursor}`}>
            <TeacherCard teacher={item.teacher} key={item.cursor} />
          </TeacherCardArea>
        ))}
      </ProfessorsList>
      <PaginationButton isLoading={isLoading} hasMore={relay.hasMore()} onClick={loadMore}>
        Load more
      </PaginationButton>
    </StyledSavedProfessorsList>
  )
}

export function NoBookmarks() {
  return (
    <NoBookmarksArea>
      <NoBookmarksImg src={NoBookmarksGif} />
      <NoBookmarksCopy>You don’t have any saved professors yet</NoBookmarksCopy>
    </NoBookmarksArea>
  )
}

export default createPaginationContainer(
  SavedProfessorsList,
  {
    user: graphql`
      fragment SavedProfessorsList_user on User
        @argumentDefinitions(count: { type: "Int", defaultValue: 10 }, cursor: { type: "String" }) {
        id
        teachers(first: $count, after: $cursor) @connection(key: "SavedProfessorsList_teachers") {
          edges {
            cursor
            teacher: node {
              legacyId
              ...TeacherCard_teacher
            }
          }
          pageInfo {
            hasNextPage
            endCursor
          }
        }
      }
    `
  },
  {
    direction: 'forward',
    getConnectionFromProps(props) {
      return props.user && props.user.teachers
    },
    getVariables(props, { count, cursor }) {
      return {
        count,
        cursor,
        id: props.user.id
      }
    },
    query: graphql`
      query SavedProfessorsListQuery($count: Int!, $cursor: String!, $id: ID!) {
        node(id: $id) {
          ... on User {
            ...SavedProfessorsList_user @arguments(count: $count, cursor: $cursor)
          }
        }
      }
    `
  }
)

SavedProfessorsList.propTypes = {
  user: UserTypes,
  relay: RelayTypes
}
