import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import Form from '@components/Form/Form'
import {
  breakpointMediumAndBelow,
  breakpointTinyAndBelow,
  breakpointXLargeAndBelow,
  getPageContentMaxWidth
} from '@components/StyledComponents/theme/helpers/breakpoints'
import IconButton from '@components/StyledComponents/IconButton'
import CompareSchoolTypes from '@types/CompareSchool.types'
import CompareSchoolsTableContent from './CompareSchoolsTableContent'
import CompareSchoolsSearch from './CompareSchoolsSearch'
import CompareSchoolsHeaderBox from './CompareSchoolsHeaderBox'

export const SharedButtonStyles = css`
  background-color: ${({ theme }) => theme.color.gray5};
  border: inherit;
  border-radius: 33px;
  color: ${({ theme }) => theme.color.white};
  cursor: pointer;
  font-size: 16px;
  margin: 16px 0px;
  padding: 8px 16px 8px 16px;

  ${props =>
    props.header &&
    css`
      ${breakpointMediumAndBelow(css`
        display: none;
      `)}
    `}

  ${props =>
    props.footer &&
    css`
      display: none;

      ${breakpointMediumAndBelow(css`
        display: flex;
        margin: 12px auto;
      `)}
    `}
`

const CompareSchoolsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 24px 128px 24px;
  max-width: 888px;
`

const CompareSchoolsColumnContainer = styled.div`
  clear: both;
  content: '';
  display: flex;
  flex-direction: row;
  width: 100%;
`

const CompareSchoolsColumn = styled.div`
  align-items: center;
  background-color: #f7f7f7;
  display: flex;
  flex-direction: column;
  margin: 0 3px 0 0;
  margin-bottom: 12px;
  max-width: 444px;
  min-height: 205px;
  padding: 10px;
  text-align: center;
  width: 100%;

  ${breakpointTinyAndBelow(css`
    max-width: 168px;
    min-height: 255px;
  `)}
`

export const CompareSchoolSearchForm = styled(Form)`
  align-content: center;
  margin: 0 14px 0 14px;
  max-width: 264px;
  ${({ theme }) => getPageContentMaxWidth(theme)};
`

const SchoolName = styled.div`
  font-size: 12px;
  line-height: 24px;
`

export const ShareComparisonButton = styled(IconButton.Share)`
  ${SharedButtonStyles}

  ${props =>
    props.secondarySchool &&
    css`
      background-color: ${({ theme }) => theme.color.black};
    `}
`

export const ResetButton = styled(IconButton.Reset)`
  ${SharedButtonStyles}
  margin-right: 8px;

  ${props =>
    !props.disabled &&
    css`
      background-color: ${({ theme }) => theme.color.black};
    `}
`

export const ShareComparisonButtonHeader = styled(ShareComparisonButton)`
  ${ShareComparisonButton}

  ${breakpointMediumAndBelow(css`
    display: none;
  `)}
`

export const ShareComparisonButtonFooter = styled(ShareComparisonButton)`
  ${breakpointXLargeAndBelow(css`
    display: none;
  `)}

  ${breakpointMediumAndBelow(css`
    display: flex;
    margin: 16px auto;
  `)}
`

const defaultCategories = [
  'Internet',
  'Safety',
  'Facilities',
  'Opportunities',
  'Location',
  'Clubs',
  'Social',
  'Food'
]

const CompareSchoolsTable = ({
  primarySchool,
  secondarySchool,
  handleEditSchool,
  handleSchoolSelection,
  handleOnShareClicked,
  handleReset
}) => {
  // Create a set of all unique category names from both entities
  const allCategories = new Set([
    ...(primarySchool && primarySchool.categores
      ? primarySchool.categories.map(category => category.name)
      : []),
    ...(secondarySchool && secondarySchool.categories
      ? secondarySchool.categories.map(category => category.name)
      : []),
    ...defaultCategories
  ])

  return (
    <CompareSchoolsContainer>
      <CompareSchoolsColumnContainer>
        <CompareSchoolsColumn>
          {primarySchool ? (
            <CompareSchoolsHeaderBox primary school={primarySchool} />
          ) : (
            <CompareSchoolSearchForm>
              <CompareSchoolsHeaderBox primary school={primarySchool} />
              <CompareSchoolsSearch primary handleSchoolSelection={handleSchoolSelection} />
              <SchoolName noMargin search>
                School Name
              </SchoolName>
            </CompareSchoolSearchForm>
          )}
        </CompareSchoolsColumn>

        <CompareSchoolsColumn>
          {secondarySchool ? (
            <CompareSchoolsHeaderBox school={secondarySchool} handleEditSchool={handleEditSchool} />
          ) : (
            <CompareSchoolSearchForm>
              <CompareSchoolsHeaderBox school={secondarySchool} />
              <CompareSchoolsSearch
                primarySelected={!!primarySchool}
                handleSchoolSelection={handleSchoolSelection}
              />
              <SchoolName noMargin search>
                School Name
              </SchoolName>
            </CompareSchoolSearchForm>
          )}
        </CompareSchoolsColumn>
      </CompareSchoolsColumnContainer>

      {[...allCategories].map(categoryName => (
        <CompareSchoolsTableContent
          key={categoryName}
          primarySchool={primarySchool}
          secondarySchool={secondarySchool}
          categoryName={categoryName}
        />
      ))}

      <ShareComparisonButton
        height="20px"
        width="15px"
        footer
        disabled={!secondarySchool}
        secondarySchool={secondarySchool}
        onClick={handleOnShareClicked}
      >
        Share Comparison
      </ShareComparisonButton>
      <ResetButton
        id="reset-button"
        height="20px"
        width="15px"
        footer
        disabled={!primarySchool && !secondarySchool}
        onClick={handleReset}
      >
        Reset
      </ResetButton>
    </CompareSchoolsContainer>
  )
}

CompareSchoolsTable.propTypes = {
  handleEditSchool: PropTypes.func,
  handleSchoolSelection: PropTypes.func,
  handleOnShareClicked: PropTypes.func,
  handleReset: PropTypes.func,
  primarySchool: CompareSchoolTypes,
  secondarySchool: CompareSchoolTypes
}

export default CompareSchoolsTable
