/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type ProfessorNoteHeader_teacher$ref: FragmentReference;
declare export opaque type ProfessorNoteHeader_teacher$fragmentType: ProfessorNoteHeader_teacher$ref;
export type ProfessorNoteHeader_teacher = {|
  +lastName: ?string,
  +$refType: ProfessorNoteHeader_teacher$ref,
|};
export type ProfessorNoteHeader_teacher$data = ProfessorNoteHeader_teacher;
export type ProfessorNoteHeader_teacher$key = {
  +$data?: ProfessorNoteHeader_teacher$data,
  +$fragmentRefs: ProfessorNoteHeader_teacher$ref,
};
*/


const node/*: ReaderFragment*/ = {
  "kind": "Fragment",
  "name": "ProfessorNoteHeader_teacher",
  "type": "Teacher",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "lastName",
      "args": null,
      "storageKey": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '9ac72a1266a1d25e07b8af720ee5400b';
module.exports = node;
