/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type ProfessorNoteSection_teacher$ref = any;
type RatingFooter_teacher$ref = any;
type RatingSuperHeader_teacher$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type Rating_teacher$ref: FragmentReference;
declare export opaque type Rating_teacher$fragmentType: Rating_teacher$ref;
export type Rating_teacher = {|
  +$fragmentRefs: RatingFooter_teacher$ref & RatingSuperHeader_teacher$ref & ProfessorNoteSection_teacher$ref,
  +$refType: Rating_teacher$ref,
|};
export type Rating_teacher$data = Rating_teacher;
export type Rating_teacher$key = {
  +$data?: Rating_teacher$data,
  +$fragmentRefs: Rating_teacher$ref,
};
*/


const node/*: ReaderFragment*/ = {
  "kind": "Fragment",
  "name": "Rating_teacher",
  "type": "Teacher",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "RatingFooter_teacher",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "RatingSuperHeader_teacher",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "ProfessorNoteSection_teacher",
      "args": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '1d11468b911a4d21fe1b3dfcc32d3c17';
module.exports = node;
