import { RecordSource, Store } from 'relay-runtime'
import { Component, Children } from 'react'
import PropTypes from 'prop-types'

class RelayStore extends Component {
  constructor(props) {
    super(props)
    if (props.store) {
      RelayStore.store = props.store
    }
  }

  render() {
    const { children } = this.props
    return Children.only(children)
  }
}

RelayStore.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  store: PropTypes.object,
  children: PropTypes.node
}

// eslint-disable-next-line no-underscore-dangle
const initialRecords = typeof window !== 'undefined' && window.__RELAY_STORE__
RelayStore.store = new Store(new RecordSource(initialRecords))

export default RelayStore
