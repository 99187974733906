import { graphql } from 'react-relay'
import parseAndSanitize from '@utils/parseAndSanitize'
import { matchPath } from 'react-router'
import ROUTE from '@enums/ROUTE'

export const rateTeacherMutation = graphql`
  mutation RateTeacherMutation(
    $forCredit: Boolean
    $attendanceMandatory: Boolean
    $tags: [ID]
    $class: String!
    $wouldTakeAgain: Boolean!
    $usesTextbooks: Boolean
    $teacherID: ID!
    $rating: Int!
    $difficulty: Int!
    $online: Boolean
    $grade: String
    $comment: UserComment!
    $recaptcha: String!
    $compID: String!
  ) {
    rateTeacher(
      forCredit: $forCredit
      attendanceMandatory: $attendanceMandatory
      tags: $tags
      class: $class
      wouldTakeAgain: $wouldTakeAgain
      usesTextbooks: $usesTextbooks
      teacherID: $teacherID
      rating: $rating
      difficulty: $difficulty
      online: $online
      grade: $grade
      comment: $comment
      recaptcha: $recaptcha
      compID: $compID
    ) {
      validation {
        ...FormController_validation
      }
    }
  }
`

export const editRateTeacherMutation = graphql`
  mutation RateTeacherEditMutation(
    $forCredit: Boolean
    $attendanceMandatory: Boolean
    $tags: [ID]
    $class: String!
    $wouldTakeAgain: Boolean!
    $usesTextbooks: Boolean
    $teacherID: ID!
    $ID: ID!
    $rating: Int!
    $difficulty: Int!
    $online: Boolean
    $grade: String
    $comment: UserComment!
  ) {
    editTeacherRating(
      forCredit: $forCredit
      attendanceMandatory: $attendanceMandatory
      tags: $tags
      class: $class
      wouldTakeAgain: $wouldTakeAgain
      usesTextbooks: $usesTextbooks
      ID: $ID
      teacherID: $teacherID
      rating: $rating
      difficulty: $difficulty
      online: $online
      grade: $grade
      comment: $comment
    ) {
      validation {
        ...FormController_validation
      }
    }
  }
`

export const addRatingTeacherQuery = graphql`
  query RateTeacherQuery($id: ID!) {
    teacherTags: teacherTags {
      name
      id
      active
    }
    teacher: node(id: $id) {
      ... on Teacher {
        firstName
        lastName
        id
        legacyId
        school {
          id
          legacyId
        }
        ...StickyHeaderContent_teacher
        ...CourseCode_teacher
      }
    }
  }
`

export const editRatingTeacherQuery = graphql`
  query RateTeacherEditQuery($id: ID!, $ratingId: ID) {
    teacherTags: teacherTags {
      name
      id
      active
    }
    teacher: node(id: $id) {
      ... on Teacher {
        firstName
        lastName
        id
        legacyId
        school {
          id
          legacyId
        }
        ...StickyHeaderContent_teacher
        ...CourseCode_teacher
      }
    }
    rating: node(id: $ratingId) {
      ... on Rating {
        id
        class
        isForOnlineClass
        qualityRating
        difficultyRatingRounded
        iWouldTakeAgain
        isForCredit
        textbookIsUsed
        attendanceMandatory
        grade
        comment
        ratingTags
      }
    }
  }
`

export const getVariables = location => {
  const match = matchPath(location.pathname, {
    path: [ROUTE.NEW_ADD_RATING_TEACHER, ROUTE.NEW_EDIT_RATING_TEACHER]
  })

  if (!match?.params?.tid) return { id: '' }

  return {
    id: btoa(`Teacher-${match.params.tid}`),
    ratingId: match?.params?.rid ? btoa(`Rating-${match.params.rid}`) : null
  }
}

export const checkTypeBool = val => {
  return typeof val === 'boolean'
}

export const checkTypeString = val => {
  return typeof val === 'string'
}

export const generateExistingData = rating => {
  const existingData = {
    class: rating.class,
    online: rating.isForOnlineClass,
    rating: rating.qualityRating,
    difficulty: rating.difficultyRatingRounded,
    wouldTakeAgain: checkTypeBool(rating.iWouldTakeAgain) && rating.iWouldTakeAgain ? 'Yes' : 'No',
    forCredit: checkTypeBool(rating.isForCredit) && rating.isForCredit ? 'Yes' : 'No',
    usesTextbooks: checkTypeBool(rating.textbookIsUsed) && rating.textbookIsUsed ? 'Yes' : 'No',
    attendanceMandatory:
      checkTypeString(rating.attendanceMandatory) && rating.attendanceMandatory === 'mandatory'
        ? 'Yes'
        : 'No',
    grade: rating.grade,
    comment: parseAndSanitize(rating.comment)
  }

  const existingTags = rating.ratingTags && rating.ratingTags
  if (existingTags) {
    const existingTagArray =
      existingTags && existingTags.includes('--')
        ? existingTags.split('--')
        : existingTags.split(',')
    existingTagArray.forEach(tag => {
      existingData[tag.split(' ').join('')] = true
    })
  }
  return existingData
}
