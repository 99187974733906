import ROUTE from '@enums/ROUTE'
import { matchPath } from 'react-router'

const getVariables = location => {
  const match = matchPath(location.pathname, {
    path: [ROUTE.RATING]
  })

  if (!match?.params?.rid) return { id: '' }

  return {
    rid: btoa(`Rating-${match.params.rid}`)
  }
}

export default getVariables
