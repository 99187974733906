import React from 'react'
import { graphql } from 'react-relay'
import PropTypes from 'prop-types'
import Form from '@components/Form/Form'
import LABEL_STYLE from '@enums/LABEL_STYLE'
import FormHeader from '@components/Form/FormHeader'
import FormArea from '@components/Form/FormArea'
import FormButtonsArea from '@components/Form/FormButtonsArea'
import CancelButton from '@components/Form/Buttons/CancelButton'
import SubmitButton from '@components/Form/Buttons/SubmitButton'
import SETTINGS_FORM_MODE from '@enums/SETTINGS_FORM_MODE'
import UserPagesTextInput from '@components/UserPagesTextInput'

const pwMutation = graphql`
  mutation UpdateAccountPasswordFormMutation($oldPassword: String!, $password: String!) {
    userUpdatePassword(oldPassword: $oldPassword, password: $password) {
      user {
        id
        Email
      }

      validation {
        ...FormController_validation
      }
    }
  }
`

export function UpdateAccountPasswordForm({ setFormMode }) {
  return (
    <FormArea>
      <Form mutation={pwMutation} onSuccess={() => setFormMode(SETTINGS_FORM_MODE.NORMAL)}>
        <FormHeader>Update Password</FormHeader>
        <UserPagesTextInput
          isPassword
          label="Old Password"
          name="oldPassword"
          labelStyle={LABEL_STYLE.LEFT}
        />
        <UserPagesTextInput
          isPassword
          label="New Password"
          name="password"
          labelStyle={LABEL_STYLE.LEFT}
        />
        <FormButtonsArea>
          <SubmitButton type="submit">Update Password</SubmitButton>
          <CancelButton onClick={() => setFormMode(SETTINGS_FORM_MODE.NORMAL)}>Cancel</CancelButton>
        </FormButtonsArea>
      </Form>
    </FormArea>
  )
}

UpdateAccountPasswordForm.propTypes = {
  setFormMode: PropTypes.func
}

export default UpdateAccountPasswordForm
