/* eslint-disable react/forbid-prop-types */
import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import ReCAPTCHA from '@lib/ReCAPTCHA'
import { Button } from 'storybook-rmp'
import { useFormikContext } from 'formik'

export const clickHandler = async (e, action, formik) => {
  e.preventDefault()
  const errors = await formik.validateForm()

  if (Object.keys(errors).length === 0) {
    ReCAPTCHA.Execute(action, token => {
      formik.setFieldValue('recaptcha', token)
      formik.submitForm()
    })
  }
}

const FormSubmitButton = ({ action, name }) => {
  const formik = useFormikContext()

  const id = `recaptcha-${name}`

  useEffect(() => {
    ReCAPTCHA.Load(id)
    return () => ReCAPTCHA.Remove()
  }, [])

  /**
   * We want to validate the form on initial render so that we can ensure the
   * submit button is disabled
   */
  useEffect(() => {
    formik.validateForm()
  }, [])

  return (
    <Button
      type="button"
      marginTop={16}
      onClick={e => clickHandler(e, action, formik)}
      disabled={!formik.isValid}
    >
      Submit
    </Button>
  )
}

FormSubmitButton.propTypes = {
  name: PropTypes.string,
  action: PropTypes.oneOf(Object.values(ReCAPTCHA.ACTIONS)).isRequired
}

export default FormSubmitButton
