import React, { useState, useEffect } from 'react'
import styled, { css } from 'styled-components'
import commitMutation from '@relay/commitMutation'
import { graphql } from 'react-relay'
import MODALS from '@enums/MODALS'
import TextInput from '@components/Form/TextInput'
import Form from '@components/Form/Form'
import ReCAPTCHA from '@lib/ReCAPTCHA'
import { poppinsBoldest3x } from '@StyledComponents/theme/typesets'
import FormRecaptcha3Button from '@components/Form/FormReCaptcha3Button'
import { ButtonAttributes } from '@StyledComponents/Buttons'
import AuthModal from './Templates/SmallModal'

const resetPasswordMutation = graphql`
  mutation ResetPasswordModalResetEmailMutation(
    $password: String!
    $confirmPassword: String!
    $token: String!
    $recaptcha: String!
  ) {
    resetPassword(
      password: $password
      confirmPassword: $confirmPassword
      token: $token
      recaptcha: $recaptcha
    ) {
      validation {
        ...FormController_validation
      }
    }
  }
`

const getEmailFromTokenMutation = graphql`
  mutation ResetPasswordModalGetEmailMutation($token: String!) {
    userEmailFromResetToken(token: $token) {
      email
    }
  }
`

const ResetPasswordHeader = styled.div`
  margin-bottom: 16px;
  text-align: center;

  ${poppinsBoldest3x};
`

const SubmitButton = styled(FormRecaptcha3Button)`
  font-size: ${({ theme }) => theme.fontSize.medium};
  height: 48px;
  margin-top: 23px;
  ${ButtonAttributes}
  width: 100%;
`

const resetPasswordBodyStyles = css`
  font-size: ${({ theme }) => theme.fontSize.medium};
  margin-bottom: 16px;
`

const ResetPasswordPromptBody = styled.div`
  display: flex;
  flex-direction: column;

  ${resetPasswordBodyStyles};

  b {
    margin-top: 5px;
  }

  span {
    margin-bottom: 10px;
  }
`

const ResetPasswordSuccessBody = styled.div`
  ${resetPasswordBodyStyles};
`

export const getBodyCopy = (didReset, email) => {
  if (didReset) {
    return (
      <ResetPasswordSuccessBody>
        You’ve successfuly reset your password. Please login with your new credentials.
      </ResetPasswordSuccessBody>
    )
  }

  if (!email) return <ResetPasswordPromptBody>Create a new password.</ResetPasswordPromptBody>

  return (
    <ResetPasswordPromptBody>
      Create a new password for <br />
      <b>{email}</b>
    </ResetPasswordPromptBody>
  )
}

export default function ResetPasswordModal() {
  const params = new URLSearchParams(window.location.search)
  const token = params.get('token')

  const [didReset, setDidReset] = useState(false)
  const [userEmail, setUserEmail] = useState(null)

  useEffect(() => {
    if (token && !userEmail) {
      commitMutation({
        mutation: getEmailFromTokenMutation,
        variables: { token },
        onCompleted: (result, err) => {
          setUserEmail(result?.userEmailFromResetToken?.email)
          if (err) {
            console.error(err)
          }
        }
      })
    }
  }, [token])

  return (
    <AuthModal modal={MODALS.RESET_PASSWORD} onClose={() => {}}>
      <ResetPasswordHeader>Reset Password</ResetPasswordHeader>
      {getBodyCopy(didReset, userEmail)}
      {!didReset && (
        <Form
          mutation={resetPasswordMutation}
          getVariables={model => ({ ...model, token })}
          onSuccess={() => setDidReset(true)}
        >
          <TextInput name="password" label="New password" isPassword />
          <TextInput name="confirmPassword" label="Confirm new password" isPassword />
          <SubmitButton action={ReCAPTCHA.ACTIONS.RESET_PASSWORD}>Reset Password</SubmitButton>
        </Form>
      )}
    </AuthModal>
  )
}
