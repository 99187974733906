import React from 'react'
import { graphql } from 'react-relay'
import PropTypes from 'prop-types'
import Form from '@components/Form/Form'
import LABEL_STYLE from '@enums/LABEL_STYLE'
import CancelButton from '@components/Form/Buttons/CancelButton'
import SubmitButton from '@components/Form/Buttons/SubmitButton'
import FormHeader from '@components/Form/FormHeader'
import FormArea from '@components/Form/FormArea'
import FormButtonsArea from '@components/Form/FormButtonsArea'
import SETTINGS_FORM_MODE from '@enums/SETTINGS_FORM_MODE'
import UserPagesTextInput from '@components/UserPagesTextInput'

const emailMutation = graphql`
  mutation UpdateAccountEmailFormsMutation($password: String!, $email: String!) {
    userUpdateEmail(password: $password, email: $email) {
      user {
        id
        Email
      }

      validation {
        ...FormController_validation
      }
    }
  }
`

export function UpdateAccountForms({ setFormMode }) {
  return (
    <FormArea>
      <FormHeader>Update Email</FormHeader>
      <Form mutation={emailMutation} onSuccess={() => setFormMode(SETTINGS_FORM_MODE.NORMAL)}>
        <UserPagesTextInput label="New Email" name="email" labelStyle={LABEL_STYLE.LEFT} />
        <UserPagesTextInput
          isPassword
          label="Password"
          name="password"
          labelStyle={LABEL_STYLE.LEFT}
        />
        <FormButtonsArea>
          <SubmitButton type="submit">Update Email</SubmitButton>
          <CancelButton onClick={() => setFormMode(SETTINGS_FORM_MODE.NORMAL)}>Cancel</CancelButton>
        </FormButtonsArea>
      </Form>
    </FormArea>
  )
}

UpdateAccountForms.propTypes = {
  setFormMode: PropTypes.func
}

export default UpdateAccountForms
