import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { getPageContainerMaxWidth } from '@StyledComponents/theme/helpers/breakpoints'

export const StyledPageWrapper = styled.div`
  margin-bottom: 58px;
  min-height: calc(
    100vh - ${props => props.theme.header.height + props.theme.leaderboard.height}px
  );
  ${({ theme }) => getPageContainerMaxWidth(theme)}
  overflow-x: hidden;
  overflow-y: hidden;
`

export default function PageWrapper({ children, className }) {
  return <StyledPageWrapper className={className}>{children}</StyledPageWrapper>
}

PageWrapper.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string
}
