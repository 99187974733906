/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type Thumbs_schoolRating$ref: FragmentReference;
declare export opaque type Thumbs_schoolRating$fragmentType: Thumbs_schoolRating$ref;
export type Thumbs_schoolRating = {|
  +id: ?string,
  +legacyId: ?number,
  +thumbsDownTotal: ?number,
  +thumbsUpTotal: ?number,
  +userThumbs: ?$ReadOnlyArray<?{|
    +computerId: ?string,
    +thumbsUp: ?number,
    +thumbsDown: ?number,
  |}>,
  +$refType: Thumbs_schoolRating$ref,
|};
export type Thumbs_schoolRating$data = Thumbs_schoolRating;
export type Thumbs_schoolRating$key = {
  +$data?: Thumbs_schoolRating$data,
  +$fragmentRefs: Thumbs_schoolRating$ref,
};
*/


const node/*: ReaderFragment*/ = {
  "kind": "Fragment",
  "name": "Thumbs_schoolRating",
  "type": "SchoolRating",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "id",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "legacyId",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "thumbsDownTotal",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "thumbsUpTotal",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "userThumbs",
      "storageKey": null,
      "args": null,
      "concreteType": "CampusRatingThumb",
      "plural": true,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "computerId",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "thumbsUp",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "thumbsDown",
          "args": null,
          "storageKey": null
        }
      ]
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = 'e855afe7a1298c465065b97d18477da7';
module.exports = node;
