/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type SimilarProfessorListItem_teacher$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type SimilarProfessors_teacher$ref: FragmentReference;
declare export opaque type SimilarProfessors_teacher$fragmentType: SimilarProfessors_teacher$ref;
export type SimilarProfessors_teacher = {|
  +department: ?string,
  +relatedTeachers: ?$ReadOnlyArray<?{|
    +legacyId: ?number,
    +$fragmentRefs: SimilarProfessorListItem_teacher$ref,
  |}>,
  +$refType: SimilarProfessors_teacher$ref,
|};
export type SimilarProfessors_teacher$data = SimilarProfessors_teacher;
export type SimilarProfessors_teacher$key = {
  +$data?: SimilarProfessors_teacher$data,
  +$fragmentRefs: SimilarProfessors_teacher$ref,
};
*/


const node/*: ReaderFragment*/ = {
  "kind": "Fragment",
  "name": "SimilarProfessors_teacher",
  "type": "Teacher",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "department",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "relatedTeachers",
      "storageKey": null,
      "args": null,
      "concreteType": "RelatedTeacher",
      "plural": true,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "legacyId",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "FragmentSpread",
          "name": "SimilarProfessorListItem_teacher",
          "args": null
        }
      ]
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '00bc8273c8b7e441e600cc6545ff8e2b';
module.exports = node;
