/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type RatingSuperHeader_teacher$ref: FragmentReference;
declare export opaque type RatingSuperHeader_teacher$fragmentType: RatingSuperHeader_teacher$ref;
export type RatingSuperHeader_teacher = {|
  +firstName: ?string,
  +lastName: ?string,
  +legacyId: ?number,
  +school: ?{|
    +name: ?string
  |},
  +$refType: RatingSuperHeader_teacher$ref,
|};
export type RatingSuperHeader_teacher$data = RatingSuperHeader_teacher;
export type RatingSuperHeader_teacher$key = {
  +$data?: RatingSuperHeader_teacher$data,
  +$fragmentRefs: RatingSuperHeader_teacher$ref,
};
*/


const node/*: ReaderFragment*/ = {
  "kind": "Fragment",
  "name": "RatingSuperHeader_teacher",
  "type": "Teacher",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "firstName",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "lastName",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "legacyId",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "school",
      "storageKey": null,
      "args": null,
      "concreteType": "School",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "name",
          "args": null,
          "storageKey": null
        }
      ]
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '4c4f87327dd92c5d34c43fbe045378be';
module.exports = node;
