import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { CircularProgress } from '@rmwc/circular-progress'
import { Button } from './Buttons'

export const StyledLoadingIcon = styled(CircularProgress)`
  /* Needed to override rmwc css */
  &&& {
    color: white;
    margin-right: 15px;
  }
`

export const StyledPaginationButton = styled(Button)`
  margin: 40px auto 40px auto;
  text-align: center;
`

const PaginationButton = ({ isLoading, hasMore, children, onClick }) => {
  if (isLoading) {
    return (
      <StyledPaginationButton disabled>
        <StyledLoadingIcon />
        Loading
      </StyledPaginationButton>
    )
  }

  if (hasMore) {
    return <StyledPaginationButton onClick={onClick}>{children}</StyledPaginationButton>
  }

  return null
}

PaginationButton.propTypes = {
  isLoading: PropTypes.bool,
  hasMore: PropTypes.bool,
  children: PropTypes.string,
  onClick: PropTypes.func
}

export default PaginationButton
