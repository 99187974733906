import PropTypes from 'prop-types'
import SchoolTypes from './School.types'
import TeacherTypes from './Teacher.types'

const teachers = PropTypes.shape({ edges: PropTypes.arrayOf(TeacherTypes) })
const schools = PropTypes.shape({ edges: PropTypes.arrayOf(SchoolTypes) })

const SearchResultsTypes = PropTypes.shape({
  teachers,
  schools
})

export default SearchResultsTypes
