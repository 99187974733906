import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import RelayTypes from '@types/Relay.types'
import { createPaginationContainer, graphql } from 'react-relay'
import TeacherTypes from '@types/Teacher.types'
import SchoolTypes from '@types/School.types'
import useRelayIsLoading from '@hooks/useRelayIsLoading'
import SEARCH_ITEM_TYPES from '@enums/SEARCHITEMTYPES'
import { getOptions } from '@components/TeacherDepartmentFilter'
import SearchError from '@components/SearchError'
import SearchResultsPage from './SearchResultsPage'

export const StyledBlankPage = styled.div`
  min-height: 100vh;
`

export const TeacherSearchPagination = props => {
  // eslint-disable-next-line react/prop-types
  const {
    search,
    relay,
    query,
    school,
    setFilter,
    filter,
    getFilters,
    preFilterId,
    successPageData
  } = props
  const [isLoading, setIsLoading] = useRelayIsLoading(relay)
  const filters = search?.teachers?.filters
  const loadMore = () => {
    setIsLoading(true)
    relay.loadMore(8, error => {
      if (error) console.error('Error', error)
      setIsLoading(false)
    })
  }

  const searchIsDown = search?.teachers === null
  const schoolTeacherNoMatch = search?.teachers?.didFallback
  const results = search?.teachers?.edges
  const resultsCount = search?.teachers?.resultCount || 0

  React.useEffect(() => {
    if (preFilterId && filters) {
      setFilter(getOptions(filters[0].options).find(option => option.id === preFilterId))
    }
  }, [])

  if (searchIsDown) return <SearchError />
  if (!results) return <StyledBlankPage>Loading...</StyledBlankPage>
  return (
    <SearchResultsPage
      {...props}
      count={resultsCount}
      results={results}
      loadMore={loadMore}
      isLoading={isLoading}
      filters={getFilters(filters)}
      filter={filter}
      setFilter={setFilter}
      school={school}
      successPageData={successPageData}
      itemType={SEARCH_ITEM_TYPES.TEACHERS}
      query={query}
      schoolTeacherNoMatch={schoolTeacherNoMatch}
    />
  )
}

TeacherSearchPagination.propTypes = {
  search: PropTypes.shape({
    teachers: PropTypes.shape({
      didFallback: PropTypes.bool,
      edges: PropTypes.arrayOf(TeacherTypes),
      resultCount: PropTypes.number,
      filters: PropTypes.arrayOf(
        PropTypes.shape({
          field: PropTypes.string,
          options: PropTypes.arrayOf(
            PropTypes.shape({ count: PropTypes.number, value: PropTypes.string })
          )
        })
      )
    })
  }),
  relay: RelayTypes,
  school: SchoolTypes,
  headerTextComponent: PropTypes.node,
  query: PropTypes.shape({
    text: PropTypes.string,
    schoolID: PropTypes.string,
    fallback: PropTypes.bool,
    departmentID: PropTypes.string
  }),
  setFilter: PropTypes.func,
  preFilterId: PropTypes.string,
  filter: PropTypes.shape({
    id: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.string
  }),
  setQueryVariables: PropTypes.func,
  getFilters: PropTypes.func,
  successPageData: PropTypes.oneOfType([
    PropTypes.shape({
      teacher: TeacherTypes
    }),
    PropTypes.shape({
      school: SchoolTypes
    })
  ])
}

export default createPaginationContainer(
  TeacherSearchPagination,
  {
    search: graphql`
      fragment TeacherSearchPagination_search on newSearch
        @argumentDefinitions(
          count: { type: "Int", defaultValue: 8 }
          cursor: { type: "String", defaultValue: null }
          query: {
            type: "TeacherSearchQuery"
            defaultValue: { text: "", schoolID: "", departmentID: "", fallback: true }
          }
        ) {
        teachers(query: $query, first: $count, after: $cursor)
          @connection(key: "TeacherSearchPagination_teachers") {
          didFallback
          edges {
            cursor
            node {
              ...TeacherCard_teacher
            }
          }
          pageInfo {
            hasNextPage
            endCursor
          }
          resultCount
          filters {
            field
            options {
              value
              id
            }
          }
        }
      }
    `
  },
  {
    direction: 'forward',
    getConnectionFromProps(props) {
      return props.search.teachers
    },
    getVariables(props, { count, cursor }) {
      return {
        query: props.query,
        count,
        cursor
      }
    },
    query: graphql`
      query TeacherSearchPaginationQuery(
        $count: Int!
        $cursor: String
        $query: TeacherSearchQuery!
      ) {
        search: newSearch {
          ...TeacherSearchPagination_search
            @arguments(count: $count, cursor: $cursor, query: $query)
        }
      }
    `
  }
)
