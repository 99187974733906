import React from 'react'
import styled, { css } from 'styled-components'
import { createFragmentContainer, graphql } from 'react-relay'
import USERTYPES from '@enums/USERTYPES'
import UserTypes from '@types/User.types'
import { USER_ACCOUNT_ROUTE } from '@enums/ROUTE'
import { Link as RouterLink, useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'
import { breakpointSmallPlusAndBelow } from './StyledComponents/theme/helpers/breakpoints'

export const TabsArea = styled.div`
  border-bottom: 1px solid ${props => props.theme.color.gray9};
  display: flex;
  margin: auto;
  max-width: 755px;
  overflow: auto;
  white-space: nowrap;
  &::-webkit-scrollbar {
    height: 2px;
    width: 0.2em;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${props => props.theme.color.default};
  }
`

export const StyledTab = styled.div`
  cursor: pointer;
  display: flex;

  /* Best compromise trial and error value to keep tabs from resizing when bolded*/
  letter-spacing: 0.0425em;

  margin: 0 50px 0 0;
  padding: 0 3px;

  ${props =>
    props.activeTab &&
    css`
      border-bottom: 2px solid ${props.theme.color.default};
      font-weight: ${props.theme.fontWeight.bold};
      letter-spacing: 0em;
      /* Best compromise trial and error value to keep tabs from resizing when bolded*/
      word-spacing: 0.05em;
      ${breakpointSmallPlusAndBelow(css`
        border-bottom: 0;
      `)}
    `};
`

const TabRouterLink = styled(RouterLink)`
  font-family: ${props => props.theme.fontFamily.helveticaNeue};
  font-size: ${props => props.theme.fontSize.medium};
  line-height: 34px;
  text-decoration: none;
  &:hover {
    font-weight: ${props => props.theme.fontWeight.bold};
    letter-spacing: 0em;
    word-spacing: 0.05em;
  }
`

function Tab({ tabPath, tabCopy }) {
  const { pathname } = useLocation()
  return (
    <StyledTab role="tab" activeTab={pathname === tabPath}>
      <TabRouterLink to={tabPath}>{tabCopy}</TabRouterLink>
    </StyledTab>
  )
}

Tab.propTypes = {
  tabPath: PropTypes.string,
  tabCopy: PropTypes.string
}

export function UserPagesTabs({ user }) {
  return (
    <TabsArea>
      <Tab tabPath={USER_ACCOUNT_ROUTE.PROFILE} tabCopy="Profile" />
      <Tab tabPath={USER_ACCOUNT_ROUTE.SETTINGS} tabCopy="Account Settings" />
      {user.Type === USERTYPES.STUDENT && (
        <React.Fragment>
          <Tab tabPath={USER_ACCOUNT_ROUTE.RATINGS} tabCopy="Ratings" />
          <Tab tabPath={USER_ACCOUNT_ROUTE.SAVED_PROFESSORS} tabCopy="Saved Professors" />
        </React.Fragment>
      )}
    </TabsArea>
  )
}

UserPagesTabs.propTypes = {
  user: UserTypes
}

export default createFragmentContainer(UserPagesTabs, {
  user: graphql`
    fragment UserPagesTabs_user on User {
      Type
    }
  `
})
