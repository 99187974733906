import React from 'react'
import PropTypes from 'prop-types'
import { TextInput, Error } from 'storybook-rmp'

const NameInput = ({ id, name, label, value, onChange, onBlur, error }) => {
  return (
    <React.Fragment>
      <TextInput
        id={id}
        name={name}
        label={label}
        aria-label={label}
        value={value}
        labelStyle="top"
        onChange={onChange}
        onBlur={onBlur}
      />
      {error && <Error error={error} />}
    </React.Fragment>
  )
}

NameInput.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  error: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func
}

export default NameInput
