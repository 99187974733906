import React from 'react'
import InfoPage from '../components/PageTemplates/InfoPage'

export default function TeacherNotFound() {
  return (
    <InfoPage
      header="Professor Not Found"
      subheader="We couldn't find the professor you were looking for. Try searching for someone else!"
      pageTitle="Professor not found | Rate My Professors"
    />
  )
}
