import React from 'react'
import styled from 'styled-components'
import rateTeacherModel from '@pages/AddRating/RateTeacher/RateTeacher.model'
import FormSelect from '@components/Form/FormSelect'

const StyledDropdownContainer = styled.div`
  display: flex;
  justify-content: center;
`

export const StyledFormSelect = styled(FormSelect)`
  width: 311px;
`

const GradeDropdown = () => {
  const { name, options, placeholder } = rateTeacherModel.gradeDropdown
  return (
    <StyledDropdownContainer>
      <StyledFormSelect name={name} options={options} placeholder={placeholder} fullWidth />
    </StyledDropdownContainer>
  )
}

export default GradeDropdown
