const rateSchoolModel = {
  sliders: [
    {
      name: 'reputation',
      label: 'Reputation',
      isRequired: true,
      type: 'QUALITY'
    },
    {
      name: 'location',
      label: 'Location',
      isRequired: true,
      type: 'QUALITY'
    },
    {
      name: 'opportunities',
      label: 'Opportunities',
      isRequired: true,
      type: 'QUALITY'
    },
    {
      name: 'facilities',
      label: 'Facilities and common areas',
      isRequired: true,
      type: 'QUALITY'
    },
    {
      name: 'internet',
      label: 'Internet',
      isRequired: true,
      type: 'QUALITY'
    },
    {
      name: 'food',
      label: 'Food',
      isRequired: true,
      type: 'QUALITY'
    },
    {
      name: 'clubs',
      label: 'Clubs',
      isRequired: true,
      type: 'QUALITY'
    },
    {
      name: 'social',
      label: 'Social',
      isRequired: true,
      type: 'QUALITY'
    },
    {
      name: 'happiness',
      label: 'Happiness',
      isRequired: true,
      type: 'QUALITY'
    },
    {
      name: 'safety',
      label: 'Safety',
      isRequired: true,
      type: 'QUALITY'
    }
  ],
  writeReview: {
    label: 'Write a Review',
    subTitle:
      'Discuss your personal experience on this school. What’s great about it? What could use improvement?',
    name: 'comment',
    placeholder: 'What do you want other students to know about this school?',
    guidelines: [
      'Your rating could be removed if you use profanity or derogatory terms.',
      'Refer to the rating categories to help you better elaborate your comments.',
      'Don’t forget to proof read!'
    ]
  }
}

export default rateSchoolModel
