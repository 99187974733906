import React from 'react'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'
import { breakpointSmediumAndBelow } from '@StyledComponents/theme/helpers/breakpoints'
import logoSmall from '@assets/images/logos/small_rmp_logo_white.svg'
import ROUTE from '@enums/ROUTE'
import Link from '@components/Link'

export const StyledLogo = styled(Link)`
  align-content: center;
  background-repeat: no-repeat;
  background-size: contain;
  display: ${props => (props.mobile ? 'none' : 'flex')};
  flex-direction: row;
  justify-content: center;
  margin-bottom: 0px;
  padding-right: 16px;
  z-index: ${({ theme }) => theme.zIndex.footerActive + 1};

  ${breakpointSmediumAndBelow(
    css`
      margin-bottom: 16px;
    `
  )}

  img {
    height: 26px;
    width: 46px;
  }
`

export default function FooterLogo({ mobile }) {
  return (
    <StyledLogo href={ROUTE.HOME} mobile={mobile}>
      <img src={logoSmall} alt="Logo" />
    </StyledLogo>
  )
}

FooterLogo.propTypes = {
  mobile: PropTypes.string
}
