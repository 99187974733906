/* stylelint-disable max-nesting-depth */
import React from 'react'
import styled, { css } from 'styled-components'
import TextInput from '@components/Form/TextInput'
import { StyledTextFieldWrapper } from '@StyledComponents/InputWrapper'
import { InputIconButton } from '@StyledComponents/TextField'
import { StyledErrorContainer } from './Form/FieldWrapper'
import {
  breakpointSmallAndBelow,
  breakpointTinyAndBelow,
  breakpointMediumAndBelow
} from './StyledComponents/theme/helpers/breakpoints'

const StyledTextInput = styled(TextInput)`
  padding-bottom: 20px;
  padding-right: 100px;
  ${StyledTextFieldWrapper} {
    justify-content: flex-start;

    ${breakpointMediumAndBelow(
      css`
        align-items: flex-start;
        flex-direction: column;
        height: auto;
        text-align: left;
        width: fit-content;
      `
    )}

    label {
      line-height: 24px;
      width: 300px;
    }
    input {
      padding: 12px 8px;
      width: 360px;
      ${breakpointSmallAndBelow(
        css`
          width: inherit;
        `
      )}
      ${breakpointTinyAndBelow(
        css`
          width: auto;
        `
      )}
    }

    ${InputIconButton} {
      bottom: 0;
      position: relative;
      right: 32px;
    }

    ${StyledErrorContainer} {
      padding-right: 100px;
      position: unset;
      text-align: right;

      ${breakpointMediumAndBelow(
        css`
          text-align: left;
          padding-right: 0;
        `
      )}
      span {
        position: unset;
      }
    }
  }
`

const UserPagesTextInput = props => {
  return <StyledTextInput {...props} />
}

export default UserPagesTextInput
