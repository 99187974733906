/**
 * @flow
 * @relayHash 30dff0945baea12bce8ce693531c3abe
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type TeacherBookmarkMutationVariables = {|
  id: string
|};
export type TeacherBookmarkMutationResponse = {|
  +userToggleSavedProfessor: ?{|
    +validation: ?{|
      +id: ?string
    |},
    +teacher: ?{|
      +isSaved: ?boolean
    |},
  |}
|};
export type TeacherBookmarkMutation = {|
  variables: TeacherBookmarkMutationVariables,
  response: TeacherBookmarkMutationResponse,
|};
*/


/*
mutation TeacherBookmarkMutation(
  $id: ID!
) {
  userToggleSavedProfessor(id: $id) {
    validation {
      id
    }
    teacher {
      isSaved
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "id",
    "type": "ID!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "validation",
  "storageKey": null,
  "args": null,
  "concreteType": "ValidationResult",
  "plural": false,
  "selections": [
    (v2/*: any*/)
  ]
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "isSaved",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "TeacherBookmarkMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "userToggleSavedProfessor",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "UserToggleSavedProfessorMutationResponse",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "teacher",
            "storageKey": null,
            "args": null,
            "concreteType": "Teacher",
            "plural": false,
            "selections": [
              (v4/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "TeacherBookmarkMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "userToggleSavedProfessor",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "UserToggleSavedProfessorMutationResponse",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "teacher",
            "storageKey": null,
            "args": null,
            "concreteType": "Teacher",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v2/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "mutation",
    "name": "TeacherBookmarkMutation",
    "id": null,
    "text": "mutation TeacherBookmarkMutation(\n  $id: ID!\n) {\n  userToggleSavedProfessor(id: $id) {\n    validation {\n      id\n    }\n    teacher {\n      isSaved\n      id\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '588aafaa77a9118f4bea0ab2098c04c8';
module.exports = node;
