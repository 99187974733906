import React from 'react'
import PropTypes from 'prop-types'
import { createPaginationContainer, graphql } from 'react-relay'
import TeacherTypes from '@types/Teacher.types'
import SchoolTypes from '@types/School.types'
import useRelayIsLoading from '@hooks/useRelayIsLoading'
import SearchError from '@components/SearchError'
import SearchResultsPage from './SearchResultsPage'
import { StyledBlankPage } from './TeacherSearchPagination'

export const SchoolSearchPagination = props => {
  const { search, relay, successPageData } = props

  const [isLoading, setIsLoading] = useRelayIsLoading(relay)

  const loadMore = () => {
    setIsLoading(true)
    relay.loadMore(8, error => {
      if (error) console.error('Error', error)
      setIsLoading(false)
    })
  }

  const searchIsDown = search?.schools === null
  const results = search?.schools?.edges
  const count = search?.schools?.resultCount || 0

  if (searchIsDown) return <SearchError />
  if (!results) return <StyledBlankPage>Loading...</StyledBlankPage>
  return (
    <SearchResultsPage
      {...props}
      count={count}
      results={results}
      loadMore={loadMore}
      isLoading={isLoading}
      successPageData={successPageData}
    />
  )
}

SchoolSearchPagination.propTypes = {
  search: PropTypes.shape({
    schools: PropTypes.shape({
      edges: PropTypes.arrayOf(TeacherTypes),
      resultCount: PropTypes.number
    })
  }),
  relay: PropTypes.shape({
    hasMore: PropTypes.func,
    isLoading: PropTypes.func,
    loadMore: PropTypes.func
  }),
  successPageData: PropTypes.shape({
    school: SchoolTypes
  })
}

export default createPaginationContainer(
  SchoolSearchPagination,
  {
    search: graphql`
      fragment SchoolSearchPagination_search on newSearch
        @argumentDefinitions(
          count: { type: "Int", defaultValue: 8 }
          cursor: { type: "String", defaultValue: null }
          query: { type: "SchoolSearchQuery", defaultValue: { text: "" } }
        ) {
        schools(query: $query, first: $count, after: $cursor)
          @connection(key: "SchoolSearchPagination_schools") {
          edges {
            cursor
            node {
              name
              ...SchoolCard_school
            }
          }
          pageInfo {
            hasNextPage
            endCursor
          }
          resultCount
        }
      }
    `
  },
  {
    direction: 'forward',
    getConnectionFromProps(props) {
      return props.search.schools
    },
    getVariables(props, { count, cursor }) {
      return {
        query: props.query,
        count,
        cursor
      }
    },
    query: graphql`
      query SchoolSearchPaginationQuery($count: Int!, $cursor: String, $query: SchoolSearchQuery!) {
        search: newSearch {
          ...SchoolSearchPagination_search @arguments(count: $count, cursor: $cursor, query: $query)
        }
      }
    `
  }
)
