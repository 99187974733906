/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import SearchResultsTypes from '@types/SearchResults.types'
import MenuItem from './MenuItem'

export const StyledTypeaheadItemList = styled.ul`
  list-style: none;
`

export default function TypeaheadItemList({
  results,
  queryValue,
  selectedIndex,
  itemType,
  isNewSearch,
  hideLink
}) {
  if (!queryValue || !results[itemType]) return null

  const items = results[itemType].edges

  if (!items || items.length === 0) return null

  return (
    <StyledTypeaheadItemList>
      {items.map((item, index) => {
        const itemNode = item.node
        return (
          <MenuItem
            key={itemNode.legacyId}
            index={index}
            itemType={itemType}
            item={itemNode}
            selectedIndex={selectedIndex}
            isNewSearch={isNewSearch}
            hideLink={hideLink}
          />
        )
      })}
    </StyledTypeaheadItemList>
  )
}

TypeaheadItemList.propTypes = {
  results: SearchResultsTypes,
  isNewSearch: PropTypes.bool,
  queryValue: PropTypes.string,
  selectedIndex: PropTypes.number,
  itemType: PropTypes.string,
  hideLink: PropTypes.bool
}
