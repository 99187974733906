import React from 'react'
import PropTypes from 'prop-types'
import { TOAST_EVENTS } from '@utils/toast'
import Toast, { ToastHeader, ToastDescription } from './Toast'

const SoftlockedTeacherToast = ({ closeToast, teacherName }) => {
  return (
    <Toast canDismiss toastEvent={TOAST_EVENTS.SOFT_LOCKED_TEACHER} closeToast={closeToast}>
      <ToastHeader>Alert</ToastHeader>
      <ToastDescription>
        Professor {teacherName}&apos;s profile is currently restricted to registered users. Please
        log in or create an account to leave a review.
      </ToastDescription>
    </Toast>
  )
}

SoftlockedTeacherToast.propTypes = {
  closeToast: PropTypes.func.isRequired,
  teacherName: PropTypes.string
}

export default SoftlockedTeacherToast
