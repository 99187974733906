import styled from 'styled-components'
import { helveticaBold } from '@StyledComponents/theme/typesets'

const Tag = styled.span`
  background-color: ${props => props.theme.color.gray};
  border-radius: 14.5px;
  font-stretch: condensed;
  margin: 0 16px 8px 0;
  padding: 8px 16px;
  text-align: center;
  text-transform: uppercase;

  ${helveticaBold}
`
export default Tag
