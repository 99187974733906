import React from 'react'
import { createFragmentContainer, graphql } from 'react-relay'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'
import { helveticaLight, poppinsBold3x } from '@components/StyledComponents/theme/typesets'
import { breakpointSmallAndBelow } from '@components/StyledComponents/theme/helpers/breakpoints'
import TeacherTypes from '@types/Teacher.types'
import { CircularBarChart } from 'storybook-rmp'
import RatingDistributionWrapper from '@components/RatingDistribution/RatingDistributionWrapper'
import svgCloseIcon from '@assets/images/close-icon.svg'
import CompareProfessorsHeaderBox from './CompareProfessorsHeaderBox'

export const CenteredContent = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;

  ${breakpointSmallAndBelow(css`
    max-width: 168px;
    min-height: 255px;
  `)}
`

const StyledColumn = styled(CenteredContent)`
  margin-right: 16px;
  position: relative;
  width: 100%;

  ${helveticaLight};

  &:last-child {
    margin-right: 0;
  }
`

const StyledColumnBox = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.color.white};
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  margin-top: 12px;
  padding: ${props => props.includePadding && `16px`};
  width: 100%;

  ${helveticaLight};
`

const BoxTitle = styled.div`
  font-size: 16px;
`

const DifficultyScore = styled.div`
  margin-top: 16px;
  min-width: 128px;
  text-align: center;
  ${poppinsBold3x}
`

const WouldTakeAgainPercent = styled.div`
  margin-top: 16px;
  min-width: 128px;
  text-align: center;
  ${poppinsBold3x}
`

const InfoBox = styled(StyledColumnBox)`
  ${props =>
    props.noProfessor &&
    css`
      max-height: 204px;
    `}
  ${breakpointSmallAndBelow(css`
    min-height: 286px;
    padding-top: 36px;
  `)}
`

const RatingsBarBox = styled(StyledColumnBox)`
  height: 100%;
  padding-top: 16px;
  width: 100%;
`

const DifficultyBox = styled(StyledColumnBox)``

const WouldTakeAgainBox = styled(StyledColumnBox)``

const ForCreditBox = styled(StyledColumnBox)``

const AttendanceBox = styled(StyledColumnBox)``

const NoRatingsBox = styled(StyledColumnBox)`
  height: 112px;
  padding-top: 16px;
`

const CompareProfessorsEmpty = styled(CenteredContent)`
  background-color: ${({ theme }) => theme.color.white};
  border: 1px dashed ${({ theme }) => theme.color.gray9};
  height: 206px;
  justify-content: center;
  margin-right: 16px;
  margin-top: 12px;
  width: 100%;

  ${helveticaLight};

  &:last-child {
    margin-right: 0;
  }
`

const CompareProfessorsEmptyText = styled.div`
  color: ${({ theme }) => theme.color.gray17};
  font-size: ${({ theme }) => theme.fontSize.teacherCardName};
  max-width: 236px;

  ${breakpointSmallAndBelow(css`
    max-width: 128px;
  `)}
`

const CircularBarChartContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  height: 124px;
  justify-content: center;
  margin-top: 16px;
  width: 224px;

  ${breakpointSmallAndBelow(css`
    width: 128px;
    height: 232px;
    flex-direction: column;
  `)}
`

const BulletPointContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 8px;
`

const BulletPoint = styled.div`
  background-color: ${props => props.color};
  border-radius: 50%;
  height: 12px;
  margin-right: 8px;
  width: 12px;
`

const BulletPoints = styled.div`
  width: 128px;
`

const BulletPointText = styled.div`
  font-size: 16px;
  font-weight: ${props => (props.bold ? 'bold' : null)};
`

export const StyledCloseIcon = styled.img`
  cursor: pointer;
  padding: 20px;
  position: absolute;
  right: 0px;
  top: 4px;
`

const ProfessorEmptyState = () => {
  return (
    <CompareProfessorsEmpty>
      <CompareProfessorsEmptyText>
        Look up a professor to add to comparison
      </CompareProfessorsEmptyText>
    </CompareProfessorsEmpty>
  )
}

const CompareProfessorsNoRatings = ({ teacher, handleClose, column }) => {
  return (
    <StyledColumn>
      <InfoBox includePadding noProfessor>
        <StyledCloseIcon
          data-testid={`close-button-${column}`}
          role="button"
          src={svgCloseIcon}
          alt="Close Icon"
          onClick={() => handleClose(column)}
        />
        <CompareProfessorsHeaderBox
          teacher={teacher}
          school={teacher.school}
          column={column}
          handleClose={handleClose}
        />
      </InfoBox>
      <NoRatingsBox data-testid="no-ratings-box">
        Professor {`${teacher.lastName}`} doesn’t have any ratings yet 😭
      </NoRatingsBox>
    </StyledColumn>
  )
}

CompareProfessorsNoRatings.propTypes = {
  teacher: TeacherTypes,
  handleClose: PropTypes.func,
  column: PropTypes.string
}

const CompareProfessorsColumn = ({ teacher, column, handleClose }) => {
  const getBulletPoints = bullets => {
    return bullets.map((bullet, index) => {
      return (
        <BulletPointContainer key={index}>
          <BulletPoint color={bullet.color} />
          <BulletPointText>{bullet.title}</BulletPointText>
          <BulletPointText bold>{bullet.total}</BulletPointText>
        </BulletPointContainer>
      )
    })
  }

  const calculatePercent = ({ value, total }) => {
    if (!value || !total) return 0
    return (value / total) * 100
  }

  const getWouldTakeAgainPercent = percent => {
    if (!percent) return 'N/A'
    return `${Math.max(parseFloat(percent?.toFixed(1)), 0)}%`
  }

  if (!teacher) {
    return <ProfessorEmptyState />
  }

  if (teacher.numRatings === 0) {
    return (
      <CompareProfessorsNoRatings teacher={teacher} handleClose={handleClose} column={column} />
    )
  }

  return (
    <StyledColumn>
      <InfoBox includePadding>
        <StyledCloseIcon
          data-testid={`close-button-${column}`}
          role="button"
          s
          src={svgCloseIcon}
          alt="Close Icon"
          onClick={() => handleClose(column)}
        />
        <CompareProfessorsHeaderBox
          teacher={teacher}
          school={teacher.school}
          column={column}
          handleClose={handleClose}
        />
      </InfoBox>
      <RatingsBarBox>
        <BoxTitle>{teacher.numRatings} Ratings</BoxTitle>
        <RatingDistributionWrapper teacher={teacher} compareProfessors />
      </RatingsBarBox>
      <DifficultyBox includePadding>
        <BoxTitle>Level of Difficulty</BoxTitle>
        <DifficultyScore>2.3</DifficultyScore>
      </DifficultyBox>
      <WouldTakeAgainBox includePadding>
        <BoxTitle>Would Take Again</BoxTitle>
        <WouldTakeAgainPercent>
          {getWouldTakeAgainPercent(teacher.wouldTakeAgainPercentRounded)}
        </WouldTakeAgainPercent>
      </WouldTakeAgainBox>
      <ForCreditBox includePadding>
        <BoxTitle>Taken for Credit</BoxTitle>
        <CircularBarChartContainer>
          <CircularBarChart
            yesPercentage={calculatePercent({
              value: teacher.takenForCredit?.yes,
              total: teacher.takenForCredit?.total
            })}
            noPercentage={calculatePercent({
              value: teacher.takenForCredit?.no,
              total: teacher.takenForCredit?.total
            })}
          />
          <BulletPoints>
            {getBulletPoints([
              {
                title: 'Yes: ',
                color: '#a6cc83',
                total: teacher.takenForCredit?.yes
              },
              { title: 'No: ', color: '#d55f5a', total: teacher.takenForCredit?.no },
              {
                title: 'N/A: ',
                color: '#d1d1d1',
                total: teacher.takenForCredit?.neither
              }
            ])}
          </BulletPoints>
        </CircularBarChartContainer>
      </ForCreditBox>
      <AttendanceBox includePadding>
        <BoxTitle>Mandatory Attendance</BoxTitle>
        <CircularBarChartContainer>
          <CircularBarChart
            yesPercentage={calculatePercent({
              value: teacher.mandatoryAttendance?.yes,
              total: teacher.mandatoryAttendance?.total
            })}
            noPercentage={calculatePercent({
              value: teacher.mandatoryAttendance?.yes,
              total: teacher.mandatoryAttendance?.total
            })}
          />
          <BulletPoints>
            {getBulletPoints([
              {
                title: 'Yes: ',
                color: '#a6cc83',
                total: teacher.mandatoryAttendance?.yes
              },
              {
                title: 'No: ',
                color: '#d55f5a',
                total: teacher.mandatoryAttendance?.no
              },
              {
                title: 'N/A: ',
                color: '#d1d1d1',
                total: teacher.mandatoryAttendance?.neither
              }
            ])}
          </BulletPoints>
        </CircularBarChartContainer>
      </AttendanceBox>
    </StyledColumn>
  )
}

CompareProfessorsColumn.propTypes = {
  teacher: TeacherTypes,
  handleClose: PropTypes.func,
  column: PropTypes.string
}

export default createFragmentContainer(CompareProfessorsColumn, {
  teacher: graphql`
    fragment CompareProfessorsColumn_teacher on Teacher {
      id
      legacyId
      firstName
      lastName
      school {
        legacyId
        name
      }
      legacyId
      department
      departmentId
      avgRating
      numRatings
      wouldTakeAgainPercentRounded
      mandatoryAttendance {
        yes
        no
        neither
        total
      }
      takenForCredit {
        yes
        no
        neither
        total
      }
      ...NoRatingsArea_teacher
      ...RatingDistributionWrapper_teacher
    }
  `
})
