import React from 'react'
import { createFragmentContainer, graphql } from 'react-relay'
import styled, { css } from 'styled-components'
import { breakpointSmallAndBelow } from '@StyledComponents/theme/helpers/breakpoints'
import SchoolTypes from '@types/School.types'
import { poppinsBoldest } from '@StyledComponents/theme/typesets'

const StyledSchoolCardHeader = styled.div`
  font-size: ${({ theme }) => theme.fontSize.teacherCardName};
  margin: 20px 0 15px 0;
  max-width: 500px;
  text-align: left;
  width: 100%;

  ${poppinsBoldest};

  ${breakpointSmallAndBelow(
    css`
      max-width: 311px;
      margin: 0;
    `
  )}
`

export const SchoolCardHeader = ({ school }) => {
  const { name } = school

  return <StyledSchoolCardHeader>{name}</StyledSchoolCardHeader>
}

export default createFragmentContainer(SchoolCardHeader, {
  school: graphql`
    fragment SchoolCardHeader_school on School {
      name
    }
  `
})

SchoolCardHeader.propTypes = {
  school: SchoolTypes
}
