import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import useDidMountEffect from '@hooks/useDidMountEffect'
import FormContext from '@contexts/FormContext'
import LABEL_STYLE from '@enums/LABEL_STYLE'
import InputWrapper from '@components/StyledComponents/InputWrapper'
import FieldWrapper from '@components/Form/FieldWrapper'

const FormSlider = ({
  disabled,
  name,
  className,
  valueMin = 0,
  label,
  valueMax,
  value,
  ...props
}) => {
  const { locked, model, onChange } = useContext(FormContext)
  useDidMountEffect(() => {
    onChange({ target: { name, value } })
  }, [value])
  return (
    <FieldWrapper fieldName={name} className={className}>
      <InputWrapper name={name} label={label} labelStyle={LABEL_STYLE.LEFT} className={className}>
        <input
          type="range"
          name={name}
          id={name}
          disabled={locked || disabled}
          onInput={onChange}
          onChange={onChange}
          value={model[name] || valueMin}
          aria-valuemin={valueMin}
          aria-valuemax={valueMax}
          {...props}
        />
      </InputWrapper>
    </FieldWrapper>
  )
}

FormSlider.propTypes = {
  valueMin: PropTypes.number,
  valueMax: PropTypes.number.isRequired,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.number
}

export default FormSlider
