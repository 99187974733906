/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type CompareSchoolLink_school$ref: FragmentReference;
declare export opaque type CompareSchoolLink_school$fragmentType: CompareSchoolLink_school$ref;
export type CompareSchoolLink_school = {|
  +legacyId: ?number,
  +$refType: CompareSchoolLink_school$ref,
|};
export type CompareSchoolLink_school$data = CompareSchoolLink_school;
export type CompareSchoolLink_school$key = {
  +$data?: CompareSchoolLink_school$data,
  +$fragmentRefs: CompareSchoolLink_school$ref,
};
*/


const node/*: ReaderFragment*/ = {
  "kind": "Fragment",
  "name": "CompareSchoolLink_school",
  "type": "School",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "legacyId",
      "args": null,
      "storageKey": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '8dbead97466dd4f512ba8246963b8c0c';
module.exports = node;
