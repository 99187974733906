import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import UpdateAccountEmailForm from '@components/UpdateAccountEmailForm'
import UpdateAccountPasswordForm from '@components/UpdateAccountPasswordForm'
import FormHeader from '@components/Form/FormHeader'
import IconButton from '@StyledComponents/IconButton'
import SETTINGS_FORM_MODE from '@enums/SETTINGS_FORM_MODE'

const StyledUpdateAccountForm = styled.div`
  margin: auto;
  max-width: 755px;
`

const DeleteAccountArea = styled.div`
  margin-bottom: 190px;
  margin-top: 36px;
`

export const DeleteIconButton = styled(IconButton.Trash)`
  cursor: pointer;
  margin: 0 auto;
`

export function UpdateAccountForms({ setFormMode }) {
  return (
    <StyledUpdateAccountForm>
      {/* TODO: Account for Non-unique ID warning for 'password' fields in both of the below forms */}
      <UpdateAccountEmailForm setFormMode={setFormMode} />
      <UpdateAccountPasswordForm setFormMode={setFormMode} />
      <DeleteAccountArea>
        <FormHeader>Delete Account</FormHeader>
        <DeleteIconButton onClick={() => setFormMode(SETTINGS_FORM_MODE.DELETE_ACCOUNT)}>
          Delete Account
        </DeleteIconButton>
      </DeleteAccountArea>
    </StyledUpdateAccountForm>
  )
}

UpdateAccountForms.propTypes = {
  setFormMode: PropTypes.func
}

export default UpdateAccountForms
