/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { helveticaBold } from '@StyledComponents/theme/typesets'
import Reply from '@assets/images/reply.svg'
import Trash from '@assets/images/trash.svg'
import Edit from '@assets/images/edit.svg'
import Share from '@assets/images/share.svg'
import ShareGray from '@assets/images/share-gray.svg'
import Reset from '@assets/images/reset.svg'
import Save from '@assets/images/save.svg'
import ArrowRight from '@assets/images/right-arrow-white.svg'
import Button from '../Button'

export const StyledIconButton = styled(Button)`
  align-items: center;
  background: none;
  border: 0;
  cursor: pointer;
  display: flex;
  outline: none;
  padding: 0;

  span {
    ${helveticaBold}
    margin: 0 5px;
  }
`

const IconButton = ({ src, alt, height, width, position, children, ...props }) => {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <StyledIconButton {...props}>
      {!position && <img src={src} alt={alt} height={height} width={width} />}
      {children && <span>{children}</span>}
      {position === 'right' && <img src={src} alt={alt} height={height} width={width} />}
    </StyledIconButton>
  )
}
IconButton.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string,
  children: PropTypes.node,
  height: PropTypes.string,
  width: PropTypes.string,
  position: PropTypes.string
}

export const ICON_BUTTONS = [
  { name: 'Reply', src: Reply, alt: 'Reply Arrow Icon' },
  { name: 'Edit', src: Edit, alt: 'Edit Icon' },
  { name: 'Trash', src: Trash, alt: 'Trashcan Icon' },
  { name: 'Share', src: Share, alt: 'Share Icon' },
  { name: 'ShareGray', src: ShareGray, alt: 'Share Icon' },
  { name: 'Reset', src: Reset, alt: 'Reset Icon' },
  { name: 'Save', src: Save, alt: 'Save Icon' },
  { name: 'ArrowRight', src: ArrowRight, alt: 'Arrow Icon' }
]

/**
 * This defines sub-components from the array above like IconButton.Reply etc.
 */
ICON_BUTTONS.forEach(buttonConfig => {
  // eslint-disable-next-line react/display-name
  IconButton[buttonConfig.name] = ({ ...props }) => {
    return <IconButton src={buttonConfig.src} alt={buttonConfig.alt} {...props} />
  }
  IconButton[buttonConfig.name].displayName = `${buttonConfig.name}Button`
})

export default IconButton
