import React from 'react'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'

import { breakpointSmallPlusAndBelow } from '@StyledComponents/theme/helpers/breakpoints'

export const StyledMetaItem = styled.div`
  font-size: ${props => props.theme.fontSize.medium};
  line-height: 1.54;
  margin-right: 16px;
  padding: 4px 0;
  white-space: nowrap;

  ${breakpointSmallPlusAndBelow(
    css`
      font-size: ${props => props.theme.fontSize.default};
    `
  )}

  span {
    font-weight: ${props => props.theme.fontWeight.bold};
  }
`

function MetaItem({ meta, object }) {
  if (!meta || !object) return null
  const value = meta.getValue(object)
  if (!value && value !== 0) return null

  return (
    <StyledMetaItem>
      {meta.label}: <span>{meta.formatValue(value)}</span>
    </StyledMetaItem>
  )
}

MetaItem.propTypes = {
  meta: PropTypes.shape({
    label: PropTypes.string,
    getValue: PropTypes.func,
    formatValue: PropTypes.func
  }),
  // eslint-disable-next-line react/forbid-prop-types
  object: PropTypes.any
}

export default MetaItem
