/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type CompareProfessorsColumn_teacher$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type CompareProfessors_secondaryProfessor$ref: FragmentReference;
declare export opaque type CompareProfessors_secondaryProfessor$fragmentType: CompareProfessors_secondaryProfessor$ref;
export type CompareProfessors_secondaryProfessor = {|
  +id: ?string,
  +legacyId: ?number,
  +$fragmentRefs: CompareProfessorsColumn_teacher$ref,
  +$refType: CompareProfessors_secondaryProfessor$ref,
|};
export type CompareProfessors_secondaryProfessor$data = CompareProfessors_secondaryProfessor;
export type CompareProfessors_secondaryProfessor$key = {
  +$data?: CompareProfessors_secondaryProfessor$data,
  +$fragmentRefs: CompareProfessors_secondaryProfessor$ref,
};
*/


const node/*: ReaderFragment*/ = {
  "kind": "Fragment",
  "name": "CompareProfessors_secondaryProfessor",
  "type": "Teacher",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "id",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "legacyId",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "FragmentSpread",
      "name": "CompareProfessorsColumn_teacher",
      "args": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '1e6771bc9fbc20fe6162234552d15b4a';
module.exports = node;
