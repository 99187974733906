import React from 'react'
import styled, { css } from 'styled-components'
import CengagePromo, { LogoCengage } from '@components/Promos/CengagePromo'
import {
  breakpointSmallPlusAndBelow,
  breakpointSmediumAndBelow
} from '@components/StyledComponents/theme/helpers/breakpoints'
import getEnv from '@utils/getEnv'
import cengageLogo from '@assets/images/logos/cengage_logo_summer22.svg'

export const StyledHomepageHeroCengageLogo = styled.div`
  display: flex;
  justify-content: center;

  margin-top: 15px;
  width: 100%;

  ${breakpointSmediumAndBelow(css`
    justify-content: center;
  `)}

  a {
    height: 36px;
    min-width: 240px;

    ${breakpointSmallPlusAndBelow(css`
      min-width: 250px;
    `)}
  }

  ${LogoCengage} {
    background-image: url(${cengageLogo});
    display: block;
    height: 56px;
    min-width: 266px;
    position: static;

    ${breakpointSmallPlusAndBelow(css`
      height: 36px;
      min-width: 240px;
    `)}
  }
`

const HomepageCengageLogo = props => {
  if (getEnv('REACT_APP_SHOW_CENGAGE') !== 'true') return null
  return (
    <StyledHomepageHeroCengageLogo>
      <CengagePromo {...props} />
    </StyledHomepageHeroCengageLogo>
  )
}

export default HomepageCengageLogo
