import React from 'react'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'
import TeacherTypes from '@types/Teacher.types'
import TeacherCard, { StyledTeacherCard } from '@components/TeacherCard/TeacherCard'
import SchoolCard, { StyledSchoolCard } from '@components/SchoolCard/SchoolCard'
import SEARCH_ITEM_TYPES from '@enums/SEARCHITEMTYPES'
import SchoolTypes from '@types/School.types'
import AdRail from '@components/Ads/AdRail'
import AddItemCTA from '@StyledComponents/AddItemCTA'
import RelayTypes from '@types/Relay.types'
import PaginationButton, { StyledPaginationButton } from '@StyledComponents/PaginationButton'
import NoResultsFoundArea from '@components/NoResultsFoundArea'
import {
  breakpointLargeAndBelow,
  breakpointMediumAndBelow,
  breakpointXLargeAndBelow,
  getPageContentMaxWidth
} from '@StyledComponents/theme/helpers/breakpoints'
import RatingSuccessHeader from '@pages/AddRating/RateTeacher/RatingSuccessHeader'
import SEOMetaInfo from '@components/SEOMetaInfo'
import AdController from '@components/Ads/AdController'
import AdLeaderboard from '@components/Ads/AdLeaderboard'
import StickyHeader from '@components/StickyHeader/StickyHeader'
import PageWrapper from '@components/PageWrapper'

const AD_TOP_SEARCH_PAGE = 264
const AD_INTERVAL = 3

const StyledSearchResultsPage = styled.div`
  min-height: 100vh;
  min-width: 100%;

  ${breakpointLargeAndBelow(
    css`
      max-width: unset;
    `
  )}

  ${StyledPaginationButton} {
    margin: 0 auto 24px auto;
    width: 240px;
  }
`

export const StyledTabs = styled.div`
  ${({ theme }) => getPageContentMaxWidth(theme)};

  ${StyledTeacherCard},
  ${StyledSchoolCard} {
    margin-bottom: 24px;
  }
`

const AddPromptWrapper = styled.div`
  margin: 0 auto;
  width: 255px;
`

const StyledResultsWrapper = styled.div`
  margin-top: 32px;
  max-width: calc(
    ${props => props.theme.pageWrapper.maxWidth}px - ${props => props.theme.adRail.width}px
  );

  ${breakpointXLargeAndBelow(css`
    margin-left: 16px;
  `)}

  ${breakpointMediumAndBelow(css`
    margin: 16px;
  `)}

  ${StyledTeacherCard},
  ${StyledSchoolCard} {
    margin-bottom: 24px;
  }
`

const getItemCards = (areItemsTeachers, results) => {
  const list = []
  let adIndex = 0
  results.forEach((item, index) => {
    if (index !== 0 && index % AD_INTERVAL === 0) {
      const adUnitKey = `search-results-${adIndex}`
      list.push(
        <AdController>
          <AdLeaderboard code="ad_lb_dyn_profile" key={adUnitKey} />
        </AdController>
      )
      adIndex += 1
    }
    if (areItemsTeachers) {
      list.push(<TeacherCard teacher={item.node} key={item.cursor} />)
    } else {
      list.push(<SchoolCard school={item.node} key={item.cursor} />)
    }
  })

  return list
}

const getMetaText = (areItemsTeachers, school) => {
  if (!school) {
    return {
      metaTitle: areItemsTeachers
        ? `Search professors | Rate My Professors`
        : `Search schools | Rate My Professors`,
      metaDescription: areItemsTeachers ? `Search professors` : `Search schools`
    }
  }

  return {
    metaTitle: areItemsTeachers
      ? `Search professors at ${school.name ?? null} | Rate My Professors`
      : `Search schools | Rate My Professors`,
    metaDescription: areItemsTeachers ? `Search professors at ${school.name}` : `Search schools`
  }
}

const SearchResults = props => {
  const { results, loadMore, relay, isLoading, itemType, successPageData, school } = props

  const areItemsTeachers = itemType === SEARCH_ITEM_TYPES.TEACHERS

  const { metaTitle, metaDescription } = getMetaText(areItemsTeachers, school)

  return (
    <React.Fragment>
      <SEOMetaInfo title={metaTitle} description={metaDescription} />
      {successPageData && <RatingSuccessHeader successPageData={successPageData} />}
      <StyledResultsWrapper>{getItemCards(areItemsTeachers, results)}</StyledResultsWrapper>
      <AddPromptWrapper>
        <PaginationButton
          onClick={loadMore}
          hasMore={relay.hasMore()}
          isLoading={isLoading}
          role="button"
        >
          Show More
        </PaginationButton>
        <AddItemCTA itemType={itemType} />
      </AddPromptWrapper>
    </React.Fragment>
  )
}

const SearchResultsPage = ({ results, itemType, query, successPageData, ...props }) => {
  const hasNoResults = !results || !results.length > 0 || !itemType

  return (
    <StyledSearchResultsPage>
      <StickyHeader
        school={null}
        teacher={null}
        searchPage
        large
        query={query}
        itemType={itemType}
        {...props}
      />
      <PageWrapper>
        {hasNoResults && <NoResultsFoundArea itemType={itemType} queryValue={query.text} />}
        {!hasNoResults && (
          <SearchResults
            results={results}
            itemType={itemType}
            query={query}
            successPageData={successPageData}
            {...props}
          />
        )}
        {!hasNoResults && <AdRail adTop={AD_TOP_SEARCH_PAGE} />}
      </PageWrapper>
    </StyledSearchResultsPage>
  )
}

const filterPropTypes = PropTypes.shape({
  field: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape({ count: PropTypes.number, value: PropTypes.string }))
})

SearchResultsPage.propTypes = {
  results: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.shape({ cursor: PropTypes.string, node: TeacherTypes })),
    PropTypes.arrayOf(PropTypes.shape({ cursor: PropTypes.string, node: SchoolTypes }))
  ]),
  itemType: PropTypes.oneOf(Object.values(SEARCH_ITEM_TYPES)),
  query: PropTypes.shape({
    text: PropTypes.string,
    schoolID: PropTypes.string
  }),
  school: SchoolTypes,
  successPageData: PropTypes.oneOfType([
    PropTypes.shape({
      teacher: TeacherTypes
    }),
    PropTypes.shape({
      school: SchoolTypes
    })
  ])
}

SearchResults.propTypes = {
  results: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.shape({ cursor: PropTypes.string, node: TeacherTypes })),
    PropTypes.arrayOf(PropTypes.shape({ cursor: PropTypes.string, node: SchoolTypes }))
  ]),
  itemType: PropTypes.oneOf(Object.values(SEARCH_ITEM_TYPES)),
  loadMore: PropTypes.func,
  isLoading: PropTypes.bool,
  relay: RelayTypes,
  filter: filterPropTypes,
  successPageData: PropTypes.oneOfType([
    PropTypes.shape({
      teacher: TeacherTypes
    }),
    PropTypes.shape({
      school: SchoolTypes
    })
  ]),
  query: PropTypes.shape({
    text: PropTypes.string,
    schoolID: PropTypes.string,
    fallback: PropTypes.bool,
    departmentID: PropTypes.string
  }),
  school: SchoolTypes
}

export default SearchResultsPage
