import React from 'react'
import styled from 'styled-components'
import { createFragmentContainer, graphql } from 'react-relay'
import ROUTE from '@enums/ROUTE'
import parseAndSanitize from '@utils/parseAndSanitize'
import RatingTypes from '@types/Rating.types'
import {
  StyledInfoBoxContainer,
  StyledInfoBoxHeader,
  StyledInfoBoxTitle,
  StyledLegalLink
} from '@StyledComponents/InfoBoxGuidelines'

const StyledFlagBoxContainer = styled(StyledInfoBoxContainer)`
  padding: 16px 16px 0;
`

const StyledFlagBoxTitle = styled(StyledInfoBoxTitle)`
  font-size: 16px;
  margin-left: 0;
`

const FlagAnswerContainer = styled.div`
  font-size: 16px;
  margin-bottom: 16px;
  text-align: left;
`

const StyledGuidelinesLink = styled(StyledLegalLink)`
  font-size: 16px;
`

const FlagCampusReportingBox = ({ rating }) => {
  return (
    <StyledFlagBoxContainer>
      <StyledInfoBoxHeader>
        <StyledFlagBoxTitle>You&#39;re reporting:</StyledFlagBoxTitle>
      </StyledInfoBoxHeader>
      <FlagAnswerContainer>{parseAndSanitize(rating.comment)}</FlagAnswerContainer>
      <StyledInfoBoxHeader>
        <StyledFlagBoxTitle>What&#39;s the problem?</StyledFlagBoxTitle>
      </StyledInfoBoxHeader>
      <FlagAnswerContainer>
        If you think this comment is inconsistent with Rate My Professors&#39;&#20;&nbsp;
        <span> </span>
        <StyledGuidelinesLink to={ROUTE.LEGAL_SITE_GUIDELINES}>
          Site Guidelines
        </StyledGuidelinesLink>
        , report it and tell us why.
      </FlagAnswerContainer>
    </StyledFlagBoxContainer>
  )
}

FlagCampusReportingBox.propTypes = {
  rating: RatingTypes
}

export default createFragmentContainer(FlagCampusReportingBox, {
  rating: graphql`
    fragment FlagCampusReportingBox_rating on SchoolRating {
      comment
    }
  `
})
