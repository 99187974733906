import React from 'react'
import TRACKING_EVENT from '@enums/TRACKING_EVENT'
import RateTeacherLink from '@components/RateTeacherLink'
import { createFragmentContainer, graphql } from 'react-relay'
import TeacherTypes from '@types/Teacher.types'
import { RATINGS_LIST_ANCHOR } from '../RatingsList'

export function NumRatingsLink({ teacher }) {
  if (teacher.numRatings === 0) {
    return (
      <div>
        No ratings yet.{' '}
        <RateTeacherLink teacher={teacher} trackingLabel={TRACKING_EVENT.PROF_RATE_FIRST_TEXT}>
          Add a rating.
        </RateTeacherLink>
      </div>
    )
  }

  return (
    <div>
      Overall Quality Based on&nbsp;
      <a href={`#${RATINGS_LIST_ANCHOR}`}>
        {teacher.numRatings}
        &nbsp;ratings
      </a>
    </div>
  )
}

export default createFragmentContainer(NumRatingsLink, {
  teacher: graphql`
    fragment NumRatingsLink_teacher on Teacher {
      numRatings
      ...RateTeacherLink_teacher
    }
  `
})

NumRatingsLink.propTypes = {
  teacher: TeacherTypes
}
