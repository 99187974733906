/**
 * @flow
 * @relayHash 0bb11861da505ee881368cc98769c35a
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type FormController_validation$ref = any;
export type AddProfessorPageMutationVariables = {|
  firstName: string,
  middleName?: ?string,
  lastName: string,
  department: string,
  directoryUrl?: ?string,
  schoolName: string,
  schoolID: string,
  recaptcha: string,
|};
export type AddProfessorPageMutationResponse = {|
  +addTeacher: ?{|
    +teacher: ?{|
      +id: ?string,
      +legacyId: ?number,
    |},
    +validation: ?{|
      +$fragmentRefs: FormController_validation$ref
    |},
  |}
|};
export type AddProfessorPageMutation = {|
  variables: AddProfessorPageMutationVariables,
  response: AddProfessorPageMutationResponse,
|};
*/


/*
mutation AddProfessorPageMutation(
  $firstName: String!
  $middleName: String
  $lastName: String!
  $department: String!
  $directoryUrl: String
  $schoolName: String!
  $schoolID: ID!
  $recaptcha: String!
) {
  addTeacher(firstName: $firstName, middleName: $middleName, lastName: $lastName, department: $department, directoryUrl: $directoryUrl, schoolName: $schoolName, schoolID: $schoolID, recaptcha: $recaptcha) {
    teacher {
      id
      legacyId
    }
    validation {
      ...FormController_validation
      id
    }
  }
}

fragment FormController_validation on ValidationResult {
  id
  success
  errors {
    field
    messages
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "firstName",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "middleName",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "lastName",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "department",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "directoryUrl",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "schoolName",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "schoolID",
    "type": "ID!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "recaptcha",
    "type": "String!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "department",
    "variableName": "department"
  },
  {
    "kind": "Variable",
    "name": "directoryUrl",
    "variableName": "directoryUrl"
  },
  {
    "kind": "Variable",
    "name": "firstName",
    "variableName": "firstName"
  },
  {
    "kind": "Variable",
    "name": "lastName",
    "variableName": "lastName"
  },
  {
    "kind": "Variable",
    "name": "middleName",
    "variableName": "middleName"
  },
  {
    "kind": "Variable",
    "name": "recaptcha",
    "variableName": "recaptcha"
  },
  {
    "kind": "Variable",
    "name": "schoolID",
    "variableName": "schoolID"
  },
  {
    "kind": "Variable",
    "name": "schoolName",
    "variableName": "schoolName"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "teacher",
  "storageKey": null,
  "args": null,
  "concreteType": "Teacher",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "legacyId",
      "args": null,
      "storageKey": null
    }
  ]
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "AddProfessorPageMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "addTeacher",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "teacherMutationResponse",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "validation",
            "storageKey": null,
            "args": null,
            "concreteType": "ValidationResult",
            "plural": false,
            "selections": [
              {
                "kind": "FragmentSpread",
                "name": "FormController_validation",
                "args": null
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "AddProfessorPageMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "addTeacher",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "teacherMutationResponse",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "validation",
            "storageKey": null,
            "args": null,
            "concreteType": "ValidationResult",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "success",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "errors",
                "storageKey": null,
                "args": null,
                "concreteType": "FieldErrorType",
                "plural": true,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "field",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "messages",
                    "args": null,
                    "storageKey": null
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "mutation",
    "name": "AddProfessorPageMutation",
    "id": null,
    "text": "mutation AddProfessorPageMutation(\n  $firstName: String!\n  $middleName: String\n  $lastName: String!\n  $department: String!\n  $directoryUrl: String\n  $schoolName: String!\n  $schoolID: ID!\n  $recaptcha: String!\n) {\n  addTeacher(firstName: $firstName, middleName: $middleName, lastName: $lastName, department: $department, directoryUrl: $directoryUrl, schoolName: $schoolName, schoolID: $schoolID, recaptcha: $recaptcha) {\n    teacher {\n      id\n      legacyId\n    }\n    validation {\n      ...FormController_validation\n      id\n    }\n  }\n}\n\nfragment FormController_validation on ValidationResult {\n  id\n  success\n  errors {\n    field\n    messages\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '9b22403d21d874095941ecb97344f6b6';
module.exports = node;
