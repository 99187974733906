import React from 'react'
import styled, { css } from 'styled-components'
import TeacherTypes from '@types/Teacher.types'
import { createFragmentContainer, graphql } from 'react-relay'
import { breakpointSmallAndBelow } from '@StyledComponents/theme/helpers/breakpoints'
import TRACKING_EVENT from '@enums/TRACKING_EVENT'
import Link from '@src/components/Link'
import ROUTE from '@enums/ROUTE'
import TeacherBookmark, { StyledTeacherBookmark } from '@StyledComponents/TeacherBookmark'
import CardNumRating, { StyledCardNumRating } from '../StyledComponents/CardNumRating'
import CardName from './CardName'
import CardFeedback from './CardFeedback'
import CardSchool from './CardSchool'

export const StyledTeacherCard = styled(Link)`
  align-items: center;
  background: ${props => props.theme.color.gray7};
  display: flex;
  justify-content: space-between;
  padding: 20px 23px 22px 27px;
  text-decoration: none;

  ${breakpointSmallAndBelow(
    css`
      padding: 20px 16px 16px 16px;
    `
  )}

  ${StyledTeacherBookmark} {
    align-self: flex-start;
    display: flex;
  }

  ${StyledCardNumRating} {
    margin: 0 42px 0 0;
  }
`

export const CardInfo = styled.div`
  display: flex;
  flex-direction: column;
  margin: 2px 0;
`

export const NumRatingWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  ${breakpointSmallAndBelow(
    css`
      margin-bottom: 16px;
    `
  )}
`

export const InfoRatingWrapper = styled.div`
  display: flex;
  width: 100%;

  ${breakpointSmallAndBelow(
    css`
      flex-direction: column;
    `
  )}
`

export const TeacherCard = ({ teacher }) => {
  if (!teacher) return null

  const cardNumRatingConfig = {
    topText: 'QUALITY',
    numRating: teacher.avgRating,
    bottomText: `${teacher.numRatings} ratings`
  }

  const teacherRoute = `${ROUTE.SHOW_RATINGS}`.replace(':tid', `${teacher.legacyId}`)

  return (
    <StyledTeacherCard to={teacherRoute} trackingLabel={TRACKING_EVENT.TEACHER_CARD_LINK}>
      <InfoRatingWrapper>
        <NumRatingWrapper>
          <CardNumRating config={cardNumRatingConfig} />
        </NumRatingWrapper>
        <CardInfo>
          <CardName teacher={teacher} />
          <CardSchool teacher={teacher} />
          <CardFeedback teacher={teacher} />
        </CardInfo>
      </InfoRatingWrapper>
      <TeacherBookmark
        teacher={teacher}
        saveEvent={TRACKING_EVENT.TEACHER_CARD_SAVED_PROF_ADD}
        unsaveEvent={TRACKING_EVENT.TEACHER_CARD_SAVED_PROF_REMOVE}
      />
    </StyledTeacherCard>
  )
}

export default createFragmentContainer(TeacherCard, {
  teacher: graphql`
    fragment TeacherCard_teacher on Teacher {
      id
      legacyId
      avgRating
      numRatings
      ...CardFeedback_teacher
      ...CardSchool_teacher
      ...CardName_teacher
      ...TeacherBookmark_teacher
    }
  `
})

TeacherCard.propTypes = {
  teacher: TeacherTypes
}
