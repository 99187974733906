import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { createFragmentContainer, graphql } from 'react-relay'
import { poppinsBoldest } from '@StyledComponents/theme/typesets'
import EmotionLabel, { StyledEmotionLabel } from '@StyledComponents/EmotionLabel'
import { breakpointSmallPlusAndBelow } from '@StyledComponents/theme/helpers/breakpoints'
import RatingTypes from '@types/Rating.types'
import { emotionMap, determineEmotion } from '@componentUtils/emotionHelpers'
import OnlineCourseLogo, { StyledLogo } from '@StyledComponents/OnlineCourseLogo'
import { ButtonAttributes } from '@components/StyledComponents/Buttons'
import Link from '@components/Link'
import TimeStamp from '../TimeStamp'

export const ViewRatingButton = styled(Link)`
  text-decoration: none;
  width: fit-content;

  ${ButtonAttributes};

  &:not(:disabled)&:hover {
    background: ${props => props.theme.color.darkMainBlue};
    transform: none;
  }
`

export const StyledHeader = styled.div`
  align-items: center;
  display: ${props => (props.mobile ? 'none' : 'flex')};
  justify-content: space-between;
  margin: 5px 0 10px;
  min-height: 31px;

  ${breakpointSmallPlusAndBelow(
    css`
      align-items: flex-start;
      margin-bottom: 15px;
      display: ${props => (props.mobile ? 'flex' : 'none')};
    `
  )};

  ${StyledLogo} {
    margin-right: 14px;
  }
`

export const ClassInfoWrapper = styled.div`
  display: flex;

  ${breakpointSmallPlusAndBelow(
    css`
      flex-direction: column;

      ${StyledEmotionLabel} {
        text-align: center;
      }
    `
  )};
`

export const StyledClass = styled.div`
  align-items: center;
  align-self: center;
  display: flex;
  font-size: ${props => props.theme.fontSize.medium};
  padding-right: ${props => props.theme.padding.teacherInfo};
  text-transform: uppercase;

  ${poppinsBoldest};

  ${breakpointSmallPlusAndBelow(
    css`
      margin-bottom: 8px;
    `
  )};
`

export const RatingTimeStamp = styled(TimeStamp)`
  font-size: ${props => props.theme.fontSize.default};
  padding-bottom: 0;
`

export function RatingHeader({ rating, mobile, showEmotionLabel }) {
  if (!rating) return null

  const overallRating = (rating.helpfulRating + rating.clarityRating) / 2
  const emotion = determineEmotion(overallRating)

  return (
    <StyledHeader mobile={mobile}>
      <ClassInfoWrapper>
        <StyledClass>
          {rating.isForOnlineClass && <OnlineCourseLogo />} {rating.class}
        </StyledClass>
        {showEmotionLabel && (
          <EmotionLabel emotion={emotion}>{emotionMap[emotion].genericRating}</EmotionLabel>
        )}
      </ClassInfoWrapper>
      <RatingTimeStamp timeStamp={rating.date} />
    </StyledHeader>
  )
}

RatingHeader.propTypes = {
  rating: RatingTypes,
  mobile: PropTypes.bool,
  showEmotionLabel: PropTypes.bool
}

export default createFragmentContainer(RatingHeader, {
  rating: graphql`
    fragment RatingHeader_rating on Rating {
      legacyId
      date
      class
      helpfulRating
      clarityRating
      isForOnlineClass
    }
  `
})
