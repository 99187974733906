import React, { useContext, useState } from 'react'
import PropTypes from 'prop-types'
import styled, { ThemeContext } from 'styled-components'

// eslint-disable-next-line import/no-named-default
import { default as ReactSelect, components as ReactSelectComponents } from 'react-select'

export const getSelectTheme = (theme, additionalTheme = {}) => baseSelectTheme => ({
  ...baseSelectTheme,
  borderRadius: '6px',
  ...additionalTheme,
  spacing: {
    ...baseSelectTheme.spacing,
    controlHeight: '32px',
    ...additionalTheme.spacing
  },
  colors: {
    ...baseSelectTheme.colors,
    primary: theme.color.mainBlue,
    primary75: theme.color.lightBlue,
    primary50: theme.color.lightBlue,
    primary25: theme.color.lightBlue,
    ...additionalTheme.colors
  }
})

// See https://react-select.com/styles
export const getStyles = (theme, additionalStyles = {}, isSearchable) => ({
  control: provided => ({
    ...provided,
    borderColor: theme.color.gray2,
    boxShadow: 0,
    '&:hover': {
      borderColor: theme.color.gray5
    },
    width: '96px',
    ...(isSearchable
      ? {
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between'
        }
      : {}),
    ...additionalStyles.control
  }),
  option: provided => ({
    ...provided,
    ...additionalStyles.option
  }),
  menu: provided => ({
    ...provided,
    ...additionalStyles.menu
  }),
  placeholder: provided => ({
    ...provided,
    ...additionalStyles.placeholder
  }),
  multiValueLabel: provided => ({
    ...provided,
    ...additionalStyles.multiValueLabel
  }),
  singleValue: provided => ({
    ...provided,
    overflow: 'visible'
  }),
  input: provided => ({
    ...provided,
    ...(isSearchable ? {} : {}),
    ...additionalStyles.input
  }),
  valueContainer: provided => ({
    ...provided,
    ...(isSearchable ? {} : {}),
    ...additionalStyles.valueContainer
  })
})

const getDropdownIndicator = open => styled(ReactSelectComponents.DropdownIndicator)`
  color: black !important;
  transform: ${open ? 'scale(1, -1)' : ''};
`

export default function Select(props) {
  const [open, setOpen] = useState(false)

  const {
    menuIsOpen,
    onMenuOpen,
    onMenuClose,
    components,
    additionalStyles,
    additionalTheming,
    isSearchable
  } = props

  const theme = useContext(ThemeContext)
  const selectComponents = {
    DropdownIndicator: getDropdownIndicator(open),
    ClearIndicator: () => null,
    IndicatorSeparator: () => null,
    ...components
  }

  if (menuIsOpen && open !== menuIsOpen) {
    setOpen(menuIsOpen)
  }

  return (
    <ReactSelect
      {...props}
      styles={getStyles(theme, additionalStyles, isSearchable)}
      theme={getSelectTheme(theme, additionalTheming)}
      components={selectComponents}
      onMenuOpen={() => {
        setOpen(true)
        return onMenuOpen && onMenuOpen()
      }}
      onMenuClose={() => {
        setOpen(false)
        return onMenuClose && onMenuClose()
      }}
      isSearchable={isSearchable}
    />
  )
}

Select.propTypes = {
  menuIsOpen: PropTypes.bool,
  onMenuOpen: PropTypes.func,
  onMenuClose: PropTypes.func,
  components: PropTypes.objectOf(PropTypes.func),
  additionalStyles: PropTypes.shape({
    select: PropTypes.objectOf(PropTypes.string),
    option: PropTypes.objectOf(PropTypes.string)
  }),
  additionalTheming: PropTypes.objectOf(PropTypes.string),
  isSearchable: PropTypes.bool
}
