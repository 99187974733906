/**
 * @flow
 * @relayHash 953c8591499b9320850c5cbb28182138
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type EmailConfirmationPromptModalMutationVariables = {||};
export type EmailConfirmationPromptModalMutationResponse = {|
  +resendEmailConfirmation: ?{|
    +validation: ?{|
      +success: ?boolean,
      +errors: ?$ReadOnlyArray<?{|
        +messages: ?$ReadOnlyArray<?string>
      |}>,
    |}
  |}
|};
export type EmailConfirmationPromptModalMutation = {|
  variables: EmailConfirmationPromptModalMutationVariables,
  response: EmailConfirmationPromptModalMutationResponse,
|};
*/


/*
mutation EmailConfirmationPromptModalMutation {
  resendEmailConfirmation {
    validation {
      success
      errors {
        messages
      }
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "success",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "errors",
  "storageKey": null,
  "args": null,
  "concreteType": "FieldErrorType",
  "plural": true,
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "messages",
      "args": null,
      "storageKey": null
    }
  ]
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "EmailConfirmationPromptModalMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "resendEmailConfirmation",
        "storageKey": null,
        "args": null,
        "concreteType": "ValidationResponse",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "validation",
            "storageKey": null,
            "args": null,
            "concreteType": "ValidationResult",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              (v1/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "EmailConfirmationPromptModalMutation",
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "resendEmailConfirmation",
        "storageKey": null,
        "args": null,
        "concreteType": "ValidationResponse",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "validation",
            "storageKey": null,
            "args": null,
            "concreteType": "ValidationResult",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              (v1/*: any*/),
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "id",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "mutation",
    "name": "EmailConfirmationPromptModalMutation",
    "id": null,
    "text": "mutation EmailConfirmationPromptModalMutation {\n  resendEmailConfirmation {\n    validation {\n      success\n      errors {\n        messages\n      }\n      id\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '2aeeb38ebfcb2b5d11b846a347543966';
module.exports = node;
