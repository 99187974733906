import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

export const SLIDER_TEST_ID = 'checkbox-slider'

export const StyledCheckboxSwitch = styled.button`
  background: ${({ theme, checked }) => (checked ? theme.color.darkMainBlue : theme.color.gray9)};
  border: 0;
  border-radius: 100px;
  cursor: pointer;
  height: 28px;
  outline: none;
  position: relative;
  width: 50px;

  input {
    height: 0;
    width: 0;
  }
`

export const Switch = styled.span`
  background: white;
  border: 0.5px solid ${({ theme }) => theme.color.gray4};
  border-radius: 100px;
  bottom: 0;
  box-shadow: 0px 2px 2px rgba(78, 78, 78, 0.25);
  height: 26px;
  left: ${({ checked }) => (checked ? '22px' : '1px')};
  position: absolute;
  right: 0;
  top: 1px;

  /* stylelint-disable property-no-vendor-prefix  */
  -webkit-transition: 0.2s;
  transition: 0.2s;
  width: 26px;
`

export default function CheckboxSwitch({ name, checked, onClick = null }) {
  if (!name || checked === null) return null

  return (
    <StyledCheckboxSwitch
      role="switch"
      checked={checked}
      aria-checked={checked}
      onClick={() => {
        if (onClick) onClick()
      }}
    >
      <input type="checkbox" checked={checked} id={name} name={name} />
      <Switch checked={checked} data-testid={SLIDER_TEST_ID} />
    </StyledCheckboxSwitch>
  )
}

CheckboxSwitch.propTypes = {
  name: PropTypes.string,
  checked: PropTypes.bool,
  onClick: PropTypes.func
}
