import React from 'react'
import styled, { css } from 'styled-components'
import parseAndSanitize from '@utils/parseAndSanitize'
import RatingTypes from '@types/Rating.types'
import ROUTE from '@enums/ROUTE'
import { StyledDepartmentLink } from '@components/TeacherInfo/TeacherDepartment'
import {
  breakpointLargeAndBelow,
  breakpointSmediumAndBelow
} from '@components/StyledComponents/theme/helpers/breakpoints'
import { helveticaBold, poppinsBoldest } from '@components/StyledComponents/theme/typesets'
import Link from '@components/Link'
import { ButtonAttributes } from '@components/StyledComponents/Buttons'

const RatingHeader = styled.div`
  padding: 8px;
`

export const HeaderTitle = styled.div`
  max-width: 100%;
  padding-bottom: 40px;
  ${breakpointLargeAndBelow(
    css`
      padding-bottom: 24px;
    `
  )}
  .span,
  a {
    font-weight: ${props => props.theme.fontWeight.bold};
  }
`

export const HeaderHelperText = styled.div`
  margin-bottom: 16px;
  margin-top: 16px;
  text-align: start;
  ${helveticaBold}
`

export const NameContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding-bottom: 10px;
  width: 100%;
`

export const Title = styled.div`
  max-width: 100%;
  padding-bottom: 16px;
`

export const NameItem = styled.div`
  font-size: 32px;
  margin-left: 4px;
  ${poppinsBoldest};
`

export const ProfileButton = styled(Link)`
  max-width: 284px;
  min-width: 220px;
  text-decoration: none;
  width: fit-content;

  ${ButtonAttributes};

  ${breakpointSmediumAndBelow(css`
    width: 100%;
    margin-top: 0px;
  `)}

  &:not(:disabled)&:hover {
    background: ${props => props.theme.color.darkMainBlue};
    transform: none;
  }
`

const RatingPermalinkHeader = ({ rating }) => {
  return (
    <RatingHeader>
      <HeaderTitle>
        <HeaderHelperText>Rating for</HeaderHelperText>
        <NameContainer>
          <NameItem>{parseAndSanitize(rating.teacher.firstName)}</NameItem>
          <NameItem>{parseAndSanitize(rating.teacher.lastName)}</NameItem>
        </NameContainer>
        <HeaderHelperText>
          Professor in the{' '}
          <StyledDepartmentLink
            to={`${ROUTE.TEACHER_SEARCH.replace(':sid?', rating.teacher.school.legacyId)}?q=*&did=${
              rating.teacher.departmentId
            }`}
          >
            <b>{rating.teacher.department} department</b>
          </StyledDepartmentLink>{' '}
          at{' '}
          <Link to={ROUTE.NEW_CAMPUS_RATINGS.replace(':sid', rating.teacher.school.legacyId)}>
            {rating.teacher.school.name}
          </Link>
        </HeaderHelperText>
        <ProfileButton blue to={ROUTE.SHOW_RATINGS.replace(':tid', rating.teacher.legacyId)}>
          View full profile
        </ProfileButton>
      </HeaderTitle>
    </RatingHeader>
  )
}

RatingPermalinkHeader.propTypes = {
  rating: RatingTypes
}

export default RatingPermalinkHeader
