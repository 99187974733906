import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import Button from '@components/Button'

import FormContext from '@contexts/FormContext'

const FormButton = ({ disabled, children, ...props }) => {
  const { locked } = useContext(FormContext)

  return (
    <Button disabled={locked || disabled} {...props}>
      {children}
    </Button>
  )
}

FormButton.propTypes = {
  disabled: PropTypes.bool,
  children: PropTypes.node
}

export default FormButton
