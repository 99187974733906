import React from 'react'
import PropTypes from 'prop-types'
import { DropdownSelect, Error } from 'storybook-rmp'

const DepartmentSelect = ({ id, name, label, value, onChange, onBlur, options, error }) => {
  return (
    <React.Fragment>
      <DropdownSelect
        id={id}
        name={name}
        label={label}
        options={options}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
      />
      {error && <Error error={error} />}
    </React.Fragment>
  )
}

DepartmentSelect.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.shape({
    value: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired
  }),
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired
    })
  ),
  error: PropTypes.string
}

export default DepartmentSelect
