import React from 'react'
import PropTypes from 'prop-types'
import FormTextArea from '@components/Form/FormTextArea'
import InfoBoxGuidelines from '@components/StyledComponents/InfoBoxGuidelines'
import { MAX_COMMENT_LENGTH } from '@src/CONSTANTS'

const WriteReview = ({ guidelines, name, placeholder }) => {
  return (
    <div>
      <InfoBoxGuidelines guidelines={guidelines} />
      <FormTextArea placeholder={placeholder} name={name} maxlength={MAX_COMMENT_LENGTH} />
    </div>
  )
}

WriteReview.propTypes = {
  guidelines: PropTypes.arrayOf(PropTypes.string),
  name: PropTypes.string,
  placeholder: PropTypes.string
}

export default WriteReview
