import React, { useEffect, useContext } from 'react'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'

import ErrorText from '@StyledComponents/ErrorText'
import FormContext from '@contexts/FormContext'
import { MAX_COMMENT_LENGTH, MIN_COMMENT_LENGTH } from '@src/CONSTANTS'

export const StyledFieldError = styled(ErrorText)`
  font-size: ${props => (props.fieldName === 'form' ? '16px' : '14px')};
  font-weight: ${props => (props.fieldName === 'form' ? 800 : 500)};
`

export const HelperText = styled.div`
  color: ${props => props.theme.color.gray8};
  font-family: ${props => props.theme.fontFamily.avenir};
  font-weight: 500;
  line-height: 24px;
  position: relative;
  text-align: left;
  top: 10px;
  ${({ hasErrors }) =>
    hasErrors &&
    css`
      color: ${props => props.theme.color.error};
    `}
`

const FieldErrors = ({ fieldName, helperText, setHasErrors = () => {} }) => {
  const { validation, model } = useContext(FormContext)
  const hasErrors = validation?.errors?.length > 0

  const matchingFields = hasErrors
    ? validation.errors.filter(e => e.field.toLowerCase() === fieldName?.toLowerCase())
    : []

  const combinedErrors = matchingFields.reduce((combined, current) => {
    /**
     * If a user receives a validation error and corrects the comment length we
     * want to remove the error from the UI, so we skip concat the error message
     * once the constraints are met
     */
    if (
      current.field.toLowerCase() === 'comment' &&
      model?.comment?.length >= MIN_COMMENT_LENGTH &&
      model?.comment?.length <= MAX_COMMENT_LENGTH &&
      model?.comment.trim().length > MIN_COMMENT_LENGTH
    ) {
      return combined
    }
    return combined.concat(current.messages)
  }, [])

  useEffect(() => {
    if (combinedErrors.length > 0) {
      setHasErrors(true)
    } else {
      setHasErrors(false)
    }
  }, [hasErrors, combinedErrors])

  if (!matchingFields.length && helperText) {
    return <HelperText hasErrors={hasErrors}>{helperText}</HelperText>
  }

  if (!hasErrors) return null

  return (
    <React.Fragment>
      {combinedErrors.map(errMsg => (
        <StyledFieldError fieldName={fieldName} key={`Error-${errMsg.split(' ').join('-')}`}>
          {errMsg}
        </StyledFieldError>
      ))}
    </React.Fragment>
  )
}

FieldErrors.propTypes = {
  fieldName: PropTypes.string,
  setHasErrors: PropTypes.func,
  helperText: PropTypes.string
}

export default FieldErrors
