import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import CompareSchoolTypes from '@types/CompareSchool.types'
import DisplaySlider from '@components/StyledComponents/DisplaySlider'
import {
  breakpointSmallPlusAndBelow,
  breakpointXLargeAndBelow
} from '@components/StyledComponents/theme/helpers/breakpoints'

const CategoryRow = styled.div`
  align-items: center;
  clear: both;
  content: '';
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 24px;
  max-width: 888px;
  width: 100%;
`

const SharedRowStyles = styled.div`
  height: 24px;
  line-height: 19px;
  padding: 10px;
  text-align: center;
`

const RowEnd = styled.div`
  ${SharedRowStyles}
  justify-content: center;
  width: 40%;
`

const RowMiddle = styled.div`
  font-weight: 700;
  min-width: 20%;
  ${SharedRowStyles}

  ${breakpointXLargeAndBelow(css`
    font-size: 15px;
  `)}

  ${breakpointSmallPlusAndBelow(css`
    font-size: 10px;
  `)}
`

const CompareSchoolsTableContent = ({ primarySchool, secondarySchool, categoryName }) => {
  // Helper function to get a category score for an entity, or 0 if the entity doesn't have that category
  const getCategoryScore = (school, category) => {
    if (!school) return -1
    const schoolCategory = school.categories.find(c => c.name === category)
    return schoolCategory ? schoolCategory.score.toFixed(1) : 0
  }

  const primaryRating = {
    score: getCategoryScore(primarySchool, categoryName)
  }
  const secondaryRating = {
    score: getCategoryScore(secondarySchool, categoryName)
  }

  return (
    <CategoryRow>
      <RowEnd>
        <DisplaySlider rating={primaryRating} hideLabel summarySlider bullet="right" />
      </RowEnd>

      <RowMiddle key={categoryName}>{categoryName}</RowMiddle>

      <RowEnd>
        <DisplaySlider rating={secondaryRating} summarySlider bullet="left" />
      </RowEnd>
    </CategoryRow>
  )
}

CompareSchoolsTableContent.propTypes = {
  categoryName: PropTypes.string,
  primarySchool: CompareSchoolTypes,
  secondarySchool: CompareSchoolTypes
}

export default CompareSchoolsTableContent
