import React from 'react'
import PropTypes from 'prop-types'
import { Redirect, Route } from 'react-router-dom'

// example directly taken from https://reactrouter.com/web/guides/server-rendering
export default function RedirectWithStatus({ to, status = 302 }) {
  return (
    <Route
      render={({ staticContext }) => {
        // there is no `staticContext` on the client, so
        // we need to guard against that here
        if (staticContext) staticContext.status = status
        return <Redirect to={to} />
      }}
    />
  )
}

RedirectWithStatus.propTypes = {
  to: PropTypes.string.isRequired,
  status: PropTypes.number.isRequired
}
