/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type ProfessorNoteFooter_teacher$ref: FragmentReference;
declare export opaque type ProfessorNoteFooter_teacher$fragmentType: ProfessorNoteFooter_teacher$ref;
export type ProfessorNoteFooter_teacher = {|
  +legacyId: ?number,
  +isProfCurrentUser: ?boolean,
  +$refType: ProfessorNoteFooter_teacher$ref,
|};
export type ProfessorNoteFooter_teacher$data = ProfessorNoteFooter_teacher;
export type ProfessorNoteFooter_teacher$key = {
  +$data?: ProfessorNoteFooter_teacher$data,
  +$fragmentRefs: ProfessorNoteFooter_teacher$ref,
};
*/


const node/*: ReaderFragment*/ = {
  "kind": "Fragment",
  "name": "ProfessorNoteFooter_teacher",
  "type": "Teacher",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "legacyId",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "isProfCurrentUser",
      "args": null,
      "storageKey": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = 'f0b78371ed406edf9733f40dd4aecf6d';
module.exports = node;
