import facilities from '@assets/images/schoolSummaryIcons/facilities.svg'
import food from '@assets/images/schoolSummaryIcons/food.svg'
import happiness from '@assets/images/schoolSummaryIcons/happiness.svg'
import internet from '@assets/images/schoolSummaryIcons/internet.svg'
import location from '@assets/images/schoolSummaryIcons/location.svg'
import opportunities from '@assets/images/schoolSummaryIcons/opportunities.svg'
import reputation from '@assets/images/schoolSummaryIcons/reputation.svg'
import safety from '@assets/images/schoolSummaryIcons/safety.svg'
import social from '@assets/images/schoolSummaryIcons/social.svg'
import clubs from '@assets/images/schoolSummaryIcons/clubs.svg'

const schoolSummaryModel = {
  schoolReputation: {
    name: 'Reputation',
    icon: reputation
  },
  schoolSatisfaction: {
    name: 'Happiness',
    icon: happiness
  },
  internetSpeed: {
    name: 'Internet',
    icon: internet
  },
  campusCondition: {
    name: 'Facilities',
    icon: facilities
  },
  schoolSafety: {
    name: 'Safety',
    icon: safety
  },
  careerOpportunities: {
    name: 'Opportunities',
    icon: opportunities
  },
  socialActivities: {
    name: 'Social',
    icon: social
  },
  foodQuality: {
    name: 'Food',
    icon: food
  },
  clubAndEventActivities: {
    name: 'Clubs',
    icon: clubs
  },
  campusLocation: {
    name: 'Location',
    icon: location
  }
}

export default schoolSummaryModel
