import React from 'react'
import ReactRouterPropTypes from 'react-router-prop-types'
import ROUTE from '@enums/ROUTE'
import { withRouter, Redirect } from 'react-router-dom'
import QueryWrapper from '@componentUtils/QueryWrapper'
import { addRatingTeacherQuery, getVariables } from './RateTeacher.utils'
import RateTeacher from './RateTeacher'

export const AddRatingTeacherPage = ({ location }) => {
  const variables = getVariables(location)
  if (!variables.id) {
    return <Redirect to={ROUTE.TEACHER_NOT_FOUND} />
  }

  return (
    <QueryWrapper query={addRatingTeacherQuery} component={RateTeacher} variables={variables} />
  )
}

AddRatingTeacherPage.propTypes = {
  location: ReactRouterPropTypes.location
}

const withRouterAddRatingTeacherPage = withRouter(AddRatingTeacherPage)
withRouterAddRatingTeacherPage.serverQueries = [
  {
    query: addRatingTeacherQuery,
    getVariables
  }
]

export default withRouterAddRatingTeacherPage
