/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type OverallRating_school$ref: FragmentReference;
declare export opaque type OverallRating_school$fragmentType: OverallRating_school$ref;
export type OverallRating_school = {|
  +avgRatingRounded: ?any,
  +avgRating: ?number,
  +numRatings: ?number,
  +$refType: OverallRating_school$ref,
|};
export type OverallRating_school$data = OverallRating_school;
export type OverallRating_school$key = {
  +$data?: OverallRating_school$data,
  +$fragmentRefs: OverallRating_school$ref,
};
*/


const node/*: ReaderFragment*/ = {
  "kind": "Fragment",
  "name": "OverallRating_school",
  "type": "School",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "avgRatingRounded",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "avgRating",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "numRatings",
      "args": null,
      "storageKey": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '39896022003bf55059f3e26a45fc9c24';
module.exports = node;
