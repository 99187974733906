import React, { useContext } from 'react'
import styled, { ThemeContext } from 'styled-components'
import PropTypes from 'prop-types'
import { poppinsBoldest3x } from '@StyledComponents/theme/typesets'
import { emotionMap, determineEmotion } from '@componentUtils/emotionHelpers'
import theme from '@StyledComponents/theme'

export const StyledCardNumRating = styled.div`
  display: flex;
  flex-direction: column;
`

export const CardNumRatingHeader = styled.div`
  font-size: ${props => props.theme.fontSize.small};
  font-weight: ${props => props.theme.fontWeight.medium};
  line-height: 14px;
  margin-bottom: 4px;
  text-transform: uppercase;
`

export const CardNumRatingNumber = styled.div`
  align-self: center;
  background: ${({ bgColor }) => bgColor};
  height: 64px;
  line-height: 36px;
  margin-bottom: 8px;
  padding: 14px 10px;
  width: 72px;

  ${poppinsBoldest3x}
`

export const CardNumRatingCount = styled.div`
  color: ${props => props.theme.color.gray8};
  line-height: 16px;
  white-space: nowrap;
`

const getBackgroundColor = (themeContext, numRating, staticColor) => {
  if (staticColor) return staticColor

  if (numRating > 0) return emotionMap[determineEmotion(numRating)].getColor

  return themeContext.color.gray5
}

export const CardNumRating = ({ config }) => {
  const themeContext = useContext(ThemeContext)

  const { numRating, topText, bottomText, staticColor } = config

  const bgColor = getBackgroundColor(themeContext, numRating, staticColor)

  const formattedAvgRating = typeof numRating === 'number' ? numRating?.toFixed(1) : 0

  return (
    <StyledCardNumRating>
      {topText && <CardNumRatingHeader>{topText}</CardNumRatingHeader>}
      <CardNumRatingNumber bgColor={bgColor}>{formattedAvgRating}</CardNumRatingNumber>
      {bottomText && <CardNumRatingCount>{bottomText}</CardNumRatingCount>}
    </StyledCardNumRating>
  )
}

export default CardNumRating

CardNumRating.propTypes = {
  config: PropTypes.shape({
    topText: PropTypes.string,
    numRating: PropTypes.number,
    bottomText: PropTypes.string,
    staticColor: PropTypes.oneOf(Object.values(theme.color))
  })
}
