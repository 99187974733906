import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import { graphql } from 'react-relay'
import PropTypes from 'prop-types'
import UserTypes from '@types/User.types'
import {
  applyBreakpointMin,
  breakpointSmediumAndBelow,
  breakpointSmallAndBelow,
  breakpointTinyAndBelow
} from '@StyledComponents/theme/helpers/breakpoints'
import QueryWrapper from '@componentUtils/QueryWrapper'
import PageWrapper from '@components/PageWrapper'
import UpdateAccountForms from '@components/UpdateAccountForms'
import LABEL_STYLE from '@enums/LABEL_STYLE'
import TextField from '@StyledComponents/TextField'
import SETTINGS_FORM_MODE from '@enums/SETTINGS_FORM_MODE'
import DeleteAccount from '@components/DeleteAccount'
import IconButton from '@StyledComponents/IconButton'
import Tooltip from '@components/Tooltip/Tooltip'
import TOOLTIPS from '@enums/TOOLTIPS'
import useOAuth from '@utils/useOAuth'
import { FormFieldsArea } from './UserProfilePage'

export const oauthTooltipText = 'You cannot change your email because you signed up with Google'

export const AccountSettingsPage = styled(PageWrapper)`
  ${applyBreakpointMin(
    'large',
    css`
      max-width: none;
    `
  )}
`

const AccountPagesWrapper = styled.div`
  margin: auto;
  max-width: 755px;
  min-height: inherit;
  width: 100%;
`

const DummyFormArea = styled.div`
  margin-top: 50px;
  text-align: left;
  width: 100%;
`

export const AccountSettingsFormsArea = styled.div`
  ${breakpointSmediumAndBelow(css`
    width: 100%;
    max-width: ${props => props.theme.mainColumnWidth.smedium};
  `)};
  ${breakpointSmallAndBelow(css`
    max-width: ${props => props.theme.mainColumnWidth.small};
  `)}
`

const StyledTextField = styled(TextField)`
  margin-bottom: 22px;

  ${breakpointSmallAndBelow(
    css`
      width: inherit;
      label {
        width: auto !important;
      }
    `
  )}
  ${breakpointTinyAndBelow(
    css`
      width: auto;
    `
  )}
`

const EditButtonContainer = styled.div`
  margin-top: 24px;
  position: relative;
  right: 0;

  &:hover {
    cursor: pointer;
  }
`

export const StyledEditIconButton = styled(IconButton.Edit)``
export const StyledEditIconButtonWrapper = styled.div``

const query = graphql`
  query UserAccountSettingsPageQuery {
    user {
      oauth
      Email
    }
  }
`

function DummyForm(email, oauth, setFormMode) {
  // NOTE: This password field uses value prop rather than placeholder because we
  // want password style bullets to show in this dummy field. placeholder shows
  // as plain text even when field type is password

  const isNewOauth = useOAuth()

  const getEditButton = () => {
    if (isNewOauth) {
      return (
        <StyledEditIconButtonWrapper
          data-for={TOOLTIPS.GLOBAL_TOOLTIP}
          data-tip={oauthTooltipText}
          data-tooltip
        >
          <StyledEditIconButton
            disabled={oauth}
            onClick={() => setFormMode(SETTINGS_FORM_MODE.EDITING)}
          >
            Edit
          </StyledEditIconButton>
        </StyledEditIconButtonWrapper>
      )
    }

    return (
      <StyledEditIconButton onClick={() => setFormMode(SETTINGS_FORM_MODE.EDITING)}>
        Edit
      </StyledEditIconButton>
    )
  }
  return (
    <AccountPagesWrapper>
      {oauth && <Tooltip id="oauth-tooltip" />}
      <FormFieldsArea>
        <DummyFormArea>
          <StyledTextField
            disabled
            placeholder={email}
            label="Email"
            value=""
            name="dummy-email"
            labelStyle={LABEL_STYLE.LEFT}
          />
          <StyledTextField
            isPassword
            disabled
            value="********"
            label="Password"
            name="dummy-password"
            labelStyle={LABEL_STYLE.LEFT}
          />
        </DummyFormArea>
        <EditButtonContainer>{getEditButton()}</EditButtonContainer>
      </FormFieldsArea>
    </AccountPagesWrapper>
  )
}

const getForm = (user, formMode, setFormMode) => {
  if (formMode === SETTINGS_FORM_MODE.EDITING) {
    return <UpdateAccountForms setFormMode={setFormMode} />
  }

  if (formMode === SETTINGS_FORM_MODE.DELETE_ACCOUNT) {
    return <DeleteAccount setFormMode={setFormMode} />
  }

  return DummyForm(user.Email, user.oauth, setFormMode)
}

export function UserAccountSettings({ user, loading }) {
  const [formMode, setFormMode] = useState(SETTINGS_FORM_MODE.NORMAL)

  if (loading) {
    return <PageWrapper>Loading...</PageWrapper>
  }

  return (
    <React.Fragment>
      <AccountSettingsPage>
        <AccountSettingsFormsArea>{getForm(user, formMode, setFormMode)}</AccountSettingsFormsArea>
      </AccountSettingsPage>
    </React.Fragment>
  )
}

export const UserAccountSettingsPage = () => {
  return <QueryWrapper query={query} component={UserAccountSettings} />
}

UserAccountSettings.propTypes = {
  user: UserTypes,
  loading: PropTypes.bool
}

UserAccountSettingsPage.serverQueries = [
  {
    query
  }
]

export default UserAccountSettingsPage
