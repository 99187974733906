import React from 'react'
import PropTypes from 'prop-types'
import { TOAST_EVENTS } from '@utils/toast'
import Toast, { ToastHeader, ToastDescription } from './Toast'

const AccountDeletedToast = ({ closeToast }) => {
  return (
    <Toast canDismiss toastEvent={TOAST_EVENTS.ACCOUNT_DELETED} closeToast={closeToast}>
      <ToastHeader>RIP</ToastHeader>
      <ToastDescription>
        Your account has been successfully deleted. Sorry to see you go{' '}
        <span role="img" aria-label="crying face emoji">
          😢
        </span>
      </ToastDescription>
    </Toast>
  )
}

AccountDeletedToast.propTypes = {
  closeToast: PropTypes.func.isRequired
}

export default AccountDeletedToast
