import React from 'react'
import AdNoBid from './NoBid/AdNoBid'

const AdPixels = () => {
  return (
    <React.Fragment>
      <AdNoBid code="ad_1x1_bounce_x" pixel />
      <AdNoBid code="ad_1x1" pixel />
    </React.Fragment>
  )
}

export default AdPixels
