/* eslint-disable no-param-reassign */
import React from 'react'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'
import { breakpointSmediumAndBelow } from '@StyledComponents/theme/helpers/breakpoints'

export const StyledCopyright = styled.div`
  align-items: flex-end;
  flex-direction: row;
  justify-content: center;
  min-width: 0;

  ${breakpointSmediumAndBelow(
    css`
      display: flex;
      flex-direction: column;
      align-items: center;
    `
  )}
`

const CopyrightText = styled.div`
  color: white;
  flex-grow: 1;
  ${breakpointSmediumAndBelow(
    css`
      text-align: center;
    `
  )}
`

const FooterCopyright = ({ isOpen, alwaysShowCopyright }) => {
  return (
    <StyledCopyright
      animationName="footer"
      alwaysShowCopyright={alwaysShowCopyright}
      watchForChanges={isOpen}
    >
      <CopyrightText>
        © {new Date().getFullYear()} Rate My Professors, LLC. All Rights Reserved
      </CopyrightText>
    </StyledCopyright>
  )
}

FooterCopyright.propTypes = {
  isOpen: PropTypes.bool,
  alwaysShowCopyright: PropTypes.bool
}

export default FooterCopyright
