/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type CardFeedback_teacher$ref = any;
type CardName_teacher$ref = any;
type CardSchool_teacher$ref = any;
type TeacherBookmark_teacher$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type TeacherCard_teacher$ref: FragmentReference;
declare export opaque type TeacherCard_teacher$fragmentType: TeacherCard_teacher$ref;
export type TeacherCard_teacher = {|
  +id: ?string,
  +legacyId: ?number,
  +avgRating: ?number,
  +numRatings: ?number,
  +$fragmentRefs: CardFeedback_teacher$ref & CardSchool_teacher$ref & CardName_teacher$ref & TeacherBookmark_teacher$ref,
  +$refType: TeacherCard_teacher$ref,
|};
export type TeacherCard_teacher$data = TeacherCard_teacher;
export type TeacherCard_teacher$key = {
  +$data?: TeacherCard_teacher$data,
  +$fragmentRefs: TeacherCard_teacher$ref,
};
*/


const node/*: ReaderFragment*/ = {
  "kind": "Fragment",
  "name": "TeacherCard_teacher",
  "type": "Teacher",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "id",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "legacyId",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "avgRating",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "numRatings",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "FragmentSpread",
      "name": "CardFeedback_teacher",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "CardSchool_teacher",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "CardName_teacher",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "TeacherBookmark_teacher",
      "args": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '28ae28f05cf0766db8c7adb5c99edfbe';
module.exports = node;
