import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import parseAndSanitize from '@utils/parseAndSanitize'

export const StyledComments = styled.div`
  flex: 1;
  font-size: ${props => props.theme.fontSize.medium};
  line-height: 1.5;
  margin-bottom: 30px;
`
const Comments = ({ content }) => {
  return <StyledComments>{parseAndSanitize(content)}</StyledComments>
}

Comments.propTypes = {
  content: PropTypes.string
}

export default Comments
