import React, { useState, useEffect, useContext } from 'react'
import PropTypes from 'prop-types'
import styled, { ThemeContext } from 'styled-components'
import isSSR from '@utils/isSSR'
import { isInBreakpointRange } from '@StyledComponents/theme/helpers/breakpoints'

const StyledRenderForBreakPoints = styled.div`
  display: ${props => {
    return props.isShown ? 'block' : 'none'
  }};
  position: relative;
`

/**
 * Conditionally Render a component based upon breakpoints.
 * NOTE: This component will always render a div, however if it's meant not to
 * be shown the div will have `display: none` styling and be empty (the children
 * won't be rendered).
 * NOTE: The component will *always* render for SSR, as otherwise this component
 * won't hydrate correctly.
 */
export default function RenderForBreakpoints({ min = null, max = null, children }) {
  const theme = useContext(ThemeContext)
  const [isShown, setIsShown] = useState(isInBreakpointRange(theme, min, max) || isSSR())

  useEffect(() => {
    const resizeCallback = () => {
      const shouldShow = isInBreakpointRange(theme, min, max)
      if (shouldShow !== isShown) setIsShown(shouldShow)
    }
    window.addEventListener('resize', resizeCallback)

    // Do it once when mounting to resolve incorrect value from SSR
    resizeCallback()

    return () => {
      window.removeEventListener('resize', resizeCallback)
    }
  })

  return (
    <StyledRenderForBreakPoints isShown={isShown}>{isShown && children}</StyledRenderForBreakPoints>
  )
}

RenderForBreakpoints.propTypes = {
  min: PropTypes.string,
  max: PropTypes.string,
  children: PropTypes.node
}
