import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { DropdownSelect, Error } from 'storybook-rmp'
import { useFormikContext } from 'formik'
import STATES from '@pages/AddCampus/constants/States'

const StateSelect = ({ id, name, label, value, onChange, onBlur, error }) => {
  const formik = useFormikContext()
  /**
   * Default to just US states b/c of duplicate key values
   */
  const [filteredStates, setFilteredStates] = useState(
    STATES.filter(option => option.countryId === '0')
  )

  useEffect(() => {
    if (formik.values.country) {
      setFilteredStates(STATES.filter(option => option.countryId === formik.values.country))
    }
  }, [formik.values.country])

  return (
    <React.Fragment>
      <DropdownSelect
        id={id}
        name={name}
        label={label}
        options={filteredStates}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
      />
      {error && <Error error={error} />}
    </React.Fragment>
  )
}

StateSelect.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  error: PropTypes.string
}

export default StateSelect
