/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type RateTeacherLink_teacher$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type NoRatingsArea_teacher$ref: FragmentReference;
declare export opaque type NoRatingsArea_teacher$fragmentType: NoRatingsArea_teacher$ref;
export type NoRatingsArea_teacher = {|
  +lastName: ?string,
  +$fragmentRefs: RateTeacherLink_teacher$ref,
  +$refType: NoRatingsArea_teacher$ref,
|};
export type NoRatingsArea_teacher$data = NoRatingsArea_teacher;
export type NoRatingsArea_teacher$key = {
  +$data?: NoRatingsArea_teacher$data,
  +$fragmentRefs: NoRatingsArea_teacher$ref,
};
*/


const node/*: ReaderFragment*/ = {
  "kind": "Fragment",
  "name": "NoRatingsArea_teacher",
  "type": "Teacher",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "lastName",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "FragmentSpread",
      "name": "RateTeacherLink_teacher",
      "args": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '92ca689b865754799c5aa9eea6b29fe6';
module.exports = node;
