/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type TeacherBookmark_teacher$ref = any;
type TeacherTitles_teacher$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type HeaderDescription_teacher$ref: FragmentReference;
declare export opaque type HeaderDescription_teacher$fragmentType: HeaderDescription_teacher$ref;
export type HeaderDescription_teacher = {|
  +id: ?string,
  +firstName: ?string,
  +lastName: ?string,
  +department: ?string,
  +school: ?{|
    +legacyId: ?number,
    +name: ?string,
    +city: ?string,
    +state: ?string,
  |},
  +$fragmentRefs: TeacherTitles_teacher$ref & TeacherBookmark_teacher$ref,
  +$refType: HeaderDescription_teacher$ref,
|};
export type HeaderDescription_teacher$data = HeaderDescription_teacher;
export type HeaderDescription_teacher$key = {
  +$data?: HeaderDescription_teacher$data,
  +$fragmentRefs: HeaderDescription_teacher$ref,
};
*/


const node/*: ReaderFragment*/ = {
  "kind": "Fragment",
  "name": "HeaderDescription_teacher",
  "type": "Teacher",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "id",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "firstName",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "lastName",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "department",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "school",
      "storageKey": null,
      "args": null,
      "concreteType": "School",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "legacyId",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "name",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "city",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "state",
          "args": null,
          "storageKey": null
        }
      ]
    },
    {
      "kind": "FragmentSpread",
      "name": "TeacherTitles_teacher",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "TeacherBookmark_teacher",
      "args": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = 'ca7a8ee84885e857b9bd3e544fca7329';
module.exports = node;
