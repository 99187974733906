import Cookies from '@utils/Cookies'
import { graphql } from 'react-relay'
import commitMutation from '@relay/commitMutation'

const userUpdateMutation = graphql`
  mutation getAndSetUserSchoolMutation($user: UserInputObject!) {
    userUpdate(user: $user) {
      user {
        school {
          id
        }
      }
    }
  }
`

const saveSchool = (user, schoolId) => {
  commitMutation({
    mutation: userUpdateMutation,
    variables: { user: { email: user.Email, schoolId } },
    onCompleted: (result, err) => {
      if (err) {
        console.error(err)
      }
    }
  })
}

export const getUserSchool = user => {
  if (!user?.school) {
    const schoolCookies = {
      id: Cookies.get('userSchoolId'),
      legacyId: Cookies.get('userSchoolLegacyId'),
      name: Cookies.get('userSchoolName')
    }

    return Cookies.get('userSchoolId') ? schoolCookies : null
  }

  return user.school
}

export const removeSchoolCookies = () => {
  Cookies.remove('userSchoolId')
  Cookies.remove('userSchoolLegacyId')
  Cookies.remove('userSchoolName')
}

export const setUserSchool = (user, item) => {
  if (!user?.Email) {
    if (item) {
      Cookies.set('userSchoolId', item?.id)
      Cookies.set('userSchoolLegacyId', item?.legacyId)
      Cookies.set('userSchoolName', item?.name)
    } else {
      removeSchoolCookies()
    }

    return
  }

  saveSchool(user, item?.id)
}
