/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type SchoolSummary_school$ref: FragmentReference;
declare export opaque type SchoolSummary_school$fragmentType: SchoolSummary_school$ref;
export type SchoolSummary_school = {|
  +summary: ?{|
    +schoolReputation: ?number,
    +schoolSatisfaction: ?number,
    +internetSpeed: ?number,
    +campusCondition: ?number,
    +schoolSafety: ?number,
    +careerOpportunities: ?number,
    +socialActivities: ?number,
    +foodQuality: ?number,
    +clubAndEventActivities: ?number,
    +campusLocation: ?number,
  |},
  +$refType: SchoolSummary_school$ref,
|};
export type SchoolSummary_school$data = SchoolSummary_school;
export type SchoolSummary_school$key = {
  +$data?: SchoolSummary_school$data,
  +$fragmentRefs: SchoolSummary_school$ref,
};
*/


const node/*: ReaderFragment*/ = {
  "kind": "Fragment",
  "name": "SchoolSummary_school",
  "type": "School",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "summary",
      "storageKey": null,
      "args": null,
      "concreteType": "SchoolSummary",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "schoolReputation",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "schoolSatisfaction",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "internetSpeed",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "campusCondition",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "schoolSafety",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "careerOpportunities",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "socialActivities",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "foodQuality",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "clubAndEventActivities",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "campusLocation",
          "args": null,
          "storageKey": null
        }
      ]
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '4b74861fa738aef745a1132f7987c755';
module.exports = node;
