/**
 * @flow
 * @relayHash 8c5d6a1b6e370075ccce22081efb1e45
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type SchoolSearchPagination_search$ref = any;
export type SchoolSearchQuery = {|
  text?: ?string
|};
export type SchoolSearchPaginationQueryVariables = {|
  count: number,
  cursor?: ?string,
  query: SchoolSearchQuery,
|};
export type SchoolSearchPaginationQueryResponse = {|
  +search: ?{|
    +$fragmentRefs: SchoolSearchPagination_search$ref
  |}
|};
export type SchoolSearchPaginationQuery = {|
  variables: SchoolSearchPaginationQueryVariables,
  response: SchoolSearchPaginationQueryResponse,
|};
*/


/*
query SchoolSearchPaginationQuery(
  $count: Int!
  $cursor: String
  $query: SchoolSearchQuery!
) {
  search: newSearch {
    ...SchoolSearchPagination_search_1jWD3d
  }
}

fragment SchoolSearchPagination_search_1jWD3d on newSearch {
  schools(query: $query, first: $count, after: $cursor) {
    edges {
      cursor
      node {
        name
        ...SchoolCard_school
        id
        __typename
      }
    }
    pageInfo {
      hasNextPage
      endCursor
    }
    resultCount
  }
}

fragment SchoolCard_school on School {
  legacyId
  name
  numRatings
  avgRating
  avgRatingRounded
  ...SchoolCardHeader_school
  ...SchoolCardLocation_school
}

fragment SchoolCardHeader_school on School {
  name
}

fragment SchoolCardLocation_school on School {
  city
  state
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "count",
    "type": "Int!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "cursor",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "query",
    "type": "SchoolSearchQuery!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "Variable",
  "name": "query",
  "variableName": "query"
},
v2 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "cursor"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "count"
  },
  (v1/*: any*/)
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "SchoolSearchPaginationQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": "search",
        "name": "newSearch",
        "storageKey": null,
        "args": null,
        "concreteType": "newSearch",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "SchoolSearchPagination_search",
            "args": [
              {
                "kind": "Variable",
                "name": "count",
                "variableName": "count"
              },
              {
                "kind": "Variable",
                "name": "cursor",
                "variableName": "cursor"
              },
              (v1/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "SchoolSearchPaginationQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": "search",
        "name": "newSearch",
        "storageKey": null,
        "args": null,
        "concreteType": "newSearch",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "schools",
            "storageKey": null,
            "args": (v2/*: any*/),
            "concreteType": "SchoolSearchConnectionConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "SchoolSearchConnectionEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "cursor",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "School",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "name",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "legacyId",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "numRatings",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "avgRating",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "avgRatingRounded",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "city",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "state",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "id",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "__typename",
                        "args": null,
                        "storageKey": null
                      }
                    ]
                  }
                ]
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "pageInfo",
                "storageKey": null,
                "args": null,
                "concreteType": "PageInfo",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "hasNextPage",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "endCursor",
                    "args": null,
                    "storageKey": null
                  }
                ]
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "resultCount",
                "args": null,
                "storageKey": null
              }
            ]
          },
          {
            "kind": "LinkedHandle",
            "alias": null,
            "name": "schools",
            "args": (v2/*: any*/),
            "handle": "connection",
            "key": "SchoolSearchPagination_schools",
            "filters": [
              "query"
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "SchoolSearchPaginationQuery",
    "id": null,
    "text": "query SchoolSearchPaginationQuery(\n  $count: Int!\n  $cursor: String\n  $query: SchoolSearchQuery!\n) {\n  search: newSearch {\n    ...SchoolSearchPagination_search_1jWD3d\n  }\n}\n\nfragment SchoolSearchPagination_search_1jWD3d on newSearch {\n  schools(query: $query, first: $count, after: $cursor) {\n    edges {\n      cursor\n      node {\n        name\n        ...SchoolCard_school\n        id\n        __typename\n      }\n    }\n    pageInfo {\n      hasNextPage\n      endCursor\n    }\n    resultCount\n  }\n}\n\nfragment SchoolCard_school on School {\n  legacyId\n  name\n  numRatings\n  avgRating\n  avgRatingRounded\n  ...SchoolCardHeader_school\n  ...SchoolCardLocation_school\n}\n\nfragment SchoolCardHeader_school on School {\n  name\n}\n\nfragment SchoolCardLocation_school on School {\n  city\n  state\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '9f2c9f5d1156089cdf0361ec7db1836b';
module.exports = node;
