import React, { useState } from 'react'
import { graphql } from 'react-relay'
import styled, { css } from 'styled-components'
import ROUTE from '@enums/ROUTE'
import { withRouter, useHistory } from 'react-router-dom'
import {
  breakpointLargePlusAndBelow,
  breakpointTinyAndBelow,
  getPageContentMaxWidth
} from '@StyledComponents/theme/helpers/breakpoints'
import LABEL_STYLE from '@enums/LABEL_STYLE'
import { poppinsBoldest } from '@StyledComponents/theme/typesets'
import PageWrapper from '@components/PageWrapper'
import AdRail from '@components/Ads/AdRail'
import Form from '@components/Form/Form'
import FormCheckbox from '@components/Form/FormCheckbox'
import TextInput from '@components/Form/TextInput'
import SEOMetaInfo from '@components/SEOMetaInfo'
import useNewForms from '@utils/useNewForms'
import AddSchoolForm from '@components/Storybook/AddSchoolForm/AddSchoolForm'
import SubmitButtonArea from './SubmitButtonArea'
import { LocationSelection } from './LocationSelection'

const addCampusPageMutation = graphql`
  mutation AddCampusPageMutation(
    $name: String!
    $nickname: String
    $city: String!
    $state: String!
    $country: String!
    $website: String!
    $email: String!
    $recaptcha: String!
  ) {
    schoolCreate(
      name: $name
      nickname: $nickname
      city: $city
      state: $state
      country: $country
      website: $website
      email: $email
      recaptcha: $recaptcha
    ) {
      validation {
        ...FormController_validation
      }
    }
  }
`

const StyledAddCampusPage = styled(PageWrapper)`
  text-align: left;
  ${breakpointLargePlusAndBelow(css`
    padding: 0 24px;
  `)}
  ${breakpointTinyAndBelow(css`
    padding: 0;
  `)}
`

const AddCampusHeading = styled.div`
  font-size: 42px;
  line-height: 60px;
  ${poppinsBoldest}
`

const AddCampusSubHeading = styled.div`
  font-size: 16px;
  font-style: italic;
  font-weight: 300;
`

const AddCampusForm = styled(Form)`
  margin-top: 35px;
  ${props => props.isNewForms && `display: none;`}
  ${({ theme }) => getPageContentMaxWidth(theme)};
`

const StyledTextInput = styled(TextInput)`
  max-width: 441px;
`

const StyledCheckbox = styled(FormCheckbox)`
  font-size: 16px;
  font-weight: 500;
  margin-right: 4px;
`

const CheckboxWrapper = styled.div`
  margin-top: 40px;
`

const AddCampusPage = () => {
  const history = useHistory()
  const isNewForms = useNewForms()

  const [headerData, setHeaderData] = useState({
    name: ''
  })

  const subHeader =
    'Please use the search bar above to make sure that the school does not already exist.'

  return (
    <StyledAddCampusPage>
      <SEOMetaInfo title="Add a school | Rate My Professors" />
      <AddCampusHeading>Add a School</AddCampusHeading>
      <AddCampusSubHeading>{subHeader}</AddCampusSubHeading>

      <AddSchoolForm isNewForms={isNewForms} />

      <AddCampusForm
        isNewForms={isNewForms}
        getVariables={model => {
          setHeaderData({
            name: model.name
          })
          return {
            name: model.name,
            nickname: model.nickname,
            city: model.city,
            state: model.state,
            country: model.country,
            website: model.website,
            email: model.email,
            recaptcha: model.recaptcha
          }
        }}
        mutation={addCampusPageMutation}
        onSuccess={() => {
          history.push({
            pathname: ROUTE.ADD_CAMPUS_SUCCESS,
            state: {
              campusName: headerData.name
            }
          })
        }}
      >
        <StyledTextInput name="name" label="Name of School" labelStyle={LABEL_STYLE.TOP} />
        <LocationSelection />
        <StyledTextInput name="website" label="Website" labelStyle={LABEL_STYLE.TOP} />
        <StyledTextInput name="email" label="Your Email" labelStyle={LABEL_STYLE.TOP} />
        <CheckboxWrapper>
          <StyledCheckbox
            label="I agree to the Terms of Use and Privacy Policy"
            name="termsPrivacy"
          />
        </CheckboxWrapper>
        <SubmitButtonArea />
      </AddCampusForm>
      <AdRail />
    </StyledAddCampusPage>
  )
}

const withRouterAddCampusPage = withRouter(AddCampusPage)

export default withRouterAddCampusPage
