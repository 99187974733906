/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type CourseCode_teacher$ref: FragmentReference;
declare export opaque type CourseCode_teacher$fragmentType: CourseCode_teacher$ref;
export type CourseCode_teacher = {|
  +courseCodes: ?$ReadOnlyArray<?{|
    +courseName: ?string
  |}>,
  +$refType: CourseCode_teacher$ref,
|};
export type CourseCode_teacher$data = CourseCode_teacher;
export type CourseCode_teacher$key = {
  +$data?: CourseCode_teacher$data,
  +$fragmentRefs: CourseCode_teacher$ref,
};
*/


const node/*: ReaderFragment*/ = {
  "kind": "Fragment",
  "name": "CourseCode_teacher",
  "type": "Teacher",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "courseCodes",
      "storageKey": null,
      "args": null,
      "concreteType": "Course",
      "plural": true,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "courseName",
          "args": null,
          "storageKey": null
        }
      ]
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '8e78430448c5a10b9561391ca8b8bb1c';
module.exports = node;
