import { toast as toastify } from 'react-toastify'

export const TOAST_TYPES = {
  SUCCESS: 'SUCCESS',
  ALERT: 'ALERT',
  FYI: 'FYI'
}

export const TOAST_EVENTS = {
  HARD_LOCKED_TEACHER: 'HARD_LOCKED_TEACHER',
  SOFT_LOCKED_TEACHER: 'SOFT_LOCKED_TEACHER',
  LOGGED_OUT: 'LOGGED_OUT',
  EMAIL_CONFIRMATION: 'EMAIL_CONFIRMATION',
  EMAIL_CONFIRMATION_REMINDER: 'EMAIL_CONFIRMATION_REMINDER',
  SIGNUP_COMPLETE: 'SIGNUP_COMPLETE',
  RATING_SUBMITTED: 'RATING_SUBMITTED',
  ACCOUNT_DELETED: 'ACCOUNT_DELETED',
  FLAG_RATING_SUCCESS: 'FLAG_RATING_SUCCESS',
  SHARE_COMPARISON: 'SHARE_COMPARISON',
  SHARE_RATING: 'SHARE_RATING'
}

export const toastConfigMap = {
  [TOAST_EVENTS.HARD_LOCKED_TEACHER]: {
    type: TOAST_TYPES.ALERT,
    emoji: '⛔',
    label: 'no entry emoji',
    id: 'locked-teacher-toast',
    autoClose: 15000
  },
  [TOAST_EVENTS.SOFT_LOCKED_TEACHER]: {
    type: TOAST_TYPES.FYI,
    emoji: '⛔',
    label: 'no entry emoji',
    id: 'locked-teacher-toast',
    autoClose: 15000
  },
  [TOAST_EVENTS.LOGGED_OUT]: {
    type: TOAST_TYPES.SUCCESS,
    emoji: '🚪',
    label: 'door emoji',
    id: 'logged-out-toast',
    autoClose: 15000
  },
  [TOAST_EVENTS.EMAIL_CONFIRMATION]: {
    type: TOAST_TYPES.SUCCESS,
    emoji: '👌',
    label: 'ok hand emoji',
    id: 'email-confirmation-toast',
    autoClose: 15000
  },
  [TOAST_EVENTS.EMAIL_CONFIRMATION_REMINDER]: {
    type: TOAST_TYPES.FYI,
    emoji: '✉️',
    label: 'email emoji',
    id: 'email-confirmation-reminder-toast',
    autoClose: false
  },
  [TOAST_EVENTS.SIGNUP_COMPLETE]: {
    type: TOAST_TYPES.SUCCESS,
    emoji: '🎉',
    label: 'party popper emoji',
    id: 'signup-complete-toast',
    autoClose: 15000
  },
  [TOAST_EVENTS.RATING_SUBMITTED]: {
    type: TOAST_TYPES.SUCCESS,
    emoji: '🎉',
    label: 'party popper emoji',
    id: 'rating_submitted_toast',
    autoClose: 15000
  },
  [TOAST_EVENTS.ACCOUNT_DELETED]: {
    type: TOAST_TYPES.SUCCESS,
    emoji: '⚰️',
    label: 'coffin emoji',
    id: 'account_deleted_toast',
    autoClose: 15000
  },
  [TOAST_EVENTS.FLAG_RATING_SUCCESS]: {
    type: TOAST_TYPES.SUCCESS,
    emoji: '✅',
    label: 'green check mark emoji',
    id: 'flag_rating_success_toast',
    autoClose: 15000
  },
  [TOAST_EVENTS.SHARE_COMPARISON]: {
    type: TOAST_TYPES.SUCCESS,
    emoji: '👌',
    label: 'ok hand emoji',
    id: 'share_comparison_toast',
    autoClose: 7500
  },
  [TOAST_EVENTS.SHARE_RATING]: {
    type: TOAST_TYPES.SUCCESS,
    emoji: '👌',
    label: 'ok hand emoji',
    id: 'share_comparison_toast',
    autoClose: 7500
  }
}

const toast = (component, toastEvent) => {
  toastify(component, {
    toastId: toastConfigMap[toastEvent].id,
    autoClose: toastConfigMap[toastEvent].autoClose
  })
}
export default toast
