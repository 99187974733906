import React from 'react'
import styled from 'styled-components'
import { Form, useFormikContext } from 'formik'
import NameInput from '@components/Storybook/Shared/Fields/NameInput'
import SearchSchoolsInput from '@components/Storybook/Shared/Fields/SearchSchoolsInput'
import DepartmentSelect from '@components/Storybook/Shared/Fields/DepartmentSelect'
import CheckboxInput from '@components/Storybook/Shared/Fields/CheckboxInput'

const StyledForm = styled(Form)`
  max-width: 564px;
  padding-bottom: 24px;
  position: relative;
`

const AddProfessorFormContent = () => {
  const formik = useFormikContext()

  return (
    <StyledForm id="add-professor-form">
      <SearchSchoolsInput
        id="schoolName"
        name="schoolName"
        label="Name of School"
        aria-label="Name of School"
        value={formik.values.schoolName}
      />
      <NameInput
        id="firstName"
        name="firstName"
        label="Professor's First Name"
        value={formik.values.firstName}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={formik.touched.firstName && formik.errors.firstName}
      />
      <NameInput
        id="middleName"
        name="middleName"
        label="Professor's Middle Name"
        value={formik.values.middleName}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={formik.touched.middleName && formik.errors.middleName}
      />
      <NameInput
        id="lastName"
        name="lastName"
        label="Professor's Last Name"
        value={formik.values.lastName}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={formik.touched.lastName && formik.errors.lastName}
      />
      <DepartmentSelect
        id="department"
        name="department"
        label="Department"
        value={formik.values.department}
        onChange={dept => formik.setFieldValue('department', dept)}
        onBlur={formik.handleBlur}
        options={formik.values.departments}
        error={formik.touched.lastName && formik.errors.department}
      />
      <NameInput
        id="directoryUrl"
        name="directoryUrl"
        label="Directory Listing of Professor"
        value={formik.values.directoryUrl}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={formik.touched.directoryUrl && formik.errors.directoryUrl}
      />
      <CheckboxInput value={formik.values.checkbox} onChange={formik.handleChange} />
    </StyledForm>
  )
}

export default AddProfessorFormContent
