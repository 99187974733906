import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import { createFragmentContainer, graphql } from 'react-relay'

import {
  breakpointLargeAndBelow,
  getPageContentMaxWidth
} from '@StyledComponents/theme/helpers/breakpoints'
import TeacherTypes from '@types/Teacher.types'
import RatingsList from './RatingsList'
import RatingsFilterList from './RatingsFilterList'

export const StyledTabs = styled(Tabs)`
  ${({ theme }) => getPageContentMaxWidth(theme)};
`

export const StyledTabList = styled(TabList)`
  border-bottom: ${props => `2px solid ${props.theme.color.gray}`};
  display: flex;
  margin: ${props =>
    `28px ${props.theme.padding.teacherInfo} 0 ${props.theme.padding.teacherInfo}`};
  ${breakpointLargeAndBelow(css`
    margin: 0;
  `)};
`

export const StyledTab = styled(Tab)`
  border-bottom: ${props => (props.selected ? `2px solid ${props.theme.color.default}` : '')};
  color: ${props => props.theme.color.default};
  cursor: pointer;
  font-weight: ${props => props.theme.fontWeight.bold};
  line-height: 1.8;
  min-width: 150px;
  padding-bottom: 8px;

  ${breakpointLargeAndBelow(
    css`
      font-size: ${props => props.theme.fontSize.default};
      width: 100%;
    `
  )}

  &:hover {
    border-bottom: ${props => `2px solid ${props.theme.color.fadedDefault}`};
    color: ${props => props.theme.color.fadedDefault};
  }
`

export const StyledTabPage = styled.div`
  padding: ${props => `0 ${props.theme.padding.teacherInfo}`};
`

const getNumberOfRatings = (teacher, courseFilter) => {
  if (!courseFilter) return teacher.numRatings

  return teacher.courseCodes.find(course => course.courseName === courseFilter).courseCount
}

export function TeacherRatingTabs({ teacher }) {
  const [courseFilter, setCourseFilter] = useState('')

  if (!teacher || (teacher.numRatings === null && teacher.numNotes === null)) return null
  return (
    <StyledTabs data-ad-target="ratings-list" defaultIndex={0}>
      <StyledTabList>
        <StyledTab>{getNumberOfRatings(teacher, courseFilter)} Student Ratings</StyledTab>
      </StyledTabList>
      <StyledTabPage>
        <TabPanel>
          <RatingsFilterList
            teacher={teacher}
            courseFilter={courseFilter}
            setCourseFilter={setCourseFilter}
            numRatings={teacher.numRatings}
          />
          <RatingsList teacher={teacher} courseFilter={courseFilter} />
        </TabPanel>
      </StyledTabPage>
    </StyledTabs>
  )
}

TeacherRatingTabs.propTypes = {
  teacher: TeacherTypes
}

export default createFragmentContainer(TeacherRatingTabs, {
  teacher: graphql`
    fragment TeacherRatingTabs_teacher on Teacher {
      numRatings
      courseCodes {
        courseName
        courseCount
      }
      ...RatingsList_teacher
      ...RatingsFilter_teacher
    }
  `
})
