import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { breakpointSmallPlusAndBelow } from '@StyledComponents/theme/helpers/breakpoints'
import GradeSquare from '@StyledComponents/GradeSquare'

const CategoryGradeContainer = styled.div`
  align-items: center;
  display: flex;
  flex-basis: 20%;
  height: 34px;
  ${breakpointSmallPlusAndBelow(css`
    justify-content: center;
    margin-bottom: 16px;
  `)}
`

const CategoryTitle = styled.div`
  font-size: 20px;
  line-height: 24px;
  margin-left: 16px;
  text-align: left;
  width: 160px;
`

const CategoryIcon = styled.img`
  height: 34px;
  width: 34px;
`

const CategoryGrade = ({ name, score, icon }) => {
  const grade = () => {
    const roundedNum = Math.round(score * 10) / 10
    const finalNum = roundedNum.toFixed(1)
    return finalNum
  }

  return (
    <CategoryGradeContainer>
      <CategoryIcon src={icon} alt={name} />
      <CategoryTitle>{name}</CategoryTitle>
      <GradeSquare grade={grade()} />
    </CategoryGradeContainer>
  )
}

CategoryGrade.propTypes = {
  name: PropTypes.string,
  score: PropTypes.number,
  icon: PropTypes.string
}

export default CategoryGrade
