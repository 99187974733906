/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type RatingSuperHeader_rating$ref: FragmentReference;
declare export opaque type RatingSuperHeader_rating$fragmentType: RatingSuperHeader_rating$ref;
export type RatingSuperHeader_rating = {|
  +legacyId: ?number,
  +$refType: RatingSuperHeader_rating$ref,
|};
export type RatingSuperHeader_rating$data = RatingSuperHeader_rating;
export type RatingSuperHeader_rating$key = {
  +$data?: RatingSuperHeader_rating$data,
  +$fragmentRefs: RatingSuperHeader_rating$ref,
};
*/


const node/*: ReaderFragment*/ = {
  "kind": "Fragment",
  "name": "RatingSuperHeader_rating",
  "type": "Rating",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "legacyId",
      "args": null,
      "storageKey": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '2b6105909fb6596284b1c64f31339f97';
module.exports = node;
