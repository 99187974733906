import React, { useContext, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import FormSchoolSelector from '@components/Form/FormSchoolSelector'
import FormContext from '@contexts/FormContext'
import LABEL_STYLE from '@enums/LABEL_STYLE'
import TOOLTIPS from '@enums/TOOLTIPS'
import theme from '@components/StyledComponents/theme'

const CompareSchoolsSearchWrapper = styled.div``

const FormSchoolStyles = `
  background-color: ${theme.color.white};
  color: ${theme.color.black};
  text-align: center;
  height: 36px;
  border-radius: 100px;
  border: 1px solid #e4e4e4;
  line-height: 36px;
  margin-top: 24px;
  display: flex;
`

const CompareSchoolsSearch = ({ primary, primarySelected, handleSchoolSelection }) => {
  const { model } = useContext(FormContext)

  const name = model?.school?.name
  const disabled = !primary && !primarySelected

  useEffect(() => {
    if (model.school) {
      handleSchoolSelection({ primary: primary, selectedSchool: model.school })
    }
  }, [model])

  const getTooltipText = () => {
    if (!disabled) return null
    return 'Search for school in left column first'
  }

  return (
    <CompareSchoolsSearchWrapper
      data-for={TOOLTIPS.GLOBAL_TOOLTIP}
      data-tip={getTooltipText(disabled)}
    >
      {!name && (
        <FormSchoolSelector
          labelStyle={LABEL_STYLE.HIDDEN}
          name="school"
          label="Name of School"
          inModal
          compareSchools
          hideOnSelection
          disabled={disabled}
          styles={FormSchoolStyles}
        />
      )}
    </CompareSchoolsSearchWrapper>
  )
}

CompareSchoolsSearch.propTypes = {
  primary: PropTypes.bool,
  primarySelected: PropTypes.bool,
  handleSchoolSelection: PropTypes.func
}

export default CompareSchoolsSearch
