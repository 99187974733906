/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type ProfessorNoteFooter_note$ref = any;
type ProfessorNoteHeader_note$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type ProfessorNote_note$ref: FragmentReference;
declare export opaque type ProfessorNote_note$fragmentType: ProfessorNote_note$ref;
export type ProfessorNote_note = {|
  +comment: ?any,
  +$fragmentRefs: ProfessorNoteHeader_note$ref & ProfessorNoteFooter_note$ref,
  +$refType: ProfessorNote_note$ref,
|};
export type ProfessorNote_note$data = ProfessorNote_note;
export type ProfessorNote_note$key = {
  +$data?: ProfessorNote_note$data,
  +$fragmentRefs: ProfessorNote_note$ref,
};
*/


const node/*: ReaderFragment*/ = {
  "kind": "Fragment",
  "name": "ProfessorNote_note",
  "type": "TeacherNotes",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "comment",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "FragmentSpread",
      "name": "ProfessorNoteHeader_note",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "ProfessorNoteFooter_note",
      "args": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '08cd5c931e093836bf86fa8aef46c770';
module.exports = node;
