import React from 'react'
import ROUTE from '@enums/ROUTE'
import ReactRouterPropTypes from 'react-router-prop-types'
import TeacherSearchResultsPage from '@pages/Search/TeacherSearchResultsPage'
import PageWrapper from '@components/PageWrapper'
import AddSuccessHeader from './AddSuccessHeader'

const SuccessPage = ({ location }) => {
  const addProfessorSuccess = location.pathname.includes(ROUTE.ADD_PROFESSOR_SUCCESS)
  const addCampusSuccess = location.pathname.includes(ROUTE.ADD_CAMPUS_SUCCESS)

  const submitProfessorCorrectionSuccess = location.pathname.includes(
    ROUTE.NEW_PROFESSOR_CORRECTION_SUCCESS
  )

  const submitCampusCorrectionSuccess = location.pathname.includes(
    ROUTE.NEW_CAMPUS_CORRECTION_SUCCESS
  )

  const headerData = location.state ?? ''

  return (
    <PageWrapper>
      {addProfessorSuccess && (
        <React.Fragment>
          <AddSuccessHeader headerData={headerData} isAddProfessor="true" />
          <TeacherSearchResultsPage />
        </React.Fragment>
      )}

      {addCampusSuccess && (
        <React.Fragment>
          <AddSuccessHeader headerData={headerData} isAddCampus="true" />
        </React.Fragment>
      )}

      {submitProfessorCorrectionSuccess && (
        <React.Fragment>
          <AddSuccessHeader headerData={headerData} isSubmitProfessorCorrection />
        </React.Fragment>
      )}

      {submitCampusCorrectionSuccess && (
        <React.Fragment>
          <AddSuccessHeader headerData={headerData} isSubmitCampusCorrection />
        </React.Fragment>
      )}
    </PageWrapper>
  )
}

SuccessPage.propTypes = {
  location: ReactRouterPropTypes.location
}

export default SuccessPage
