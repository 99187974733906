import React from 'react'
import styled, { css } from 'styled-components'
import { graphql } from 'react-relay'
import UserTypes from '@types/User.types'
import {
  applyBreakpointMin,
  breakpointSmediumAndBelow,
  breakpointSmallAndBelow
} from '@StyledComponents/theme/helpers/breakpoints'
import PropTypes from 'prop-types'
import QueryWrapper from '@componentUtils/QueryWrapper'
import PageWrapper from '@components/PageWrapper'
import MyRatingsList from '@components/MyRatingsList'

const RatingsPage = styled(PageWrapper)`
  align-items: center;
  display: inline-flex;
  flex-direction: column;
  ${applyBreakpointMin(
    'large',
    css`
      max-width: none;
    `
  )}
`

const ratingsSubAreas = css`
  ${breakpointSmediumAndBelow(css`
    width: 100%;
    max-width: ${props => props.theme.mainColumnWidth.smedium};
  `)};
  ${breakpointSmallAndBelow(css`
    max-width: ${props => props.theme.mainColumnWidth.small};
  `)}
`

export const MyRatingsListArea = styled.div`
  margin-top: 30px;
  ${ratingsSubAreas}
  max-width: ${props => props.theme.mainColumnWidth.medium};
`

const query = graphql`
  query UserRatingsPageQuery {
    user {
      Email
      ...MyRatingsList_user
    }
  }
`

export function UserRatings({ user, loading }) {
  if (loading) {
    return <PageWrapper>Loading...</PageWrapper>
  }

  return (
    <RatingsPage>
      <MyRatingsListArea>
        <MyRatingsList user={user} />
      </MyRatingsListArea>
    </RatingsPage>
  )
}

export const UserRatingsPage = () => {
  return <QueryWrapper query={query} component={UserRatings} />
}

UserRatings.propTypes = {
  user: UserTypes.isRequired,
  loading: PropTypes.bool
}

UserRatingsPage.serverQueries = [
  {
    query
  }
]

export default UserRatingsPage
