/* stylelint-disable indentation */
import { css } from 'styled-components'

// Poppins 400, 700, 900 all italic
const Poppins = css`
  /* poppins-regular - latin */
  @font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    src: url('/fonts/poppins-v8-latin-regular.eot'); /* IE9 Compat Modes */
    src: local('Poppins Regular'), local('Poppins-Regular'),
      url('/fonts/poppins-v8-latin-regular.eot?#iefix') format('embedded-opentype'),
      /* IE6-IE8 */ url('/fonts/poppins-v8-latin-regular.woff2') format('woff2'),
      /* Super Modern Browsers */ url('/fonts/poppins-v8-latin-regular.woff') format('woff'),
      /* Modern Browsers */ url('/fonts/poppins-v8-latin-regular.ttf') format('truetype'),
      /* Safari, Android, iOS */ url('/fonts/poppins-v8-latin-regular.svg#Poppins') format('svg'); /* Legacy iOS */
  }

  /* poppins-italic - latin */
  @font-face {
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 400;
    src: url('/fonts/poppins-v8-latin-italic.eot'); /* IE9 Compat Modes */
    src: local('Poppins Italic'), local('Poppins-Italic'),
      url('/fonts/poppins-v8-latin-italic.eot?#iefix') format('embedded-opentype'),
      /* IE6-IE8 */ url('/fonts/poppins-v8-latin-italic.woff2') format('woff2'),
      /* Super Modern Browsers */ url('/fonts/poppins-v8-latin-italic.woff') format('woff'),
      /* Modern Browsers */ url('/fonts/poppins-v8-latin-italic.ttf') format('truetype'),
      /* Safari, Android, iOS */ url('/fonts/poppins-v8-latin-italic.svg#Poppins') format('svg'); /* Legacy iOS */
  }

  /* poppins-700 - latin */
  @font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    src: url('/fonts/poppins-v8-latin-700.eot'); /* IE9 Compat Modes */
    src: local('Poppins Bold'), local('Poppins-Bold'),
      url('/fonts/poppins-v8-latin-700.eot?#iefix') format('embedded-opentype'),
      /* IE6-IE8 */ url('/fonts/poppins-v8-latin-700.woff2') format('woff2'),
      /* Super Modern Browsers */ url('/fonts/poppins-v8-latin-700.woff') format('woff'),
      /* Modern Browsers */ url('/fonts/poppins-v8-latin-700.ttf') format('truetype'),
      /* Safari, Android, iOS */ url('/fonts/poppins-v8-latin-700.svg#Poppins') format('svg'); /* Legacy iOS */
  }

  /* poppins-700italic - latin */
  @font-face {
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 700;
    src: url('/fonts/poppins-v8-latin-700italic.eot'); /* IE9 Compat Modes */
    src: local('Poppins Bold Italic'), local('Poppins-BoldItalic'),
      url('/fonts/poppins-v8-latin-700italic.eot?#iefix') format('embedded-opentype'),
      /* IE6-IE8 */ url('/fonts/poppins-v8-latin-700italic.woff2') format('woff2'),
      /* Super Modern Browsers */ url('/fonts/poppins-v8-latin-700italic.woff') format('woff'),
      /* Modern Browsers */ url('/fonts/poppins-v8-latin-700italic.ttf') format('truetype'),
      /* Safari, Android, iOS */ url('/fonts/poppins-v8-latin-700italic.svg#Poppins') format('svg'); /* Legacy iOS */
  }

  /* poppins-900 - latin */
  @font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 900;
    src: url('/fonts/poppins-v8-latin-900.eot'); /* IE9 Compat Modes */
    src: local('Poppins Black'), local('Poppins-Black'),
      url('/fonts/poppins-v8-latin-900.eot?#iefix') format('embedded-opentype'),
      /* IE6-IE8 */ url('/fonts/poppins-v8-latin-900.woff2') format('woff2'),
      /* Super Modern Browsers */ url('/fonts/poppins-v8-latin-900.woff') format('woff'),
      /* Modern Browsers */ url('/fonts/poppins-v8-latin-900.ttf') format('truetype'),
      /* Safari, Android, iOS */ url('/fonts/poppins-v8-latin-900.svg#Poppins') format('svg'); /* Legacy iOS */
  }

  /* poppins-900italic - latin */
  @font-face {
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 900;
    src: url('/fonts/poppins-v8-latin-900italic.eot'); /* IE9 Compat Modes */
    src: local('Poppins Black Italic'), local('Poppins-BlackItalic'),
      url('/fonts/poppins-v8-latin-900italic.eot?#iefix') format('embedded-opentype'),
      /* IE6-IE8 */ url('/fonts/poppins-v8-latin-900italic.woff2') format('woff2'),
      /* Super Modern Browsers */ url('/fonts/poppins-v8-latin-900italic.woff') format('woff'),
      /* Modern Browsers */ url('/fonts/poppins-v8-latin-900italic.ttf') format('truetype'),
      /* Safari, Android, iOS */ url('/fonts/poppins-v8-latin-900italic.svg#Poppins') format('svg'); /* Legacy iOS */
  }
`

export default Poppins
