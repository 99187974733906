/**
 * @flow
 * @relayHash bee1b2be03454492f475e40f8f1c274b
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type FormController_validation$ref = any;
export type RateTeacherEditMutationVariables = {|
  forCredit?: ?boolean,
  attendanceMandatory?: ?boolean,
  tags?: ?$ReadOnlyArray<?string>,
  class: string,
  wouldTakeAgain: boolean,
  usesTextbooks?: ?boolean,
  teacherID: string,
  ID: string,
  rating: number,
  difficulty: number,
  online?: ?boolean,
  grade?: ?string,
  comment: any,
|};
export type RateTeacherEditMutationResponse = {|
  +editTeacherRating: ?{|
    +validation: ?{|
      +$fragmentRefs: FormController_validation$ref
    |}
  |}
|};
export type RateTeacherEditMutation = {|
  variables: RateTeacherEditMutationVariables,
  response: RateTeacherEditMutationResponse,
|};
*/


/*
mutation RateTeacherEditMutation(
  $forCredit: Boolean
  $attendanceMandatory: Boolean
  $tags: [ID]
  $class: String!
  $wouldTakeAgain: Boolean!
  $usesTextbooks: Boolean
  $teacherID: ID!
  $ID: ID!
  $rating: Int!
  $difficulty: Int!
  $online: Boolean
  $grade: String
  $comment: UserComment!
) {
  editTeacherRating(forCredit: $forCredit, attendanceMandatory: $attendanceMandatory, tags: $tags, class: $class, wouldTakeAgain: $wouldTakeAgain, usesTextbooks: $usesTextbooks, ID: $ID, teacherID: $teacherID, rating: $rating, difficulty: $difficulty, online: $online, grade: $grade, comment: $comment) {
    validation {
      ...FormController_validation
      id
    }
  }
}

fragment FormController_validation on ValidationResult {
  id
  success
  errors {
    field
    messages
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "forCredit",
    "type": "Boolean",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "attendanceMandatory",
    "type": "Boolean",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "tags",
    "type": "[ID]",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "class",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "wouldTakeAgain",
    "type": "Boolean!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "usesTextbooks",
    "type": "Boolean",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "teacherID",
    "type": "ID!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "ID",
    "type": "ID!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "rating",
    "type": "Int!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "difficulty",
    "type": "Int!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "online",
    "type": "Boolean",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "grade",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "comment",
    "type": "UserComment!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "ID",
    "variableName": "ID"
  },
  {
    "kind": "Variable",
    "name": "attendanceMandatory",
    "variableName": "attendanceMandatory"
  },
  {
    "kind": "Variable",
    "name": "class",
    "variableName": "class"
  },
  {
    "kind": "Variable",
    "name": "comment",
    "variableName": "comment"
  },
  {
    "kind": "Variable",
    "name": "difficulty",
    "variableName": "difficulty"
  },
  {
    "kind": "Variable",
    "name": "forCredit",
    "variableName": "forCredit"
  },
  {
    "kind": "Variable",
    "name": "grade",
    "variableName": "grade"
  },
  {
    "kind": "Variable",
    "name": "online",
    "variableName": "online"
  },
  {
    "kind": "Variable",
    "name": "rating",
    "variableName": "rating"
  },
  {
    "kind": "Variable",
    "name": "tags",
    "variableName": "tags"
  },
  {
    "kind": "Variable",
    "name": "teacherID",
    "variableName": "teacherID"
  },
  {
    "kind": "Variable",
    "name": "usesTextbooks",
    "variableName": "usesTextbooks"
  },
  {
    "kind": "Variable",
    "name": "wouldTakeAgain",
    "variableName": "wouldTakeAgain"
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "RateTeacherEditMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "editTeacherRating",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "AddTeacherRatingMutationResponse",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "validation",
            "storageKey": null,
            "args": null,
            "concreteType": "ValidationResult",
            "plural": false,
            "selections": [
              {
                "kind": "FragmentSpread",
                "name": "FormController_validation",
                "args": null
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "RateTeacherEditMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "editTeacherRating",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "AddTeacherRatingMutationResponse",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "validation",
            "storageKey": null,
            "args": null,
            "concreteType": "ValidationResult",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "id",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "success",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "errors",
                "storageKey": null,
                "args": null,
                "concreteType": "FieldErrorType",
                "plural": true,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "field",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "messages",
                    "args": null,
                    "storageKey": null
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "mutation",
    "name": "RateTeacherEditMutation",
    "id": null,
    "text": "mutation RateTeacherEditMutation(\n  $forCredit: Boolean\n  $attendanceMandatory: Boolean\n  $tags: [ID]\n  $class: String!\n  $wouldTakeAgain: Boolean!\n  $usesTextbooks: Boolean\n  $teacherID: ID!\n  $ID: ID!\n  $rating: Int!\n  $difficulty: Int!\n  $online: Boolean\n  $grade: String\n  $comment: UserComment!\n) {\n  editTeacherRating(forCredit: $forCredit, attendanceMandatory: $attendanceMandatory, tags: $tags, class: $class, wouldTakeAgain: $wouldTakeAgain, usesTextbooks: $usesTextbooks, ID: $ID, teacherID: $teacherID, rating: $rating, difficulty: $difficulty, online: $online, grade: $grade, comment: $comment) {\n    validation {\n      ...FormController_validation\n      id\n    }\n  }\n}\n\nfragment FormController_validation on ValidationResult {\n  id\n  success\n  errors {\n    field\n    messages\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '75545b6568e28dd61c13909207c10337';
module.exports = node;
