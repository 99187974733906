import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { poppinsBoldest3x } from '@StyledComponents/theme/typesets'

export const StyledFeedbackItem = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
`

export const FeedbackNumber = styled.div`
  color: ${props => props.theme.color.darkDefault};
  margin-bottom: 4px;

  ${poppinsBoldest3x};
`

export const FeedbackDescription = styled.div`
  font-weight: ${props => props.theme.fontWeight.light};
`

export default function FeedbackItem({ number, label, numRatings }) {
  if (!number || !label) return null

  return (
    <StyledFeedbackItem>
      <FeedbackNumber>{numRatings === 0 ? 'N/A' : number}</FeedbackNumber>
      <FeedbackDescription>{label}</FeedbackDescription>
    </StyledFeedbackItem>
  )
}

FeedbackItem.propTypes = {
  number: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.string,
  numRatings: PropTypes.number
}
