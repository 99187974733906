import ROUTE from '@enums/ROUTE'
import commitMutation from '@relay/commitMutation'
import { graphql } from 'relay-runtime'

const addSchoolFormMutation = graphql`
  mutation AddSchoolFormMutation(
    $name: String!
    $nickname: String
    $city: String!
    $state: String!
    $country: String!
    $website: String!
    $email: String!
    $recaptcha: String!
  ) {
    schoolCreate(
      name: $name
      nickname: $nickname
      city: $city
      state: $state
      country: $country
      website: $website
      email: $email
      recaptcha: $recaptcha
    ) {
      validation {
        id
        success
        errors {
          field
          messages
        }
      }
    }
  }
`

const submitSchoolForm = ({ values, history, setSubmissionError }) => {
  commitMutation({
    mutation: addSchoolFormMutation,
    variables: values,
    onCompleted: response => {
      if (response?.schoolCreate?.validation?.success) {
        /**
         * Handle successful submission
         */
        history.push({
          pathname: ROUTE.ADD_CAMPUS_SUCCESS,
          state: {
            campusName: values.name
          }
        })
      } else {
        const validationErrors = response?.schoolCreate?.validation?.errors?.[0]?.messages
        setSubmissionError(validationErrors?.[0] ?? 'Server error')
      }
    },
    onError: err => {
      setSubmissionError(err.message)
    }
  })
}

export default submitSchoolForm
