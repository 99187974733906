import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import VoteThumb from '@StyledComponents/VoteThumb'
import styled from 'styled-components'
import useUserInfo from '@hooks/useUserInfo'
import VOTEDIRECTION from '@enums/VOTEDIRECTION'
import LOCK_STATUS from '@enums/LOCK_STATUS'
import { createFragmentContainer, graphql } from 'react-relay'
import commitMutation from '@relay/commitMutation'
import RatingTypes from '@types/Rating.types'
import SchoolTypes from '@types/School.types'
import * as Sentry from '@sentry/react'
import TeacherTypes from '@types/Teacher.types'
import generateCompId from '@utils/generateCompId'

const schoolRatingThumbsMutation = graphql`
  mutation ThumbsSchoolRatingMutation(
    $userId: ID
    $ratingId: ID!
    $schoolId: ID!
    $thumbsUp: Boolean!
    $thumbsDown: Boolean!
    $compID: String!
  ) {
    createCampusRatingThumb(
      userId: $userId
      ratingId: $ratingId
      schoolId: $schoolId
      thumbsUp: $thumbsUp
      thumbsDown: $thumbsDown
      compID: $compID
    ) {
      id
      thumbsDown
      thumbsUp
      campusRatingId
      userId
      legacyId
    }
  }
`

const ratingThumbsMutation = graphql`
  mutation ThumbsRatingMutation(
    $ratingId: Int!
    $teacherId: Int!
    $thumbsUpCount: Int!
    $thumbsDownCount: Int!
    $userId: Int
    $compID: String!
  ) {
    createThumb(
      rid: $ratingId
      tid: $teacherId
      thumbsUp: $thumbsUpCount
      thumbsDown: $thumbsDownCount
      userId: $userId
      compID: $compID
    ) {
      id
    }
  }
`

export const ButtonWrapper = styled.div`
  align-items: center;
  display: flex;
`
export const ThumbContainer = styled.div`
  display: flex;
  flex-direction: row;
  font-weight: ${props => props.theme.fontWeight.bold};
  margin-right: 16px;

  img {
    height: 22px;
  }
`

const HelpTotalNumber = styled.div`
  font-size: 16px;
  margin-top: 5px;
`

function getCompVote(thumbs, compId) {
  if (!thumbs) return null

  const compThumbs = thumbs.filter(t => {
    return t.computerId === compId
  })
  if (compThumbs.length === 0) return null

  // Always use the latest (last) one
  const t = compThumbs[compThumbs.length - 1]
  if (t.thumbsUp === 0 && t.thumbsDown === 0) return null
  return t.thumbsUp > t.thumbsDown ? VOTEDIRECTION.UPVOTE : VOTEDIRECTION.DOWNVOTE
}

const THUMB_CONFIG = [
  {
    direction: VOTEDIRECTION.UPVOTE,
    getTotal: rating => rating.thumbsUpTotal
  },
  {
    direction: VOTEDIRECTION.DOWNVOTE,
    getTotal: rating => rating.thumbsDownTotal
  }
]

const getTooltip = (didVote, voteDirection) => {
  if (didVote) {
    return 'Thanks for your feedback!'
  }

  if (voteDirection === VOTEDIRECTION.UPVOTE) {
    return 'Helpful'
  }

  return 'Not helpful'
}

function updateRating(ratingRecord, voteDirection, isAddingVote) {
  const change = isAddingVote ? 1 : -1
  const field = voteDirection === VOTEDIRECTION.UPVOTE ? 'thumbsUpTotal' : 'thumbsDownTotal'
  ratingRecord.setValue(ratingRecord.getValue(field) + change, field)
}

function updateThumb(isSchoolRating, store, ratingRecord, thumbsUpCount, thumbsDownCount, compId) {
  const thumbs = ratingRecord.getLinkedRecords(isSchoolRating ? 'userThumbs' : 'thumbs')
  let compThumbRecord = thumbs.find(t => t.getValue('computerId') === compId)

  if (!compThumbRecord) {
    compThumbRecord = store.create(`Thumb-TEMP-${ratingRecord.getValue('id')}-${compId}`, 'Thumb')
    compThumbRecord.setValue(compId, 'computerId')
    thumbs.push(compThumbRecord)
  }

  compThumbRecord.setValue(thumbsUpCount, 'thumbsUp')
  compThumbRecord.setValue(thumbsDownCount, 'thumbsDown')

  ratingRecord.setLinkedRecords(thumbs, isSchoolRating ? 'userThumbs' : 'thumbs')
}

function getThumbButtons(config) {
  const {
    rating,
    schoolRating,
    addVote,
    compVote,
    votesLocked,
    shouldPromptSignup,
    shouldPromptConfirmEmail,
    didVote,
    setDidVote
  } = config

  const isSchoolRating = !!schoolRating

  return THUMB_CONFIG.map(thumb => (
    <ThumbContainer
      key={thumb.direction}
      id={thumb.direction === VOTEDIRECTION.UPVOTE ? 'thumbs_up' : 'thumbs_down'}
    >
      <VoteThumb
        voteDirection={thumb.direction}
        addVote={addVote}
        vote={compVote}
        locked={votesLocked}
        shouldPromptSignup={shouldPromptSignup}
        shouldPromptConfirmEmail={shouldPromptConfirmEmail}
        tooltip={getTooltip(didVote, thumb.direction)}
        setDidVote={setDidVote}
        id={isSchoolRating ? schoolRating.id : rating.id}
      />{' '}
      <HelpTotalNumber>{thumb.getTotal(isSchoolRating ? schoolRating : rating)}</HelpTotalNumber>
    </ThumbContainer>
  ))
}

function graphUpdater(config) {
  const {
    isSchoolRating,
    ratingId,
    voteDirection,
    isAddingVote,
    thumbsUpCount,
    thumbsDownCount,
    compId,
    store
  } = config
  const ratingRecord = store.get(ratingId)
  if (!ratingRecord) return

  updateRating(ratingRecord, voteDirection, isAddingVote)
  updateThumb(isSchoolRating, store, ratingRecord, thumbsUpCount, thumbsDownCount, compId)
}

function sendMutation(isSchoolRating, variables, updater, setRequestActive, setDidVote) {
  setRequestActive(true)
  commitMutation({
    mutation: isSchoolRating ? schoolRatingThumbsMutation : ratingThumbsMutation,
    variables,
    optimisticUpdater: updater,
    updater,
    onCompleted: (_, err) => {
      setDidVote(!err)
      setRequestActive(false)
      if (err) {
        console.error(err)
      }
    },
    onError: err => {
      setRequestActive(false)
      Sentry.captureException(err)
      if (err) {
        console.error(err)
      }
    }
  })
}

/**
 * buildAddVote builds a function which, given a vote direction, attempts to
 * send the mutation to the backend
 */
function buildAddVote(config) {
  const {
    isSchoolRating,
    rating,
    school,
    teacher,
    userInfo,
    compId,
    compVote,
    setRequestActive,
    setDidVote
  } = config
  return voteDirection => {
    const isAddingVote = voteDirection !== compVote
    const thumbsUpCount = isAddingVote && voteDirection === VOTEDIRECTION.UPVOTE ? 1 : 0
    const thumbsDownCount = isAddingVote && voteDirection === VOTEDIRECTION.DOWNVOTE ? 1 : 0

    const variables = isSchoolRating
      ? {
          ratingId: rating.id,
          schoolId: school.id,
          userId: userInfo?.id,
          thumbsUp: thumbsUpCount,
          thumbsDown: thumbsDownCount,
          compID: compId
        }
      : {
          ratingId: rating.legacyId,
          teacherId: teacher.legacyId,
          userId: userInfo?.legacyId,
          thumbsUpCount,
          thumbsDownCount,
          compID: compId
        }

    const updater = store =>
      graphUpdater({
        isSchoolRating,
        ratingId: rating.id,
        voteDirection,
        isAddingVote,
        legacyUserId: userInfo?.legacyId,
        thumbsUpCount,
        thumbsDownCount,
        compId,
        store
      })

    sendMutation(isSchoolRating, variables, updater, setRequestActive, setDidVote)
  }
}

const Thumbs = ({
  rating,
  schoolRating,
  school,
  teacher,
  signupFeatureBlocked = false,
  confirmEmailFeature = false
}) => {
  const compId = generateCompId()
  const userInfo = useUserInfo()

  const isSchoolRating = !!schoolRating

  const [requestActive, setRequestActive] = useState(false)
  const [didVote, setDidVote] = useState()
  const [compVote, setCompVote] = useState(null)

  const isLoggedIn = userInfo && !!userInfo.Email
  const hasConfirmedEmail = isLoggedIn && userInfo.emailConfirmed === true

  const shouldPrompt =
    !requestActive && (school || (teacher && teacher.lockStatus !== LOCK_STATUS.HARD))

  const shouldPromptSignup = shouldPrompt && !isLoggedIn && !signupFeatureBlocked
  const shouldPromptConfirmEmail = shouldPrompt && !hasConfirmedEmail && confirmEmailFeature

  const votesLocked =
    shouldPromptConfirmEmail ||
    requestActive ||
    (teacher && teacher.lockStatus === LOCK_STATUS.HARD)

  useEffect(() => {
    if (compId) {
      setCompVote(getCompVote(isSchoolRating ? schoolRating.userThumbs : rating.thumbs, compId))
    }
  }, [rating, schoolRating])

  const addVote = buildAddVote({
    isSchoolRating,
    rating: isSchoolRating ? schoolRating : rating,
    school,
    teacher,
    userInfo,
    compId,
    compVote,
    setRequestActive,
    setDidVote
  })

  return (
    <ButtonWrapper id="thumbs-id">
      {getThumbButtons({
        rating,
        schoolRating,
        addVote,
        compVote,
        votesLocked,
        shouldPromptSignup,
        shouldPromptConfirmEmail,
        didVote,
        setDidVote
      })}
    </ButtonWrapper>
  )
}

Thumbs.propTypes = {
  rating: RatingTypes,
  schoolRating: RatingTypes,
  school: SchoolTypes,
  teacher: TeacherTypes,
  signupFeatureBlocked: PropTypes.bool,
  confirmEmailFeature: PropTypes.bool
}

export default createFragmentContainer(Thumbs, {
  teacher: graphql`
    fragment Thumbs_teacher on Teacher {
      id
      legacyId
      lockStatus
      isProfCurrentUser
    }
  `,
  school: graphql`
    fragment Thumbs_school on School {
      id
      legacyId
    }
  `,
  schoolRating: graphql`
    fragment Thumbs_schoolRating on SchoolRating {
      id
      legacyId
      thumbsDownTotal
      thumbsUpTotal
      userThumbs {
        computerId
        thumbsUp
        thumbsDown
      }
    }
  `,
  rating: graphql`
    fragment Thumbs_rating on Rating {
      id
      comment
      adminReviewedAt
      flagStatus
      legacyId
      thumbsUpTotal
      thumbsDownTotal
      thumbs {
        computerId
        thumbsUp
        thumbsDown
      }
      teacherNote {
        id
      }
    }
  `
})
