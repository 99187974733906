/* eslint-disable import/prefer-default-export */
export const RATING_LEVEL_LIMITS = {
  MIN: 1,
  MAX: 5
}

export const RATING_LEVEL_TEXTS = {
  QUALITY: ['', '1 - Awful', '2 - OK', '3 - Good', '4 - Great', '5 - Awesome'],
  DIFFICULTY: [
    '',
    '1 - Very Easy',
    '2 - Easy',
    '3 - Average',
    '4 - Difficult',
    '5 - Very Difficult'
  ]
}
