import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const StyledFormArea = styled.div`
  border-bottom: 1px solid ${props => props.theme.color.fadedDefault};
  padding: 28px 0;
`

export function FormArea({ children }) {
  return <StyledFormArea>{children}</StyledFormArea>
}

FormArea.propTypes = {
  children: PropTypes.node
}

export default FormArea
