/**
 * @flow
 * @relayHash 7a90da8e3b00a2e680f383c4d803475a
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ThumbsSchoolRatingMutationVariables = {|
  userId?: ?string,
  ratingId: string,
  schoolId: string,
  thumbsUp: boolean,
  thumbsDown: boolean,
  compID: string,
|};
export type ThumbsSchoolRatingMutationResponse = {|
  +createCampusRatingThumb: ?{|
    +id: ?string,
    +thumbsDown: ?number,
    +thumbsUp: ?number,
    +campusRatingId: ?number,
    +userId: ?number,
    +legacyId: ?number,
  |}
|};
export type ThumbsSchoolRatingMutation = {|
  variables: ThumbsSchoolRatingMutationVariables,
  response: ThumbsSchoolRatingMutationResponse,
|};
*/


/*
mutation ThumbsSchoolRatingMutation(
  $userId: ID
  $ratingId: ID!
  $schoolId: ID!
  $thumbsUp: Boolean!
  $thumbsDown: Boolean!
  $compID: String!
) {
  createCampusRatingThumb(userId: $userId, ratingId: $ratingId, schoolId: $schoolId, thumbsUp: $thumbsUp, thumbsDown: $thumbsDown, compID: $compID) {
    id
    thumbsDown
    thumbsUp
    campusRatingId
    userId
    legacyId
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "userId",
    "type": "ID",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "ratingId",
    "type": "ID!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "schoolId",
    "type": "ID!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "thumbsUp",
    "type": "Boolean!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "thumbsDown",
    "type": "Boolean!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "compID",
    "type": "String!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "createCampusRatingThumb",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "compID",
        "variableName": "compID"
      },
      {
        "kind": "Variable",
        "name": "ratingId",
        "variableName": "ratingId"
      },
      {
        "kind": "Variable",
        "name": "schoolId",
        "variableName": "schoolId"
      },
      {
        "kind": "Variable",
        "name": "thumbsDown",
        "variableName": "thumbsDown"
      },
      {
        "kind": "Variable",
        "name": "thumbsUp",
        "variableName": "thumbsUp"
      },
      {
        "kind": "Variable",
        "name": "userId",
        "variableName": "userId"
      }
    ],
    "concreteType": "CampusRatingThumb",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "thumbsDown",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "thumbsUp",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "campusRatingId",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "userId",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "legacyId",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "ThumbsSchoolRatingMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "ThumbsSchoolRatingMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "ThumbsSchoolRatingMutation",
    "id": null,
    "text": "mutation ThumbsSchoolRatingMutation(\n  $userId: ID\n  $ratingId: ID!\n  $schoolId: ID!\n  $thumbsUp: Boolean!\n  $thumbsDown: Boolean!\n  $compID: String!\n) {\n  createCampusRatingThumb(userId: $userId, ratingId: $ratingId, schoolId: $schoolId, thumbsUp: $thumbsUp, thumbsDown: $thumbsDown, compID: $compID) {\n    id\n    thumbsDown\n    thumbsUp\n    campusRatingId\n    userId\n    legacyId\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'cf2b29a96c9b2d651c9d145add47afa3';
module.exports = node;
