import React from 'react'
import styled, { css } from 'styled-components'
import SmallRMPLogoWhite from '@assets/images/logos/small_rmp_logo_white.svg'
import SmallRMPLogoBlack from '@assets/images/logos/small_rmp_logo_black.svg'
import MobileMenu, { MobileMenuWrapper } from '@components/MobileMenu'
import {
  breakpointSmediumAndBelow,
  breakpointXMediumAndBelow
} from '@StyledComponents/theme/helpers/breakpoints'
import SearchWhite from '@assets/images/search-white.svg'
import SearchBlack from '@assets/images/search-black.svg'
import Link from '@components/Link'
import ROUTE from '@enums/ROUTE'
import { useHeaderContext } from './HeaderContext'

const SearchIcon = styled.button`
  ${({ isMobileMenuOpen }) =>
    isMobileMenuOpen &&
    css`
      opacity: 0;
      pointer-events: none;
    `}
`

const RMPLogo = styled.img`
  padding-right: 41px;

  ${breakpointXMediumAndBelow(css`
    padding-right: 17px;
  `)}

  ${breakpointSmediumAndBelow(
    css`
      padding-right: 0;
    `
  )}
`

const StyledHeaderLogo = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: fit-content;

  ${breakpointSmediumAndBelow(css`
    width: 100%;
  `)}

  ${({ isMobileMenuOpen }) =>
    isMobileMenuOpen &&
    css`
      justify-content: space-between;
      order: 2;

      ${RMPLogo} {
        padding: 0;
      }
    `}

  ${MobileMenuWrapper},
  ${SearchIcon} {
    border: 0;
    display: none;
    outline: none;

    ${breakpointSmediumAndBelow(css`
      display: block;
    `)}
  }

  ${SearchIcon} {
    background: none;
  }
`

const HeaderLogo = () => {
  const {
    searchCategory,
    isMobileMenuOpen,
    setIsMobileMenuOpen,
    setIsMobileSearchOpen
  } = useHeaderContext()

  return (
    <StyledHeaderLogo searchCategory={searchCategory} isMobileMenuOpen={isMobileMenuOpen}>
      <MobileMenu isMobileMenuOpen={isMobileMenuOpen} setIsMobileMenuOpen={setIsMobileMenuOpen} />
      <Link to={ROUTE.HOME}>
        <RMPLogo src={isMobileMenuOpen ? SmallRMPLogoBlack : SmallRMPLogoWhite} alt="Logo" />
      </Link>
      <SearchIcon
        type="button"
        title={`${isMobileMenuOpen ? 'Hide' : 'Open'} search menu`}
        onClick={() => setIsMobileSearchOpen(true)}
        isMobileMenuOpen={isMobileMenuOpen}
      >
        <img src={isMobileMenuOpen ? SearchBlack : SearchWhite} alt="Search Icon" />
      </SearchIcon>
    </StyledHeaderLogo>
  )
}

export default HeaderLogo
