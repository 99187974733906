import React from 'react'
import PropTypes from 'prop-types'
import { TOAST_EVENTS } from '@utils/toast'
import Toast, { ToastHeader, ToastDescription } from './Toast'

const LoggedOutTeacherToast = ({ closeToast }) => {
  return (
    <Toast canDismiss toastEvent={TOAST_EVENTS.LOGGED_OUT} closeToast={closeToast}>
      <ToastHeader>Logged Out</ToastHeader>
      <ToastDescription>Log out successful. See you soon!</ToastDescription>
    </Toast>
  )
}

LoggedOutTeacherToast.propTypes = {
  closeToast: PropTypes.func.isRequired
}

export default LoggedOutTeacherToast
