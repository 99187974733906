import EMOTION from '@enums/EMOTION'

export const emotionMap = {}
emotionMap[EMOTION.POSITIVE] = {
  sentiment: 'high',
  genericRating: 'awesome',
  emoji: '😎',
  aria: 'Sunglasses',
  getColor: props => props.theme.color.green
}
emotionMap[EMOTION.NEUTRAL] = {
  sentiment: 'neutral',
  genericRating: 'average',
  emoji: '😐',
  aria: 'Neutral Face',
  getColor: props => props.theme.color.yellow
}
emotionMap[EMOTION.NEGATIVE] = {
  sentiment: 'low',
  genericRating: 'awful',
  emoji: '😖',
  aria: 'Confounded',
  getColor: props => props.theme.color.red
}

export const determineEmotion = overallRating => {
  if (overallRating >= 4) return EMOTION.POSITIVE
  if (overallRating < 3) return EMOTION.NEGATIVE
  return EMOTION.NEUTRAL
}
