import React from 'react'
import styled from 'styled-components'
import { helveticaLight, helveticaNormal } from '@components/StyledComponents/theme/typesets'
import TeacherTypes from '@types/Teacher.types'

const StyledSelectedTeacherContainer = styled.div`
  text-align: left;
  width: 100%;
`

const StyledSelectedTeacherBox = styled.div`
  background: ${({ theme }) => theme.color.gray12};
  border: 1px solid ${({ theme }) => theme.color.gray14};
  border-radius: 9px;
  box-sizing: border-box;
  min-height: 91px;
  padding: 16px;
`

const StyledSelectedTeacherName = styled.div`
  font-size: 24px;
  line-height: 29px;
  ${helveticaNormal};
`

const StyledSelectedTeacherSchool = styled.div`
  color: ${({ theme }) => theme.color.gray9};
  font-size: 18px;
  line-height: 21px;
  ${helveticaLight};
  margin-top: 4px;
`

export default function ProfessorSelectHeader({ teacher }) {
  return (
    <StyledSelectedTeacherContainer>
      <StyledSelectedTeacherBox>
        <StyledSelectedTeacherName>{`${teacher.firstName} ${teacher.lastName}`}</StyledSelectedTeacherName>
        <StyledSelectedTeacherSchool>{teacher.school.name}</StyledSelectedTeacherSchool>
      </StyledSelectedTeacherBox>
    </StyledSelectedTeacherContainer>
  )
}

ProfessorSelectHeader.propTypes = {
  teacher: TeacherTypes
}
