/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type Thumbs_teacher$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type RatingFooter_teacher$ref: FragmentReference;
declare export opaque type RatingFooter_teacher$fragmentType: RatingFooter_teacher$ref;
export type RatingFooter_teacher = {|
  +id: ?string,
  +legacyId: ?number,
  +lockStatus: ?string,
  +isProfCurrentUser: ?boolean,
  +$fragmentRefs: Thumbs_teacher$ref,
  +$refType: RatingFooter_teacher$ref,
|};
export type RatingFooter_teacher$data = RatingFooter_teacher;
export type RatingFooter_teacher$key = {
  +$data?: RatingFooter_teacher$data,
  +$fragmentRefs: RatingFooter_teacher$ref,
};
*/


const node/*: ReaderFragment*/ = {
  "kind": "Fragment",
  "name": "RatingFooter_teacher",
  "type": "Teacher",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "id",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "legacyId",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "lockStatus",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "isProfCurrentUser",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "FragmentSpread",
      "name": "Thumbs_teacher",
      "args": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '56ae92c4da89ed335f9b08f7f21cddd4';
module.exports = node;
