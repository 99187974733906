import React from 'react'
import styled from 'styled-components'
import PinkBlob from '@assets/images/bkgd-blob-pink.svg'
import BlueBlob from '@assets/images/bkgd-blob-blue.svg'

const BlueBackground = styled.img`
  position: absolute;
  right: calc(50vw - ${props => props.theme.bkgrdGraphicOffsets.blueBlob.right});
  top: ${props => props.theme.bkgrdGraphicOffsets.blueBlob.top};
`

const PinkBackground = styled.img`
  left: calc(50vw - ${props => props.theme.bkgrdGraphicOffsets.pinkBlob.left});
  position: absolute;
  top: ${props => props.theme.bkgrdGraphicOffsets.pinkBlob.top};
`
export default function HomepageBkgds() {
  return (
    <React.Fragment>
      <PinkBackground src={PinkBlob} />
      <BlueBackground src={BlueBlob} />
    </React.Fragment>
  )
}
