import React from 'react'
import { graphql } from 'react-relay'
import ReactRouterPropTypes from 'react-router-prop-types'
import { withRouter } from 'react-router-dom'
import QueryWrapper from '@componentUtils/QueryWrapper'
import FlagTeacherRating from './FlagTeacherRating'
import { getVariables } from './FlagTeacherRating.utils'

export const FlagTeacherRatingPageQuery = graphql`
  query FlagTeacherRatingPageQuery($id: ID!, $ratingId: ID) {
    teacher: node(id: $id) {
      ... on Teacher {
        firstName
        lastName
        id
        legacyId
        school {
          id
        }
      }
    }
    rating: node(id: $ratingId) {
      ... on Rating {
        id
        ...FlagReportingBox_rating
      }
    }
  }
`

export const FlagTeacherRatingPage = ({ location }) => {
  const variables = getVariables(location)

  return (
    <QueryWrapper
      query={FlagTeacherRatingPageQuery}
      component={FlagTeacherRating}
      variables={variables}
    />
  )
}

FlagTeacherRatingPage.propTypes = {
  location: ReactRouterPropTypes.location
}

const withRouterFlagTeacherRatingPage = withRouter(FlagTeacherRatingPage)
withRouterFlagTeacherRatingPage.serverQueries = [
  {
    query: FlagTeacherRatingPageQuery,
    getVariables
  }
]

export default withRouterFlagTeacherRatingPage
