import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import PencilLadySVG from '@assets/images/instructional-slide-pencil-lady.svg'
import ThumbWarSVG from '@assets/images/instructional-slide-thumb-war.svg'
import MysteryLadySVG from '@assets/images/instructional-slide-mystery-lady.svg'
import { poppinsBold } from '@StyledComponents/theme/typesets'

export const IMAGES = {
  MYSTERY_LADY: 'MYSTERY_LADY',
  PENCIL_LADY: 'PENCIL_LADY',
  THUMB_WAR: 'THUMB_WAR'
}

export const cardImagesConfig = {
  [IMAGES.MYSTERY_LADY]: {
    src: MysteryLadySVG,
    alt: 'Person making an anonymous entry',
    width: 300,
    height: 260
  },
  [IMAGES.PENCIL_LADY]: {
    src: PencilLadySVG,
    alt: 'Lady with a pencil',
    width: 340,
    height: 226
  },
  [IMAGES.THUMB_WAR]: {
    src: ThumbWarSVG,
    alt: 'Thumb War',
    width: 340,
    height: 220
  }
}

export const StyledCTACard = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-height: 320px;
`

const CardImage = styled.img`
  height: ${props => (props.small ? '138px' : `${props.svgHeight}px`)};
  margin-bottom: ${props => (props.small ? '14px' : '24px')};
  max-width: 100%;
  width: ${props => (props.small ? '180px' : `${props.svgWidth}px`)};
`

const CardText = styled.div`
  font-size: ${props => (props.small ? props.theme.fontSize.default : props.theme.fontSize.xlarge)};
  font-weight: ${props =>
    props.small ? props.theme.fontWeight.light : props.theme.fontWeight.bold};
  line-height: ${props => (props.small ? '21px' : '26px')};
  margin: 0 auto;
  max-width: ${props => (props.small ? '180px' : '300px')};
  text-align: center;
  ${poppinsBold};
`

export default function CTACard({ children, imageConfig, small }) {
  return (
    <StyledCTACard>
      <CardImage
        alt={imageConfig.alt}
        src={imageConfig.src}
        svgHeight={imageConfig.height}
        svgWidth={imageConfig.width}
        small={small}
      />
      <CardText small={small}>{children}</CardText>
    </StyledCTACard>
  )
}

CTACard.propTypes = {
  children: PropTypes.node,
  imageConfig: PropTypes.shape({
    src: PropTypes.string,
    alt: PropTypes.string,
    height: PropTypes.number,
    width: PropTypes.number
  }),
  small: PropTypes.bool
}
