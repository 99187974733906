import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import TeacherTypes from '@types/Teacher.types'
import RatingsFilter, { FILTER_TYPES } from './RatingsFilter'

const StyledRatingsFilterList = styled.div`
  display: flex;
  margin-top: 16px;
  width: 100%;
`
export default function RatingsFilterList({ teacher, courseFilter, setCourseFilter, numRatings }) {
  if (numRatings === 0) return null

  return (
    <StyledRatingsFilterList>
      <RatingsFilter
        teacher={teacher}
        value={courseFilter}
        setValue={setCourseFilter}
        filterType={FILTER_TYPES.COURSE}
      />
    </StyledRatingsFilterList>
  )
}

RatingsFilterList.propTypes = {
  teacher: TeacherTypes,
  courseFilter: PropTypes.string,
  setCourseFilter: PropTypes.func,
  numRatings: PropTypes.number
}
