import React from 'react'
import { createFragmentContainer, graphql } from 'react-relay'
import PropTypes from 'prop-types'

import RatingTypes from '@types/Rating.types'
import TeacherTypes from '@types/Teacher.types'
import ProfessorNoteEditor from './ProfessorNoteEditor'
import ProfessorNote from './ProfessorNote'

export const ProfessorNoteSection = ({ rating, teacher, replyModeActive, setReplyModeActive }) => {
  if (replyModeActive) {
    return (
      <ProfessorNoteEditor
        rating={rating}
        teacher={teacher}
        setReplyModeActive={setReplyModeActive}
      />
    )
  }

  if (!rating.teacherNote) return null

  return (
    <ProfessorNote
      note={rating.teacherNote}
      teacher={teacher}
      setReplyModeActive={setReplyModeActive}
    />
  )
}

ProfessorNoteSection.propTypes = {
  rating: RatingTypes,
  teacher: TeacherTypes,
  replyModeActive: PropTypes.bool,
  setReplyModeActive: PropTypes.func
}

export default createFragmentContainer(ProfessorNoteSection, {
  teacher: graphql`
    fragment ProfessorNoteSection_teacher on Teacher {
      ...ProfessorNote_teacher
      ...ProfessorNoteEditor_teacher
    }
  `,
  rating: graphql`
    fragment ProfessorNoteSection_rating on Rating {
      teacherNote {
        ...ProfessorNote_note
      }
      ...ProfessorNoteEditor_rating
    }
  `
})
