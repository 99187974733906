import React from 'react'
import { Redirect, withRouter } from 'react-router-dom'
import ReactRouterPropTypes from 'react-router-prop-types'
import { graphql } from 'relay-runtime'
import QueryWrapper from '@componentUtils/QueryWrapper'
import useCompareSchools from '@utils/useCompareSchools'
import ROUTE from '@enums/ROUTE'
import useUserInfo from '@hooks/useUserInfo'
import { getUserSchool } from '@utils/getAndSetUserSchool'
import CompareSchools from './CompareSchools'
import { getVariables } from './CompareSchools.utils'

const compareSchoolsQuery = graphql`
  query CompareSchoolsPageQuery(
    $primaryId: ID!
    $secondaryId: ID
    $skipPrimary: Boolean!
    $skipSecondary: Boolean!
  ) {
    primarySchool: node(id: $primaryId) @skip(if: $skipPrimary) {
      __typename
      ... on School {
        ...CompareSchools_primarySchool
      }
    }
    secondarySchool: node(id: $secondaryId) @skip(if: $skipSecondary) {
      __typename
      ... on School {
        ...CompareSchools_secondarySchool
      }
    }
  }
`

export const CompareSchoolsPage = ({ location }) => {
  if (!useCompareSchools()) {
    return <Redirect to={ROUTE.HOME} />
  }

  const userInfo = useUserInfo()
  const userSchool = getUserSchool(userInfo)

  const variables = getVariables(location, userSchool)

  return (
    <QueryWrapper query={compareSchoolsQuery} component={CompareSchools} variables={variables} />
  )
}

CompareSchoolsPage.propTypes = {
  location: ReactRouterPropTypes.location
}

const withRouterCompareSchoolsPage = withRouter(CompareSchoolsPage)
withRouterCompareSchoolsPage.serverQueries = [
  {
    query: compareSchoolsQuery,
    getVariables
  }
]
export default withRouterCompareSchoolsPage
