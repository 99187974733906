import React, { useEffect } from 'react'
import styled from 'styled-components'
import ROUTE from '@enums/ROUTE'
import useGlobalContext from '@hooks/useGlobalContext'
import MODALS from '@enums/MODALS'
import PageWrapper from '@components/PageWrapper'

const StyledModalLandingPage = styled.div`
  min-height: 100vh;
`
const StyledWrapper = styled.div`
  max-width: 1280px;
  width: 100%;
`

// An array of objects that tie a route and a modal together
const modalRoutes = [
  { route: ROUTE.LOGIN, modal: MODALS.LOGIN },
  { route: ROUTE.LOGOUT, modal: MODALS.LOGOUT },
  { route: ROUTE.RESET_PASSWORD, modal: MODALS.RESET_PASSWORD },
  { route: ROUTE.FORGOT_PASSWORD, modal: MODALS.FORGOT_PASSWORD },
  { route: ROUTE.NEW_LOGIN, modal: MODALS.LOGIN },
  { route: ROUTE.NEW_LOGOUT, modal: MODALS.LOGOUT },
  { route: ROUTE.NEW_SIGN_UP, modal: MODALS.SIGN_UP_FORM },
  { route: ROUTE.STUDENT_SIGN_UP, modal: MODALS.SIGN_UP_FORM },
  { route: ROUTE.CONFIRM_EMAIL, modal: MODALS.CONFIRM_EMAIL },
  { route: ROUTE.PROFESSOR_SIGN_UP, modal: MODALS.PROFESSOR_SIGN_UP }
]

const getRouteFromReferrer = () => {
  return modalRoutes.find(mr => window.location.pathname === mr?.route)
}

const ModalLandingPage = () => {
  const { setActiveModal, setShouldRedirectAfterClose } = useGlobalContext()

  /**
   * All modals are wrapped by this useEffect and
   * set to redirect to a specific route after close
   */
  useEffect(() => {
    const modalRoute = getRouteFromReferrer()

    if (modalRoute) setActiveModal(modalRoute.modal)

    setShouldRedirectAfterClose(true)
  }, [])

  return (
    <PageWrapper>
      <StyledModalLandingPage>
        <StyledWrapper />
      </StyledModalLandingPage>
    </PageWrapper>
  )
}

export default ModalLandingPage
