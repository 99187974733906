/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type CardFeedback_teacher$ref: FragmentReference;
declare export opaque type CardFeedback_teacher$fragmentType: CardFeedback_teacher$ref;
export type CardFeedback_teacher = {|
  +wouldTakeAgainPercent: ?number,
  +avgDifficulty: ?number,
  +$refType: CardFeedback_teacher$ref,
|};
export type CardFeedback_teacher$data = CardFeedback_teacher;
export type CardFeedback_teacher$key = {
  +$data?: CardFeedback_teacher$data,
  +$fragmentRefs: CardFeedback_teacher$ref,
};
*/


const node/*: ReaderFragment*/ = {
  "kind": "Fragment",
  "name": "CardFeedback_teacher",
  "type": "Teacher",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "wouldTakeAgainPercent",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "avgDifficulty",
      "args": null,
      "storageKey": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '9fd340fa0363b7c8b97ddce3d94d9fdc';
module.exports = node;
