import { nanoid } from 'nanoid'
import Cookies from './Cookies'

const buildDate = () => {
  const today = new Date()
  let dd = today.getDate()
  let mm = today.getMonth() + 1
  if (mm < 10) mm = `0${mm}`
  if (dd < 10) dd = `0${dd}`
  const yyyy = today.getFullYear()
  const fullDate = `${yyyy}${mm}${dd}`
  return fullDate
}

const generateCompId = () => {
  let compID
  const cid = Cookies.get('cid')
  const fullDate = buildDate()
  if (cid) {
    // If the cid cookie exists with a date return it
    if (cid.includes('-')) {
      return cid
    }
    // If the cid cookie exists without a date append the date to it
    compID = `${cid}-${fullDate}`
    Cookies.set('cid', compID)
    return compID
  }

  // If the cid cookie does not exist (new user), create it
  const nanoID = nanoid(10)
  compID = `${nanoID}-${fullDate}`
  Cookies.set('cid', compID)
  return compID
}

export default generateCompId
