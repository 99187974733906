import React, { useState } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import NewSearch from '@components/NewSearch'
import { poppinsNormal } from '@components/StyledComponents/theme/typesets'
import { DebouncedSearchInput } from '@components/Search'

const StyledNewSearch = styled(NewSearch)`
  ${DebouncedSearchInput} {
    border: ${props =>
      props.errorBorder ? `2px solid ${props.theme.color.errorRed} !important` : '0'};
  }
`

const DisabledText = styled.p`
  color: red;
  margin-bottom: 8px;

  ${poppinsNormal}
`

export default function SearchContainer(props) {
  const { useErrorText = false, useErrorBorder } = props
  const [error, setError] = useState(null)

  const showErrorBorder = useErrorBorder && !!error

  return (
    <React.Fragment>
      {error && useErrorText && <DisabledText>{error}</DisabledText>}
      <StyledNewSearch {...props} setError={setError} errorBorder={showErrorBorder} />
    </React.Fragment>
  )
}

SearchContainer.propTypes = {
  useErrorText: PropTypes.bool,
  useErrorBorder: PropTypes.bool
}
