import React, { useRef } from 'react'
import styled from 'styled-components'
import { graphql } from 'react-relay'
import PropTypes from 'prop-types'
import Form from '@components/Form/Form'
import TextInput from '@components/Form/TextInput'
import FormButton from '@components/Form/FormButton'
import svgArrowIcon from '@assets/images/right-arrow-black.svg'
import useGlobalContext from '@hooks/useGlobalContext'
import { ButtonAttributes } from '@StyledComponents/Buttons'
import SIGNUP_STAGE from '@enums/SIGNUP_STAGE'
import SignupModalHeader from './SignupModalHeader'

const StyledPasswordForm = styled(Form)`
  margin-top: 42px;
`

const StyledArrowIcon = styled.img`
  cursor: pointer;
  left: 16px;
  position: absolute;
  top: 16px;
  transform: rotate(180deg);
`

const FormDisclosure = styled.div`
  font-size: ${props => props.theme.fontSize.default};
  margin: 38px 0 0;
`

const PasswordFormHeader = styled(SignupModalHeader)`
  font-weight: ${props => props.theme.fontWeight.boldest};
  margin-right: 8px;
  margin-top: 5px;
`

const SubmitButton = styled(FormButton)`
  margin-top: 20px;
  width: 100%;

  ${ButtonAttributes}
`

const userCreateAndLoginMutation = graphql`
  mutation PasswordFormMutation($user: UserInputObject!, $email: String!, $password: String!) {
    userCreate(user: $user) {
      user {
        id
        Email
      }

      validation {
        ...FormController_validation
      }
    }

    userLogin(email: $email, password: $password) {
      token

      validation {
        ...FormController_validation
      }
    }
  }
`
export default function PasswordForm({ setStage, newEmail }) {
  const { login } = useGlobalContext()
  const userToken = useRef(null)

  const onPasswordFormSuccess = async () => {
    // User is logged in if this form successfully submits, since next stage is
    // optional and dismissable
    if (userToken.current) {
      await login({ token: userToken.current, shouldRedirect: false })
      setStage(SIGNUP_STAGE.DETAILS)
    }
  }

  return (
    <React.Fragment>
      <StyledArrowIcon
        src={svgArrowIcon}
        alt="Go Back"
        onClick={() => setStage(SIGNUP_STAGE.EMAIL)}
      />
      <PasswordFormHeader>Create Password</PasswordFormHeader>
      <StyledPasswordForm
        getVariables={model => ({
          user: { email: newEmail, password: model.password },
          email: newEmail,
          password: model.password
        })}
        mutation={userCreateAndLoginMutation}
        updater={store => {
          userToken.current = store?.getRootField('userLogin')?.getValue('token')
        }}
        onSuccess={() => onPasswordFormSuccess()}
      >
        <TextInput
          autoFocus
          isPassword
          name="password"
          label="Password"
          autoComplete="new-password"
          helperText="Minimum 8 characters"
        />
        <FormDisclosure>
          By clicking &apos;Continue&apos; you agree to the Terms of Use and our Privacy Policy.
          RateMyProfessors is designed for and targeted to U.S. audiences and is governed by and
          operated in accordance with U.S. laws.
        </FormDisclosure>
        <SubmitButton type="submit">Continue</SubmitButton>
      </StyledPasswordForm>
    </React.Fragment>
  )
}

PasswordForm.propTypes = {
  setStage: PropTypes.func.isRequired,
  newEmail: PropTypes.string.isRequired
}
