import React, { useState, useEffect } from 'react'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'
import {
  breakpointLargeAndBelow,
  applyBreakpointMin
} from '@StyledComponents/theme/helpers/breakpoints'
import RenderForBreakpoints from '@StyledComponents/RenderForBreakpoints'
import AdNoBid from './NoBid/AdNoBid'

const DEFAULT_AD_TOP = 220
const MIN_HEIGHT_FOR_SECOND_AD_UNIT = 900

// eslint-disable no-nested-ternary
export const StyledAdRail = styled.div`
  display: block;
  flex-grow: 0;
  margin: 0 37px 0 17px;
  max-height: 600px;
  padding: 4px;
  position: fixed;
  top: ${props => props.adTop}px;
  width: 308px;
  z-index: ${props => props.theme.zIndex.adRail};
  ${applyBreakpointMin(
    'large',
    css`
      margin-left: calc(
        ${props => props.theme.pageWrapper.maxWidth}px - ${props => props.theme.adRail.width}px +
          ${props => props.theme.adRail.margin}px
      );
    `
  )}
  ${breakpointLargeAndBelow(
    css`
      width: 100%;
      display: flex;
      justify-content: center;
      position: static;
      margin: 10px 0;
    `
  )}
  > * {
    margin-bottom: 5px;
  }
`

const Divider = styled.div`
  height: 16px;
`

export default function AdRail({ adTargetingSchools, adTop = DEFAULT_AD_TOP }) {
  const [showSecondAd, setShowSecondAd] = useState(false)

  /**
   * This observes changes the window size which we use to show / hide the second ad unit
   */
  useEffect(() => {
    const checkHeightAndSetAd = () => {
      const pageHeight = document.documentElement.clientHeight
      setShowSecondAd(pageHeight > MIN_HEIGHT_FOR_SECOND_AD_UNIT)
    }

    checkHeightAndSetAd()

    window.addEventListener('resize', checkHeightAndSetAd)

    return () => {
      window.removeEventListener('resize', checkHeightAndSetAd)
    }
  }, [showSecondAd])

  return (
    <StyledAdRail adTop={adTop}>
      <AdNoBid code="ad_rec_01_profile" targeting={adTargetingSchools} />
      {showSecondAd && (
        <RenderForBreakpoints min="large">
          <Divider />
          <AdNoBid code="ad_rec_01_profile" targeting={adTargetingSchools} />
        </RenderForBreakpoints>
      )}
    </StyledAdRail>
  )
}

AdRail.propTypes = {
  // This needs to be object b/c targeting varies by page
  // eslint-disable-next-line react/forbid-prop-types
  adTargetingSchools: PropTypes.object,
  adTop: PropTypes.number
}
