import React from 'react'
import styled, { css } from 'styled-components'
import { breakpointMediumAndBelow } from '@StyledComponents/theme/helpers/breakpoints'
import DownArrow from '@assets/images/down-arrow-black.svg'
import Link from '@src/components/Link'
import { RATINGS_LIST_ANCHOR } from '@components/RatingsList'

export const StyledJumpToRatings = styled(Link)`
  display: none;
  font-size: ${props => props.theme.fontSize.small};
  font-weight: ${props => props.theme.fontWeight.bold};
  height: 100%;
  text-decoration: none;

  ${breakpointMediumAndBelow(
    css`
      display: block;
    `
  )}
`

export default function JumpToRatings() {
  return (
    <StyledJumpToRatings to={`#${RATINGS_LIST_ANCHOR}`}>
      <span>Jump To Ratings</span> <img src={DownArrow} alt="Down Arrow" />
    </StyledJumpToRatings>
  )
}
