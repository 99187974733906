import React from 'react'
import styled, { css } from 'styled-components'
import RateTeacherLink from '@components/RateTeacherLink'
import RateSchoolLink from '@components/RateSchoolLink'
import CompareSchoolLink from '@components/CompareSchoolLink'
import CompareProfessorLink from '@components/CompareProfessorLink'
import { ButtonAttributes } from '@StyledComponents/Buttons'
import TRACKING_EVENT from '@enums/TRACKING_EVENT'
import TeacherTypes from '@types/Teacher.types'
import SchoolTypes from '@types/School.types'
import { createFragmentContainer, graphql } from 'react-relay'
import {
  breakpointLargeAndBelow,
  breakpointSmallAndBelow,
  breakpointSmallPlusAndBelow,
  breakpointSmediumAndBelow,
  breakpointXLargeAndBelow
} from '@StyledComponents/theme/helpers/breakpoints'
import useCompareSchools from '@utils/useCompareSchools'
import PropTypes from 'prop-types'
import SchoolTitles from './SchoolTitles'

const RateButtonAttributes = css`
  ${ButtonAttributes}
  font-size: 16px;
  min-width: 164px;
  max-width: 284px;
  text-decoration: none;

  ${breakpointLargeAndBelow(css``)}

  ${breakpointSmediumAndBelow(css`
    min-width: 175px;
    margin-top: 0px;
  `)}

  ${breakpointSmallPlusAndBelow(css`
    max-width: 100%;
  `)}

  &:not(:disabled)&:hover {
    background: ${props => props.theme.color.darkMainBlue};
    transform: none;
  }

  &:not(:disabled)&:hover {
    background: ${props => props.theme.color.darkMainBlue};
    transform: none;
  }
`

export const StyledRateTeacherButton = styled(RateTeacherLink)`
  ${RateButtonAttributes}
`

export const StyledCompareProfessorButton = styled(CompareProfessorLink)`
  ${RateButtonAttributes}

  margin-left: 8px;

  ${breakpointSmallAndBelow(css`
    margin-left: 0px;
  `)}
`

export const StyledRateSchoolButton = styled(RateSchoolLink)`
  ${RateButtonAttributes}
`

export const StyledCompareSchoolButton = styled(CompareSchoolLink)`
  ${RateButtonAttributes}

  margin-left: 8px;

  ${breakpointSmallAndBelow(css`
    margin-left: 0px;
  `)}
`

const SchoolActionButtons = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  min-width: 100%;

  ${breakpointXLargeAndBelow(css`
    flex-direction: column;
    align-items: flex-start;
  `)}
`

const StyledButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 8px;

  ${breakpointSmallAndBelow(css`
    flex-direction: column;
    gap: 8px;
    min-width: 100%;
  `)}
`

export function HeaderRateButton({ teacher, school, large }) {
  const isCompareSchoolOn = useCompareSchools()
  const trackingLabel = () => {
    if (teacher) {
      return teacher.numRatings === 0
        ? TRACKING_EVENT.PROF_RATE_BUTTON_TOP
        : TRACKING_EVENT.PROF_RATE_BUTTON
    }
    return TRACKING_EVENT.SCHOOL_RATE_BUTTON_TOP
  }

  return teacher ? (
    <React.Fragment>
      <SchoolActionButtons>
        <StyledButtonWrapper>
          <StyledRateTeacherButton
            id="rate-prof-btn"
            teacher={teacher}
            trackingLabel={trackingLabel()}
            blue
          >
            Rate
          </StyledRateTeacherButton>
          <StyledCompareProfessorButton
            id="compare-prof-btn"
            teacher={teacher}
            trackingLabel={trackingLabel()}
            white
            secondary
          >
            Compare
          </StyledCompareProfessorButton>
        </StyledButtonWrapper>
        <SchoolTitles school={school} large={large} />
      </SchoolActionButtons>
    </React.Fragment>
  ) : (
    <React.Fragment>
      <SchoolActionButtons>
        <StyledButtonWrapper>
          <StyledRateSchoolButton
            id="rate-school-btn"
            school={school}
            trackingLabel={trackingLabel()}
            blue
          >
            Rate
          </StyledRateSchoolButton>
          {isCompareSchoolOn && (
            <StyledCompareSchoolButton
              id="compare-school-btn"
              school={school}
              trackingLabel={trackingLabel()}
              white
              secondary
            >
              Compare
            </StyledCompareSchoolButton>
          )}
        </StyledButtonWrapper>
        <SchoolTitles school={school} large={large} />
      </SchoolActionButtons>
    </React.Fragment>
  )
}

export default createFragmentContainer(HeaderRateButton, {
  teacher: graphql`
    fragment HeaderRateButton_teacher on Teacher {
      ...RateTeacherLink_teacher
      ...CompareProfessorLink_teacher
    }
  `,
  school: graphql`
    fragment HeaderRateButton_school on School {
      ...RateSchoolLink_school
      ...CompareSchoolLink_school
    }
  `
})

HeaderRateButton.propTypes = {
  teacher: TeacherTypes,
  school: SchoolTypes,
  large: PropTypes.bool
}
