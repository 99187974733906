import React, { useEffect } from 'react'
import styled from 'styled-components'
import ReactTooltip from 'react-tooltip'
import TOOLTIPS from '@enums/TOOLTIPS'

// NOTE: rebuilding react tooltip makes live updating of a tooltip possible
export const useDynamicTooltip = tooltip => {
  useEffect(() => {
    ReactTooltip.rebuild()
  }, [tooltip])
}

const StyledTooltip = styled(ReactTooltip)`
  background: ${({ theme }) => theme.color.default};
  border-radius: 6px;
  box-shadow: 0px 4px 4px rgba(126, 126, 126, 0.25);
  color: white;
  display: block;
  font-family: ${({ theme }) => theme.fontFamily.helveticaNeue};
  font-size: 13px;
  letter-spacing: 0.5px;
  line-height: 15px;
  margin-top: 15px;
  opacity: 0.9;
  padding: 12px 16px;
`

/**
 *  Tooltip to be used throughout the app
 *  We should only ever need one, as long as we use the id associated with it on any element
 *  You can do this by passing the prop 'data-tooltip', with whatever content you want
 * */

const Tooltip = () => {
  return (
    <StyledTooltip
      id={TOOLTIPS.GLOBAL_TOOLTIP}
      arrowColor="transparent"
      effect="solid"
      place="bottom"
      getContent={tooltip => tooltip}
    />
  )
}

export default Tooltip
