/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type SchoolRatingSuperHeader_school$ref: FragmentReference;
declare export opaque type SchoolRatingSuperHeader_school$fragmentType: SchoolRatingSuperHeader_school$ref;
export type SchoolRatingSuperHeader_school = {|
  +name: ?string,
  +legacyId: ?number,
  +$refType: SchoolRatingSuperHeader_school$ref,
|};
export type SchoolRatingSuperHeader_school$data = SchoolRatingSuperHeader_school;
export type SchoolRatingSuperHeader_school$key = {
  +$data?: SchoolRatingSuperHeader_school$data,
  +$fragmentRefs: SchoolRatingSuperHeader_school$ref,
};
*/


const node/*: ReaderFragment*/ = {
  "kind": "Fragment",
  "name": "SchoolRatingSuperHeader_school",
  "type": "School",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "name",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "legacyId",
      "args": null,
      "storageKey": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = 'c934da6e7c857b3180a7a01c92b9f60f';
module.exports = node;
