import PropTypes from 'prop-types'

const FLAG_STATUS = {
  UNFLAGGED: 'UNFLAGGED',
  REVIEWED: 'REVIEWED',
  FLAGGED: 'FLAGGED',
  DELETED: 'DELETED',
  HIDDEN: 'HIDDEN'
}

export const FlagStatusType = PropTypes.oneOf(Object.values(FLAG_STATUS))

export default FLAG_STATUS
