/**
 * @flow
 * @relayHash 24f62e89bc2d6d35d1977fb1d01b2126
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type CompareSchools_primarySchool$ref = any;
type CompareSchools_secondarySchool$ref = any;
export type CompareSchoolsPageQueryVariables = {|
  primaryId: string,
  secondaryId?: ?string,
  skipPrimary: boolean,
  skipSecondary: boolean,
|};
export type CompareSchoolsPageQueryResponse = {|
  +primarySchool?: ?({|
    +__typename: "School",
    +$fragmentRefs: CompareSchools_primarySchool$ref,
  |} | {|
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    +__typename: "%other"
  |}),
  +secondarySchool?: ?({|
    +__typename: "School",
    +$fragmentRefs: CompareSchools_secondarySchool$ref,
  |} | {|
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    +__typename: "%other"
  |}),
|};
export type CompareSchoolsPageQuery = {|
  variables: CompareSchoolsPageQueryVariables,
  response: CompareSchoolsPageQueryResponse,
|};
*/


/*
query CompareSchoolsPageQuery(
  $primaryId: ID!
  $secondaryId: ID
  $skipPrimary: Boolean!
  $skipSecondary: Boolean!
) {
  primarySchool: node(id: $primaryId) @skip(if: $skipPrimary) {
    __typename
    ... on School {
      ...CompareSchools_primarySchool
    }
    id
  }
  secondarySchool: node(id: $secondaryId) @skip(if: $skipSecondary) {
    __typename
    ... on School {
      ...CompareSchools_secondarySchool
    }
    id
  }
}

fragment CompareSchools_primarySchool on School {
  name
  city
  state
  country
  numRatings
  avgRatingRounded
  legacyId
  summary {
    campusCondition
    campusLocation
    careerOpportunities
    clubAndEventActivities
    foodQuality
    internetSpeed
    libraryCondition
    schoolReputation
    schoolSafety
    schoolSatisfaction
    socialActivities
  }
}

fragment CompareSchools_secondarySchool on School {
  name
  city
  state
  country
  numRatings
  avgRatingRounded
  legacyId
  summary {
    campusCondition
    campusLocation
    careerOpportunities
    clubAndEventActivities
    foodQuality
    internetSpeed
    libraryCondition
    schoolReputation
    schoolSafety
    schoolSatisfaction
    socialActivities
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "primaryId",
    "type": "ID!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "secondaryId",
    "type": "ID",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "skipPrimary",
    "type": "Boolean!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "skipSecondary",
    "type": "Boolean!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "primaryId"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "__typename",
  "args": null,
  "storageKey": null
},
v3 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "secondaryId"
  }
],
v4 = [
  (v2/*: any*/),
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "id",
    "args": null,
    "storageKey": null
  },
  {
    "kind": "InlineFragment",
    "type": "School",
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "name",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "city",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "state",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "country",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "numRatings",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "avgRatingRounded",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "legacyId",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "summary",
        "storageKey": null,
        "args": null,
        "concreteType": "SchoolSummary",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "campusCondition",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "campusLocation",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "careerOpportunities",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "clubAndEventActivities",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "foodQuality",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "internetSpeed",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "libraryCondition",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "schoolReputation",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "schoolSafety",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "schoolSatisfaction",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "socialActivities",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "CompareSchoolsPageQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "Condition",
        "passingValue": false,
        "condition": "skipPrimary",
        "selections": [
          {
            "kind": "LinkedField",
            "alias": "primarySchool",
            "name": "node",
            "storageKey": null,
            "args": (v1/*: any*/),
            "concreteType": null,
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "kind": "InlineFragment",
                "type": "School",
                "selections": [
                  {
                    "kind": "FragmentSpread",
                    "name": "CompareSchools_primarySchool",
                    "args": null
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        "kind": "Condition",
        "passingValue": false,
        "condition": "skipSecondary",
        "selections": [
          {
            "kind": "LinkedField",
            "alias": "secondarySchool",
            "name": "node",
            "storageKey": null,
            "args": (v3/*: any*/),
            "concreteType": null,
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "kind": "InlineFragment",
                "type": "School",
                "selections": [
                  {
                    "kind": "FragmentSpread",
                    "name": "CompareSchools_secondarySchool",
                    "args": null
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "CompareSchoolsPageQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "Condition",
        "passingValue": false,
        "condition": "skipPrimary",
        "selections": [
          {
            "kind": "LinkedField",
            "alias": "primarySchool",
            "name": "node",
            "storageKey": null,
            "args": (v1/*: any*/),
            "concreteType": null,
            "plural": false,
            "selections": (v4/*: any*/)
          }
        ]
      },
      {
        "kind": "Condition",
        "passingValue": false,
        "condition": "skipSecondary",
        "selections": [
          {
            "kind": "LinkedField",
            "alias": "secondarySchool",
            "name": "node",
            "storageKey": null,
            "args": (v3/*: any*/),
            "concreteType": null,
            "plural": false,
            "selections": (v4/*: any*/)
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "CompareSchoolsPageQuery",
    "id": null,
    "text": "query CompareSchoolsPageQuery(\n  $primaryId: ID!\n  $secondaryId: ID\n  $skipPrimary: Boolean!\n  $skipSecondary: Boolean!\n) {\n  primarySchool: node(id: $primaryId) @skip(if: $skipPrimary) {\n    __typename\n    ... on School {\n      ...CompareSchools_primarySchool\n    }\n    id\n  }\n  secondarySchool: node(id: $secondaryId) @skip(if: $skipSecondary) {\n    __typename\n    ... on School {\n      ...CompareSchools_secondarySchool\n    }\n    id\n  }\n}\n\nfragment CompareSchools_primarySchool on School {\n  name\n  city\n  state\n  country\n  numRatings\n  avgRatingRounded\n  legacyId\n  summary {\n    campusCondition\n    campusLocation\n    careerOpportunities\n    clubAndEventActivities\n    foodQuality\n    internetSpeed\n    libraryCondition\n    schoolReputation\n    schoolSafety\n    schoolSatisfaction\n    socialActivities\n  }\n}\n\nfragment CompareSchools_secondarySchool on School {\n  name\n  city\n  state\n  country\n  numRatings\n  avgRatingRounded\n  legacyId\n  summary {\n    campusCondition\n    campusLocation\n    careerOpportunities\n    clubAndEventActivities\n    foodQuality\n    internetSpeed\n    libraryCondition\n    schoolReputation\n    schoolSafety\n    schoolSatisfaction\n    socialActivities\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '8081eea2e91ce697995e6ca7da8ce34b';
module.exports = node;
