import PropTypes from 'prop-types'
import TeacherTypes from './Teacher.types'

export const UserAttributes = {
  id: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  legacyId: PropTypes.number,
  Email: PropTypes.string,
  Type: PropTypes.string,
  teacher: TeacherTypes,
  emailConfirmed: PropTypes.bool
}

const UserTypes = PropTypes.shape(UserAttributes)

export default UserTypes
