/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type SchoolRatingsList_school$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type SchoolRatingsContainer_school$ref: FragmentReference;
declare export opaque type SchoolRatingsContainer_school$fragmentType: SchoolRatingsContainer_school$ref;
export type SchoolRatingsContainer_school = {|
  +numRatings: ?number,
  +$fragmentRefs: SchoolRatingsList_school$ref,
  +$refType: SchoolRatingsContainer_school$ref,
|};
export type SchoolRatingsContainer_school$data = SchoolRatingsContainer_school;
export type SchoolRatingsContainer_school$key = {
  +$data?: SchoolRatingsContainer_school$data,
  +$fragmentRefs: SchoolRatingsContainer_school$ref,
};
*/


const node/*: ReaderFragment*/ = {
  "kind": "Fragment",
  "name": "SchoolRatingsContainer_school",
  "type": "School",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "numRatings",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "FragmentSpread",
      "name": "SchoolRatingsList_school",
      "args": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = 'd6733571327883737e019c97e567b122';
module.exports = node;
