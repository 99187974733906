import React, { useEffect } from 'react'
import styled, { css } from 'styled-components'
import Cookies from '@utils/Cookies'
import ROUTE from '@enums/ROUTE'
import { breakpointSmediumAndBelow } from '@StyledComponents/theme/helpers/breakpoints'
import TRACKING_EVENT from '@enums/TRACKING_EVENT'
import COOKIE from '@enums/COOKIE'
import Link from '@components/Link'
import MODALS from '@enums/MODALS'
import useGlobalContext from '@hooks/useGlobalContext'
import { Button } from '@StyledComponents/Buttons'
import FullPageModalContainer from './Templates/FullPageModal'

const CCPAPromptArea = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 48px 41px 12px;
  width: 100%;
`
export const CCPAPromptBody = styled.div`
  font-family: ${props => props.theme.fontFamily.poppins};
  line-height: 24px;
  max-width: 100%;
  text-align: center;
  width: 475px;

  ${breakpointSmediumAndBelow(
    css`
      width: 295px;
    `
  )}

  a {
    font-weight: ${props => props.theme.fontWeight.bold};
    text-decoration: underline;
  }
`

export const StyledCloseButton = styled(Button)`
  font-size: ${props => props.theme.fontSize.medium};
  height: 36px;
  margin: 0 auto 24px;
  padding: 6px 0;
  text-align: center;
  width: 92px;
`

export function PrivacyLink() {
  return (
    <Link
      to={ROUTE.LEGAL_PRIVACY}
      onClick={() => Cookies.set(COOKIE.CCPA_NOTICE_VIEWED, true)}
      trackingLabel={TRACKING_EVENT.CCPA_FOOTER_PRIVACY}
    >
      Privacy Policy
    </Link>
  )
}

export function OptOutLink() {
  return (
    <Link
      to={ROUTE.CCPA}
      onClick={() => Cookies.set(COOKIE.CCPA_NOTICE_VIEWED, true)}
      trackingLabel={TRACKING_EVENT.CCPA_FOOTER_DNS}
    >
      CA Do Not Sell or Share My Personal Information
    </Link>
  )
}

const PromptBody = () => (
  <CCPAPromptBody>
    This site uses cookies. We and our third party advertising partners use cookies and similar
    technologies on this site to collect identifiers, such as IP address, and cookie and device IDs,
    for advertising and marketing purposes, as described in our <PrivacyLink />. By continuing to
    use this site, you consent to our use of cookies, as described in Sections 3.2 and 4 of our
    Privacy Policy, unless you have disabled them. CA residents can opt-out of the sale of these
    identifiers by visiting <OptOutLink />.
  </CCPAPromptBody>
)

export default function CCPAModal() {
  const { setActiveModal, closeActiveModal } = useGlobalContext()

  const onClose = () => {
    Cookies.set(COOKIE.CCPA_NOTICE_VIEWED, true)
  }

  const shouldShow = !Cookies.get(COOKIE.CCPA_NOTICE_VIEWED) && !Cookies.get(COOKIE.CCPA_OPT_OUT)
  useEffect(() => {
    if (shouldShow) {
      setActiveModal(MODALS.CCPA)
    }
  }, [])

  return (
    <FullPageModalContainer modal={MODALS.CCPA} onClose={onClose}>
      <CCPAPromptArea>
        <PromptBody />
      </CCPAPromptArea>
      <StyledCloseButton
        onClick={() => {
          onClose()
          closeActiveModal()
        }}
      >
        Close
      </StyledCloseButton>
    </FullPageModalContainer>
  )
}
