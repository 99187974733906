import React, { useContext, useState } from 'react'
import styled, { css } from 'styled-components'
import FormContext from '@contexts/FormContext'
import PropTypes from 'prop-types'
import { breakpointSmallAndBelow } from '@StyledComponents/theme/helpers/breakpoints'
import { HiddenCheckbox, CheckBoxContainer } from '@StyledComponents/Checkbox'

export const StyledCheckBoxContainer = styled(CheckBoxContainer)`
  margin-bottom: 7px;
  margin-top: 6px;
  width: auto;
`
export const StyledTag = styled.div`
  background-color: ${props =>
    props.checked ? props.theme.color.lightBlue : props.theme.color.gray12};
  border-radius: 14.5px;
  color: ${props => (props.checked ? props.theme.color.default : props.theme.color.gray8)};
  font-stretch: condensed;
  line-height: 17px;
  margin: 0 10px 8px 0;
  padding: 8px 20px;
  text-align: center;

  &:active {
    transform: ${props => !props.disabled && 'scale(0.9)'};
  }

  &:hover {
    background-color: ${props =>
      props.disabled ? props.theme.color.gray12 : props.theme.color.lightBlue};
    color: ${props => (props.disabled ? props.theme.color.gray8 : props.theme.color.default)};
    cursor: ${props => (props.disabled ? 'disabled' : 'pointer')};
  }

  ${breakpointSmallAndBelow(css`
    margin: 0 8px 8px 0;
  `)}
`

const FormTag = ({ name, label, disabled, setCount, count }) => {
  const { model, onChange } = useContext(FormContext)
  const [isFocused, setIsFocused] = useState(false)
  const isChecked = (model && model[name]) || false

  const toggle = () => {
    if (count < 3 || (model && model[name])) {
      const countNum = isChecked ? count - 1 : count + 1
      setCount(countNum)
      onChange({ target: { name, type: 'checkbox', checked: !isChecked } })
    }
  }

  return (
    <StyledCheckBoxContainer isFocused={isFocused}>
      <HiddenCheckbox
        checked={isChecked}
        disabled={disabled}
        onChange={onChange}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        name={name}
      />
      <StyledTag
        checked={isChecked}
        aria-hidden
        disabled={disabled}
        onChange={onChange}
        value={isChecked}
        onClick={toggle}
        isFocused={isFocused}
      >
        {label}
      </StyledTag>
    </StyledCheckBoxContainer>
  )
}

FormTag.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  setCount: PropTypes.func,
  count: PropTypes.number
}

export default FormTag
