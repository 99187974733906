import React, { useContext } from 'react'
import styled from 'styled-components'
import ReCAPTCHA from '@lib/ReCAPTCHA'
import FormContext from '@contexts/FormContext'
import { ButtonAttributes } from '@StyledComponents/Buttons'
import FormRecaptcha3Button from '@components/Form/FormReCaptcha3Button'

const AreaWrapper = styled.div`
  margin: 44px 0;
`

const StyledFormRecaptchaButton = styled(FormRecaptcha3Button)`
  ${ButtonAttributes};
  background: ${props => props.theme.color.darkMainBlue};
  min-width: 220px;
  text-decoration: none;
  width: fit-content;
`

const SubmitButtonArea = () => {
  const { model } = useContext(FormContext)
  const isDisabled = model && !model.termsPrivacy

  return (
    <AreaWrapper>
      <StyledFormRecaptchaButton action={ReCAPTCHA.ACTIONS.ADD_NEW_CAMPUS} disabled={isDisabled}>
        Add School
      </StyledFormRecaptchaButton>
    </AreaWrapper>
  )
}

export default SubmitButtonArea
