import React from 'react'
import PropTypes from 'prop-types'

export default function Alert({ bgColor = '#000000' }) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="12" cy="12" r="9" fill={bgColor} />
      <path
        d="M11 14.6338V16.5955H13V14.6338H11ZM12.9363 9.94586V7.5H11.051V9.94586L11.535 13.8694H12.4395L12.9363 9.94586Z"
        fill="white"
      />
    </svg>
  )
}

Alert.propTypes = {
  bgColor: PropTypes.string
}
