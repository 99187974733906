import Cookies from '@utils/Cookies'

export const LOGIN_COOKIES = ['rmpAuth', 'isLoggedIn', 'userinfo', 'JSESSIONID']

const processLogout = () => {
  const domainWithDot = `.${window.location.host}`
  LOGIN_COOKIES.forEach(c => Cookies.remove(c))
  LOGIN_COOKIES.forEach(c => Cookies.remove(c, { path: '', domain: domainWithDot }))
  // Once New Auth is on production this if statement can be removed (as can useNewAuth() app-wide).
  LOGIN_COOKIES.forEach(c =>
    Cookies.remove(c, {
      path: '',
      domain: window.location.host.replace('www', '')
    })
  )
}

export default processLogout
