const COUNTRIES = [
  {
    value: '0',
    label: 'United States'
  },
  {
    value: '1',
    label: 'Canada'
  },
  {
    value: '2',
    label: 'England'
  },
  {
    value: '5',
    label: 'Scotland'
  },
  {
    value: '6',
    label: 'Northern Ireland'
  },
  {
    value: '7',
    label: 'Wales'
  }
]

export default COUNTRIES
