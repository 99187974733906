import React from 'react'
import ReactDOM from 'react-dom'
import '@src/index.css'
import AppWrapper from '@src/AppWrapper'
import * as serviceWorker from '@src/serviceWorker'
import { ROOT_ID } from '@src/CONSTANTS'
// import * as Sentry from '@sentry/react'
// import { Integrations } from '@sentry/tracing'
// import getEnv from '@utils/getEnv'

// const REACT_APP_ENV = getEnv('REACT_APP_ENV')
// const REACT_APP_SENTRY_DSN = getEnv('REACT_APP_SENTRY_DSN')

// if (REACT_APP_SENTRY_DSN) {
//   Sentry.init({
//     dsn: REACT_APP_SENTRY_DSN,
//     environment: REACT_APP_ENV || 'dev',
//     integrations: [new Integrations.BrowserTracing()],
//     tracesSampleRate: 0,
//     tracePropagationTargets: [/.*ratemyprofessors\.com.*/],
//     tags: {
//       app: 'lockhart'
//     }
//   })
// } else {
//   // eslint-disable-next-line
//   console.warn('No sentry DSN provided, errors will not be reported')
// }

const root = document.getElementById(ROOT_ID)
if (root === null) {
  console.error(`Unable to get root element with id "${ROOT_ID}"`)
} else if (root.innerHTML.match(/^\s*$/)) {
  ReactDOM.render(<AppWrapper />, root)
} else {
  ReactDOM.hydrate(<AppWrapper />, root)
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
