/* eslint-disable no-param-reassign */
import React, { useState, useRef, useEffect } from 'react'
import styled from 'styled-components'
import { createFragmentContainer, graphql } from 'react-relay'
import PropTypes from 'prop-types'
import FormContext from '@contexts/FormContext'
import commitMutation from '@relay/commitMutation'
import ErrorText from '@StyledComponents/ErrorText'
import commitLocalUpdate from '@relay/commitLocalUpdate'
import FormErrors from './FormErrors'

export const VALIDATION_OBJECT_ID = 'ValidationResult-1'
export const DEFAULT_VALIDATION_ID = btoa(VALIDATION_OBJECT_ID)
let isMounted
const updateValidationInStore = (store, validation) => {
  // hermione always responds with validation id 1, we want the validation response
  // to only apply to our form instead of all forms, so we copy it's fields to
  // this forms instance and remove the server version with id 1 from the store.
  const formVal = store.get(validation.id)
  formVal.copyFieldsFrom(store.get(DEFAULT_VALIDATION_ID))
  formVal.setValue(validation.id, 'id')
  store.delete(DEFAULT_VALIDATION_ID)
}

const generateVariables = (form, getVariables) => {
  // FormData is not a conventional map, so we turn it into one
  const rawFormData = new FormData(form)
  const formData = {}
  // eslint-disable-next-line no-restricted-syntax
  for (const [key, value] of rawFormData) {
    formData[key] = value
  }
  return typeof getVariables === 'function' ? getVariables(formData) : formData
}

const buildOnSubmit = props => e => {
  const {
    mutation,
    validation,
    setLocked,
    setFormError,
    getVariables,
    updater,
    handleResult
  } = props
  e.preventDefault()
  setLocked(true)
  setFormError(null)
  const variables = generateVariables(e.target, getVariables)
  const update = store => {
    updateValidationInStore(store, validation)
    if (typeof updater === 'function') updater(store, variables)
  }

  commitMutation({
    mutation,
    variables,
    updater: update,
    onCompleted: result => {
      // Must check if component is mounted to prevent updating an unmounted component (no-op)
      if (isMounted) setLocked(false)

      if (handleResult) {
        handleResult.current = result
      }
    },
    onError: err => {
      console.error(err)

      setFormError(
        'Something went wrong, please try again and if the problem persists contact support.'
      )
      setLocked(false)
    }
  })
}

const buildOnChange = (model, setFormModel) => e => {
  // In the case of checkbox inputs 'name' attribute is not shared, but value
  // is a boolean determined by 'checked' attribute of that checkbox instead
  // of value
  const value = e.target.type === 'checkbox' ? !!e.target.checked : e.target.value
  setFormModel({ ...model, [e.target.name]: value })
}

const callOnSuccessIfNeeded = (validation, onSuccess) => {
  if (!validation?.success) return
  // Reset the validation object to prevent multiple calls to onSuccess
  // This allows the same form to be submitted multiple times in a row, etc.
  commitLocalUpdate(store => {
    const val = store.get(validation.id)
    val.setValue(false, 'success')
    if (typeof onSuccess === 'function') onSuccess()
  })
}

const buildManualSubmit = (submit, formRef) => () => {
  if (formRef.current == null) return
  submit({
    target: formRef.current,
    preventDefault: () => {}
  })
}

const StyledFormErrors = styled(FormErrors)`
  margin-bottom: 15px;
  text-align: center;
`

const FormController = props => {
  const {
    mutation,
    children,
    className,
    updater,
    validation,
    onSuccess,
    getVariables = m => m,
    existingData = {},
    handleResult
  } = props

  const [model, setFormModel] = useState(existingData)
  const [formError, setFormError] = useState(null)
  const [locked, setLocked] = useState(false)
  const formRef = useRef(null)

  useEffect(() => {
    isMounted = true
    return () => {
      isMounted = false
    }
  })

  useEffect(() => {
    callOnSuccessIfNeeded(validation, onSuccess)
  }, [validation, onSuccess])

  const onChange = buildOnChange(model, setFormModel)
  const submit = buildOnSubmit({
    mutation,
    validation,
    getVariables,
    setLocked,
    setFormError,
    updater,
    handleResult
  })

  const manualSubmit = buildManualSubmit(submit, formRef)
  const context = { model, onChange, locked, validation, submit: manualSubmit }
  return (
    <FormContext.Provider value={context}>
      {/* eslint-disable-next-line jsx-a11y/no-redundant-roles */}
      <form ref={formRef} role="form" className={className} onSubmit={submit}>
        <StyledFormErrors>{formError && <ErrorText>{formError}</ErrorText>}</StyledFormErrors>
        {children}
      </form>
    </FormContext.Provider>
  )
}

FormController.propTypes = {
  mutation: PropTypes.func.isRequired,
  getVariables: PropTypes.func,
  children: PropTypes.node,
  className: PropTypes.string,
  updater: PropTypes.func,
  validation: PropTypes.shape({
    errors: PropTypes.arrayOf(
      PropTypes.shape({ field: PropTypes.string, errors: PropTypes.arrayOf(PropTypes.string) })
    ),
    success: PropTypes.bool
  }),
  onSuccess: PropTypes.func,
  // We must allow object here to keep these generic
  // eslint-disable-next-line react/forbid-prop-types
  handleResult: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  // eslint-disable-next-line react/forbid-prop-types
  existingData: PropTypes.object
}

export default createFragmentContainer(FormController, {
  validation: graphql`
    fragment FormController_validation on ValidationResult {
      id
      success
      errors {
        field
        messages
      }
    }
  `
})
