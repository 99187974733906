import React from 'react'
import PropTypes from 'prop-types'
import { TOAST_EVENTS } from '@utils/toast'
import Toast, { ToastHeader, ToastDescription } from './Toast'

const FlagRatingSuccessToast = ({ closeToast }) => {
  return (
    <Toast canDismiss toastEvent={TOAST_EVENTS.FLAG_RATING_SUCCESS} closeToast={closeToast}>
      <ToastHeader>Rating Succesfully Flagged</ToastHeader>
      <ToastDescription>Thanks for the heads up! Our team will look it over.</ToastDescription>
    </Toast>
  )
}

FlagRatingSuccessToast.propTypes = {
  closeToast: PropTypes.func.isRequired
}

export default FlagRatingSuccessToast
