import React from 'react'
import styled, { css } from 'styled-components'
import { createFragmentContainer, graphql } from 'react-relay'

import MetaItem, { StyledMetaItem } from '@StyledComponents/MetaItem'
import { breakpointSmallPlusAndBelow } from '@StyledComponents/theme/helpers/breakpoints'
import RatingTypes from '@types/Rating.types'

export const META_ITEMS = [
  {
    label: 'For Credit',
    getValue: rating => rating.isForCredit,
    formatValue: value => (value ? 'Yes' : 'No')
  },
  {
    label: 'Attendance',
    getValue: rating => rating.attendanceMandatory,
    formatValue: value => (value && value !== 'non mandatory' ? 'Mandatory' : 'Not Mandatory')
  },
  {
    label: 'Would Take Again',
    getValue: rating => rating.wouldTakeAgain,
    formatValue: value => (value === 1 ? 'Yes' : 'No')
  },
  {
    label: 'Grade',
    getValue: rating => rating.grade,
    formatValue: value => value
  },
  {
    label: 'Textbook',
    getValue: rating => rating.textbookUse,
    formatValue: value => {
      if (value > 2) return 'Yes'
      return value < 0 ? 'N/A' : 'No'
    }
  },
  {
    label: 'Online Class',
    getValue: rating => rating.isForOnlineClass,
    formatValue: value => (value ? 'Yes' : '')
  }
]

const StyledCourseMeta = styled.div`
  display: flex;
  flex-flow: wrap;
  margin-bottom: 14px;

  ${breakpointSmallPlusAndBelow(
    css`
      flex-flow: column;
    `
  )}

  ${StyledMetaItem} {
    margin-bottom: 8px;
  }
`

export function CourseMeta({ rating }) {
  if (!rating) return null
  return (
    <StyledCourseMeta>
      {META_ITEMS.map((item, index) => (
        <MetaItem key={index.toString()} meta={item} object={rating} />
      ))}
    </StyledCourseMeta>
  )
}

CourseMeta.propTypes = { rating: RatingTypes }

export default createFragmentContainer(CourseMeta, {
  rating: graphql`
    fragment CourseMeta_rating on Rating {
      attendanceMandatory
      wouldTakeAgain
      grade
      textbookUse
      isForOnlineClass
      isForCredit
    }
  `
})
