/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type NoRatingsArea_teacher$ref = any;
type RatingDistributionChart_ratingsDistribution$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type RatingDistributionWrapper_teacher$ref: FragmentReference;
declare export opaque type RatingDistributionWrapper_teacher$fragmentType: RatingDistributionWrapper_teacher$ref;
export type RatingDistributionWrapper_teacher = {|
  +ratingsDistribution: ?{|
    +total: ?number,
    +$fragmentRefs: RatingDistributionChart_ratingsDistribution$ref,
  |},
  +$fragmentRefs: NoRatingsArea_teacher$ref,
  +$refType: RatingDistributionWrapper_teacher$ref,
|};
export type RatingDistributionWrapper_teacher$data = RatingDistributionWrapper_teacher;
export type RatingDistributionWrapper_teacher$key = {
  +$data?: RatingDistributionWrapper_teacher$data,
  +$fragmentRefs: RatingDistributionWrapper_teacher$ref,
};
*/


const node/*: ReaderFragment*/ = {
  "kind": "Fragment",
  "name": "RatingDistributionWrapper_teacher",
  "type": "Teacher",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "ratingsDistribution",
      "storageKey": null,
      "args": null,
      "concreteType": "ratingsDistribution",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "total",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "FragmentSpread",
          "name": "RatingDistributionChart_ratingsDistribution",
          "args": null
        }
      ]
    },
    {
      "kind": "FragmentSpread",
      "name": "NoRatingsArea_teacher",
      "args": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = 'e75ec0916bf9aeb0a496e6cfd6fc7e70';
module.exports = node;
