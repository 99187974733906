/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type Rating_rating$ref = any;
type Rating_teacher$ref = any;
type SchoolRating_rating$ref = any;
type SchoolRating_school$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type MyRatingsList_user$ref: FragmentReference;
declare export opaque type MyRatingsList_user$fragmentType: MyRatingsList_user$ref;
export type MyRatingsList_user = {|
  +id: ?string,
  +ratings: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +cursor: ?string,
      +rating: ?{|
        +legacyId: ?number,
        +teacher: ?{|
          +$fragmentRefs: Rating_teacher$ref
        |},
        +$fragmentRefs: Rating_rating$ref,
      |},
    |}>,
    +pageInfo: ?{|
      +hasNextPage: ?boolean,
      +endCursor: ?string,
    |},
  |},
  +campusRatings: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +cursor: ?string,
      +campusRating: ?{|
        +school: ?{|
          +$fragmentRefs: SchoolRating_school$ref
        |},
        +$fragmentRefs: SchoolRating_rating$ref,
      |},
    |}>,
    +pageInfo: ?{|
      +hasNextPage: ?boolean,
      +endCursor: ?string,
    |},
  |},
  +$refType: MyRatingsList_user$ref,
|};
export type MyRatingsList_user$data = MyRatingsList_user;
export type MyRatingsList_user$key = {
  +$data?: MyRatingsList_user$data,
  +$fragmentRefs: MyRatingsList_user$ref,
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "cursor",
  "args": null,
  "storageKey": null
},
v1 = [
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "hasNextPage",
    "args": null,
    "storageKey": null
  },
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "endCursor",
    "args": null,
    "storageKey": null
  }
];
return {
  "kind": "Fragment",
  "name": "MyRatingsList_user",
  "type": "User",
  "metadata": {
    "connection": [
      {
        "count": "count",
        "cursor": "cursor",
        "direction": "forward",
        "path": [
          "ratings"
        ]
      }
    ]
  },
  "argumentDefinitions": [
    {
      "kind": "LocalArgument",
      "name": "count",
      "type": "Int",
      "defaultValue": 10
    },
    {
      "kind": "LocalArgument",
      "name": "cursor",
      "type": "String",
      "defaultValue": null
    }
  ],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "id",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": "ratings",
      "name": "__MyRatingsList_ratings_connection",
      "storageKey": null,
      "args": null,
      "concreteType": "ratingConnectionTypeConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "ratingConnectionTypeEdges",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            {
              "kind": "LinkedField",
              "alias": "rating",
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "Rating",
              "plural": false,
              "selections": [
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "legacyId",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "teacher",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "Teacher",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "FragmentSpread",
                      "name": "Rating_teacher",
                      "args": null
                    }
                  ]
                },
                {
                  "kind": "FragmentSpread",
                  "name": "Rating_rating",
                  "args": null
                }
              ]
            },
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "Rating",
              "plural": false,
              "selections": [
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "__typename",
                  "args": null,
                  "storageKey": null
                }
              ]
            }
          ]
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "pageInfo",
          "storageKey": null,
          "args": null,
          "concreteType": "ratingConnectionTypePageInfo",
          "plural": false,
          "selections": (v1/*: any*/)
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "campusRatings",
      "storageKey": null,
      "args": [
        {
          "kind": "Variable",
          "name": "after",
          "variableName": "cursor"
        },
        {
          "kind": "Variable",
          "name": "first",
          "variableName": "count"
        }
      ],
      "concreteType": "campusRatingConnectionTypeConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "campusRatingConnectionTypeEdges",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            {
              "kind": "LinkedField",
              "alias": "campusRating",
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "SchoolRating",
              "plural": false,
              "selections": [
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "school",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "School",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "FragmentSpread",
                      "name": "SchoolRating_school",
                      "args": null
                    }
                  ]
                },
                {
                  "kind": "FragmentSpread",
                  "name": "SchoolRating_rating",
                  "args": null
                }
              ]
            }
          ]
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "pageInfo",
          "storageKey": null,
          "args": null,
          "concreteType": "campusRatingConnectionTypePageInfo",
          "plural": false,
          "selections": (v1/*: any*/)
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '405155d7ebdba95cafa0e97fd109bda1';
module.exports = node;
