import React from 'react'
import TeacherTypes from '@types/Teacher.types'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'
import { createFragmentContainer, graphql } from 'react-relay'
import ROUTE from '@enums/ROUTE'
import { breakpointSmediumAndBelow } from '@StyledComponents/theme/helpers/breakpoints'
import Link, { LINK_TYPE } from '@components/Link'

const StyledTeacherTitles = styled.div`
  display: flex;
  flex-direction: row;

  ${breakpointSmediumAndBelow(css`
    flex-direction: ${props => (props.large ? 'column' : 'row')};
    padding-bottom: ${props => (props.large ? '10px' : '0')};
  `)}
`

const StyledDot = styled.span`
  font-weight: bold;
  padding: 0 5px;

  ${breakpointSmediumAndBelow(css`
    display: none;
  `)}
`
export const StyledDepartmentName = styled.span`
  ${breakpointSmediumAndBelow(css`
    padding-bottom: 2px;
    margin-right: 5px;
  `)}
`

const TeacherSchoolLink = styled(Link)`
  color: ${props => props.theme.color.gray11};
`

export const TeacherTitles = ({ teacher, large }) => {
  if (!teacher || !teacher.school || !teacher.school.name || !teacher.school.legacyId) {
    return null
  }

  return (
    <StyledTeacherTitles large={large}>
      {teacher.department && (
        <React.Fragment>
          <StyledDepartmentName>{teacher.department} </StyledDepartmentName>
          <StyledDot>·</StyledDot>
        </React.Fragment>
      )}
      <TeacherSchoolLink
        to={ROUTE.NEW_CAMPUS_RATINGS.replace(':sid', teacher.school.legacyId)}
        type={LINK_TYPE.INTERNAL}
      >
        {teacher.school.name}
      </TeacherSchoolLink>
    </StyledTeacherTitles>
  )
}

export default createFragmentContainer(TeacherTitles, {
  teacher: graphql`
    fragment TeacherTitles_teacher on Teacher {
      department
      school {
        legacyId
        name
      }
    }
  `
})

TeacherTitles.propTypes = {
  teacher: TeacherTypes,
  large: PropTypes.bool
}
