import React, { useState, useContext } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import FormContext from '@contexts/FormContext'
import FormTag from './FormTag'

export const TagsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`

export function FormTags({ teacherTags }) {
  const buildTagName = n => n.split(' ').join('')
  const buildTagLabel = n => {
    const separateWord = n.toLowerCase().split(' ')
    /* eslint-disable no-plusplus */
    for (let i = 0; i < separateWord.length; i++) {
      separateWord[i] = separateWord[i].charAt(0).toUpperCase() + separateWord[i].substring(1)
    }
    return separateWord.join(' ')
  }

  const { model } = useContext(FormContext)
  const initialCount = teacherTags.filter(tag => model[buildTagName(tag.name)]).length
  const [count, setCount] = useState(initialCount)

  const buildTeacherTags = () => {
    const tagList = teacherTags.map(tag => {
      const tagName = buildTagName(tag.name)
      const tagLabel = buildTagLabel(tag.name)
      const isDisabled = model && !model[tagName] && count > 2
      return (
        <FormTag
          key={`${tag.id.toString()}`}
          name={tagName}
          label={tagLabel}
          disabled={isDisabled}
          setCount={setCount}
          count={count}
        />
      )
    })
    return tagList
  }
  return <TagsContainer>{buildTeacherTags()}</TagsContainer>
}

FormTags.propTypes = {
  teacherTags: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.id,
      name: PropTypes.string
    })
  )
}

export default FormTags
