import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { breakpointSmediumAndBelow } from '@StyledComponents/theme/helpers/breakpoints'

export const SocialIcon = styled.img`
  box-sizing: content-box;
  height: 18px;
  padding-left: 20px;
  width: 18px;

  ${breakpointSmediumAndBelow(
    css`
      padding: 0px 10px;
    `
  )}
`

const StyledSocialIconLink = styled.a`
  &:first-child {
    ${SocialIcon} {
      padding-left: 0px;
    }
  }
`

export default function SocialIconLink({ href, src, platform }) {
  return (
    <StyledSocialIconLink href={href}>
      <SocialIcon src={src} alt={`RateMyProfessors on ${platform}`} />
    </StyledSocialIconLink>
  )
}

SocialIconLink.propTypes = {
  href: PropTypes.string.isRequired,
  src: PropTypes.string.isRequired,
  platform: PropTypes.string.isRequired
}
