import React, { useContext } from 'react'
import styled, { ThemeContext, css } from 'styled-components'
import Select from '@StyledComponents/Select'
import PropTypes from 'prop-types'
import { breakpointSmallPlusAndBelow } from './StyledComponents/theme/helpers/breakpoints'

const StyledSelect = styled(Select)`
  width: 100%;
`

const getFilterStyles = (theme, additionalStyles) => {
  return {
    control: {
      borderColor: theme.color.gray4,
      fontSize: theme.fontSize.medium,
      fontWeight: theme.fontWeight.normal,
      ...additionalStyles?.control
    },
    option: {
      textAlign: 'left',
      ...additionalStyles?.option
    },
    placeholder: {
      fontSize: theme.fontSize.medium,
      fontFamily: theme.fontFamily.avenir
    },
    menu: {
      ...additionalStyles?.option
    }
  }
}

const getFilterTheme = theme => {
  return {
    borderRadius: '3px',
    fontWeight: theme.fontWeight.normal,
    fontSize: theme.fontSize.large
  }
}

const StyledTeacherSchoolSearchDropdown = styled.div`
  align-items: center;
  display: flex;
  width: 100%;

  ${breakpointSmallPlusAndBelow(css`
    flex-direction: column;
  `)}
`

export const StyledLabel = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.avenir};
  font-size: ${({ theme }) => theme.fontSize.medium};
  font-weight: ${({ theme }) => theme.fontWeight.bolder};
  line-height: 24px;
  margin-right: 12px;
  white-space: nowrap;

  ${breakpointSmallPlusAndBelow(css`
    margin-bottom: 10px;
    margin-right: 0;
    text-align: left;
    width: 100%;
  `)}
`

const TeacherSchoolSearchDropdown = props => {
  const theme = useContext(ThemeContext)

  const { additionalStyles, label, className, value, onChange } = props

  return (
    <StyledTeacherSchoolSearchDropdown className={className}>
      <StyledLabel>{label}</StyledLabel>
      <StyledSelect
        value={value}
        styles={getFilterStyles(theme, additionalStyles || {})}
        theme={getFilterTheme(theme)}
        onChange={option => onChange(option)}
        {...props}
      />
    </StyledTeacherSchoolSearchDropdown>
  )
}

TeacherSchoolSearchDropdown.propTypes = {
  additionalStyles: PropTypes.shape({}),
  label: PropTypes.string,
  className: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape({ count: PropTypes.number, value: PropTypes.string })),
  value: PropTypes.string,
  onChange: PropTypes.func
}

export default TeacherSchoolSearchDropdown
