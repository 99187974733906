import React from 'react'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'
import {
  breakpointSmediumAndBelow,
  breakpointSmallAndBelow
} from '@StyledComponents/theme/helpers/breakpoints'
import { poppinsBold } from './theme/typesets'

const DisplaySliderStyles = `
  margin-top: 8px;	
  width: 45%;	
  ${breakpointSmediumAndBelow(css`
    width: 286px;
  `)}	
  ${breakpointSmallAndBelow(css`
    width: 100%;
  `)}
`

const SummarySliderStyles = `
  width: 100%;
`

const DisplaySliderContainer = styled.div`
  align-items: center;
  display: inline-flex;
  height: 30px;
  justify-content: space-between;

  ${props => (props.summary ? SummarySliderStyles : DisplaySliderStyles)}
`

const DisplaySliderLabel = styled.div`
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  margin-right: 5px;
`

export const SliderBoxContainer = styled.div`
  ${props =>
    props.summary &&
    css`
      align-content: center;
      width: 80%;
    `}
  display: flex;
  flex-direction: row;
  justify-content: center;
`

const getBackgroundColor = props => {
  if (props.summary) {
    return props.boxValue <= props.score ? props.theme.color.darkMainBlue : props.theme.color.gray5
  }

  return props.boxValue <= props.score
    ? props.theme.color[props.displayColor]
    : props.theme.color.gray5
}

const DisplaySliderBox = styled.div`
  background-color: ${props => getBackgroundColor(props)};

  height: 18px;
  touch-action: none;
  width: ${props => (props.summary ? '100%' : '32px')};

  ${props =>
    props.summary &&
    css`
      max-width: 90%;
      min-width: 15px;
    `}

  ${props =>
    props.boxValue === 1 &&
    css`
      border-radius: 20px 0 0 20px;
    `};
  ${props =>
    props.boxValue === 5 &&
    css`
      border-radius: 0 20px 20px 0;
    `};
  ${props =>
    props.boxValue < 5 &&
    css`
      border-right: 2px solid white;
    `};
`

const BulletPoint = styled.div`
  background-color: ${props => props.theme.color[props.displayColor] ?? props.theme.color.gray5};
  border-radius: 60%;
  margin: 0px 8px 0px 8px;
  min-height: 12px;
  min-width: 12px;
`

const DisplayScore = styled.div`
  font-size: 20px;
  min-width: 32px;
  width: 10%;
  ${poppinsBold}

  ${breakpointSmallAndBelow(css`
    font-size: 16px;
  `)}
`

const DisplaySlider = ({ rating, summarySlider, bullet }) => {
  const SliderBoxes = []
  const { name, score } = rating

  const generateColor = () => {
    if (score < 0) return 'grey'
    if (score === 0) return 'grey'
    if (score < 3) return 'awfulScore'
    if (score > 3) return 'awesomeScore'
    return 'averageScore'
  }

  const displayColor = generateColor()

  for (let i = 1; i < 6; i += 1) {
    SliderBoxes.push(
      <DisplaySliderBox
        key={`slider-box-${i}`}
        boxValue={i}
        score={score}
        displayColor={displayColor}
        data-testid={`slider-box-${i}`}
        summary={summarySlider}
      />
    )
  }

  if (summarySlider) {
    return (
      <DisplaySliderContainer summary={summarySlider}>
        {bullet === 'left' && (
          <React.Fragment>
            <DisplayScore>{score >= 0 && score}</DisplayScore>
            <BulletPoint displayColor={displayColor} />
          </React.Fragment>
        )}
        <SliderBoxContainer summary={summarySlider}>{SliderBoxes}</SliderBoxContainer>
        {bullet === 'right' && (
          <React.Fragment>
            <BulletPoint displayColor={displayColor} />
            <DisplayScore>{score >= 0 && score}</DisplayScore>
          </React.Fragment>
        )}
      </DisplaySliderContainer>
    )
  }

  return (
    <DisplaySliderContainer>
      <DisplaySliderLabel>{name}</DisplaySliderLabel>
      <SliderBoxContainer>{SliderBoxes}</SliderBoxContainer>
    </DisplaySliderContainer>
  )
}

DisplaySlider.propTypes = {
  rating: PropTypes.shape({
    tag: PropTypes.string,
    score: PropTypes.number,
    name: PropTypes.string
  }),
  summarySlider: PropTypes.bool,
  bullet: PropTypes.string
}

export default DisplaySlider
