/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type NoRatingsArea_teacher$ref = any;
type RatingDistributionWrapper_teacher$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type CompareProfessorsColumn_teacher$ref: FragmentReference;
declare export opaque type CompareProfessorsColumn_teacher$fragmentType: CompareProfessorsColumn_teacher$ref;
export type CompareProfessorsColumn_teacher = {|
  +id: ?string,
  +legacyId: ?number,
  +firstName: ?string,
  +lastName: ?string,
  +school: ?{|
    +legacyId: ?number,
    +name: ?string,
  |},
  +department: ?string,
  +departmentId: ?string,
  +avgRating: ?number,
  +numRatings: ?number,
  +wouldTakeAgainPercentRounded: ?any,
  +mandatoryAttendance: ?{|
    +yes: ?number,
    +no: ?number,
    +neither: ?number,
    +total: ?number,
  |},
  +takenForCredit: ?{|
    +yes: ?number,
    +no: ?number,
    +neither: ?number,
    +total: ?number,
  |},
  +$fragmentRefs: NoRatingsArea_teacher$ref & RatingDistributionWrapper_teacher$ref,
  +$refType: CompareProfessorsColumn_teacher$ref,
|};
export type CompareProfessorsColumn_teacher$data = CompareProfessorsColumn_teacher;
export type CompareProfessorsColumn_teacher$key = {
  +$data?: CompareProfessorsColumn_teacher$data,
  +$fragmentRefs: CompareProfessorsColumn_teacher$ref,
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "legacyId",
  "args": null,
  "storageKey": null
},
v1 = [
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "yes",
    "args": null,
    "storageKey": null
  },
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "no",
    "args": null,
    "storageKey": null
  },
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "neither",
    "args": null,
    "storageKey": null
  },
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "total",
    "args": null,
    "storageKey": null
  }
];
return {
  "kind": "Fragment",
  "name": "CompareProfessorsColumn_teacher",
  "type": "Teacher",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "id",
      "args": null,
      "storageKey": null
    },
    (v0/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "firstName",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "lastName",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "school",
      "storageKey": null,
      "args": null,
      "concreteType": "School",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "name",
          "args": null,
          "storageKey": null
        }
      ]
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "department",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "departmentId",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "avgRating",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "numRatings",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "wouldTakeAgainPercentRounded",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "mandatoryAttendance",
      "storageKey": null,
      "args": null,
      "concreteType": "mandatoryAttendance",
      "plural": false,
      "selections": (v1/*: any*/)
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "takenForCredit",
      "storageKey": null,
      "args": null,
      "concreteType": "takenForCredit",
      "plural": false,
      "selections": (v1/*: any*/)
    },
    {
      "kind": "FragmentSpread",
      "name": "NoRatingsArea_teacher",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "RatingDistributionWrapper_teacher",
      "args": null
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '69494cdddd87a844cd08f6e06bfd306f';
module.exports = node;
