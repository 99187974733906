import React from 'react'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'
import { breakpointSmallPlusAndBelow } from '@StyledComponents/theme/helpers/breakpoints'
import FullPageModal, { fullPageModalStyles } from './FullPageModal'

export const StyledModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding: ${props => (props.padding ? props.padding : '48px 116px')};
  text-align: center;
  width: 100%;

  ${breakpointSmallPlusAndBelow(css`
    padding: 48px 16px;
  `)}
`

const StyledSmallModal = styled(FullPageModal)`
  ${fullPageModalStyles}

  &__content {
    margin: 0 16px;
    max-width: ${props => (props.maxWidth ? props.maxWidth : '575px')};
    min-height: ${props => (props.minHeight ? props.minHeight : null)};
    width: 100%;
  }
`

/**
 * SmallModal is a wrapper for all modals involving auth
 * It piggybacks off of FullpageModal's styling and functionality, while including it's own responsiveness
 */
export default function SmallModal(props) {
  const { children, padding } = props
  return (
    <StyledSmallModal {...props}>
      <StyledModalContainer padding={padding}>{children}</StyledModalContainer>
    </StyledSmallModal>
  )
}

SmallModal.propTypes = {
  children: PropTypes.node,
  padding: PropTypes.string
}
