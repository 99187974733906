import React, { useEffect, useReducer, useState } from 'react'
import { graphql } from 'react-relay'
import PropTypes from 'prop-types'

import Cookies from '@utils/Cookies'
import getLoginCookies from '@utils/getLoginCookies'
import UserTypes from '@types/User.types'
import QueryWrapper from '@componentUtils/QueryWrapper'
import commitMutation from '@relay/commitMutation'
import routeConfigs from '@src/config/routeConfig'
import { removeSchoolCookies } from '@utils/getAndSetUserSchool'
import GlobalContext from './index'
import reducer, { ACTIONS } from './reducer'

export const userInfoQuery = graphql`
  query GlobalContextProviderQuery {
    user {
      firstName
      id
      legacyId
      Email
      Type
      graduationYear
      emailConfirmed
      major
      school {
        id
        legacyId
        name
        numRatings
        avgRatingRounded
        summary {
          campusCondition
          campusLocation
          careerOpportunities
          clubAndEventActivities
          foodQuality
          internetSpeed
          libraryCondition
          schoolReputation
          schoolSafety
          schoolSatisfaction
          socialActivities
        }
      }
    }
  }
`

const userUpdateMutation = graphql`
  mutation GlobalContextProviderUpdateUserMutation($user: UserInputObject!) {
    userUpdate(user: $user) {
      user {
        school {
          id
        }
      }
    }
  }
`

const updateUserSchool = user => {
  const userCookieSchoolId = Cookies.get('userSchoolId')

  if (!userCookieSchoolId) {
    return
  }

  commitMutation({
    mutation: userUpdateMutation,
    variables: { user: { email: user.Email, schoolId: userCookieSchoolId } },
    onCompleted: (result, err) => {
      if (result) {
        removeSchoolCookies()
        return
      }

      console.error(err)
    }
  })
}

export default function GlobalContextProvider({ children }) {
  const [hasLoginCookies, setHasLoginCookies] = useState(getLoginCookies())
  const renderProvider = node => <Provider node={node}>{children}</Provider>
  return hasLoginCookies ? (
    <QueryWrapper query={userInfoQuery} component={renderProvider} />
  ) : (
    renderProvider({ retry: () => setHasLoginCookies(getLoginCookies()) })
  )
}

GlobalContextProvider.propTypes = {
  children: PropTypes.node
}

export function Provider({ node, children }) {
  const globalRef = React.useRef({})
  const [state, dispatch] = useReducer(reducer, {
    authToken: Cookies.get('rmpAuth') ? `Bearer ${Cookies.get('rmpAuth')}` : null,
    activeModal: null
  })

  useEffect(() => {
    // Will only update authToken if no modals are open to prevent them closing.
    if (typeof node.retry === 'function' && state.activeModal === null) node.retry()
  }, [state.authToken, state.activeModal])

  useEffect(() => {
    if (node.user) updateUserSchool(node.user)
  }, [node.user])

  const value = {
    user: node.user,
    activeModal: state.activeModal,
    setActiveModal: modal => {
      dispatch({
        modal,
        type: ACTIONS.SET_ACTIVE_MODAL
      })
    },
    closeActiveModal: () => {
      dispatch({
        type: ACTIONS.CLOSE_ACTIVE_MODAL
      })
    },
    setShouldRedirectAfterClose: shouldRedirect => {
      dispatch({ shouldRedirect, type: ACTIONS.SET_REDIRECT_AFTER_CLOSE })
    },
    login: ({ token, shouldRedirect }) => dispatch({ type: ACTIONS.LOG_IN, token, shouldRedirect }),
    logout: () => dispatch({ type: ACTIONS.LOG_OUT }),

    // Needed for `useCurrentRoute` as otherwise it would create cyclomatic dependencies
    routes: routeConfigs,

    // Needed for using data passed as ref in global modals
    // eslint-disable-next-line object-shorthand
    globalRef: globalRef
  }
  return <GlobalContext.Provider value={value}>{children}</GlobalContext.Provider>
}

Provider.propTypes = {
  node: PropTypes.shape({
    user: UserTypes,
    retry: PropTypes.func
  }),
  children: PropTypes.node
}
