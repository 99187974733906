import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { createFragmentContainer, graphql } from 'react-relay'
import RatingTypes from '@types/Rating.types'
import IconButton from '@components/StyledComponents/IconButton'
import TeacherTypes from '@types/Teacher.types'
import usePermalink from '@utils/usePermalink'
import TRACKING_EVENT from '@enums/TRACKING_EVENT'
import { LINK_TYPE } from '@src/components/Link'
import { convertDBtoReadable } from '@utils/date'
import Thumbs from '@components/Thumbs'
import ROUTE from '@enums/ROUTE'
import ShareRating from '@components/StyledComponents/ShareRating'
import ShareRatingToast from '@components/Toast/ShareRatingToast'
import toast, { TOAST_EVENTS } from '@utils/toast'
import ReportFlag from '../ReportFlag'

export const StyledRatingFooter = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 24px 0 32px;
`

export const ButtonWrapper = styled.div`
  align-items: center;
  display: flex;
`

const ReplyButton = styled(IconButton.Reply)`
  margin-left: 14px;
  padding: 0 3px 3px;
`

export function RatingFooter({
  rating,
  teacher,
  className,
  onReplyClicked,
  signupFeatureBlocked = false,
  confirmEmailFeature = false
}) {
  const { flagStatus } = rating
  const isPermalink = usePermalink()

  const showReply = !rating.teacherNote && onReplyClicked && teacher.isProfCurrentUser

  const handleOnShare = async () => {
    const url = `${window.location.origin}`
      .concat(ROUTE.RATING.replace(':rid', rating.legacyId))
      .concat('?utm_source=share&utm_medium=web&utm_campaign=prof_rating')
    await navigator?.clipboard?.writeText(url)
    toast(ShareRatingToast, TOAST_EVENTS.SHARE_RATING)
  }

  return (
    <StyledRatingFooter className={className}>
      <Thumbs
        teacher={teacher}
        rating={rating}
        school={null}
        schoolRating={null}
        signupFeatureBlocked={signupFeatureBlocked}
        confirmEmailFeature={confirmEmailFeature}
      />

      <ButtonWrapper>
        {isPermalink && (
          <ShareRating
            trackingLabel={TRACKING_EVENT.FLAG_RATING}
            type={LINK_TYPE.INTERNAL}
            onClick={handleOnShare}
          />
        )}
        <ReportFlag
          status={flagStatus}
          link={ROUTE.NEW_FLAG_PROF_RATING.replace(':tid', teacher.legacyId).replace(
            ':rid',
            rating.legacyId
          )}
          trackingLabel={TRACKING_EVENT.FLAG_RATING}
          type={LINK_TYPE.INTERNAL}
          entityLabel="rating"
          reviewedDate={rating.adminReviewedAt && convertDBtoReadable(rating.adminReviewedAt)}
        />

        {showReply && (
          <ReplyButton
            trackingEvent={TRACKING_EVENT.REPLY_CLICK}
            onClick={onReplyClicked}
            title="Reply to this rating"
          />
        )}
      </ButtonWrapper>
    </StyledRatingFooter>
  )
}

RatingFooter.propTypes = {
  rating: RatingTypes,
  teacher: TeacherTypes,
  className: PropTypes.string,
  onReplyClicked: PropTypes.func,
  signupFeatureBlocked: PropTypes.bool,
  confirmEmailFeature: PropTypes.bool
}

export default createFragmentContainer(RatingFooter, {
  teacher: graphql`
    fragment RatingFooter_teacher on Teacher {
      id
      legacyId
      lockStatus
      isProfCurrentUser
      ...Thumbs_teacher
    }
  `,
  rating: graphql`
    fragment RatingFooter_rating on Rating {
      id
      comment
      adminReviewedAt
      flagStatus
      legacyId
      thumbsUpTotal
      thumbsDownTotal
      thumbs {
        thumbsUp
        thumbsDown
        computerId
      }
      teacherNote {
        id
      }
      ...Thumbs_rating
    }
  `
})
