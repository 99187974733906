import { helveticaBoldXLarge } from '@components/StyledComponents/theme/typesets'
import React from 'react'
import styled from 'styled-components'

export const StyledRatingRemoved = styled.div`
  align-items: center;
  background-color: rgba(241, 241, 241, 0.4);
  display: flex;
  height: 364px;
  justify-content: center;
  margin: 16px 0;
  width: 100%;
  ${helveticaBoldXLarge}
`

const RatingRemoved = () => {
  return <StyledRatingRemoved>This rating has been removed</StyledRatingRemoved>
}

export default RatingRemoved
