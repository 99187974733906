import React, { useContext } from 'react'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'
import FormContext from '@contexts/FormContext'
import { ButtonAttributes } from '@StyledComponents/Buttons'
import FormRecaptcha3Button from '@components/Form/FormReCaptcha3Button'
import SubmitButton from '@components/Form/Buttons/SubmitButton'
import ReCAPTCHA from '@lib/ReCAPTCHA'
import Link from '@components/Link'
import ROUTE from '@enums/ROUTE'
import { breakpointSmallPlusAndBelow } from '@components/StyledComponents/theme/helpers/breakpoints'

const StyledSubmitArea = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`
export const StyledLegal = styled.div`
  line-height: 17px;
  margin: 0 62px 24px;
  ${breakpointSmallPlusAndBelow(
    css`
      margin: 0 0 24px;
    `
  )}
`

export const StyledLegalLink = styled(Link).attrs(props => ({ ...props, target: '_blank' }))`
  color: ${({ theme }) => theme.color.mainBlue};
  text-decoration: none;
`

export const StyledButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`

const ButtonStyles = css`
  ${ButtonAttributes}
  padding: 9px 66px;
  &:disabled {
    &:hover {
      background: ${({ theme }) => theme.color.fadedDefault};
    }
  }
  &:hover {
    background: ${({ theme }) => theme.color.gray8};
  }
`

export const StyledFormRecaptchaButton = styled(FormRecaptcha3Button)`
  ${ButtonStyles}
`

const StyledFormSubmitButton = styled(SubmitButton)`
  ${ButtonStyles}
  font-size: unset;
  height: unset;
  margin: unset;
  width: unset;
`

const SubmitArea = ({ isEditRating, isTeacherRating }) => {
  const { model } = useContext(FormContext)
  const {
    rating,
    difficulty,
    wouldTakeAgain,
    location,
    opportunities,
    social,
    safety,
    comment,
    internet,
    facilities,
    food,
    clubs,
    happiness,
    reputation
  } = model
  const teacherDisabled =
    (model && !model.class) || !rating || !difficulty || !wouldTakeAgain || !comment
  const schoolDisabled =
    (model && !location) ||
    !opportunities ||
    !social ||
    !safety ||
    !comment ||
    !internet ||
    !facilities ||
    !food ||
    !clubs ||
    !happiness ||
    !reputation
  const disabled = isTeacherRating ? teacherDisabled : schoolDisabled

  return (
    <StyledSubmitArea>
      <StyledLegal>
        By clicking the &quot;Submit&quot; button, I acknowledge that I have read and agreed to the{' '}
        Rate My Professors{' '}
        <StyledLegalLink to={ROUTE.LEGAL_SITE_GUIDELINES}>Site Guidelines</StyledLegalLink>,{' '}
        <StyledLegalLink to={ROUTE.LEGAL_TERMS}>Terms of Use</StyledLegalLink> and{' '}
        <StyledLegalLink to={ROUTE.LEGAL_PRIVACY}>Privacy Policy</StyledLegalLink>. Submitted data{' '}
        becomes the property of Rate My Professors.
      </StyledLegal>
      <StyledButtonContainer>
        {isEditRating ? (
          <StyledFormSubmitButton type="submit" disabled={disabled} id="edit-teacher-rating-btn">
            Submit Rating
          </StyledFormSubmitButton>
        ) : (
          <StyledFormRecaptchaButton
            action={
              isTeacherRating
                ? ReCAPTCHA.ACTIONS.ADD_RATING_PROFESSOR
                : ReCAPTCHA.ACTIONS.ADD_RATING_SCHOOL
            }
            disabled={disabled}
            className={isTeacherRating ? 'add-teacher-rating-btn' : 'add-school-rating-btn'}
          >
            Submit Rating
          </StyledFormRecaptchaButton>
        )}
      </StyledButtonContainer>
    </StyledSubmitArea>
  )
}

SubmitArea.propTypes = {
  isEditRating: PropTypes.bool,
  isTeacherRating: PropTypes.bool
}

export default SubmitArea
