import React from 'react'
import styled, { css } from 'styled-components'
import SEARCH_ITEM_TYPES from '@enums/SEARCHITEMTYPES'
import ROUTE from '@enums/ROUTE'
import Link, { LINK_TYPE } from '@components/Link'
import PropTypes from 'prop-types'
import { breakpointSmediumAndBelow } from './theme/helpers/breakpoints'

export const StyledLink = styled(Link)`
  font-size: ${({ theme }) => theme.fontSize.medium};
  margin-top: 10px;
`

const StyledAddItemCTA = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
`

const StyledPrompt = styled.div`
  font-size: ${({ theme }) => theme.fontSize.medium};

  ${breakpointSmediumAndBelow(css`
    margin-bottom: 16px;
  `)}
`

const AddItemCTA = ({ itemType, className }) => {
  const areItemsTeachers = itemType === SEARCH_ITEM_TYPES.TEACHERS
  const itemString = areItemsTeachers ? 'Professor' : 'School'

  return (
    <StyledAddItemCTA className={className}>
      <StyledPrompt>
        Don&apos;t see the {itemString.toLowerCase()} you&apos;re looking for?{' '}
      </StyledPrompt>

      <StyledLink
        to={areItemsTeachers ? `${ROUTE.NEW_ADD_PROFESSOR}` : `${ROUTE.NEW_ADD_CAMPUS}`}
        type={LINK_TYPE.INTERNAL}
      >
        Add a {itemString}
      </StyledLink>
    </StyledAddItemCTA>
  )
}

export default AddItemCTA

AddItemCTA.propTypes = {
  className: PropTypes.string,
  itemType: PropTypes.string
}
