/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type SimilarProfessorListItem_teacher$ref: FragmentReference;
declare export opaque type SimilarProfessorListItem_teacher$fragmentType: SimilarProfessorListItem_teacher$ref;
export type SimilarProfessorListItem_teacher = {|
  +legacyId: ?number,
  +firstName: ?string,
  +lastName: ?string,
  +avgRating: ?number,
  +$refType: SimilarProfessorListItem_teacher$ref,
|};
export type SimilarProfessorListItem_teacher$data = SimilarProfessorListItem_teacher;
export type SimilarProfessorListItem_teacher$key = {
  +$data?: SimilarProfessorListItem_teacher$data,
  +$fragmentRefs: SimilarProfessorListItem_teacher$ref,
};
*/


const node/*: ReaderFragment*/ = {
  "kind": "Fragment",
  "name": "SimilarProfessorListItem_teacher",
  "type": "RelatedTeacher",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "legacyId",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "firstName",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "lastName",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "avgRating",
      "args": null,
      "storageKey": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = 'f6a8fdad04aa1b6867999ccc930d3195';
module.exports = node;
