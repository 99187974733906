import React from 'react'
import ReactRouterPropTypes from 'react-router-prop-types'
import ROUTE from '@enums/ROUTE'
import { withRouter, Redirect } from 'react-router-dom'
import QueryWrapper from '@componentUtils/QueryWrapper'
import { editRatingTeacherQuery, getVariables } from './RateTeacher.utils'
import RateTeacher from './RateTeacher'

export const EditRatingTeacherPage = ({ location }) => {
  const variables = getVariables(location)
  if (!variables.id) {
    return <Redirect to={ROUTE.TEACHER_NOT_FOUND} />
  }

  return (
    <QueryWrapper query={editRatingTeacherQuery} component={RateTeacher} variables={variables} />
  )
}

EditRatingTeacherPage.propTypes = {
  location: ReactRouterPropTypes.location
}

const withRouterEditRatingTeacherPage = withRouter(EditRatingTeacherPage)
withRouterEditRatingTeacherPage.serverQueries = [
  {
    query: editRatingTeacherQuery,
    getVariables
  }
]

export default withRouterEditRatingTeacherPage
