import React from 'react'
import styled, { css } from 'styled-components'
import { createFragmentContainer, graphql } from 'react-relay'
import TeacherTypes from '@types/Teacher.types'
import VerticalSeperator from '@StyledComponents/VerticalSeparator'
import { breakpointSmallAndBelow } from '@StyledComponents/theme/helpers/breakpoints'
import { poppinsBolder } from '@StyledComponents/theme/typesets'

export const StyledCardFeedback = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: wrap;

  ${VerticalSeperator} {
    margin: 0 8px;
    max-height: 14px;
    min-height: 14px;
  }
`

export const CardFeedbackItem = styled.div`
  align-items: center;
  display: flex;
  min-width: fit-content;

  ${breakpointSmallAndBelow(css`
    min-width: unset;
  `)}
`

export const CardFeedbackNumber = styled.div`
  font-size: ${props => props.theme.fontSize.large};
  line-height: 27px;
  margin-right: 8px;

  ${poppinsBolder}
`

export const CardFeedback = ({ teacher }) => {
  const { wouldTakeAgainPercent, avgDifficulty } = teacher

  const formattedWouldTakeAgain =
    wouldTakeAgainPercent < 0 ? 'N/A' : `${Math.ceil(wouldTakeAgainPercent)}%`

  return (
    <StyledCardFeedback>
      <CardFeedbackItem>
        <CardFeedbackNumber>{formattedWouldTakeAgain}</CardFeedbackNumber> would take again
      </CardFeedbackItem>
      <VerticalSeperator />{' '}
      <CardFeedbackItem>
        <CardFeedbackNumber>{avgDifficulty}</CardFeedbackNumber> level of difficulty
      </CardFeedbackItem>
    </StyledCardFeedback>
  )
}

export default createFragmentContainer(CardFeedback, {
  teacher: graphql`
    fragment CardFeedback_teacher on Teacher {
      wouldTakeAgainPercent
      avgDifficulty
    }
  `
})

CardFeedback.propTypes = {
  teacher: TeacherTypes
}
