import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import customParseFormat from 'dayjs/plugin/customParseFormat'

dayjs.extend(advancedFormat)
dayjs.extend(utc)
dayjs.extend(customParseFormat)

/**
 * convertDBtoReadable converts a database created timestamp in the format
 * 'YYYY-MM-DD HH-mm-ss Z UTC' to the human readable format: 'MMM Do, YYYY'
 */
export const convertDBtoReadable = timestamp => {
  if (!timestamp) return 'Invalid Timestamp'

  return dayjs(timestamp, 'YYYY-MM-DD HH-mm-ss Z UTC').format('MMM Do, YYYY')
}

/**
 * convertUnixToDB converts a typical UNIX timestamp to the database created
 * format 'YYYY-MM-DD HH-mm-ss Z UTC'
 */
export const convertUnixToDB = unixTimeStamp => {
  if (!unixTimeStamp) return 'Invalid Timestamp'

  return dayjs(unixTimeStamp)
    .utc()
    .format('YYYY-MM-DD HH-mm-ss Z UTC')
}

export default { convertDBtoReadable, convertUnixToDB }
