import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import SEARCH_ITEM_TYPES from '@enums/SEARCHITEMTYPES'
import { ButtonAttributes } from '@StyledComponents/Buttons'
import AddItemCTA, { StyledLink } from '@StyledComponents/AddItemCTA'
import { getUserSchool } from '@utils/getAndSetUserSchool'
import useUserInfo from '@hooks/useUserInfo'
import { Helmet } from 'react-helmet'
import {
  breakpointSmallPlusAndBelow,
  breakpointSmediumAndBelow
} from './StyledComponents/theme/helpers/breakpoints'

export const NO_RESULTS_FOUND_AREA_WRAPPER_TEST_ID = 'no_results_found_area_wrapper_test_id'

const StyledNoResultsFound = styled.div`
  margin-top: 64px;
  max-width: 780px;
  min-height: 100vh;
  text-align: left;
  width: 100%;

  ${StyledLink} {
    ${ButtonAttributes}

    height: 36px;
    line-height: 20px;
    margin-top: 15px;
    padding: 8px 24px;
    text-decoration: none;
    width: fit-content;

    ${breakpointSmallPlusAndBelow(css`
      height: unset;
      margin-top: 0;
      padding: 14px 28px;
      width: 100%;
    `)}
  }
`

const NoResultsFoundHeader = styled.div`
  font-size: ${({ theme }) => theme.fontSize.xlarge};
  margin-bottom: 32px;

  ${breakpointSmediumAndBelow(css`
    font-size: ${({ theme }) => theme.fontSize.medium};

    b {
      font-size: ${({ theme }) => theme.fontSize.medium};
    }
  `)}
`
const NoResultsFoundSubHeader = styled.div`
  font-size: ${({ theme }) => theme.fontSize.medium};
  margin-bottom: 41px;

  ${breakpointSmediumAndBelow(css`
    font-size: ${({ theme }) => theme.fontSize.medium};
  `)}
`

const AddCTAWrapper = styled(AddItemCTA)`
  align-items: center;
  background: ${({ theme }) => theme.color.gray7};
  display: flex;
  font-size: ${({ theme }) => theme.fontSize.medium};
  justify-content: space-between;
  padding: 29px 37px 29px 21px;

  ${breakpointSmallPlusAndBelow(css`
    justify-content: center;
  `)}
`

const getHeader = (areItemsTeachers, queryValue, school) => {
  if (!areItemsTeachers) {
    return (
      <React.Fragment>
        No school with <b>&quot;{queryValue}&quot;</b> in its name.
      </React.Fragment>
    )
  }

  return (
    <React.Fragment>
      No professors with <b>&quot;{queryValue}&quot;</b> in their name
      {school && (
        <span>
          {' '}
          at <b>{school.name}</b>
        </span>
      )}
      .
    </React.Fragment>
  )
}

const NoResultsFoundArea = ({ itemType, queryValue }) => {
  const areItemsTeachers = itemType === SEARCH_ITEM_TYPES.TEACHERS

  const user = useUserInfo()

  const school = getUserSchool(user)

  return (
    <StyledNoResultsFound data-testid={NO_RESULTS_FOUND_AREA_WRAPPER_TEST_ID} id="gtm_no_results">
      <Helmet>
        <title>Search results - RateMyProfessors</title>
      </Helmet>
      <NoResultsFoundHeader>{getHeader(areItemsTeachers, queryValue, school)}</NoResultsFoundHeader>
      <NoResultsFoundSubHeader>
        Use the search bar above and check the spelling or try an alternate spelling
      </NoResultsFoundSubHeader>
      <AddCTAWrapper itemType={itemType} />
    </StyledNoResultsFound>
  )
}

export default NoResultsFoundArea

NoResultsFoundArea.propTypes = {
  itemType: PropTypes.oneOf(Object.values(SEARCH_ITEM_TYPES)).isRequired,
  queryValue: PropTypes.string.isRequired
}
