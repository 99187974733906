import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import FormContext from '@contexts/FormContext'
import CheckMark from '@assets/images/checkmark-clear.svg'
import XMark from '@assets/images/close-icon-white.svg'
import FieldWrapper from '@components/Form/FieldWrapper'

export const RadioButtonsSet = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`

export const RadioButtonContainer = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  margin: 0 32px;
  max-width: 44px;
  text-align: center;
`

export const RadioButtonLabel = styled.label`
  color: ${({ theme }) => theme.color.gray11};
  font-size: ${({ theme }) => theme.fontSize.medium};
  line-height: 24px;
`

const selectedButtonStyles = css`
  background: ${props =>
    props.value === 'Yes' ? props.theme.color.lime : props.theme.color.softRed};
  border: unset;
  &::before {
    background-size: cover;
    content: '';
    display: flex;
    height: 15px;
    ${props =>
      props.value === 'Yes' &&
      css`
        width: 20px;
        background-image: url(${CheckMark});
      `}
    ${props =>
      props.value === 'No' &&
      css`
        width: 15px;
        background-image: url(${XMark});
      `}
  }
`

export const StyledRadioButton = styled.input`
  align-items: center;
  appearance: none;
  border: 2px solid ${props => props.theme.color.gray9};
  border-radius: 50%;
  display: flex;
  height: 44px;
  justify-content: center;
  margin: 0 0 8px;
  opacity: 0.5;
  width: 44px;

  &:focus {
    opacity: 1;
  }

  &:hover {
    cursor: pointer;
    ${selectedButtonStyles}
    + ${RadioButtonLabel} {
      color: ${({ theme }) => theme.color.gray8};
    }
  }

  &:checked {
    opacity: 1;
    ${selectedButtonStyles}
    + ${RadioButtonLabel} {
      color: ${({ theme }) => theme.color.default};
    }
  }
`
const RadioButtons = ({ name, disabled, ...props }) => {
  const { locked, model, onChange } = useContext(FormContext)
  const valueSet = ['Yes', 'No']
  return (
    <FieldWrapper fieldName={name}>
      <RadioButtonsSet>
        {valueSet.map(buttonVal => {
          const itemLabel = `${name}-${buttonVal}`
          return (
            <RadioButtonContainer key={itemLabel}>
              <StyledRadioButton
                type="radio"
                name={name}
                disabled={locked || disabled}
                onChange={onChange}
                id={itemLabel}
                value={buttonVal}
                checked={(model && model[name] === buttonVal) || false}
                {...props}
              />
              <RadioButtonLabel>{buttonVal}</RadioButtonLabel>
            </RadioButtonContainer>
          )
        })}
      </RadioButtonsSet>
    </FieldWrapper>
  )
}

RadioButtons.propTypes = {
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool
}

export default RadioButtons
