import React from 'react'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'
import useGlobalContext from '@hooks/useGlobalContext'
import svgCloseIcon from '@assets/images/close-icon.svg'
import Modal from './Modal'

export const SLIDE_DURATION_MS = 100

const slidingModalStyles = css`
  top: 110vh;
  transition: all ${SLIDE_DURATION_MS}ms;

  &--after-open {
    top: 0vh;
  }

  &--before-close {
    top: 110vh;
  }
`

export const fullPageModalStyles = css`
  &__overlay {
    align-items: center;
    background-color: ${props => props.theme.color.transparentBackgroundGray};
    bottom: 0px;
    display: flex;
    justify-content: center;
    left: 0px;
    position: fixed;
    right: 0px;
    top: 0px;
    z-index: ${props => props.theme.zIndex.fullPageModal};
  }

  &__content {
    background: #fff;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    height: fit-content;
    max-height: calc(100% - 16px);
    max-width: calc(100% - 16px);
    outline: none;
    overflow: inherit;
    -webkit-overflow-scrolling: touch;
    padding: 0;
    position: relative;
    width: fit-content;

    ${({ isSlidingModal }) => isSlidingModal && slidingModalStyles}
  }
`

export const StyledCloseIcon = styled.img`
  cursor: pointer;
  padding: 16px;
  position: absolute;
  right: 0px;
  top: 0px;
`

export const StyledFullPageModal = styled(Modal)`
  ${fullPageModalStyles}
`

/**
 * FullPageModal is a Modal template for a large modal, with a close button in
 * the top right.
 */
export default function FullPageModal(props) {
  const { onClose, children, isSlidingModal } = props
  const { closeActiveModal } = useGlobalContext()
  const onCloseActions = () => {
    if (typeof onClose === 'function') onClose()
    closeActiveModal()
  }

  return (
    <StyledFullPageModal {...props} closeTimeoutMS={isSlidingModal ? SLIDE_DURATION_MS : 0}>
      <StyledCloseIcon
        role="button"
        src={svgCloseIcon}
        alt="Banner Close Icon"
        onClick={onCloseActions}
      />
      {children}
    </StyledFullPageModal>
  )
}

FullPageModal.propTypes = {
  ...Modal.propTypes,
  onClose: PropTypes.func,
  children: PropTypes.node
}
