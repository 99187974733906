import React from 'react'
import PropTypes from 'prop-types'
import { createFragmentContainer, graphql } from 'react-relay'
import SchoolTypes from '@types/School.types'
import ROUTE from '@enums/ROUTE'
import Link from './Link'

export function CompareSchoolLink({ school, trackingLabel, className, children, id }) {
  return (
    <Link
      className={className}
      to={ROUTE.COMPARE_SCHOOLS.replace(':primaryId?', school.legacyId).replace(
        '/:secondaryId?',
        ''
      )}
      trackingLabel={trackingLabel}
      id={id}
    >
      {children}
    </Link>
  )
}

export default createFragmentContainer(CompareSchoolLink, {
  school: graphql`
    fragment CompareSchoolLink_school on School {
      legacyId
    }
  `
})

CompareSchoolLink.propTypes = {
  school: SchoolTypes.isRequired,
  trackingLabel: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  id: PropTypes.string
}
