import styled from 'styled-components'

const ErrorText = styled.span.attrs({
  // we can define static props
  role: 'alert'
})`
  color: ${props => props.theme.color.error};
  font-family: ${props => props.theme.fontFamily.avenir};
  line-height: 1.71;
`

export default ErrorText
