import PropTypes from 'prop-types'

export const TeacherNoteAttributes = {
  id: PropTypes.string,
  legacyId: PropTypes.number,
  comment: PropTypes.string,
  createDate: PropTypes.string,
  updateDate: PropTypes.string
}

const TeacherNoteTypes = PropTypes.shape(TeacherNoteAttributes)

export default TeacherNoteTypes
