import React, { useState, useEffect } from 'react'
import styled, { css } from 'styled-components'
import SEARCH_CATEGORY from '@enums/SEARCH_CATEGORY'
import { DebouncedSearchInput, SearchInputContainer } from '@components/Search'
import { breakpointSmediumAndBelow } from '@StyledComponents/theme/helpers/breakpoints'
import { SearchIconWrapper } from '@components/NewSearch'
import useUserInfo from '@hooks/useUserInfo'
import { getUserSchool } from '@utils/getAndSetUserSchool'
import TeacherSchoolSelectorInput from './TeacherSchoolSelectorInput'
import { useHeaderContext } from './HeaderContext'
import HeaderSearchMainInput from './HeaderSearchMainInput'

const StyledNewSearch = styled.div`
  width: 100%;

  ${breakpointSmediumAndBelow(css`
    display: ${({ isMobileSearchOpen }) => (isMobileSearchOpen ? 'block' : 'none')};
  `)}

  ${SearchInputContainer} {
    max-width: 350px;

    ${breakpointSmediumAndBelow(css`
      max-width: unset;
    `)}
  }

  ${SearchIconWrapper} {
    left: 22px;
    top: 9px;
  }
`

const SchoolSelectorWrapper = styled.div`
  align-items: center;
  display: none;
  justify-content: center;
  margin-top: 12px;

  ${breakpointSmediumAndBelow(css`
    display: flex;

    ${DebouncedSearchInput} {
      max-width: unset;
      width: 100%:
    }

  `)}
`

const SchoolSelectorWrapperAt = styled.div`
  color: ${({ theme }) => theme.color.white};
  font-size: ${({ theme }) => theme.fontSize.medium};
  margin-right: 8px;
`

const HeaderSearch = () => {
  const userInfo = useUserInfo()
  const userSchool = getUserSchool(userInfo)

  const { searchCategory, isMobileSearchOpen, isMobileMenuOpen } = useHeaderContext()

  const [value, setValue] = useState('')
  const [school, setSchool] = useState(userSchool)

  useEffect(() => {
    setSchool(userSchool)
  }, [userInfo])

  if (isMobileMenuOpen) return null

  return (
    <StyledNewSearch isMobileSearchOpen={isMobileSearchOpen}>
      <HeaderSearchMainInput school={school} setSchool={setSchool} />
      {searchCategory === SEARCH_CATEGORY.TEACHERS && (
        <SchoolSelectorWrapper>
          <SchoolSelectorWrapperAt>at</SchoolSelectorWrapperAt>
          <TeacherSchoolSelectorInput
            school={school}
            setSchool={setSchool}
            inputValue={value}
            setInputValue={setValue}
          />
        </SchoolSelectorWrapper>
      )}
    </StyledNewSearch>
  )
}

export default HeaderSearch
