/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type Thumbs_schoolRating$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type SchoolRatingFooter_rating$ref: FragmentReference;
declare export opaque type SchoolRatingFooter_rating$fragmentType: SchoolRatingFooter_rating$ref;
export type SchoolRatingFooter_rating = {|
  +id: ?string,
  +comment: ?any,
  +flagStatus: ?string,
  +legacyId: ?number,
  +$fragmentRefs: Thumbs_schoolRating$ref,
  +$refType: SchoolRatingFooter_rating$ref,
|};
export type SchoolRatingFooter_rating$data = SchoolRatingFooter_rating;
export type SchoolRatingFooter_rating$key = {
  +$data?: SchoolRatingFooter_rating$data,
  +$fragmentRefs: SchoolRatingFooter_rating$ref,
};
*/


const node/*: ReaderFragment*/ = {
  "kind": "Fragment",
  "name": "SchoolRatingFooter_rating",
  "type": "SchoolRating",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "id",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "comment",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "flagStatus",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "legacyId",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "FragmentSpread",
      "name": "Thumbs_schoolRating",
      "args": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = 'facb55995f58221df9ababbf1bcf907f';
module.exports = node;
