import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { convertDBtoReadable } from '@utils/date'

const StyledTimeStamp = styled.div`
  font-weight: ${props => props.theme.fontWeight.bold};
  padding-bottom: 15px;
`

export default function TimeStamp({ className, timeStamp }) {
  if (!timeStamp) return null

  return <StyledTimeStamp className={className}>{convertDBtoReadable(timeStamp)}</StyledTimeStamp>
}

TimeStamp.propTypes = {
  timeStamp: PropTypes.string,
  className: PropTypes.string
}
