/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import LABEL_STYLE, { LabelStyleTypes } from '@enums/LABEL_STYLE'

export const StyledTextFieldWrapper = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  height: ${props => (props.labelStyle === LABEL_STYLE.TOP ? 'unset' : '70px')};
  justify-content: flex-end;
  position: relative;

  ${({ labelStyle }) =>
    labelStyle === LABEL_STYLE.TOP &&
    css`
      margin-top: 20px;
    `}

  ${({ labelStyle }) =>
    labelStyle === LABEL_STYLE.LEFT &&
    css`
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    `}

  ${({ labelStyle }) =>
    labelStyle === LABEL_STYLE.HIDDEN &&
    css`
      margin-top: 8px;
      margin-bottom: 8px;
      height: unset;
    `}
`

export const FloatingLabelTop = styled.label`
  color: inherit;
  cursor: text;
  font-size: ${props => props.theme.fontSize.medium};
  position: absolute;
  transform: ${props =>
    `translate(${props.theme.padding.textFieldH}px, ${-props.theme.padding.textFieldV}px)`};
  transition: transform 0.2s ease-in-out;
  will-change: transform;
  z-index: ${props => props.theme.zIndex.search};

  ${props =>
    props.up &&
    css`
      transform: translate(3px, -48px);
      font-weight: ${props.theme.fontWeight.boldest};
      cursor: default;
    `}
`

export const StaticLabel = styled.label`
  font-family: ${props => props.theme.fontFamily.avenirHeavy};
  font-size: ${props => props.theme.fontSize.medium};
  text-align: left;
  white-space: nowrap;
  ${({ labelStyle }) =>
    labelStyle === LABEL_STYLE.TOP &&
    css`
      margin-bottom: 7px;
    `}
`

export const InputWrapper = ({
  isFocused,
  hasValue,
  name,
  className,
  setFocus = () => {},
  setHasValue = () => {},
  label = null,
  labelStyle = LABEL_STYLE.FLOATING,
  children
}) => {
  useEffect(() => {
    if (isFocused) setHasValue(true)
  }, [isFocused])

  return (
    <StyledTextFieldWrapper className={className} labelStyle={labelStyle}>
      {label && labelStyle === LABEL_STYLE.FLOATING && (
        <FloatingLabelTop
          up={isFocused || hasValue}
          onClick={() => {
            setFocus(true)
          }}
          htmlFor={name}
        >
          {label}
        </FloatingLabelTop>
      )}
      {label && (labelStyle === LABEL_STYLE.LEFT || labelStyle === LABEL_STYLE.TOP) && (
        <StaticLabel htmlFor={name} labelStyle={labelStyle}>
          {label}
        </StaticLabel>
      )}

      {children}
    </StyledTextFieldWrapper>
  )
}

InputWrapper.propTypes = {
  name: PropTypes.string.isRequired,
  hasValue: PropTypes.bool,
  isFocused: PropTypes.bool,
  setFocus: PropTypes.func,
  label: PropTypes.string,
  labelStyle: LabelStyleTypes,
  className: PropTypes.string,
  children: PropTypes.node,
  setHasValue: PropTypes.func
}

export default InputWrapper
