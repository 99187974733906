/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type RateTeacherLink_teacher$ref: FragmentReference;
declare export opaque type RateTeacherLink_teacher$fragmentType: RateTeacherLink_teacher$ref;
export type RateTeacherLink_teacher = {|
  +legacyId: ?number,
  +numRatings: ?number,
  +lockStatus: ?string,
  +$refType: RateTeacherLink_teacher$ref,
|};
export type RateTeacherLink_teacher$data = RateTeacherLink_teacher;
export type RateTeacherLink_teacher$key = {
  +$data?: RateTeacherLink_teacher$data,
  +$fragmentRefs: RateTeacherLink_teacher$ref,
};
*/


const node/*: ReaderFragment*/ = {
  "kind": "Fragment",
  "name": "RateTeacherLink_teacher",
  "type": "Teacher",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "legacyId",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "numRatings",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "lockStatus",
      "args": null,
      "storageKey": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '15def82a7ac13c21b1818a07e0a95200';
module.exports = node;
