import React, { useState } from 'react'
import { graphql, createFragmentContainer } from 'react-relay'
import styled from 'styled-components'
import TeacherTypes from '@types/Teacher.types'
import TRACKING_EVENT, { TrackingEventTypes } from '@enums/TRACKING_EVENT'
import useGlobalContext from '@hooks/useGlobalContext'
import commitMutation from '@relay/commitMutation'
import { useDynamicTooltip } from '@components/Tooltip/Tooltip'
import TOOLTIPS from '@enums/TOOLTIPS'
import useUserInfo from '@hooks/useUserInfo'
import Button from '@src/components/Button'
import BookmarkFill from '@assets/images/bookmark-fill.svg'
import BookmarkOutline from '@assets/images/bookmark-default.svg'
import BookmarkHover from '@assets/images/bookmark-hover.svg'
import MODALS from '@enums/MODALS'
import getEnv from '@utils/getEnv'

export const StyledTeacherBookmark = styled(Button)`
  background: none;
  border: 0;
  cursor: ${props => (props.isLoggedIn ? 'pointer' : 'default')};
  height: fit-content;
  outline: none;
  padding: 0;
  width: fit-content;
`

const mutationQuery = graphql`
  mutation TeacherBookmarkMutation($id: ID!) {
    userToggleSavedProfessor(id: $id) {
      validation {
        id
      }
      teacher {
        isSaved
      }
    }
  }
`

export const getTooltip = (isSuccess, isSaved) => {
  if (isSuccess && isSaved) return 'Saved!'

  if (isSuccess && !isSaved) return 'Unsaved!'

  if (!isSuccess && !isSaved) return 'Save Professor'

  return 'Unsave Professor'
}

const sendMutation = ({ id, isLoggedIn, setTooltip }) => {
  if (!isLoggedIn) {
    return null
  }

  return () => {
    const variables = {
      id
    }

    commitMutation({
      mutation: mutationQuery,
      variables,
      onCompleted: (result, err) => {
        const isSaved = result?.userToggleSavedProfessor?.teacher?.isSaved

        setTooltip(getTooltip(!err, isSaved))

        if (err) {
          console.error(err)
        }
      }
    })
  }
}

const getBookmarkSvg = (hovering, isSaved) => {
  // eslint-disable-next-line no-nested-ternary
  return hovering ? BookmarkHover : isSaved ? BookmarkFill : BookmarkOutline
}

export const TeacherBookmark = ({ teacher, saveEvent, unsaveEvent }) => {
  const userInfo = useUserInfo()

  if (!teacher) return null

  const { id, isSaved } = teacher

  const [hovering, setHovering] = useState(null)
  const [tooltip, setTooltip] = useState(getTooltip(false, isSaved))

  useDynamicTooltip(tooltip)

  const { setActiveModal } = useGlobalContext()
  const isLoggedIn = userInfo && !!userInfo.Email
  const emailConfirmed = isLoggedIn && userInfo.emailConfirmed === true
  const bookmarkSvg = getBookmarkSvg(hovering, isSaved)
  const blockedEvent = TRACKING_EVENT.SAVED_PROF_REGWALL_OPEN
  const unblockedEvent = isSaved ? unsaveEvent : saveEvent
  const trackingLabel = !isLoggedIn ? blockedEvent : unblockedEvent
  const enableConfirmEmailCTA = getEnv('REACT_APP_CONFIRM_EMAIL_CTA') === 'true'

  const toggleSavedProf = sendMutation({
    id,
    isLoggedIn,
    setTooltip
  })

  return (
    <StyledTeacherBookmark
      trackingLabel={trackingLabel}
      onClick={e => {
        e.preventDefault()
        if (!isLoggedIn) {
          setActiveModal(MODALS.SIGN_UP_PROMPT)
        } else if (!emailConfirmed && enableConfirmEmailCTA) {
          setActiveModal(MODALS.CONFIRM_EMAIL_PROMPT)
        } else {
          toggleSavedProf()
        }
      }}
      onMouseEnter={() => setHovering(true)}
      onMouseLeave={() => {
        setHovering(false)
        setTooltip(getTooltip(false, isSaved))
      }}
      onFocus={() => setHovering(true)}
      onBlur={() => setHovering(false)}
      isLoggedIn={isLoggedIn}
    >
      <img
        src={bookmarkSvg}
        alt="Bookmark"
        data-tooltip
        data-tip={tooltip}
        key={isSaved ? 'bookmark-saved' : 'bookmark-unsaved'}
        data-for={TOOLTIPS.GLOBAL_TOOLTIP}
      />
    </StyledTeacherBookmark>
  )
}

TeacherBookmark.propTypes = {
  teacher: TeacherTypes,
  saveEvent: TrackingEventTypes,
  unsaveEvent: TrackingEventTypes
}

export default createFragmentContainer(TeacherBookmark, {
  teacher: graphql`
    fragment TeacherBookmark_teacher on Teacher {
      id
      isSaved
    }
  `
})
