import React from 'react'
import PropTypes from 'prop-types'
import { TOAST_EVENTS } from '@utils/toast'
import Toast, { ToastHeader, ToastDescription } from './Toast'

const ShareRatingToast = ({ closeToast }) => {
  return (
    <Toast canDismiss toastEvent={TOAST_EVENTS.SHARE_RATING} closeToast={closeToast}>
      <ToastHeader>Share Rating</ToastHeader>
      <ToastDescription>Link copied to clipboard</ToastDescription>
    </Toast>
  )
}

ShareRatingToast.propTypes = {
  closeToast: PropTypes.func.isRequired
}

export default ShareRatingToast
