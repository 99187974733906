import React, { useState, useEffect } from 'react'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js'
import PropTypes from 'prop-types'
import { Bar } from 'react-chartjs-2'
import { createFragmentContainer, graphql } from 'react-relay'
import theme from '@StyledComponents/theme'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import isSSR from '@utils/isSSR'

const ProfessorProfilePadding = {
  top: 9,
  right: 40,
  bottom: 40,
  left: 24
}

const CompareProfessorsPadding = {
  right: 32
}

export const RatingDistributionChart = ({ ratingsDistribution, compareProfessors }) => {
  const [styles, setStyles] = useState({
    offset: 16,
    padding: compareProfessors ? CompareProfessorsPadding : ProfessorProfilePadding,
    height: 325,
    width: compareProfessors ? 328 : 512
  })
  useEffect(() => {
    if (isSSR()) return null
    const smallplus = window.matchMedia('(max-width: 680px)')
    const handleResize = () => {
      if (smallplus.matches) {
        setStyles({
          ...styles,
          padding: {
            top: 9,
            right: 40,
            bottom: 24,
            left: compareProfessors ? 12 : 24
          }
        })
      }
    }
    window.addEventListener('resize', handleResize)
    handleResize()
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)
  const chartData = Object.values(ratingsDistribution).reverse()
  const chartDataRemainders = () => {
    const maxNum = Math.max(...chartData)
    const remainders = []
    chartData.forEach(num => {
      remainders.push(maxNum - num)
    })
    return remainders
  }

  const options = {
    elements: {
      bar: {
        borderWidth: 0
      }
    },
    hover: { mode: null },
    indexAxis: 'y',
    layout: {
      padding: styles.padding
    },
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      datalabels: {
        align: 'end',
        anchor: 'end',
        color: theme.color.default,
        display: true,
        font: {
          weight: !compareProfessors ? 'bold' : 'unset',
          size: 16
        },
        offset: styles.offset,
        formatter: (value, context) => {
          return context.chart.data.datasets[0].data[context.dataIndex]
        }
      },
      legend: {
        display: false,
        labels: {
          font: 24
        }
      },
      title: {
        align: 'start',
        color: theme.color.default,
        display: !compareProfessors,
        font: {
          size: 16,
          weight: 'bold'
        },
        lineHeight: 20,
        text: 'Rating Distribution'
      },
      tooltip: {
        enabled: false
      }
    },
    scales: {
      x: {
        display: false
      },
      y: {
        barThickness: 36,
        barPercentage: 1,
        grid: {
          display: false,
          drawBorder: false
        },
        stacked: true,
        ticks: {
          backdropPadding: {
            x: 10,
            y: 4
          },
          color: theme.color.default,
          font: {
            size: 14,
            weight: compareProfessors && 'bold'
          }
        }
      }
    }
  }

  // https://yaytext.com/bold-italic/ to render number bold without styling.
  const labels = compareProfessors
    ? ['5', '4', '3', '2', '1']
    : ['Awesome 𝟱', 'Great 𝟰', 'Good 𝟯', 'OK 𝟮', 'Awful 𝟭']

  const data = {
    labels,
    datasets: [
      {
        data: chartData,
        borderColor: theme.color.mainBlue,
        backgroundColor: theme.color.mainBlue,
        stack: 1,
        datalabels: {
          display: false
        }
      },
      {
        data: chartDataRemainders(),
        borderColor: theme.color.gray5,
        backgroundColor: theme.color.gray5,
        stack: 1
      }
    ]
  }

  return (
    <React.Fragment>
      {!isSSR() && (
        <Bar
          options={options}
          data={data}
          plugins={[ChartDataLabels]}
          height={styles.height}
          width={styles.width}
        />
      )}
    </React.Fragment>
  )
}

RatingDistributionChart.propTypes = {
  ratingsDistribution: PropTypes.shape({
    r1: PropTypes.number,
    r2: PropTypes.number,
    r3: PropTypes.number,
    r4: PropTypes.number,
    r5: PropTypes.number,
    total: PropTypes.number
  }),
  compareProfessors: PropTypes.bool
}

export default createFragmentContainer(RatingDistributionChart, {
  ratingsDistribution: graphql`
    fragment RatingDistributionChart_ratingsDistribution on ratingsDistribution {
      r1
      r2
      r3
      r4
      r5
    }
  `
})
