import React from 'react'
import styled, { css } from 'styled-components'

import PropTypes from 'prop-types'

const StyledRatingSliderBox = styled.div`
  background-color: ${props => {
    const { background, type } = props
    return props.theme.color.ratingLevelColors[type][background] || props.theme.color.gray5
  }};
  height: 40px;
  opacity: ${props => (props.isLit ? '1' : '0.5')};
  touch-action: none;
  transition: all 200ms ease-in-out;
  width: 60px;

  ${props =>
    props.boxValue === 1 &&
    css`
      border-radius: 20px 0 0 20px;
    `};
  ${props =>
    props.boxValue === 5 &&
    css`
      border-radius: 0 20px 20px 0;
    `};
  ${props =>
    props.boxValue < 5 &&
    css`
      border-right: 3px solid white;
    `};
`
export default function RatingSliderBox({
  boxValue,
  ratingValue,
  setRatingValue,
  hoverValue,
  setHoverValue,
  type,
  ...props
}) {
  function isLit() {
    // Boxes should be lit if they are selected, or are one of the boxes within a
    // selected group (i.e. boxes lower than selected box), so long as the user
    // is not hovering over slider to select a new box.
    return hoverValue <= 0 && boxValue <= ratingValue
  }

  function onBoxClick() {
    setRatingValue(ratingValue === boxValue ? 0 : boxValue)
    setHoverValue(0)
  }

  function backgroundColorNum() {
    const boxWithinHighlightRange = boxValue <= ratingValue || boxValue <= hoverValue
    const isHovering = hoverValue > 0
    const boxBetweenHoverAndChosen = isHovering && hoverValue < ratingValue && boxValue > hoverValue
    if (boxWithinHighlightRange && !boxBetweenHoverAndChosen) {
      return boxValue
    }
    return 0
  }

  return (
    <StyledRatingSliderBox
      boxValue={boxValue}
      background={backgroundColorNum()}
      onMouseEnter={() => setHoverValue(boxValue)}
      onMouseLeave={() => setHoverValue(0)}
      onClick={() => onBoxClick()}
      isLit={isLit()}
      type={type}
      {...props}
    />
  )
}

RatingSliderBox.propTypes = {
  boxValue: PropTypes.number.isRequired,
  ratingValue: PropTypes.number.isRequired,
  setRatingValue: PropTypes.func.isRequired,
  hoverValue: PropTypes.number.isRequired,
  setHoverValue: PropTypes.func.isRequired,
  type: PropTypes.string
}
