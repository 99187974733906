/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type FlagCampusReportingBox_rating$ref: FragmentReference;
declare export opaque type FlagCampusReportingBox_rating$fragmentType: FlagCampusReportingBox_rating$ref;
export type FlagCampusReportingBox_rating = {|
  +comment: ?any,
  +$refType: FlagCampusReportingBox_rating$ref,
|};
export type FlagCampusReportingBox_rating$data = FlagCampusReportingBox_rating;
export type FlagCampusReportingBox_rating$key = {
  +$data?: FlagCampusReportingBox_rating$data,
  +$fragmentRefs: FlagCampusReportingBox_rating$ref,
};
*/


const node/*: ReaderFragment*/ = {
  "kind": "Fragment",
  "name": "FlagCampusReportingBox_rating",
  "type": "SchoolRating",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "comment",
      "args": null,
      "storageKey": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '1537c78b575b75d5f61bbe21fec8d1a3';
module.exports = node;
