import React from 'react'
import styled from 'styled-components'
import { Form, useFormikContext } from 'formik'
import NameInput from '@components/Storybook/Shared/Fields/NameInput'
import CheckboxInput from '@components/Storybook/Shared/Fields/CheckboxInput'
import CountrySelect from '@components/Storybook/Shared/Fields/CountrySelect'
import StateSelect from '@components/Storybook/Shared/Fields/StateSelect'

const StyledForm = styled(Form)`
  max-width: 564px;
  padding-bottom: 24px;
  position: relative;
`

const AddSchoolFormContent = () => {
  const formik = useFormikContext()

  return (
    <StyledForm id="add-school-form">
      <NameInput
        id="name"
        name="name"
        label="Name of School"
        value={formik.values.name}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={formik.touched.name && formik.errors.name}
      />
      <CountrySelect
        id="country"
        name="country"
        label="Country"
        value={formik.values.country}
        onChange={country => formik.setFieldValue('country', country)}
        onBlur={formik.handleBlur}
        options={formik.values.countries}
        error={formik.touched.country && formik.errors.country}
      />
      <StateSelect
        id="state"
        name="state"
        label="State"
        value={formik.values.state}
        onChange={state => formik.setFieldValue('state', state)}
        onBlur={formik.handleBlur}
        options={formik.values.states}
        error={formik.touched.state && formik.errors.state}
      />
      <NameInput
        id="city"
        name="city"
        label="City"
        value={formik.values.city}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={formik.touched.city && formik.errors.city}
      />
      <NameInput
        id="website"
        name="website"
        label="Website"
        value={formik.values.website}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={formik.touched.website && formik.errors.website}
      />
      <NameInput
        id="email"
        name="email"
        label="Your Email"
        value={formik.values.email}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={formik.touched.email && formik.errors.email}
      />
      <CheckboxInput value={formik.values.checkbox} onChange={formik.handleChange} />
    </StyledForm>
  )
}

export default AddSchoolFormContent
