import React from 'react'
import PropTypes from 'prop-types'

export default function EyeSlash({ bgColor = '#000000' }) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.69 6.55L2.01 3.87L3.42 2.45L21.15 20.19L19.74 21.6L16.32 18.18C14.98 18.71 13.52 19 12 19C7 19 2.73 15.89 1 11.5C1.77 9.53 3.06 7.83 4.69 6.55ZM12 6C15.79 6 19.17 8.13 20.82 11.5C20.23 12.72 19.4 13.77 18.41 14.62L19.82 16.03C21.21 14.8 22.31 13.26 23 11.5C21.27 7.11 17 4 12 4C10.73 4 9.51 4.2 8.36 4.57L10.01 6.22C10.66 6.09 11.32 6 12 6ZM10.93 7.14L13 9.21C13.57 9.46 14.03 9.92 14.28 10.49L16.35 12.56C16.43 12.22 16.49 11.86 16.49 11.49C16.5 9.01 14.48 7 12 7C11.63 7 11.28 7.05 10.93 7.14ZM9.51 11.37L12.12 13.98C12.08 13.99 12.04 14 12 14C10.62 14 9.5 12.88 9.5 11.5C9.5 11.475 9.5025 11.455 9.505 11.435L9.505 11.435L9.505 11.435C9.5075 11.415 9.51 11.395 9.51 11.37ZM7.86 9.72L6.11 7.97C4.9 8.89 3.88 10.07 3.18 11.5C4.83 14.87 8.21 17 12 17C12.95 17 13.87 16.86 14.75 16.62L13.77 15.64C13.23 15.87 12.63 16 12 16C9.52 16 7.5 13.98 7.5 11.5C7.5 10.87 7.63 10.27 7.86 9.72Z"
        fill={bgColor}
      />
    </svg>
  )
}

EyeSlash.propTypes = {
  bgColor: PropTypes.string
}
