import React from 'react'
import PropTypes from 'prop-types'

export const GRAD_CAP_PATH_TESTID = 'GRAD_CAP_PATH_TESTID'

export default function GraduationCap({ fillColor = '#151515', height = '18', width = '22' }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 22 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 0L0 6L4 8.18V14.18L11 18L18 14.18V8.18L20 7.09V14H22V6L11 0ZM17.82 6L11 9.72L4.18 6L11 2.28L17.82 6ZM11 15.72L16 12.99V9.27L11 12L6 9.27V12.99L11 15.72Z"
        fill={fillColor}
        data-testid={GRAD_CAP_PATH_TESTID}
      />
    </svg>
  )
}

GraduationCap.propTypes = {
  fillColor: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string
}
