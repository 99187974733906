import React from 'react'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'
import Link from '@components/Link'
import IconButton from '@StyledComponents/IconButton'
import { breakpointSmediumAndBelow } from '@StyledComponents/theme/helpers/breakpoints'
import { helveticaBold } from '@StyledComponents/theme/typesets'

export const StyledProfileEditButton = styled(IconButton.Edit)`
  display: flex;
  font-size: ${props => props.theme.fontSize.default};
  justify-content: flex-end;
  margin-top: 24px;
  width: 100%;
  white-space: nowrap;

  ${breakpointSmediumAndBelow(css`
    margin: 0;
    position: relative;
    top: 28px;
  `)}

  ${helveticaBold};
`

export const IconLink = styled(Link)`
  align-items: center;
  display: flex;
  text-decoration: none;
  width: 100%;

  &:hover {
    cursor: pointer;
  }

  img {
    margin-right: 11px;
  }
`

const ProfileEditButton = ({ children, to, type, onClick }) => {
  return (
    <IconLink to={to} type={type} onClick={onClick}>
      <StyledProfileEditButton>{children}</StyledProfileEditButton>
    </IconLink>
  )
}

ProfileEditButton.propTypes = {
  children: PropTypes.node,
  ...Link.propTypes
}

export default ProfileEditButton
