import React, { useContext, useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import FormContext from '@contexts/FormContext'
import ReCAPTCHA from '@lib/ReCAPTCHA'
import FieldWrapper from './FieldWrapper'
import FormButton from './FormButton'

const FormRecaptcha3Button = ({
  name = 'recaptcha',
  action,
  className,
  onClick,
  disabled,
  ...props
}) => {
  const { model, onChange, submit, locked } = useContext(FormContext)
  const [requestActive, setRequestActive] = useState(false)
  const [isDisabled, setIsDisabled] = useState(true)
  const lastValue = useRef(null)
  const id = `recaptcha-${name}`
  const resetPasswordBtn = action === ReCAPTCHA.ACTIONS.RESET_PASSWORD
  const addRatingBtn =
    action === ReCAPTCHA.ACTIONS.ADD_RATING_PROFESSOR ||
    action === ReCAPTCHA.ACTIONS.ADD_RATING_SCHOOL
  const flagRatingBtn = action === ReCAPTCHA.ACTIONS.FLAG_RATING
  const addNewProfBtn = action === ReCAPTCHA.ACTIONS.ADD_NEW_PROFESSOR
  const submitCorrectionBtn = action === ReCAPTCHA.ACTIONS.SUBMIT_CORRECTION
  const interactiveBtn =
    resetPasswordBtn || addRatingBtn || flagRatingBtn || addNewProfBtn || submitCorrectionBtn

  const clickHandler = e => {
    setRequestActive(true)
    e.preventDefault()
    if (typeof onClick === 'function') onClick()
    ReCAPTCHA.Execute(action, token => {
      setRequestActive(false)
      onChange({
        target: {
          name,
          value: token
        }
      })
    })
  }

  useEffect(() => {
    ReCAPTCHA.Load(id)
    return () => ReCAPTCHA.Remove()
  }, [])

  const validateFlagBtn = () => {
    const commentLength = model?.flagComment?.length
    const commentRightLength = commentLength && commentLength > 0 && commentLength < 351
    return commentRightLength
  }

  useEffect(() => {
    if (model[name] && model[name] !== lastValue.current) {
      lastValue.current = model[name]
      submit()
    }
    const resetPasswordValidated = resetPasswordBtn && model.password && model.confirmPassword
    const addRatingTeacherValidated = addRatingBtn && !disabled
    const flagRatingValidated = flagRatingBtn && validateFlagBtn()
    const addNewProfValidated = addNewProfBtn && !disabled
    const submitCorrectionValidated = submitCorrectionBtn && !disabled
    if (
      resetPasswordValidated ||
      addRatingTeacherValidated ||
      flagRatingValidated ||
      addNewProfValidated ||
      submitCorrectionValidated
    ) {
      setIsDisabled(false)
    } else {
      setIsDisabled(true)
    }
  }, [model])

  return (
    <FieldWrapper id={id} fieldName={name}>
      <input hidden readOnly name={name} value={model[name] || ''} />
      <FormButton
        disabled={interactiveBtn ? isDisabled || requestActive : requestActive || locked}
        type="submit"
        className={className}
        onClick={clickHandler}
        {...props}
      />
    </FieldWrapper>
  )
}

FormRecaptcha3Button.propTypes = {
  name: PropTypes.string,
  action: PropTypes.oneOf(Object.values(ReCAPTCHA.ACTIONS)).isRequired,
  onClick: PropTypes.func,
  className: PropTypes.string,
  disabled: PropTypes.bool
}

export default FormRecaptcha3Button
