/* eslint-disable import/prefer-default-export */
import { css } from 'styled-components'
import isSSR from '@utils/isSSR'

export function applyBreakpointMax(max, rules) {
  return css`
    @media screen and (max-width: ${props => props.theme.breakpoints[max]}px) {
      ${rules}
    }
  `
}

export function applyBreakpointMin(min, rules) {
  return css`
    @media screen and (min-width: ${props => props.theme.breakpoints[min] + 1}px) {
      ${rules}
    }
  `
}

export function applyBreakpointRange(min, max, rules) {
  return css`
    @media screen and (min-width: ${props =>
        props.theme.breakpoints[min] + 1}px) and (max-width: ${props =>
        props.theme.breakpoints[max]}px) {
      ${rules}
    }
  `
}

export function breakpointSmallAndBelow(rules) {
  return applyBreakpointMax('small', rules)
}

export function breakpointTinyAndBelow(rules) {
  return applyBreakpointMax('tiny', rules)
}

export function breakpointSmallPlusAndBelow(rules) {
  return applyBreakpointMax('smallplus', rules)
}

export function breakpointSmediumAndBelow(rules) {
  return applyBreakpointMax('smedium', rules)
}

export function breakpointMediumAndBelow(rules) {
  return applyBreakpointMax('medium', rules)
}

export function breakpointXMediumAndBelow(rules) {
  return applyBreakpointMax('xmedium', rules)
}

export function breakpointLargeAndBelow(rules) {
  return applyBreakpointMax('large', rules)
}

export function breakpointLargePlusAndBelow(rules) {
  return applyBreakpointMax('largeplus', rules)
}

export function breakpointXLargeAndBelow(rules) {
  return applyBreakpointMax('xlarge', rules)
}

/**
 * isInBreakpointRange determines if the current viewport is in a specific
 * breakpoint range, and returns true or false
 */
export function isInBreakpointRange(theme, min, max) {
  if (isSSR()) return false

  let query = 'screen'
  const minBreakpoint = theme.breakpoints[min]
  const maxBreakpoint = theme.breakpoints[max]
  if (minBreakpoint) {
    query += ` and (min-width: ${minBreakpoint + 1}px)`
  }
  if (maxBreakpoint) {
    query += ` and (max-width: ${maxBreakpoint}px)`
  }

  return window.matchMedia(query).matches
}

export function getPageContentMaxWidth(theme) {
  return applyBreakpointMin(
    'large',
    css`
      max-width: calc(${theme.pageWrapper.maxWidth}px - ${theme.adRail.width}px);
    `
  )
}

export function getPageContainerMaxWidth(theme) {
  return applyBreakpointMin(
    'large',
    css`
      margin: 0 auto;
      max-width: ${theme.pageWrapper.maxWidth}px;
      width: 100%;
    `
  )
}
