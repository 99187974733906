import React from 'react'
import ComputerLogo from '@assets/images/computer-icon.svg'
import styled from 'styled-components'
import TOOLTIPS from '@enums/TOOLTIPS'

export const StyledLogo = styled.img`
  height: 20px;
  width: 20px;
`

const OnlineCourseLogo = () => {
  return (
    <StyledLogo
      src={ComputerLogo}
      alt="Computer Icon"
      data-for={TOOLTIPS.GLOBAL_TOOLTIP}
      data-tip="Online Class"
      data-tooltip
    />
  )
}

export default OnlineCourseLogo
