import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import EMOTION from '@enums/EMOTION'
import { emotionMap } from '@componentUtils/emotionHelpers'
import { poppinsBold } from '@StyledComponents/theme/typesets'

const EmotionLabelTypes = {
  emotion: PropTypes.oneOf(Object.values(EMOTION)).isRequired,
  children: PropTypes.node,
  className: PropTypes.string
}

export const StyledEmotionLabel = styled.div`
  align-items: center;
  background: ${props => emotionMap[props.emotion].getColor};
  display: inline-flex;
  line-height: 1.54;
  padding: 5px 20px;
  text-align: left;
  text-transform: uppercase;

  ${poppinsBold};

  span {
    padding-right: 5px;
  }
`

export default function EmotionLabel({ emotion, className, children }) {
  const { emoji, aria } = emotionMap[emotion]

  return (
    <StyledEmotionLabel className={className} emotion={emotion}>
      <span role="img" aria-label={aria}>
        {emoji}
      </span>
      {children}
    </StyledEmotionLabel>
  )
}

EmotionLabel.propTypes = EmotionLabelTypes
