import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import CloseIcon from '@assets/images/close-icon.svg'
import CloseIconWhite from '@assets/images/close-icon-white.svg'
import { TrackingEventTypes } from '@enums/TRACKING_EVENT'
import Button from '../Button'

const StyledCloseButton = styled(Button)`
  background: ${props => (props.inverted ? props.theme.color.default : 'white')};
  border: 0;
  cursor: pointer;
  outline: none;
  padding: 2px;
  position: absolute;
  right: 1px;
  top: 1px;

  &:hover {
    background: ${props => props.theme.color.fadedDefault};
  }
`

export default function CloseButton({ onClick, className, trackingLabel, inverted = false }) {
  if (!onClick) return null

  return (
    <StyledCloseButton
      type="button"
      onClick={onClick}
      className={className}
      trackingLabel={trackingLabel}
      inverted={inverted || undefined}
    >
      <img src={inverted ? CloseIconWhite : CloseIcon} alt="close symbol" />
    </StyledCloseButton>
  )
}

CloseButton.propTypes = {
  onClick: PropTypes.func,
  className: PropTypes.string,
  trackingLabel: TrackingEventTypes,
  inverted: PropTypes.bool
}
