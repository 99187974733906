import PropTypes from 'prop-types'

export const SchoolRatingAttributes = {
  id: PropTypes.string,
  clubsRating: PropTypes.number,
  comment: PropTypes.string,
  date: PropTypes.string,
  facilitiesRating: PropTypes.number,
  flagStatus: PropTypes.string,
  foodRating: PropTypes.number,
  happinessRating: PropTypes.number,
  internetRating: PropTypes.number,
  legacyId: PropTypes.number,
  locationRating: PropTypes.number,
  opportunitiesRating: PropTypes.number,
  reputationRating: PropTypes.number,
  safetyRating: PropTypes.number,
  socialRating: PropTypes.number,
  thumbsDownTotal: PropTypes.number,
  thumbsUpTotal: PropTypes.number,
  school: PropTypes.shape({
    name: PropTypes.string
  })
}

const SchoolRatingTypes = PropTypes.shape(SchoolRatingAttributes)

export const SchoolRatingList = PropTypes.shape({
  edges: PropTypes.arrayOf(
    PropTypes.shape({
      cursor: PropTypes.string,
      node: SchoolRatingTypes
    })
  ),
  pageInfo: PropTypes.shape({
    hasNextPage: PropTypes.bool
  })
})

export default SchoolRatingTypes
