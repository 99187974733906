import { css } from 'styled-components'

const poppinsNormal = css`
  font-family: ${({ theme }) => theme.fontFamily.poppins};
  font-weight: ${({ theme }) => theme.fontWeight.normal};
`
const poppinsBold = css`
  font-family: ${({ theme }) => theme.fontFamily.poppins};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
`

const poppinsBoldMedium = css`
  font-size: ${({ theme }) => theme.fontSize.medium};

  ${poppinsBold}
`

const poppinsBold3x = css`
  font-size: ${({ theme }) => theme.fontSize.large3x};

  ${poppinsBold}
`

const poppinsBolder = css`
  font-family: ${({ theme }) => theme.fontFamily.poppins};
  font-weight: ${({ theme }) => theme.fontWeight.bolder};
`

const poppinsBoldest = css`
  font-family: ${({ theme }) => theme.fontFamily.poppins};
  font-weight: ${({ theme }) => theme.fontWeight.boldest};
`

const poppinsBoldest3x = css`
  font-size: ${({ theme }) => theme.fontSize.large3x};

  ${poppinsBoldest}
`

const helveticaLight = css`
  font-family: ${({ theme }) => theme.fontFamily.helveticaNeue};
  font-weight: ${({ theme }) => theme.fontWeight.light};
`

const helveticaNormal = css`
  font-family: ${({ theme }) => theme.fontFamily.helveticaNeue};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
`

const helveticaBold = css`
  font-family: ${({ theme }) => theme.fontFamily.helveticaNeue};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
`

const helveticaBoldXLarge = css`
  font-size: ${({ theme }) => theme.fontSize.xlarge};

  ${helveticaBold}
`

export {
  poppinsNormal,
  poppinsBold,
  poppinsBoldMedium,
  poppinsBold3x,
  poppinsBolder,
  poppinsBoldest,
  poppinsBoldest3x,
  helveticaLight,
  helveticaNormal,
  helveticaBold,
  helveticaBoldXLarge
}
