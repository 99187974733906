/**
 * @flow
 * @relayHash 88f06747d5e8a994bd4109af4357ea6b
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type CourseCode_teacher$ref = any;
type StickyHeaderContent_teacher$ref = any;
export type RateTeacherQueryVariables = {|
  id: string
|};
export type RateTeacherQueryResponse = {|
  +teacherTags: ?$ReadOnlyArray<?{|
    +name: ?string,
    +id: ?string,
    +active: ?boolean,
  |}>,
  +teacher: ?{|
    +firstName?: ?string,
    +lastName?: ?string,
    +id?: ?string,
    +legacyId?: ?number,
    +school?: ?{|
      +id: ?string,
      +legacyId: ?number,
    |},
    +$fragmentRefs: StickyHeaderContent_teacher$ref & CourseCode_teacher$ref,
  |},
|};
export type RateTeacherQuery = {|
  variables: RateTeacherQueryVariables,
  response: RateTeacherQueryResponse,
|};
*/


/*
query RateTeacherQuery(
  $id: ID!
) {
  teacherTags: teacherTags {
    name
    id
    active
  }
  teacher: node(id: $id) {
    __typename
    ... on Teacher {
      firstName
      lastName
      id
      legacyId
      school {
        id
        legacyId
      }
      ...StickyHeaderContent_teacher
      ...CourseCode_teacher
    }
    id
  }
}

fragment StickyHeaderContent_teacher on Teacher {
  ...HeaderDescription_teacher
  ...HeaderRateButton_teacher
}

fragment CourseCode_teacher on Teacher {
  courseCodes {
    courseName
  }
}

fragment HeaderDescription_teacher on Teacher {
  id
  firstName
  lastName
  department
  school {
    legacyId
    name
    city
    state
    id
  }
  ...TeacherTitles_teacher
  ...TeacherBookmark_teacher
}

fragment HeaderRateButton_teacher on Teacher {
  ...RateTeacherLink_teacher
  ...CompareProfessorLink_teacher
}

fragment RateTeacherLink_teacher on Teacher {
  legacyId
  numRatings
  lockStatus
}

fragment CompareProfessorLink_teacher on Teacher {
  legacyId
}

fragment TeacherTitles_teacher on Teacher {
  department
  school {
    legacyId
    name
    id
  }
}

fragment TeacherBookmark_teacher on Teacher {
  id
  isSaved
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "id",
    "type": "ID!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "LinkedField",
  "alias": "teacherTags",
  "name": "teacherTags",
  "storageKey": null,
  "args": null,
  "concreteType": "Tags",
  "plural": true,
  "selections": [
    (v1/*: any*/),
    (v2/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "active",
      "args": null,
      "storageKey": null
    }
  ]
},
v4 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "firstName",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "lastName",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "legacyId",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "RateTeacherQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      (v3/*: any*/),
      {
        "kind": "LinkedField",
        "alias": "teacher",
        "name": "node",
        "storageKey": null,
        "args": (v4/*: any*/),
        "concreteType": null,
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "type": "Teacher",
            "selections": [
              (v5/*: any*/),
              (v6/*: any*/),
              (v2/*: any*/),
              (v7/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "school",
                "storageKey": null,
                "args": null,
                "concreteType": "School",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v7/*: any*/)
                ]
              },
              {
                "kind": "FragmentSpread",
                "name": "StickyHeaderContent_teacher",
                "args": null
              },
              {
                "kind": "FragmentSpread",
                "name": "CourseCode_teacher",
                "args": null
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "RateTeacherQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      (v3/*: any*/),
      {
        "kind": "LinkedField",
        "alias": "teacher",
        "name": "node",
        "storageKey": null,
        "args": (v4/*: any*/),
        "concreteType": null,
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "__typename",
            "args": null,
            "storageKey": null
          },
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "type": "Teacher",
            "selections": [
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "school",
                "storageKey": null,
                "args": null,
                "concreteType": "School",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v7/*: any*/),
                  (v1/*: any*/),
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "city",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "state",
                    "args": null,
                    "storageKey": null
                  }
                ]
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "department",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "isSaved",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "numRatings",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "lockStatus",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "courseCodes",
                "storageKey": null,
                "args": null,
                "concreteType": "Course",
                "plural": true,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "courseName",
                    "args": null,
                    "storageKey": null
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "RateTeacherQuery",
    "id": null,
    "text": "query RateTeacherQuery(\n  $id: ID!\n) {\n  teacherTags: teacherTags {\n    name\n    id\n    active\n  }\n  teacher: node(id: $id) {\n    __typename\n    ... on Teacher {\n      firstName\n      lastName\n      id\n      legacyId\n      school {\n        id\n        legacyId\n      }\n      ...StickyHeaderContent_teacher\n      ...CourseCode_teacher\n    }\n    id\n  }\n}\n\nfragment StickyHeaderContent_teacher on Teacher {\n  ...HeaderDescription_teacher\n  ...HeaderRateButton_teacher\n}\n\nfragment CourseCode_teacher on Teacher {\n  courseCodes {\n    courseName\n  }\n}\n\nfragment HeaderDescription_teacher on Teacher {\n  id\n  firstName\n  lastName\n  department\n  school {\n    legacyId\n    name\n    city\n    state\n    id\n  }\n  ...TeacherTitles_teacher\n  ...TeacherBookmark_teacher\n}\n\nfragment HeaderRateButton_teacher on Teacher {\n  ...RateTeacherLink_teacher\n  ...CompareProfessorLink_teacher\n}\n\nfragment RateTeacherLink_teacher on Teacher {\n  legacyId\n  numRatings\n  lockStatus\n}\n\nfragment CompareProfessorLink_teacher on Teacher {\n  legacyId\n}\n\nfragment TeacherTitles_teacher on Teacher {\n  department\n  school {\n    legacyId\n    name\n    id\n  }\n}\n\nfragment TeacherBookmark_teacher on Teacher {\n  id\n  isSaved\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '025ae202bd5e46e66cf1bb0378fc1a71';
module.exports = node;
