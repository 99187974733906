/**
 * @flow
 * @relayHash 26c3c93eadaba7241d6f4fd766dce3ef
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type FormController_validation$ref = any;
export type FlagCampusRatingMutationVariables = {|
  ratingID: string,
  flagComment: any,
|};
export type FlagCampusRatingMutationResponse = {|
  +flagCampusRating: ?{|
    +validation: ?{|
      +$fragmentRefs: FormController_validation$ref
    |}
  |}
|};
export type FlagCampusRatingMutation = {|
  variables: FlagCampusRatingMutationVariables,
  response: FlagCampusRatingMutationResponse,
|};
*/


/*
mutation FlagCampusRatingMutation(
  $ratingID: ID!
  $flagComment: UserComment!
) {
  flagCampusRating(ratingID: $ratingID, flagComment: $flagComment) {
    validation {
      ...FormController_validation
      id
    }
  }
}

fragment FormController_validation on ValidationResult {
  id
  success
  errors {
    field
    messages
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "ratingID",
    "type": "ID!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "flagComment",
    "type": "UserComment!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "flagComment",
    "variableName": "flagComment"
  },
  {
    "kind": "Variable",
    "name": "ratingID",
    "variableName": "ratingID"
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "FlagCampusRatingMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "flagCampusRating",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "FlagCampusRatingMutationResponse",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "validation",
            "storageKey": null,
            "args": null,
            "concreteType": "ValidationResult",
            "plural": false,
            "selections": [
              {
                "kind": "FragmentSpread",
                "name": "FormController_validation",
                "args": null
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "FlagCampusRatingMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "flagCampusRating",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "FlagCampusRatingMutationResponse",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "validation",
            "storageKey": null,
            "args": null,
            "concreteType": "ValidationResult",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "id",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "success",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "errors",
                "storageKey": null,
                "args": null,
                "concreteType": "FieldErrorType",
                "plural": true,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "field",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "messages",
                    "args": null,
                    "storageKey": null
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "mutation",
    "name": "FlagCampusRatingMutation",
    "id": null,
    "text": "mutation FlagCampusRatingMutation(\n  $ratingID: ID!\n  $flagComment: UserComment!\n) {\n  flagCampusRating(ratingID: $ratingID, flagComment: $flagComment) {\n    validation {\n      ...FormController_validation\n      id\n    }\n  }\n}\n\nfragment FormController_validation on ValidationResult {\n  id\n  success\n  errors {\n    field\n    messages\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '492fe3ca7fe7142a0197d712ebafadbd';
module.exports = node;
