import PropTypes from 'prop-types'

const LABEL_STYLE = {
  LEFT: 'left',
  FLOATING: 'floating',
  HIDDEN: 'hidden',
  TOP: 'top'
}

export const LabelStyleTypes = PropTypes.oneOf(Object.values(LABEL_STYLE))

export default LABEL_STYLE
