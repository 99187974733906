import React, { useContext } from 'react'
import styled, { ThemeContext } from 'styled-components'
import PropTypes from 'prop-types'
import TOOLTIPS from '@enums/TOOLTIPS'
import FLAG_STATUS, { FlagStatusType } from '@enums/FLAG_STATUS'
import Link from '@src/components/Link'
import { TrackingEventTypes } from '@enums/TRACKING_EVENT'
import Flag from '@assets/images/js/Flag'

export const REPORTFLAG_TEST_ID = 'reportflag_test_id'

export const StyledReportFlag = styled(Link)`
  cursor: ${props => (props.disabled ? 'default' : 'pointer')};
  text-decoration: none;
`

export const FlagWrapper = styled.div`
  align-items: center;
  display: flex;
  span {
    color: ${props => props.theme.color.gray8};
    font-style: italic;
    padding-right: 12px;
  }
`

export const isFlagDisabled = flagStatus => {
  return flagStatus === FLAG_STATUS.FLAGGED || flagStatus === FLAG_STATUS.REVIEWED
}

export const getFlagConfig = (status, theme, entityLabel, reviewedDate) => {
  switch (status) {
    case FLAG_STATUS.REVIEWED:
      return {
        icon: {
          outlineFill: theme.color.gray4,
          insideFill: theme.color.gray5
        },
        text: reviewedDate ? `Reviewed: ${reviewedDate}` : 'Reviewed',
        tooltip: 'This rating has been reviewed and cleared'
      }
    case FLAG_STATUS.FLAGGED:
      return {
        icon: {
          outlineFill: theme.color.default,
          insideFill: theme.color.error
        },
        text: 'In Review',
        tooltip: 'Somebody has already flagged this and it is currently under review'
      }
    case [FLAG_STATUS.UNFLAGGED]: /* Intentional Fallthrough */
    default:
      return {
        icon: {
          outlineFill: theme.color.default,
          insideFill: 'none'
        },
        tooltip: 'Report this rating'
      }
  }
}

const ReportFlag = ({
  status,
  link,
  reviewedDate,
  trackingLabel,
  entityLabel,
  className,
  type
}) => {
  const theme = useContext(ThemeContext)
  const flagConfig = getFlagConfig(status, theme, entityLabel, reviewedDate)
  return (
    <StyledReportFlag
      className={className}
      to={link}
      type={type}
      trackingLabel={trackingLabel}
      disabled={isFlagDisabled(status)}
      data-tooltip
      data-tip={flagConfig.tooltip}
      data-for={TOOLTIPS.GLOBAL_TOOLTIP}
      data-testid={REPORTFLAG_TEST_ID}
    >
      <FlagWrapper>
        {flagConfig.text && <span>{flagConfig.text}</span>}
        {Flag(flagConfig.icon)}
      </FlagWrapper>
    </StyledReportFlag>
  )
}

ReportFlag.propTypes = {
  status: FlagStatusType,
  link: PropTypes.string,
  className: PropTypes.string,
  trackingLabel: TrackingEventTypes,
  entityLabel: PropTypes.string,
  reviewedDate: PropTypes.string,
  type: PropTypes.string
}

export default ReportFlag
