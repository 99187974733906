/* eslint-disable react/no-array-index-key */

import React from 'react'
import { Switch as RouterSwitch, Redirect, Route } from 'react-router-dom'
import ROUTE, { USER_ACCOUNT_ROUTE } from '@enums/ROUTE'
import UserProfilePage from '@pages/UserProfilePage'
import UserAccountSettingsPage from '@pages/UserAccountSettingsPage'
import UserSavedProfessorsPage from '@pages/UserSavedProfessorsPage'
import UserRatingsPage from '@pages/UserRatingsPage'

export const userAccountRouteConfigs = [
  { path: USER_ACCOUNT_ROUTE.PROFILE, render: UserProfilePage, exact: true },
  {
    path: USER_ACCOUNT_ROUTE.SETTINGS,
    render: UserAccountSettingsPage,
    exact: true
  },
  { path: USER_ACCOUNT_ROUTE.RATINGS, render: UserRatingsPage, exact: true },
  {
    path: USER_ACCOUNT_ROUTE.SAVED_PROFESSORS,
    render: UserSavedProfessorsPage,
    exact: true
  }
]

export function generateRouteList() {
  // eslint-disable-next-line react/jsx-props-no-spreading
  const redirect = <Redirect exact from={ROUTE.USER_ACCOUNT} to={USER_ACCOUNT_ROUTE.PROFILE} />
  const routes = userAccountRouteConfigs.map(config => {
    return <Route key={`${config.render.diplayName}-Route`} {...config} />
  })
  return (
    <RouterSwitch>
      {redirect}
      {routes}
    </RouterSwitch>
  )
}

export default generateRouteList
