import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import { loginMenuStyles } from '@components/UserMenu'
import Button from '@components/Button'
import { breakpointXMediumAndBelow } from '@components/StyledComponents/theme/helpers/breakpoints'

export const HelpScoutGlobalStyles = css`
  #beacon-container .BeaconFabButtonFrame {
    display: none;
  }

  #beacon-container {
    .BeaconContainer {
      bottom: 24px;
      right: 15px;
      z-index: ${({ theme }) => theme.zIndex.helpScout} !important;
    }
  }
`

export const HelpScoutButtonLink = styled(Button)`
  ${loginMenuStyles};

  background-color: white;
  color: black;
  min-width: 72px;

  ${breakpointXMediumAndBelow(css`
    display: none;
  `)}
`

const HelpScoutCustomButton = () => {
  const [open, setOpen] = useState(false)

  const handleClick = () => {
    if (window.Beacon) {
      if (open) {
        window.Beacon('close')
      } else {
        window.Beacon('open')
      }
      setOpen(!open)
    }
  }

  return <HelpScoutButtonLink onClick={handleClick}>{open ? 'Close' : 'Help'}</HelpScoutButtonLink>
}

export default HelpScoutCustomButton
