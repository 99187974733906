import React from 'react'
import styled from 'styled-components'
import TeacherTypes from '@types/Teacher.types'
import FormCreatableSelect from '@components/Form/FormCreatableSelect'
import rateTeacherModel from '@pages/AddRating/RateTeacher/RateTeacher.model'
import { createFragmentContainer, graphql } from 'react-relay'
import FormCheckbox from '@components/Form/FormCheckbox'
import Computer from '@assets/images/computer-icon.svg'

const StyledDropdownContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
`

const StyledFormCreatableSelect = styled(FormCreatableSelect)`
  width: 311px;
`

const StyledOnlineContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-start;
  margin-top: 8px;
  width: 311px;
`

const StyledRoundCheckbox = styled(FormCheckbox)`
  margin-right: 4px;
`

const StyledLabelText = styled.div`
  margin-left: 4px;
`

const CourseCode = ({ teacher }) => {
  const { courseCode, onlineCourse } = rateTeacherModel
  const courseNames = teacher?.courseCodes
    ? teacher.courseCodes.map(course => {
        return {
          label: course.courseName,
          value: course.courseName
        }
      })
    : []

  return (
    <StyledDropdownContainer>
      <StyledFormCreatableSelect
        name={courseCode.name}
        options={courseNames}
        placeholder={courseCode.label}
        fullWidth
      />
      <StyledOnlineContainer>
        <StyledRoundCheckbox name={onlineCourse.name} rounded />
        <img src={Computer} alt="computer" />
        <StyledLabelText>{onlineCourse.label}</StyledLabelText>
      </StyledOnlineContainer>
    </StyledDropdownContainer>
  )
}

CourseCode.propTypes = {
  teacher: TeacherTypes
}

export default createFragmentContainer(CourseCode, {
  teacher: graphql`
    fragment CourseCode_teacher on Teacher {
      courseCodes {
        courseName
      }
    }
  `
})
