import { graphql } from 'react-relay'
import parseAndSanitize from '@utils/parseAndSanitize'
import { matchPath } from 'react-router'
import ROUTE from '@enums/ROUTE'

export const rateSchoolMutation = graphql`
  mutation RateSchoolMutation(
    $social: Int!
    $safety: Int!
    $comment: UserComment!
    $schoolID: ID!
    $internet: Int!
    $opportunities: Int!
    $facilities: Int!
    $food: Int!
    $clubs: Int!
    $happiness: Int!
    $reputation: Int!
    $location: Int!
    $recaptcha: String!
    $compID: String!
  ) {
    addSchoolRating(
      social: $social
      safety: $safety
      comment: $comment
      schoolID: $schoolID
      internet: $internet
      opportunities: $opportunities
      facilities: $facilities
      food: $food
      clubs: $clubs
      happiness: $happiness
      reputation: $reputation
      location: $location
      recaptcha: $recaptcha
      compID: $compID
    ) {
      validation {
        ...FormController_validation
      }
    }
  }
`

export const editRateSchoolMutation = graphql`
  mutation RateSchoolEditMutation(
    $social: Int!
    $safety: Int!
    $comment: UserComment!
    $schoolID: ID!
    $internet: Int!
    $opportunities: Int!
    $facilities: Int!
    $food: Int!
    $clubs: Int!
    $happiness: Int!
    $reputation: Int!
    $location: Int!
    $ID: ID!
  ) {
    editSchoolRating(
      social: $social
      safety: $safety
      comment: $comment
      schoolID: $schoolID
      internet: $internet
      opportunities: $opportunities
      facilities: $facilities
      food: $food
      clubs: $clubs
      happiness: $happiness
      reputation: $reputation
      location: $location
      ID: $ID
    ) {
      validation {
        ...FormController_validation
      }
    }
  }
`

export const generateExistingData = rating => {
  return {
    location: rating.locationRating,
    opportunities: rating.opportunitiesRating,
    social: rating.socialRating,
    safety: rating.safetyRating,
    comment: parseAndSanitize(rating.comment),
    internet: rating.internetRating,
    facilities: Number(rating.facilitiesRating),
    food: rating.foodRating,
    clubs: rating.clubsRating,
    happiness: rating.happinessRating,
    reputation: rating.reputationRating
  }
}

export const getVariables = location => {
  const match = matchPath(location.pathname, {
    path: [ROUTE.NEW_ADD_RATING_SCHOOL, ROUTE.NEW_EDIT_RATING_SCHOOL]
  })

  if (!match?.params?.sid) return { id: '' }

  return {
    id: btoa(`School-${match.params.sid}`),
    ratingId: match?.params?.rid ? btoa(`SchoolRating-${match.params.rid}`) : null
  }
}
