/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type SchoolRatingSuperHeader_rating$ref: FragmentReference;
declare export opaque type SchoolRatingSuperHeader_rating$fragmentType: SchoolRatingSuperHeader_rating$ref;
export type SchoolRatingSuperHeader_rating = {|
  +legacyId: ?number,
  +$refType: SchoolRatingSuperHeader_rating$ref,
|};
export type SchoolRatingSuperHeader_rating$data = SchoolRatingSuperHeader_rating;
export type SchoolRatingSuperHeader_rating$key = {
  +$data?: SchoolRatingSuperHeader_rating$data,
  +$fragmentRefs: SchoolRatingSuperHeader_rating$ref,
};
*/


const node/*: ReaderFragment*/ = {
  "kind": "Fragment",
  "name": "SchoolRatingSuperHeader_rating",
  "type": "SchoolRating",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "legacyId",
      "args": null,
      "storageKey": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = 'f507afe6bfe37b389286e9b34009687c';
module.exports = node;
