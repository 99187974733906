import React from 'react'
import styled from 'styled-components'

import RenderForBreakpoints from '@StyledComponents/RenderForBreakpoints'
import PencilLadySlide from '@assets/images/instructional-slide-pencil-lady.svg'
import ThumbWarSlide from '@assets/images/instructional-slide-thumb-war.svg'
import MysteryLadySlide from '@assets/images/instructional-slide-mystery-lady.svg'

const SlidesStrip = styled.div`
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 42px;
  width: 100%;
`

const SlideArea = styled.div`
  align-content: center;
  display: flex;
  flex-direction: column;
  height: 194px;
  justify-content: flex-end;
  max-width: 200px;
  text-align: center;
`

export const SlideCaption = styled.div`
  font-family: ${props => props.theme.fontFamily.poppins};
  margin-top: 10px;

  em {
    font-style: italic;
    font-weight: ${props => props.theme.fontWeight.medium};
  }
`

export default function SignupInstructionalSlides() {
  return (
    <SlidesStrip>
      <RenderForBreakpoints min="smedium">
        <SlideArea>
          <img src={PencilLadySlide} alt="Lady with a pencil" />
          <SlideCaption>Manage and edit your reviews</SlideCaption>
        </SlideArea>
      </RenderForBreakpoints>
      <SlideArea>
        <img src={ThumbWarSlide} alt="Thumb war" />
        <SlideCaption>Thumbs up or down other reviews</SlideCaption>
      </SlideArea>
      <RenderForBreakpoints min="smedium">
        <SlideArea>
          <img src={MysteryLadySlide} alt="Person making an anonymous entry" />
          <SlideCaption>
            Your entries are <em> always </em> anonymous!
          </SlideCaption>
        </SlideArea>
      </RenderForBreakpoints>
    </SlidesStrip>
  )
}
