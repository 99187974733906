import React from 'react'
import PropTypes from 'prop-types'
import { createFragmentContainer, graphql } from 'react-relay'
import SchoolTypes from '@types/School.types'
import ROUTE from '@enums/ROUTE'
import Link from './Link'

export function RateSchoolLink({ school, trackingLabel, className, children, id }) {
  return (
    <Link
      className={className}
      to={ROUTE.NEW_ADD_RATING_SCHOOL.replace(':sid', school.legacyId)}
      trackingLabel={trackingLabel}
      id={id}
    >
      {children}
    </Link>
  )
}

export default createFragmentContainer(RateSchoolLink, {
  school: graphql`
    fragment RateSchoolLink_school on School {
      legacyId
    }
  `
})

RateSchoolLink.propTypes = {
  school: SchoolTypes.isRequired,
  trackingLabel: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  id: PropTypes.string
}
