/* stylelint-disable comment-empty-line-before */
import React from 'react'
import styled, { css } from 'styled-components'
import { breakpointSmallAndBelow } from '@StyledComponents/theme/helpers/breakpoints'
import PropTypes from 'prop-types'
import { poppinsBoldest } from '@StyledComponents/theme/typesets'

const SchoolInfoItem = styled.div`
  align-items: center;
  border-left: 1px solid ${({ theme }) => theme.color.default};
  border-right: 1px solid ${({ theme }) => theme.color.default};
  display: flex;
  font-size: ${({ theme }) => theme.fontSize.medium};
  padding-right: 16px;

  ${breakpointSmallAndBelow(
    css`
      font-size: ${({ theme }) => theme.fontSize.default};
    `
  )}

  /* css styles to ensure borders should appear if: */
  /* they're the second item of three items in the array (both left and right borders) */
  /* if they're the first of two items in the array (right border) */
  /* they won't appear if they are the only item in the array */

  &:last-of-type {
    border-left: 1px solid ${({ theme }) => theme.color.default};
    border-right: 0;
  }

  &:first-of-type {
    border-left: 0;
  }

  &:not(:first-of-type) {
    padding-left: 16px;
  }
`

const StyledSchoolCardInfo = styled.div`
  display: flex;
`

const InfoItemNumber = styled.div`
  font-size: ${({ theme }) => theme.fontSize.teacherCardName};
  margin-right: 8px;

  ${poppinsBoldest};

  ${breakpointSmallAndBelow(
    css`
      font-size: ${({ theme }) => theme.fontSize.large};
    `
  )}
`

const SchoolCardInfo = ({ infoArray, name }) => {
  if (!infoArray || !infoArray.length > 0) return null

  return (
    <StyledSchoolCardInfo>
      {infoArray.map((info, index) => {
        if (!info?.label || !info?.rating) {
          return null
        }

        return (
          <SchoolInfoItem key={`${name}-card-${index.toString()}`}>
            <InfoItemNumber>{info.rating}</InfoItemNumber>
            <span>{info.label}</span>
          </SchoolInfoItem>
        )
      })}
    </StyledSchoolCardInfo>
  )
}

export default SchoolCardInfo

// This structure is placeholder for now until we can get something else in place
SchoolCardInfo.propTypes = {
  infoArray: PropTypes.arrayOf({ rating: PropTypes.number, label: PropTypes.string }),
  name: PropTypes.string
}
