const hashLinkScroll = hash => {
  if (!hash) return

  const id = hash.replace('#', '')
  const element = document.getElementById(id)

  if (!element) return

  element.scrollIntoView({ behavior: 'smooth' })
  // In preventing the clicked event, the url doesn't actually change
  // pushState changes the hash without causing a refresh
  if (window && window.history && window.history.pushState) {
    window.history.pushState({}, '', hash)
  }
}

export default hashLinkScroll
