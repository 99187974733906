/* eslint-disable no-unused-vars */
import styled, { css } from 'styled-components'
import svgRightArrow from '@assets/images/right-arrow-white.svg'
import styledMap from 'styled-map'
import RMPButton from '../Button'
import LoadingIcon from './LoadingIcon'

const getStyledButtonMap = isTextButton => {
  return styledMap`
    blue: ${({ theme }) => theme.color.mainBlue};
    pink: ${({ theme }) => theme.color.pink};
    white: ${({ theme }) => theme.color.white};
    default: ${isTextButton ? 'none' : 'black'};
  `
}

export const ButtonAttributes = css`
  background: ${getStyledButtonMap(false)};
  border: 1px solid ${props => props.theme.color.darkMainBlue};
  border-radius: 100px;
  color: ${props => (props.secondary ? props.theme.color.darkMainBlue : 'white')};
  display: flex;
  font-stretch: normal;
  font-weight: ${props => props.theme.fontWeight.bold};
  justify-content: center;
  letter-spacing: normal;
  line-height: 1.54;
  outline: none;
  padding: 11px 46px;
  transition: transform 0.2s;

  &:not(:disabled):hover {
    transform: scale(1.05);
  }

  :hover {
    color: ${props => (props.secondary ? props.theme.color.white : null)};
    cursor: pointer;
  }

  :disabled {
    background: ${props => props.theme.color.fadedDefault};
    cursor: not-allowed;
  }

  ::after {
    align-self: center;
    background-image: ${props => (props.secondary ? null : `url(${svgRightArrow})`)};
    content: '';
    display: ${props => (props.rightArrow ? 'block' : 'none')};
    height: 15px;
    margin-left: 10px;
    width: 15px;
  }

  ${LoadingIcon} {
    color: white;
  }
`

export const GoogleButtonAttributes = css`
  background: white;
  border: 1px solid ${({ theme }) => theme.color.gray4};
  border-radius: 100px;
  color: black;
  display: flex;
  font-stretch: normal;
  font-weight: ${props => props.theme.fontWeight.bold};
  justify-content: center;
  left: 24px;
  letter-spacing: normal;
  line-height: 1.54;
  outline: none;
  padding: 11px 46px;
  transition: transform 0.2s;

  &:not(:disabled):hover {
    transform: scale(1.05);
  }

  :hover {
    cursor: pointer;
  }

  :disabled {
    background: ${props => props.theme.color.fadedDefault};
    cursor: not-allowed;
  }

  ::after {
    align-self: center;
    background-image: url(${svgRightArrow});
    content: '';
    display: ${props => (props.rightArrow ? 'block' : 'none')};
    height: 15px;
    margin-left: 10px;
    width: 15px;
  }

  ${LoadingIcon} {
    color: white;
  }
`

export const TextButton = styled(RMPButton)`
  background: ${getStyledButtonMap(true)};
  border: 0;
  cursor: ${props => (props.disabled ? 'default' : 'pointer')};
  display: inline-block;
  font-weight: ${props => props.theme.fontWeight.bold};
  outline: none;
  padding: none;
`

export const Button = styled(RMPButton)`
  ${ButtonAttributes}
`
