/**
 * @flow
 * @relayHash b1d8f54494c4e3ee48530eed179bdb04
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ThumbsRatingMutationVariables = {|
  ratingId: number,
  teacherId: number,
  thumbsUpCount: number,
  thumbsDownCount: number,
  userId?: ?number,
  compID: string,
|};
export type ThumbsRatingMutationResponse = {|
  +createThumb: ?{|
    +id: ?string
  |}
|};
export type ThumbsRatingMutation = {|
  variables: ThumbsRatingMutationVariables,
  response: ThumbsRatingMutationResponse,
|};
*/


/*
mutation ThumbsRatingMutation(
  $ratingId: Int!
  $teacherId: Int!
  $thumbsUpCount: Int!
  $thumbsDownCount: Int!
  $userId: Int
  $compID: String!
) {
  createThumb(rid: $ratingId, tid: $teacherId, thumbsUp: $thumbsUpCount, thumbsDown: $thumbsDownCount, userId: $userId, compID: $compID) {
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "ratingId",
    "type": "Int!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "teacherId",
    "type": "Int!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "thumbsUpCount",
    "type": "Int!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "thumbsDownCount",
    "type": "Int!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "userId",
    "type": "Int",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "compID",
    "type": "String!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "createThumb",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "compID",
        "variableName": "compID"
      },
      {
        "kind": "Variable",
        "name": "rid",
        "variableName": "ratingId"
      },
      {
        "kind": "Variable",
        "name": "thumbsDown",
        "variableName": "thumbsDownCount"
      },
      {
        "kind": "Variable",
        "name": "thumbsUp",
        "variableName": "thumbsUpCount"
      },
      {
        "kind": "Variable",
        "name": "tid",
        "variableName": "teacherId"
      },
      {
        "kind": "Variable",
        "name": "userId",
        "variableName": "userId"
      }
    ],
    "concreteType": "Thumb",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "ThumbsRatingMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "ThumbsRatingMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "ThumbsRatingMutation",
    "id": null,
    "text": "mutation ThumbsRatingMutation(\n  $ratingId: Int!\n  $teacherId: Int!\n  $thumbsUpCount: Int!\n  $thumbsDownCount: Int!\n  $userId: Int\n  $compID: String!\n) {\n  createThumb(rid: $ratingId, tid: $teacherId, thumbsUp: $thumbsUpCount, thumbsDown: $thumbsDownCount, userId: $userId, compID: $compID) {\n    id\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '3ee9b19a3d1eaaa1ca3b548c522b4558';
module.exports = node;
