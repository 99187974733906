import Parse from 'html-react-parser'
import sanitizeHtml from 'sanitize-html'

const parseAndSanitize = string => {
  /**
   * The UTF-8 single quote character (0x2019) causes issues when writing to the latin1 encoded
   * column in db, so we replace with latin1 single quote (0x0027)
   */
  return Parse(sanitizeHtml(string?.replace(/\u2019/g, '\u0027')))
}

export default parseAndSanitize
