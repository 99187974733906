import React from 'react'
import styled, { css } from 'styled-components'
import SocialLinks, { SOCIAL_ICON_COLORS } from '@components/SocialLinks'
import { breakpointSmediumAndBelow } from '@StyledComponents/theme/helpers/breakpoints'
import { helveticaBold } from '@components/StyledComponents/theme/typesets'
import FooterLogo from './FooterLogo'
import FooterCopyright from './FooterCopyright'
import FooterLink from './FooterLink'
import FOOTER_LINKS from './FOOTER_LINKS'

export const StyledFooterContainer = styled.div`
  background-color: black;
  color: white;
  display: flex;
  flex-direction: column;
  padding: 16px;
`

const StyledLinksWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  height: 100%;
  justify-content: left;

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: center;
  }
`

const StyledFooterLinkWrapper = styled.div`
  color: white;
  display: flex;
  justify-content: left;
  margin-right: 16px;
`

const StyledFooterLink = styled(FooterLink)`
  color: white;
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 16px;
  text-decoration: none;

  ${helveticaBold}
`

const StyledLogoAndCopyrightContainer = styled.div`
  color: white;
  display: flex;
  flex-direction: column;
`

const StyledLogoAndCopyrightWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;

  ${breakpointSmediumAndBelow(
    css`
      flex-direction: column;
      justify-content: center;
    `
  )}
`

const Footer = () => {
  return (
    <StyledFooterContainer>
      <StyledLinksWrapper>
        {FOOTER_LINKS.map(s => {
          return (
            <StyledFooterLinkWrapper key={s.label}>
              <StyledFooterLink link={s} />
            </StyledFooterLinkWrapper>
          )
        })}
      </StyledLinksWrapper>
      <StyledLogoAndCopyrightContainer>
        <SocialLinks iconColor={SOCIAL_ICON_COLORS.WHITE} />
        <StyledLogoAndCopyrightWrapper>
          <FooterLogo />
          <FooterCopyright />
        </StyledLogoAndCopyrightWrapper>
      </StyledLogoAndCopyrightContainer>
    </StyledFooterContainer>
  )
}

export default Footer
