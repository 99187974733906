import React, { useRef, useState } from 'react'
import * as Yup from 'yup'
import { useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'
import FormController from '@components/Storybook/Shared/FormController'
import FormSubmitButton from '@components/Storybook/Shared/FormSubmitButton'
import { Error } from 'storybook-rmp'
import ReCAPTCHA from '@lib/ReCAPTCHA'
import COUNTRIES from '@pages/AddCampus/constants/Countries'
import STATES from '@pages/AddCampus/constants/States'
import AddSchoolFormContent from './AddSchoolFormContent'
import submitSchoolForm from './AddSchoolForm.utils'

const schema = Yup.object().shape({
  name: Yup.string()
    .notOneOf([''], 'Cannot be empty')
    .required('School name is required'),
  country: Yup.string().required('Country is required'),
  state: Yup.string().required('State is required'),
  website: Yup.string().required('The school website is required'),
  email: Yup.string()
    .email()
    .required('Your email is required')
})

const initialFormValues = {
  name: '',
  country: '',
  countries: COUNTRIES,
  state: '',
  states: STATES,
  city: '',
  website: '',
  email: '',
  checkbox: false,
  recaptcha: ''
}

const AddSchoolForm = ({ isNewForms }) => {
  const formRef = useRef(null)
  const history = useHistory()

  if (!isNewForms) return null

  const [submissionError, setSubmissionError] = useState(null)

  const handleSubmit = values => {
    submitSchoolForm({
      values,
      history,
      setSubmissionError
    })
  }

  return (
    <FormController
      initialValues={initialFormValues}
      validationSchema={schema}
      onSubmit={handleSubmit}
      validateOnChange={false}
      validateOnBlur={false}
    >
      <React.Fragment>
        <AddSchoolFormContent />
        <FormSubmitButton
          type="submit"
          name="recaptcha"
          formRef={formRef}
          action={ReCAPTCHA.ACTIONS.ADD_NEW_CAMPUS}
        />
        {/* We send a request to the server to validate ReCAPTCHA so this error field captures that */}
        {submissionError && <Error error={submissionError} />}
      </React.Fragment>
    </FormController>
  )
}

AddSchoolForm.propTypes = {
  isNewForms: PropTypes.bool
}

export default AddSchoolForm
