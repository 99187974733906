import React, { useContext, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import SEARCH_ITEM_TYPES from '@enums/SEARCHITEMTYPES'
import SEARCH_CATEGORY from '@enums/SEARCH_CATEGORY'
import { DebouncedSearchInput } from '@components/Search'
import { TextFieldStyles } from '@StyledComponents/TextField'
import FormContext from '@contexts/FormContext'
import LABEL_STYLE, { LabelStyleTypes } from '@enums/LABEL_STYLE'
import InputWrapper from '@components/StyledComponents/InputWrapper'
import ROUTE from '@enums/ROUTE'
import useGlobalContext from '@hooks/useGlobalContext'
import { helveticaBold, helveticaLight } from '@components/StyledComponents/theme/typesets'
import Link, { LINK_TYPE } from '@components/Link'
import SearchContainer from '@components/Search/SearchContainer'
import FieldWrapper from './FieldWrapper'

const FormSearch = styled(SearchContainer)`
  margin-bottom: 8px;
  margin-top: 8px;
  max-width: ${props => (props.inModal ? 'unset' : '360px')};
  width: 100%;
  ${DebouncedSearchInput} {
    ${TextFieldStyles}
  }
`

const StyledHelperText = styled.div`
  display: flex;
  font-size: ${props => props.theme.fontSize.medium};
  justify-content: left;
  margin-left: 0px;
  ${props => (props.bold ? helveticaBold : helveticaLight)}
`

const CreateProfileLink = styled(Link)`
  color: ${({ theme }) => theme.color.mainBlue};
  font-size: ${({ theme }) => theme.fontSize.medium};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  text-decoration: none;
`

const buildOnTeacherSelected = setValue => ({ event, itemType, item, setIsOpen }) => {
  /**
   * We are preventing clicking here so that the item value can be selected for the form
   */
  event.preventDefault()
  if (itemType === SEARCH_ITEM_TYPES.TEACHERS) {
    setValue(item)
    setIsOpen(false)
  }
}

const FormTeacherSelector = ({
  disabled,
  name,
  className,
  label,
  labelStyle = LABEL_STYLE.LEFT,
  inModal,
  setSelectedTeacher
}) => {
  const { setActiveModal } = useGlobalContext()
  const { locked, model, onChange } = useContext(FormContext)
  const teacher = model?.[name]
  const [isFocused, setFocus] = useState(false)
  const setValue = value => onChange({ target: { value, name } })
  const [hasValue, setHasValue] = useState()
  const onTeacherSelected = buildOnTeacherSelected(setValue)

  if (teacher) {
    setSelectedTeacher(teacher)
  }

  return (
    <FieldWrapper fieldName={name} className={className}>
      <InputWrapper
        isFocused={isFocused}
        setFocus={setFocus}
        hasValue={hasValue}
        setHasValue={setHasValue}
        name={name}
        label={label}
        labelStyle={labelStyle}
        className={className}
      >
        {/* we save input value and render styled box upon teacher selection */}
        <StyledHelperText bold>Search for your professor profile</StyledHelperText>
        <FormSearch
          category={SEARCH_CATEGORY.TEACHERS}
          disabled={locked || disabled}
          allowRedirect={false}
          onItemSelected={onTeacherSelected}
          onKeyDown={e => {
            if (e.keyCode >= 65 && e.keyCode <= 90) {
              setValue(null)
            }
          }}
          value={teacher?.id || ''}
          onFocus={() => setFocus(true)}
          onBlur={() => setFocus(false)}
          inModal={inModal}
          hidePlaceholder
          useErrorBorder
        />
        <StyledHelperText>
          Can&apos;t find your profile?&nbsp;
          <CreateProfileLink
            to={ROUTE.NEW_ADD_PROFESSOR}
            type={LINK_TYPE.INTERNAL}
            onClick={() => setActiveModal(null)}
          >
            Create one here
          </CreateProfileLink>
        </StyledHelperText>
      </InputWrapper>
    </FieldWrapper>
  )
}

FormTeacherSelector.propTypes = {
  disabled: PropTypes.bool,
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  labelStyle: LabelStyleTypes,
  inModal: PropTypes.bool,
  setSelectedTeacher: PropTypes.func
}

export default FormTeacherSelector
