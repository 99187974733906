import React from 'react'
import RatingTypes from '@types/Teacher.types'
import FormTextArea from '@components/Form/FormTextArea'
import { MAX_COMMENT_LENGTH } from '@src/CONSTANTS'
import FlagCampusReportingBox from './FlagCampusReportingBox'

const FlagComment = ({ rating }) => {
  return (
    <div>
      <FlagCampusReportingBox rating={rating} />
      <FormTextArea
        placeholder="Tell us what's wrong with this comment..."
        name="flagComment"
        maxlength={MAX_COMMENT_LENGTH}
      />
    </div>
  )
}

FlagComment.propTypes = {
  rating: RatingTypes
}

export default FlagComment
