import React from 'react'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'
import {
  breakpointSmediumAndBelow,
  breakpointLargeAndBelow,
  breakpointLargePlusAndBelow,
  getPageContentMaxWidth
} from '@StyledComponents/theme/helpers/breakpoints'
import { AdNoBidLeaderboardStyled } from './NoBid/AdNoBid'

export const StyledAdLeaderboard = styled.div`
  display: ${({ onlyMobile }) => (onlyMobile ? 'none' : 'block')};

  ${breakpointLargePlusAndBelow(css`
    display: block;
    margin-left: 16px !important;
  `)}

  margin: ${props => (props.center ? '24px auto' : null)};
  text-align: ${props => (props.center ? 'center' : 'left')};
  width: 100%;
  z-index: ${props => props.theme.zIndex.stickyLeaderboard};
  
  ${breakpointLargePlusAndBelow(css`
    margin: 24px 0px 24px 0px;
  `)}

  ${breakpointLargeAndBelow(css`
    text-align: center;
  `)}

  ${props =>
    props.hasLightHeader &&
    css`
      max-width: 1000px;
      min-height: 90px;
      text-align: center;
      z-index: ${props.theme.zIndex.header};
      ${breakpointLargePlusAndBelow(css`
        margin: 27px auto;
      `)}
      ${breakpointSmediumAndBelow(
        css`
          z-index: ${props.theme.zIndex.stickyLeaderboard};
          padding: 0;
          margin: 0 auto 10px;
          min-height: 50px;
        `
      )}
    `};
  
  ${({ theme }) => getPageContentMaxWidth(theme)};
`

const AdWrapper = styled.div`
  display: inline-block;
  min-width: 100%;
`

const StyledWunderkindWrapper = styled.div`
  display: inline-block;
  max-height: 250px;
  max-width: 970px;
  position: relative;
`

export default function AdLeaderboard({
  code,
  hasLightHeader,
  adTargetingSchools,
  center,
  onlyMobile = false,
  schoolHeader = false
}) {
  return (
    <StyledAdLeaderboard
      id="ad-header-leaderboard"
      role="complementary"
      alt="advertisement leaderboard"
      aria-label="advertisement leaderboard"
      hasLightHeader={hasLightHeader}
      center={center}
      onlyMobile={onlyMobile}
    >
      <AdWrapper>
        {!schoolHeader && <StyledWunderkindWrapper id="ad-container-wunderkind" />}
        <AdNoBidLeaderboardStyled code={code} targeting={adTargetingSchools} />
      </AdWrapper>
    </StyledAdLeaderboard>
  )
}

AdLeaderboard.propTypes = {
  code: PropTypes.string,
  hasLightHeader: PropTypes.bool,
  // This needs to be object b/c the targeting varies by page
  // eslint-disable-next-line react/forbid-prop-types
  adTargetingSchools: PropTypes.object,
  center: PropTypes.bool,
  onlyMobile: PropTypes.bool,
  schoolHeader: PropTypes.bool
}
