import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import styled, { ThemeContext } from 'styled-components'
import { ToastContainer } from 'react-toastify'
import { isInBreakpointRange } from '@StyledComponents/theme/helpers/breakpoints'
import isSSR from '@utils/isSSR'

const StyledToastContainer = styled(ToastContainer).attrs({
  className: 'toast-container',
  toastClassName: 'toast'
})`
  .toast {
    margin-bottom: 0.25rem;
  }
`

const ToastContainerWrapper = props => {
  const themeContext = useContext(ThemeContext)
  const isMobile = isSSR ? isInBreakpointRange(themeContext, 'xsmall', 'smallplus') : false
  return (
    <StyledToastContainer
      position={isMobile ? 'top-center' : 'top-right'}
      closeButton={false}
      hideProgressBar
      newestOnTop
      draggable={false}
      {...props}
    />
  )
}

ToastContainerWrapper.propTypes = {
  autoClose: PropTypes.oneOfType([PropTypes.bool, PropTypes.number])
}

export default ToastContainerWrapper
