/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type RatingsFilter_teacher$ref = any;
type RatingsList_teacher$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type TeacherRatingTabs_teacher$ref: FragmentReference;
declare export opaque type TeacherRatingTabs_teacher$fragmentType: TeacherRatingTabs_teacher$ref;
export type TeacherRatingTabs_teacher = {|
  +numRatings: ?number,
  +courseCodes: ?$ReadOnlyArray<?{|
    +courseName: ?string,
    +courseCount: ?number,
  |}>,
  +$fragmentRefs: RatingsList_teacher$ref & RatingsFilter_teacher$ref,
  +$refType: TeacherRatingTabs_teacher$ref,
|};
export type TeacherRatingTabs_teacher$data = TeacherRatingTabs_teacher;
export type TeacherRatingTabs_teacher$key = {
  +$data?: TeacherRatingTabs_teacher$data,
  +$fragmentRefs: TeacherRatingTabs_teacher$ref,
};
*/


const node/*: ReaderFragment*/ = {
  "kind": "Fragment",
  "name": "TeacherRatingTabs_teacher",
  "type": "Teacher",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "numRatings",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "courseCodes",
      "storageKey": null,
      "args": null,
      "concreteType": "Course",
      "plural": true,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "courseName",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "courseCount",
          "args": null,
          "storageKey": null
        }
      ]
    },
    {
      "kind": "FragmentSpread",
      "name": "RatingsList_teacher",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "RatingsFilter_teacher",
      "args": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = 'a0da9968c15b947d6f029b70b0422090';
module.exports = node;
