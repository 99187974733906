import React from 'react'
import PropTypes from 'prop-types'
import TeacherSchoolSearchDropdown from './TeacherSearchDropdown'

export const DEFAULT_FILTER = { label: 'Any', value: null }

const getLabelFromValue = label => {
  return label
    .split(' ')
    .map(s => s.charAt(0).toUpperCase() + s.substring(1))
    .join(' ')
}

export const getOptions = options => {
  const unfilteredOptions = options.map(option => ({
    label: getLabelFromValue(option.value),
    value: option.value,
    id: option.id
  }))

  const result = unfilteredOptions.filter(option => option.id)
  result.unshift(DEFAULT_FILTER)
  return result
}

const TeacherDepartmentFilter = props => {
  const additionalStyles = {
    control: {
      height: '44px',
      maxWidth: '264px',
      width: '100%'
    },
    menu: {
      maxWidth: '264px'
    },
    option: {
      textAlign: 'left'
    }
  }

  const { filterOptions } = props

  if (!filterOptions) return null

  return (
    <TeacherSchoolSearchDropdown
      label="Department"
      additionalStyles={additionalStyles}
      options={getOptions(filterOptions)}
      {...props}
    />
  )
}

TeacherDepartmentFilter.propTypes = {
  filterOptions: PropTypes.arrayOf(
    PropTypes.shape({ count: PropTypes.number, value: PropTypes.string })
  )
}

export default TeacherDepartmentFilter
