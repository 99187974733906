import React from 'react'
import ReactRouterPropTypes from 'react-router-prop-types'
import { Redirect, withRouter } from 'react-router-dom'

import ROUTE from '@enums/ROUTE'

function getAdditionalHashes(max, suffix = '') {
  const results = []
  for (let i = 0; i < max; i += 1) {
    results.push(`#section${suffix}${i}`)
  }
  return results
}

export const LEGAL_REDIRECTS = [
  {
    hash: '#privacy',
    route: ROUTE.LEGAL_PRIVACY,
    additionalHashes: [
      '#otherbyus',
      '#trackingtpspa',
      '#mimtt',
      '#micc',
      ...getAdditionalHashes(12, 'p')
    ]
  },
  {
    hash: '#use',
    route: ROUTE.LEGAL_TERMS,
    additionalHashes: ['#nineteenfive', ...getAdditionalHashes(21)]
  },
  { hash: '#guidelines', route: ROUTE.LEGAL_SITE_GUIDELINES, additionalHashes: [] },
  { hash: '#copyright', route: ROUTE.LEGAL_COPYRIGHT, additionalHashes: [] }
]

function hasHashMatch(redirect, location) {
  const matchesMainHash = redirect.hash === location.hash
  const matchesAdditionalHashes = redirect.additionalHashes.some(h => h === location.hash)
  return matchesMainHash || matchesAdditionalHashes
}

function getRedirectComponent(redirect, location) {
  const matchesAdditionalHashes = redirect.additionalHashes.some(h => h === location.hash)
  return (
    <Redirect
      to={{
        pathname: redirect.route,
        // Any additional hashes must be maintained for link routing
        hash: matchesAdditionalHashes ? location.hash : ''
      }}
    />
  )
}

/**
 * LegalRedirect is set-up to handle links to the old RMP terms and conditions
 * page which handled all 4 legal pages via hash routing. We force redirect
 * all of these to individual new routes, which will be used going forward.
 *
 * In addition, there were various section links used via IDs like `sectionp1`
 * which need to be appropriately routed and have their hash maintained.
 */
export function LegalHashRouting({ location }) {
  for (let i = 0; i < LEGAL_REDIRECTS.length; i += 1) {
    const redirect = LEGAL_REDIRECTS[i]
    if (hasHashMatch(redirect, location)) {
      return getRedirectComponent(redirect, location)
    }
  }
  return <Redirect to={{ pathname: ROUTE.LEGAL_TERMS, hash: '' }} />
}

LegalHashRouting.propTypes = {
  location: ReactRouterPropTypes.location
}

export default withRouter(LegalHashRouting)
