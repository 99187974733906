/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type RateSchoolLink_school$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type NoRatingsArea_school$ref: FragmentReference;
declare export opaque type NoRatingsArea_school$fragmentType: NoRatingsArea_school$ref;
export type NoRatingsArea_school = {|
  +name: ?string,
  +$fragmentRefs: RateSchoolLink_school$ref,
  +$refType: NoRatingsArea_school$ref,
|};
export type NoRatingsArea_school$data = NoRatingsArea_school;
export type NoRatingsArea_school$key = {
  +$data?: NoRatingsArea_school$data,
  +$fragmentRefs: NoRatingsArea_school$ref,
};
*/


const node/*: ReaderFragment*/ = {
  "kind": "Fragment",
  "name": "NoRatingsArea_school",
  "type": "School",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "name",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "FragmentSpread",
      "name": "RateSchoolLink_school",
      "args": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '30ed627494ce9c183589d7ecd787a0ef';
module.exports = node;
