import React from 'react'
import styled from 'styled-components'
import { createFragmentContainer, graphql } from 'react-relay'
import RatingTypes from '@types/Rating.types'
import TRACKING_EVENT from '@enums/TRACKING_EVENT'
import { LINK_TYPE } from '@src/components/Link'
import ROUTE from '@enums/ROUTE'
import SchoolTypes from '@types/School.types'
import Thumbs from '@components/Thumbs'
import ReportFlag from '../ReportFlag'

const SchoolRatingFooterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 36px;
`

export const ButtonWrapper = styled.div`
  align-items: center;
  display: flex;
`

export function SchoolRatingFooter({ rating, school }) {
  return (
    <SchoolRatingFooterContainer>
      <Thumbs school={school} schoolRating={rating} teacher={null} rating={null} />
      <ReportFlag
        status={rating.flagStatus}
        link={ROUTE.NEW_FLAG_CAMPUS_RATING.replace(':sid', school.legacyId).replace(
          ':rid',
          rating.legacyId
        )}
        trackingLabel={TRACKING_EVENT.SCHOOL_FLAG_RATING}
        type={LINK_TYPE.INTERNAL}
        entityLabel="rating"
      />
    </SchoolRatingFooterContainer>
  )
}

SchoolRatingFooter.propTypes = {
  rating: RatingTypes,
  school: SchoolTypes
}

export default createFragmentContainer(SchoolRatingFooter, {
  rating: graphql`
    fragment SchoolRatingFooter_rating on SchoolRating {
      id
      comment
      flagStatus
      legacyId
      ...Thumbs_schoolRating
    }
  `,
  school: graphql`
    fragment SchoolRatingFooter_school on School {
      id
      legacyId
      ...Thumbs_school
    }
  `
})
