/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type CompareProfessorLink_teacher$ref = any;
type RateTeacherLink_teacher$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type HeaderRateButton_teacher$ref: FragmentReference;
declare export opaque type HeaderRateButton_teacher$fragmentType: HeaderRateButton_teacher$ref;
export type HeaderRateButton_teacher = {|
  +$fragmentRefs: RateTeacherLink_teacher$ref & CompareProfessorLink_teacher$ref,
  +$refType: HeaderRateButton_teacher$ref,
|};
export type HeaderRateButton_teacher$data = HeaderRateButton_teacher;
export type HeaderRateButton_teacher$key = {
  +$data?: HeaderRateButton_teacher$data,
  +$fragmentRefs: HeaderRateButton_teacher$ref,
};
*/


const node/*: ReaderFragment*/ = {
  "kind": "Fragment",
  "name": "HeaderRateButton_teacher",
  "type": "Teacher",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "RateTeacherLink_teacher",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "CompareProfessorLink_teacher",
      "args": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = 'a0ae95fd0f19f83961c565d03c9c3b07';
module.exports = node;
