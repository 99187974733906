/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type NameLink_teacher$ref: FragmentReference;
declare export opaque type NameLink_teacher$fragmentType: NameLink_teacher$ref;
export type NameLink_teacher = {|
  +isProfCurrentUser: ?boolean,
  +id: ?string,
  +legacyId: ?number,
  +firstName: ?string,
  +lastName: ?string,
  +school: ?{|
    +name: ?string
  |},
  +$refType: NameLink_teacher$ref,
|};
export type NameLink_teacher$data = NameLink_teacher;
export type NameLink_teacher$key = {
  +$data?: NameLink_teacher$data,
  +$fragmentRefs: NameLink_teacher$ref,
};
*/


const node/*: ReaderFragment*/ = {
  "kind": "Fragment",
  "name": "NameLink_teacher",
  "type": "Teacher",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "isProfCurrentUser",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "id",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "legacyId",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "firstName",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "lastName",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "school",
      "storageKey": null,
      "args": null,
      "concreteType": "School",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "name",
          "args": null,
          "storageKey": null
        }
      ]
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '8b73a79d590b7937caf817fc7d48a124';
module.exports = node;
