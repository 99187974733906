import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import Checkbox from '@StyledComponents/Checkbox'
import FormContext from '@contexts/FormContext'
import FieldWrapper from './FieldWrapper'

const StyledCheckboxWrapper = styled.div`
  display: flex;
  font-size: ${({ theme }) => theme.fontSize.medium};
  line-height: 28px;
  &:hover {
    cursor: pointer;
  }
  span {
    margin-left: 20px;
  }
`

const StyledFormCheckbox = styled(Checkbox)`
  ${props => (props.labelBefore ? 'margin-left: 10px;' : 'margin-right: 10px')}
`

const FormCheckbox = ({ disabled, name, label, rounded, labelBefore = false, ...props }) => {
  const { locked, model, onChange } = useContext(FormContext)

  return (
    <FieldWrapper fieldName={name}>
      <StyledCheckboxWrapper>
        {labelBefore && <span>{label}</span>}
        <StyledFormCheckbox
          labelBefore={labelBefore}
          name={name}
          disabled={locked || disabled}
          onChange={onChange}
          checked={(model && model[name]) || false}
          rounded={rounded}
          {...props}
        />
        {!labelBefore && <span>{label}</span>}
      </StyledCheckboxWrapper>
    </FieldWrapper>
  )
}

FormCheckbox.propTypes = {
  disabled: PropTypes.bool,
  name: PropTypes.string.isRequired,
  label: PropTypes.node,
  labelBefore: PropTypes.bool,
  rounded: PropTypes.bool
}

export default FormCheckbox
