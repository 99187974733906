import React from 'react'
import PropTypes from 'prop-types'
import { Error, Checkbox } from 'storybook-rmp'

const CheckboxInput = ({ value, onChange, error }) => {
  return (
    <React.Fragment>
      <Checkbox
        id="checkbox"
        name="checkbox"
        label="I agree to the Terms of Use and Privacy Policy"
        checked={value}
        onChange={onChange}
      />
      {error && <Error error={error} />}
    </React.Fragment>
  )
}

CheckboxInput.propTypes = {
  value: PropTypes.string,
  error: PropTypes.string,
  onChange: PropTypes.func
}

export default CheckboxInput
