import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const StyledCarouselItem = styled.div`
  align-items: center;
  display: inline-flex;
  justify-content: center;
  white-space: normal;
`

const CarouselItem = ({ children, width }) => {
  return <StyledCarouselItem style={{ width }}>{children}</StyledCarouselItem>
}

CarouselItem.propTypes = {
  children: PropTypes.node,
  width: PropTypes.string
}

export default CarouselItem
