import React, { useEffect, useRef } from 'react'
import styled from 'styled-components'

const StyledJWPlayer = styled.div`
  position: relative;
  z-index: ${({ theme }) => theme.zIndex.videoAnchor};
`

const JWVideoPlayer = () => {
  const playerRef = useRef(null)

  useEffect(() => {
    const scriptId = 'rmp-jwplayer-script'

    if (!document.getElementById(scriptId)) {
      const script = document.createElement('script')
      script.id = scriptId
      script.src = 'https://cdn.jwplayer.com/libraries/QSNV25sR.js'
      script.async = true
      document.body.appendChild(script)

      script.onload = () => {
        if (window.jwplayer) {
          window.jwplayer(playerRef.current.id).setup({
            aspectratio: '16:9',
            playlist: 'https://cdn.jwplayer.com/v2/playlists/a43LhOp6?format=json'
          })
        }
      }
    } else if (window.jwplayer) {
      window.jwplayer(playerRef.current.id).setup({
        aspectratio: '16:9',
        playlist: 'https://cdn.jwplayer.com/v2/playlists/a43LhOp6?format=json'
      })
    }

    return () => {
      if (window.jwplayer && playerRef.current) {
        window.jwplayer(playerRef.current.id).remove()
      }
    }
  }, [])

  return <StyledJWPlayer id="rmp-jwplayer-container" ref={playerRef} />
}

export default JWVideoPlayer
