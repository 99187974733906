import React, { useRef, useState } from 'react'
import * as Yup from 'yup'
import { useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'
import FormController from '@components/Storybook/Shared/FormController'
import FormSubmitButton from '@components/Storybook/Shared/FormSubmitButton'
import { Error } from 'storybook-rmp'
import ReCAPTCHA from '@lib/ReCAPTCHA'
import AddProfessorFormContent from './AddProfessorFormContent'
import submitProfessorForm from './AddProfessorForm.utils'

const schema = Yup.object().shape({
  firstName: Yup.string()
    .notOneOf([''], 'Cannot be empty')
    .required('First name is required'),
  lastName: Yup.string()
    .notOneOf([''], 'Cannot be empty')
    .required('Last name is required'),
  department: Yup.string()
    .notOneOf([''], 'Cannot be empty')
    .required('Department is required'),
  schoolName: Yup.string()
    .notOneOf([''], 'Cannot be empty')
    .required('Department is required'),
  schoolID: Yup.string()
})

const initialFormValues = {
  firstName: '',
  middleName: '',
  lastName: '',
  schoolName: '',
  schoolID: '',
  department: '',
  departments: [],
  directoryUrl: '',
  checkbox: false,
  recaptcha: ''
}

const AddProfessorForm = ({ isNewForms }) => {
  const formRef = useRef(null)
  const history = useHistory()

  if (!isNewForms) return null

  const [submissionError, setSubmissionError] = useState(null)

  const handleSubmit = values => {
    submitProfessorForm({
      values,
      history,
      setSubmissionError
    })
  }

  return (
    <FormController
      initialValues={initialFormValues}
      validationSchema={schema}
      onSubmit={handleSubmit}
      validateOnChange={false}
      validateOnBlur={false}
    >
      <React.Fragment>
        <AddProfessorFormContent />
        <FormSubmitButton
          type="submit"
          name="recaptcha"
          formRef={formRef}
          action={ReCAPTCHA.ACTIONS.ADD_NEW_PROFESSOR}
        />
        {/* We send a request to the server to validate ReCAPTCHA so this error field captures that */}
        {submissionError && <Error error={submissionError} />}
      </React.Fragment>
    </FormController>
  )
}

AddProfessorForm.propTypes = {
  isNewForms: PropTypes.bool
}

export default AddProfessorForm
