import React from 'react'
import styled, { css } from 'styled-components'
import { createFragmentContainer, graphql } from 'react-relay'
import { breakpointLargeAndBelow } from '@StyledComponents/theme/helpers/breakpoints'
import TeacherTypes from '@types/Teacher.types'
import { poppinsBoldest, poppinsBold } from '@StyledComponents/theme/typesets'
import NumRatingsLink from './NumRatingsLink'
import JumpToRatings from './JumpToRatings'

export const NumRatings = styled.div`
  margin-bottom: 25px;
  padding-top: 10px;

  ${poppinsBold};

  a {
    color: ${props => props.theme.color.default};
  }
`

export const AvgRating = styled.div`
  display: flex;
  justify-content: space-between;
`

export const Numerator = styled.div`
  font-size: ${props => props.theme.fontSize.jumbo};
  margin-right: 11px;

  ${poppinsBoldest};

  ${breakpointLargeAndBelow(
    css`
      font-size: ${props => props.theme.fontSize.huge};
    `
  )}
`

const AvgRatingWrapper = styled.div`
  display: flex;
`

/* TODO: ADJUST DENOMINATOR FOR WHEN FOR POPPINS IS ADDED IN FOR NUMERATOR FONT */

export const Denominator = styled.div`
  color: ${props => props.theme.color.fadedDefault};
  font-size: ${props => props.theme.fontSize.large};
  font-weight: ${props => props.theme.fontWeight.bold};
  position: relative;
  top: 15px;

  ${breakpointLargeAndBelow(
    css`
      top: 7px;
    `
  )}
`

export function RatingValue({ teacher }) {
  const { avgRating, numRatings } = teacher
  if (avgRating === null || numRatings === null) return null

  return (
    <div>
      <AvgRating>
        <AvgRatingWrapper>
          <Numerator>{numRatings !== 0 ? avgRating : 'N/A'}</Numerator>
          <Denominator>/ 5</Denominator>
        </AvgRatingWrapper>
        <JumpToRatings />
      </AvgRating>
      <NumRatings>
        <NumRatingsLink teacher={teacher} />
      </NumRatings>
    </div>
  )
}

RatingValue.propTypes = {
  teacher: TeacherTypes
}

export default createFragmentContainer(RatingValue, {
  teacher: graphql`
    fragment RatingValue_teacher on Teacher {
      avgRating
      numRatings
      ...NumRatingsLink_teacher
    }
  `
})
