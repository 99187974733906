import { useLocation, matchPath } from 'react-router'
import useGlobalContext from './useGlobalContext'

export default function useCurrentRoute() {
  try {
    const location = useLocation()
    const { routes } = useGlobalContext()
    return routes?.find(rc => matchPath(location.pathname, rc)) || {}
  } catch {
    // Rather than refactor all tests containing ads (which use this function)
    // we allow it to fail gracefully
    return {}
  }
}
