/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type TeacherFeedback_teacher$ref: FragmentReference;
declare export opaque type TeacherFeedback_teacher$fragmentType: TeacherFeedback_teacher$ref;
export type TeacherFeedback_teacher = {|
  +numRatings: ?number,
  +avgDifficulty: ?number,
  +wouldTakeAgainPercent: ?number,
  +$refType: TeacherFeedback_teacher$ref,
|};
export type TeacherFeedback_teacher$data = TeacherFeedback_teacher;
export type TeacherFeedback_teacher$key = {
  +$data?: TeacherFeedback_teacher$data,
  +$fragmentRefs: TeacherFeedback_teacher$ref,
};
*/


const node/*: ReaderFragment*/ = {
  "kind": "Fragment",
  "name": "TeacherFeedback_teacher",
  "type": "Teacher",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "numRatings",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "avgDifficulty",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "wouldTakeAgainPercent",
      "args": null,
      "storageKey": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '4dc015e1c9779b977457bb32733382be';
module.exports = node;
