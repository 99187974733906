import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import MODALS from '@enums/MODALS'
import TextInput from '@components/Form/TextInput'
import { graphql } from 'react-relay'
import { breakpointSmallAndBelow } from '@StyledComponents/theme/helpers/breakpoints'
import { ButtonAttributes } from '@StyledComponents/Buttons'
import FormButton from '@components/Form/FormButton'
import { poppinsBoldest3x } from '@StyledComponents/theme/typesets'
import Form from '../Form/Form'

import AuthModal from './Templates/SmallModal'

const ForgotPasswordHeader = styled.div`
  line-height: 45px;
  margin-bottom: 16px;

  ${poppinsBoldest3x};

  ${breakpointSmallAndBelow(css`
    font-size: ${({ theme }) => theme.fontSize.xlarge};
    line-height: 39px;
  `)}
`

const ForgotPasswordStatusMessage = styled.div`
  font-size: ${({ theme }) => theme.fontSize.medium};
  line-height: 24px;
  margin-bottom: 9px;

  ${breakpointSmallAndBelow(css`
    font-size: ${({ theme }) => theme.fontSize.default};
    line-height: 16px;
  `)}
`

const SubmitButton = styled(FormButton)`
  margin-top: 23px;
  ${ButtonAttributes};
  width: 100%;
`

export const getStatusMessage = (isEmailSent, email) => {
  if (isEmailSent && email) {
    return (
      <ForgotPasswordStatusMessage>
        We&apos;ve sent a password reset link to <b>{email}</b>. Follow the instructions to reset
        your password.
      </ForgotPasswordStatusMessage>
    )
  }

  return (
    <ForgotPasswordStatusMessage>
      Enter your email to reset your password.
    </ForgotPasswordStatusMessage>
  )
}

const mutation = graphql`
  mutation ForgotPasswordModalMutation($email: String!) {
    resetPasswordRequest(email: $email) {
      validation {
        ...FormController_validation
      }
    }
  }
`

const ForgotPasswordModalForm = ({ setIsEmailSent, isEmailSent, setEmail }) => {
  if (isEmailSent) return null

  return (
    <div>
      <Form
        onSuccess={() => setIsEmailSent(true)}
        getVariables={model => {
          setEmail(model.email)
          return { ...model }
        }}
        mutation={mutation}
      >
        <TextInput name="email" label="Email" />
        <SubmitButton type="submit">Reset Password</SubmitButton>
      </Form>
    </div>
  )
}

export default function ForgotPasswordModal() {
  const [isEmailSent, setIsEmailSent] = useState(false)
  const [email, setEmail] = useState(null)

  const onClose = () => {
    setIsEmailSent(false)
  }

  return (
    <AuthModal modal={MODALS.FORGOT_PASSWORD} onClose={onClose} isSlidingModal>
      <ForgotPasswordHeader>Forgot Password</ForgotPasswordHeader>
      {getStatusMessage(isEmailSent, email)}
      <ForgotPasswordModalForm
        setIsEmailSent={setIsEmailSent}
        isEmailSent={isEmailSent}
        setEmail={setEmail}
      />
    </AuthModal>
  )
}

ForgotPasswordModalForm.propTypes = {
  setIsEmailSent: PropTypes.func,
  isEmailSent: PropTypes.bool,
  setEmail: PropTypes.func
}
