/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type RatingHeader_rating$ref: FragmentReference;
declare export opaque type RatingHeader_rating$fragmentType: RatingHeader_rating$ref;
export type RatingHeader_rating = {|
  +legacyId: ?number,
  +date: ?string,
  +class: ?string,
  +helpfulRating: ?number,
  +clarityRating: ?number,
  +isForOnlineClass: ?boolean,
  +$refType: RatingHeader_rating$ref,
|};
export type RatingHeader_rating$data = RatingHeader_rating;
export type RatingHeader_rating$key = {
  +$data?: RatingHeader_rating$data,
  +$fragmentRefs: RatingHeader_rating$ref,
};
*/


const node/*: ReaderFragment*/ = {
  "kind": "Fragment",
  "name": "RatingHeader_rating",
  "type": "Rating",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "legacyId",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "date",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "class",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "helpfulRating",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "clarityRating",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "isForOnlineClass",
      "args": null,
      "storageKey": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '63553c83d10106a4ae3c28e2338d7f5d';
module.exports = node;
