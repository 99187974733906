/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type CompareSchools_secondarySchool$ref: FragmentReference;
declare export opaque type CompareSchools_secondarySchool$fragmentType: CompareSchools_secondarySchool$ref;
export type CompareSchools_secondarySchool = {|
  +name: ?string,
  +city: ?string,
  +state: ?string,
  +country: ?string,
  +numRatings: ?number,
  +avgRatingRounded: ?any,
  +legacyId: ?number,
  +summary: ?{|
    +campusCondition: ?number,
    +campusLocation: ?number,
    +careerOpportunities: ?number,
    +clubAndEventActivities: ?number,
    +foodQuality: ?number,
    +internetSpeed: ?number,
    +libraryCondition: ?number,
    +schoolReputation: ?number,
    +schoolSafety: ?number,
    +schoolSatisfaction: ?number,
    +socialActivities: ?number,
  |},
  +$refType: CompareSchools_secondarySchool$ref,
|};
export type CompareSchools_secondarySchool$data = CompareSchools_secondarySchool;
export type CompareSchools_secondarySchool$key = {
  +$data?: CompareSchools_secondarySchool$data,
  +$fragmentRefs: CompareSchools_secondarySchool$ref,
};
*/


const node/*: ReaderFragment*/ = {
  "kind": "Fragment",
  "name": "CompareSchools_secondarySchool",
  "type": "School",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "name",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "city",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "state",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "country",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "numRatings",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "avgRatingRounded",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "legacyId",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "summary",
      "storageKey": null,
      "args": null,
      "concreteType": "SchoolSummary",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "campusCondition",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "campusLocation",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "careerOpportunities",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "clubAndEventActivities",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "foodQuality",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "internetSpeed",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "libraryCondition",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "schoolReputation",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "schoolSafety",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "schoolSatisfaction",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "socialActivities",
          "args": null,
          "storageKey": null
        }
      ]
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = 'b95d7cf4345c28f3f8e46b23c4c0ed9e';
module.exports = node;
