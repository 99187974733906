import React from 'react'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'
import { RATING_LEVEL_LIMITS, RATING_LEVEL_TEXTS } from '@enums/RATING_SCALE_ENUMS'
import { breakpointSmallPlusAndBelow } from '@StyledComponents/theme/helpers/breakpoints'

const SliderStatusContainer = styled.div`
  display: flex;
  justify-content: center;
`
const StyledSliderStatus = styled.div`
  color: ${props => props.theme.color.gray9};
  font-family: ${props => props.theme.fontFamily.helveticaNeue};
  font-size: ${props => props.theme.fontSize.medium};
  line-height: 24px;
  margin-top: 8px;
  opacity: ${props => (props.isDisplayed ? '1' : '0')};
  overflow: hidden;
  text-align: ${props => () => {
    switch (props.order) {
      case 1:
        return 'right'
      case 3:
        return 'left'
      default:
        return 'center'
    }
  }};
  transition: opacity 200ms ease-out;
  width: ${props => props.width};

  ${props =>
    props.isLit &&
    css`
      color: ${props.theme.color.default};
    `};

  ${breakpointSmallPlusAndBelow(css`
    width: ${props => (props.order === 2 ? '113px' : '40%')};
    ${props => props.order === 2 && 'position: absolute'};
    text-align: ${props => () => {
      switch (props.order) {
        case 1:
          return 'left'
        case 3:
          return 'right'
        default:
          return 'center'
      }
    }};
  `)}
`

export default function RatingSliderStatus({ value, isLit, type }) {
  const levelTexts = RATING_LEVEL_TEXTS[type]
    ? RATING_LEVEL_TEXTS[type]
    : RATING_LEVEL_TEXTS.QUALITY

  function getValueText(val) {
    return levelTexts[val] ? levelTexts[val] : ' '
  }
  return (
    <SliderStatusContainer>
      <StyledSliderStatus sideStatus isDisplayed={value === 0} order={1} width="113px">
        {getValueText(RATING_LEVEL_LIMITS.MIN)}
      </StyledSliderStatus>
      <StyledSliderStatus
        centerStatus
        isDisplayed={value > 0}
        order={2}
        isLit={isLit}
        width="278px"
      >
        {getValueText(value)}
      </StyledSliderStatus>
      <StyledSliderStatus sideStatus isDisplayed={value === 0} order={3} width="113px">
        {getValueText(RATING_LEVEL_LIMITS.MAX)}
      </StyledSliderStatus>
    </SliderStatusContainer>
  )
}

RatingSliderStatus.propTypes = {
  type: PropTypes.string,
  value: PropTypes.number,
  isLit: PropTypes.bool
}
