import ROUTE from '@enums/ROUTE'
import { useLocation } from 'react-router'

export const webViewPageRoutes = [ROUTE.LEGAL_PRIVACY, ROUTE.LEGAL_TERMS, ROUTE.LEGAL_COPYRIGHT]

Object.freeze(webViewPageRoutes)

const useIsWebViewPage = () => {
  const location = useLocation()
  const params = new URLSearchParams(location.search)

  const isWebViewFriendly = params.get('webview') === 'true'

  return webViewPageRoutes.includes(location.pathname) && isWebViewFriendly
}

export default useIsWebViewPage
