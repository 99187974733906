import React from 'react'
import InfoPage from '../components/PageTemplates/InfoPage'

export default function PageNotFound() {
  return (
    <InfoPage
      header="404"
      subheader="Page Not Found... maybe the dog ate it?"
      pageTitle="Page Not Found"
      headerSize="jumbo"
    />
  )
}
