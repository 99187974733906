import ROUTE from '@enums/ROUTE'
import { matchPath } from 'react-router'

/* eslint-disable import/prefer-default-export */
export const getVariables = location => {
  const match = matchPath(location.pathname, { path: ROUTE.NEW_CAMPUS_RATINGS })

  if (!match?.params?.sid) {
    return { id: '' }
  }

  return { id: btoa(`School-${match.params.sid}`) }
}

export const getCountryName = country => {
  /**
   * Country is returned from the server in the format "id-abbr-name"
   * ex: "5-SC-Scotland"
   */
  if (!country || !country.split('-')[2]) return null

  return country.split('-')[2]
}
