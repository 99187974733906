import React from 'react'
import { graphql } from 'relay-runtime'
import { Redirect, withRouter } from 'react-router-dom'
import ReactRouterPropTypes from 'react-router-prop-types'
import QueryWrapper from '@componentUtils/QueryWrapper'
import ROUTE from '@enums/ROUTE'
import useCompareProfessors from '@utils/useCompareProfessors'
import CompareProfessors from './CompareProfessors'
import { getVariables } from './CompareProfessors.utils'

const compareProfessorsQuery = graphql`
  query CompareProfessorsPageQuery(
    $primaryId: ID!
    $secondaryId: ID
    $skipPrimary: Boolean!
    $skipSecondary: Boolean!
  ) {
    primaryProfessor: node(id: $primaryId) @skip(if: $skipPrimary) {
      __typename
      ... on Teacher {
        ...CompareProfessors_primaryProfessor
      }
    }
    secondaryProfessor: node(id: $secondaryId) @skip(if: $skipSecondary) {
      __typename
      ... on Teacher {
        ...CompareProfessors_secondaryProfessor
      }
    }
  }
`

export const CompareProfessorsPage = ({ location }) => {
  if (!useCompareProfessors()) {
    return <Redirect to={ROUTE.HOME} />
  }

  const variables = getVariables(location)

  return (
    <QueryWrapper
      query={compareProfessorsQuery}
      component={CompareProfessors}
      variables={variables}
    />
  )
}

CompareProfessorsPage.propTypes = {
  location: ReactRouterPropTypes.location
}

const withRouterCompareProfessorsPage = withRouter(CompareProfessorsPage)
withRouterCompareProfessorsPage.serverQueries = [
  {
    query: compareProfessorsQuery,
    getVariables
  }
]

export default withRouterCompareProfessorsPage
