import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import { breakpointSmediumAndBelow } from '@StyledComponents/theme/helpers/breakpoints'
import { graphql } from 'react-relay'
import useGlobalContext from '@hooks/useGlobalContext'
import MODALS from '@enums/MODALS'
import { Button, TextButton } from '@StyledComponents/Buttons'
import Form from '@components/Form/Form'
import { poppinsBoldest } from '@StyledComponents/theme/typesets'
import AuthModal from './Templates/SmallModal'

export const StyledEmailConfirmation = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  padding: 43px 0 48px 0;
  width: 756px;

  ${breakpointSmediumAndBelow(
    css`
      width: 345px;
    `
  )}

  a {
    font-weight: ${props => props.theme.fontWeight.bold};
  }
`

export const EmailConfirmationHeaderArea = styled.div`
  margin-bottom: 18px;
  max-width: 100%;
  width: 440px;

  ${breakpointSmediumAndBelow(
    css`
      margin-bottom: 8px;
      width: 300px;
    `
  )}
`
export const EmailConfirmationHeader = styled.h1`
  font-size: ${props => props.theme.fontSize.xxlarge};
  margin-bottom: 12px;
  text-align: center;

  ${poppinsBoldest};

  ${breakpointSmediumAndBelow(
    css`
      font-size: ${props => props.theme.fontSize.xlarge};
    `
  )}
`
export const EmailConfirmationSubHeader = styled.h2`
  font-family: ${props => props.theme.fontFamily.helveticaNeue};
  font-size: ${props => props.theme.fontSize.medium};
  text-align: center;

  ${breakpointSmediumAndBelow(
    css`
      font-size: ${props => props.theme.fontSize.default};
      line-height: 22px;
    `
  )}
`
const CTAContainer = styled.div`
  margin: 10px auto 0;
  max-width: 100%;
  width: 310px;
`
export const EmailConfirmationFormTextFields = styled.div`
  align-content: space-between;
  display: flex;
  flex-direction: column;
`
export const TermsCheckLabel = styled.label`
  align-items: center;
  display: flex;
  height: 27px;
  justify-content: space-between;
  left: -7px;
  max-width: 100%;
  position: relative;
  width: 325px;
`

export const DismissButton = styled(Button)`
  font-size: ${props => props.theme.fontSize.medium};
  height: 48px;
  margin: 14px auto 18px;
  max-width: calc(100% - 40px);
  width: 305px;
`
const SuccessText = styled.div`
  color: ${props => props.theme.color.ratingLevelColors[5]};
  margin-top: 10px;
  min-height: 14px;
  text-align: center;
`
const SubmitButton = styled(TextButton)`
  font-size: ${props => props.theme.fontSize.medium};
  text-decoration: underline;
`
const StyledResendEmailForm = styled(Form)`
  align-items: baseline;
  display: flex;
  justify-content: center;
  margin-top: 50px;
`
const mutationQuery = graphql`
  mutation EmailConfirmationPromptModalMutation {
    resendEmailConfirmation {
      validation {
        success
        errors {
          messages
        }
      }
    }
  }
`
export function ResendEmailForm() {
  const [successMessage, setSuccessMessage] = useState(false)
  return (
    <React.Fragment>
      <StyledResendEmailForm mutation={mutationQuery} onSuccess={() => setSuccessMessage(true)}>
        <EmailConfirmationSubHeader>Didn&#39;t get an email?</EmailConfirmationSubHeader>
        <SubmitButton type="submit">Resend email</SubmitButton>
      </StyledResendEmailForm>
      <SuccessText>{successMessage ? 'Sent. Check your email again.' : null}</SuccessText>
    </React.Fragment>
  )
}

export default function EmailConfirmationPromptModal() {
  const { closeActiveModal } = useGlobalContext()

  return (
    <AuthModal modal={MODALS.CONFIRM_EMAIL_PROMPT}>
      <StyledEmailConfirmation>
        <EmailConfirmationHeaderArea>
          <EmailConfirmationHeader> Confirm your email</EmailConfirmationHeader>
          <EmailConfirmationSubHeader>
            We sent you an email to confirm your address, you&#39;ll need to confirm it before you
            can do this.
          </EmailConfirmationSubHeader>
        </EmailConfirmationHeaderArea>
        <CTAContainer>
          <DismissButton onClick={() => closeActiveModal()}>
            Ok, I&#39;ll check my email
          </DismissButton>
          <ResendEmailForm />
        </CTAContainer>
      </StyledEmailConfirmation>
    </AuthModal>
  )
}
