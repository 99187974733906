import React from 'react'
import InfoPage from '../components/PageTemplates/InfoPage'

export default function RatingNotFound() {
  return (
    <InfoPage
      header="Rating Not Found"
      subheader="We couldn't find the rating you were looking for. It may have been removed."
      pageTitle="Rating not found | Rate My Professors"
    />
  )
}
