import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { poppinsBoldest } from '@StyledComponents/theme/typesets'

const StyledFormHeader = styled.div`
  font-size: ${props => props.theme.fontSize.teacherNotes};
  margin-bottom: 35px;
  text-align: left;

  ${poppinsBoldest};
`

const FormHeader = ({ children }) => {
  return <StyledFormHeader>{children}</StyledFormHeader>
}

FormHeader.propTypes = {
  children: PropTypes.node
}

export default FormHeader
