/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type TeacherBookmark_teacher$ref = any;
type TeacherDepartment_teacher$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type NameTitle_teacher$ref: FragmentReference;
declare export opaque type NameTitle_teacher$fragmentType: NameTitle_teacher$ref;
export type NameTitle_teacher = {|
  +id: ?string,
  +firstName: ?string,
  +lastName: ?string,
  +department: ?string,
  +school: ?{|
    +legacyId: ?number,
    +name: ?string,
  |},
  +$fragmentRefs: TeacherDepartment_teacher$ref & TeacherBookmark_teacher$ref,
  +$refType: NameTitle_teacher$ref,
|};
export type NameTitle_teacher$data = NameTitle_teacher;
export type NameTitle_teacher$key = {
  +$data?: NameTitle_teacher$data,
  +$fragmentRefs: NameTitle_teacher$ref,
};
*/


const node/*: ReaderFragment*/ = {
  "kind": "Fragment",
  "name": "NameTitle_teacher",
  "type": "Teacher",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "id",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "firstName",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "lastName",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "department",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "school",
      "storageKey": null,
      "args": null,
      "concreteType": "School",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "legacyId",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "name",
          "args": null,
          "storageKey": null
        }
      ]
    },
    {
      "kind": "FragmentSpread",
      "name": "TeacherDepartment_teacher",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "TeacherBookmark_teacher",
      "args": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = 'e5f18d9052a07705b6de155d34eb43a6';
module.exports = node;
