import React from 'react'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'
import {
  breakpointSmediumAndBelow,
  breakpointMediumAndBelow
} from '@StyledComponents/theme/helpers/breakpoints'
import DisplaySlider from '@components/StyledComponents/DisplaySlider'

const SchoolRatingSummaryContainer = styled.div`
  column-gap: 10%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  height: 190px;
  justify-content: space-between;
  margin-top: 16px;
  padding-right: 36px;
  width: 100%;
  ${breakpointSmediumAndBelow(css`
    margin-top: 32px;
    height: unset;
  `)}
  ${breakpointMediumAndBelow(css`
    column-gap: 10%;
    padding-right: 0;
  `)}
`

const SchoolRatingSummary = ({ ratingList }) => {
  const generateCategories = () => {
    const categories = ratingList.map(rating => {
      return <DisplaySlider key={rating.name} rating={rating} />
    })

    return categories
  }

  const categoriesList = generateCategories()

  return (
    <div>
      <SchoolRatingSummaryContainer>{categoriesList}</SchoolRatingSummaryContainer>
    </div>
  )
}

SchoolRatingSummary.propTypes = {
  ratingList: PropTypes.arrayOf(
    PropTypes.shape({
      score: PropTypes.number,
      name: PropTypes.string
    })
  )
}

export default SchoolRatingSummary
