import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { createFragmentContainer, graphql } from 'react-relay'
import commitMutation from '@relay/commitMutation'
import RatingTypes from '@types/Rating.types'
import TeacherTypes from '@types/Teacher.types'
import TextArea from '@StyledComponents/TextArea'
import ROUTE from '@enums/ROUTE'
import { Button, TextButton } from '@StyledComponents/Buttons'
import { convertUnixToDB } from '@utils/date'
import TRACKING_EVENT from '@enums/TRACKING_EVENT'
import Link from '../Link'

export const NOTE_MAX_LENGTH = 350

const StyledProfessorNoteEditor = styled.form`
  display: flex;
  flex-direction: column;
  margin: 10px 0;
`

export const Title = styled.span`
  font-family: ${props => props.theme.fontFamily.avenir};
  font-size: ${props => props.theme.fontSize.medium};
  font-weight: ${props => props.theme.fontWeight.boldest};
  margin-bottom: 10px;
`

export const LegalText = styled.span`
  font-family: ${props => props.theme.fontFamily.helveticaNeue};
  margin: 16px 0;
`

export const LegalLink = styled(Link).attrs(props => ({ ...props, target: '_blank' }))`
  font-weight: ${props => props.theme.fontWeight.bold};
  text-decoration: none;
`

export const ErrorText = styled.span`
  color: ${props => props.theme.color.error};
  font-family: ${props => props.theme.fontFamily.avenir};
  line-height: 1.71;
`

const ButtonContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-top: 8px;
  width: 100%;
`

export const CancelButton = styled(TextButton)`
  font-size: ${props => props.theme.fontSize.medium};
  margin: 14px 0;
`

/**
 * teacherNotesInput:
 *  id: ID
 *  teacherId: ID:
 *  ratingId: ID
 *  class: String
 *  comment: String
 */
const mutationQuery = graphql`
  mutation ProfessorNoteEditorMutation($teacherNote: teacherNotesInput!) {
    teacherNoteCreate(teacherNote: $teacherNote) {
      errors
      teacherNote {
        id
        teacherId
        ...ProfessorNote_note
        ...ProfessorNoteHeader_note
      }
    }
  }
`

const updater = (store, noteStub) => {
  const now = Date.now()
  const nowDB = convertUnixToDB(now)

  const payload = store.getRootField('teacherNoteCreate')
  if (!payload) return

  if (payload.getValue('errors')) return

  const newNoteRecord = payload.getLinkedRecord('teacherNote')
  newNoteRecord.setValue(nowDB, 'updatedAt')

  if (!noteStub.id) {
    newNoteRecord.setValue(nowDB, 'createdAt')
  }

  const ratingRecord = store.get(noteStub.ratingId)
  if (!ratingRecord) return // Something weird has happened, abort.
  ratingRecord.setLinkedRecord(newNoteRecord, 'teacherNote')
}

const submit = (rating, teacher, comment, setErrors, setRequestActive, onSuccess) => {
  const teacherNote = {
    ratingId: rating.id,
    teacherId: teacher.id,
    class: rating.class,
    comment
  }
  if (rating.teacherNote) teacherNote.id = rating.teacherNote.id
  commitMutation({
    mutation: mutationQuery,
    variables: { teacherNote },
    updater: store => updater(store, teacherNote),
    onCompleted: (_, errors) => {
      setRequestActive(false)
      if (errors) {
        console.error(errors)

        setErrors(errors.map(e => e.message).join('\n'))
      } else {
        onSuccess()
      }
    }
  })
}

const getSubmitEvent = isEdit =>
  isEdit ? TRACKING_EVENT.REPLY_SUBMIT_EDIT : TRACKING_EVENT.REPLY_SUBMIT_NEW
const getCancelEvent = isEdit =>
  isEdit ? TRACKING_EVENT.REPLY_CANCEL_EDIT : TRACKING_EVENT.REPLY_CANCEL_NEW

export const ProfessorNoteEditor = ({ rating, teacher, setReplyModeActive }) => {
  const edit = !!rating.teacherNote
  const [value, setValue] = useState(edit ? rating.teacherNote.comment : '')
  const [errors, setErrors] = useState('')
  const [requestActive, setRequestActive] = useState(false)

  const onChange = e => setValue(e.target.value)
  const onSuccess = () => {
    setReplyModeActive(false)
  }
  const onSubmit = e => {
    e.preventDefault()
    setRequestActive(true)
    submit(rating, teacher, value, setErrors, setRequestActive, onSuccess)
  }

  return (
    <StyledProfessorNoteEditor onSubmit={onSubmit}>
      <Title>{edit && 'Edit your '}Comment</Title>
      <TextArea value={value} onChange={onChange} rows={8} maxlength={NOTE_MAX_LENGTH} />
      {errors && <ErrorText>{errors}</ErrorText>}
      <LegalText>
        By clicking the &apos;Submit&apos; button, I acknowledge that I have read and agreed to the
        Rate My Professors <LegalLink to={ROUTE.LEGAL_SITE_GUIDELINES}>Site Guidelines</LegalLink>,{' '}
        <LegalLink to={ROUTE.LEGAL_TERMS}>Terms of Use</LegalLink> and{' '}
        <LegalLink to={ROUTE.LEGAL_PRIVACY}>Privacy Policy</LegalLink>. Submitted data becomes the
        property of RateMyProfessors.com. IP addresses are logged.
      </LegalText>
      <ButtonContainer>
        <Button
          disabled={value.length > NOTE_MAX_LENGTH}
          isLoading={requestActive}
          trackingLabel={getSubmitEvent(edit)}
          type="submit"
        >
          Submit{edit && ' Revision'}
        </Button>
        <CancelButton
          trackingLabel={getCancelEvent(edit)}
          disabled={requestActive}
          onClick={() => setReplyModeActive(false)}
        >
          Cancel
        </CancelButton>
      </ButtonContainer>
    </StyledProfessorNoteEditor>
  )
}

ProfessorNoteEditor.propTypes = {
  rating: RatingTypes,
  teacher: TeacherTypes,
  setReplyModeActive: PropTypes.func
}

export default createFragmentContainer(ProfessorNoteEditor, {
  rating: graphql`
    fragment ProfessorNoteEditor_rating on Rating {
      id
      legacyId
      class
      teacherNote {
        id
        teacherId
        comment
      }
    }
  `,
  teacher: graphql`
    fragment ProfessorNoteEditor_teacher on Teacher {
      id
    }
  `
})
