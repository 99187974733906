/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type ProfessorNoteEditor_teacher$ref: FragmentReference;
declare export opaque type ProfessorNoteEditor_teacher$fragmentType: ProfessorNoteEditor_teacher$ref;
export type ProfessorNoteEditor_teacher = {|
  +id: ?string,
  +$refType: ProfessorNoteEditor_teacher$ref,
|};
export type ProfessorNoteEditor_teacher$data = ProfessorNoteEditor_teacher;
export type ProfessorNoteEditor_teacher$key = {
  +$data?: ProfessorNoteEditor_teacher$data,
  +$fragmentRefs: ProfessorNoteEditor_teacher$ref,
};
*/


const node/*: ReaderFragment*/ = {
  "kind": "Fragment",
  "name": "ProfessorNoteEditor_teacher",
  "type": "Teacher",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "id",
      "args": null,
      "storageKey": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '1c80161537ffb55d55b01f908427ce01';
module.exports = node;
