import React from 'react'
import PropTypes from 'prop-types'
import getRMPRoot from '@utils/getRMPRoot'

export default function OldRMPLink({ to, children, ...props }) {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <a href={`${getRMPRoot()}${to}`} {...props}>
      {children}
    </a>
  )
}

OldRMPLink.propTypes = {
  to: PropTypes.string,
  children: PropTypes.node
}
