import React from 'react'
import styled from 'styled-components'
import { createFragmentContainer, graphql } from 'react-relay'
import Tag from '@StyledComponents/Tag'
import RatingTypes from '@types/Rating.types'

const StyledTags = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 12px;
`

export function RatingTags({ rating }) {
  const { ratingTags } = rating
  if (!ratingTags || ratingTags === '') return null
  const tags = ratingTags.includes('--') ? ratingTags.split('--') : ratingTags.split(',')
  return (
    <StyledTags>
      {tags.map(tag => (
        <Tag key={`tag-${tag}`}>{tag}</Tag>
      ))}
    </StyledTags>
  )
}

RatingTags.propTypes = {
  rating: RatingTypes
}

export default createFragmentContainer(RatingTags, {
  rating: graphql`
    fragment RatingTags_rating on Rating {
      ratingTags
    }
  `
})
