import React from 'react'
import styled, { css } from 'styled-components'
import { graphql } from 'react-relay'
import UserTypes from '@types/User.types'
import {
  applyBreakpointMin,
  breakpointMediumAndBelow,
  breakpointSmallAndBelow
} from '@StyledComponents/theme/helpers/breakpoints'
import PropTypes from 'prop-types'
import QueryWrapper from '@componentUtils/QueryWrapper'
import PageWrapper from '@components/PageWrapper'
import SavedProfessorsList from '@components/SavedProfessorsList'

const SavedProfessorsPage = styled(PageWrapper)`
  align-items: center;
  display: inline-flex;
  flex-direction: column;

  ${applyBreakpointMin(
    'large',
    css`
      max-width: none;
    `
  )}
`

export const SavedProfessorsListArea = styled.div`
  margin: 20px 0;
  width: ${props => props.theme.mainColumnWidth.medium};

  ${breakpointMediumAndBelow(css`
    width: 100%;
    max-width: ${props => props.theme.mainColumnWidth.medium};
  `)};
  ${breakpointSmallAndBelow(css`
    max-width: ${props => props.theme.mainColumnWidth.small};
  `)}
`

const query = graphql`
  query UserSavedProfessorsPageQuery {
    user {
      Email
      ...SavedProfessorsList_user
    }
  }
`

export function UserSavedProfessors({ user, loading }) {
  if (loading) {
    return <PageWrapper>Loading...</PageWrapper>
  }

  return (
    <SavedProfessorsPage>
      <SavedProfessorsListArea>
        <SavedProfessorsList user={user} />
      </SavedProfessorsListArea>
    </SavedProfessorsPage>
  )
}

export const UserSavedProfessorsPage = () => {
  return <QueryWrapper query={query} component={UserSavedProfessors} />
}

UserSavedProfessors.propTypes = {
  user: UserTypes,
  loading: PropTypes.bool
}

UserSavedProfessorsPage.serverQueries = [
  {
    query
  }
]

export default UserSavedProfessorsPage
