/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type CardName_teacher$ref: FragmentReference;
declare export opaque type CardName_teacher$fragmentType: CardName_teacher$ref;
export type CardName_teacher = {|
  +firstName: ?string,
  +lastName: ?string,
  +$refType: CardName_teacher$ref,
|};
export type CardName_teacher$data = CardName_teacher;
export type CardName_teacher$key = {
  +$data?: CardName_teacher$data,
  +$fragmentRefs: CardName_teacher$ref,
};
*/


const node/*: ReaderFragment*/ = {
  "kind": "Fragment",
  "name": "CardName_teacher",
  "type": "Teacher",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "firstName",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "lastName",
      "args": null,
      "storageKey": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = 'b39aa88f0df6f7cbd2f3fdad51ca9486';
module.exports = node;
