import React from 'react'
import styled, { css } from 'styled-components'
import {
  breakpointSmallPlusAndBelow,
  breakpointSmediumAndBelow
} from '@components/StyledComponents/theme/helpers/breakpoints'
import getEnv from '@utils/getEnv'
import mcgrawhillLogo from '@assets/images/logos/mcgrawhill-logo-2023.svg'
import McgrawhillPromo, { LogoMcgrawhill } from '@components/Promos/McgrawhillPromo'

export const StyledHomepageHeroMcgrawhillLogo = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 15px;
  width: 100%;
  ${breakpointSmediumAndBelow(css`
    justify-content: center;
  `)}
  a {
    height: 36px;
    min-width: 240px;
    ${breakpointSmallPlusAndBelow(css`
      min-width: 250px;
    `)}
  }
  ${LogoMcgrawhill} {
    background-image: url(${mcgrawhillLogo});
    display: block;
    height: 64px;
    min-width: 172px;
    position: static;
    ${breakpointSmallPlusAndBelow(css`
      height: 48px;
      min-width: 142px;
    `)}
  }
`

const HomepageMcgrawhillLogo = props => {
  if (getEnv('REACT_APP_SHOW_MCGRAWHILL') !== 'true') return null
  return (
    <StyledHomepageHeroMcgrawhillLogo>
      <McgrawhillPromo {...props} />
    </StyledHomepageHeroMcgrawhillLogo>
  )
}

export default HomepageMcgrawhillLogo
