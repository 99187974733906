import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { poppinsNormal } from '@StyledComponents/theme/typesets'

const ProfessorSignUpSuccessTitle = styled.div`
  ${poppinsNormal};
  font-size: 24px;
  line-height: 42px;
  margin-top: 42px;
`

const ProfessorSignUpSuccessBody = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.poppins};
  font-size: 14px;
  line-height: 23px;
  margin-top: 36px;
`

const ProfessorSignUpSuccessFooter = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.poppins};
  font-size: 14px;
  line-height: 23px;
  margin-top: 36px;
`

const ProfessorSignUpSuccessContainer = styled.div`
  margin: auto;
  width: 100%;
`

export default function ProfessorSignUpSuccess({ email }) {
  return (
    <ProfessorSignUpSuccessContainer>
      <ProfessorSignUpSuccessTitle>
        Your Certified Professor account has been created!
      </ProfessorSignUpSuccessTitle>
      <ProfessorSignUpSuccessBody>
        {`Moderators still need to review your account and an email will be sent to
        ${email} when the account is approved.`}
      </ProfessorSignUpSuccessBody>
      <ProfessorSignUpSuccessFooter>
        Contact us at support@ratemyprofessors.com if you have any questions
      </ProfessorSignUpSuccessFooter>
    </ProfessorSignUpSuccessContainer>
  )
}

ProfessorSignUpSuccess.propTypes = {
  email: PropTypes.string
}
