import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import TOOLTIPS from '@enums/TOOLTIPS'
import { TrackingEventTypes } from '@enums/TRACKING_EVENT'
import Share from '@assets/images/js/Share'
import IconButton from './IconButton'

export const SHARERATING_TEST_ID = 'sharerating_test_id'

export const StyledShareRating = styled(IconButton)`
  cursor: ${props => (props.disabled ? 'default' : 'pointer')};
  text-decoration: none;
`

export const ShareWrapper = styled.div`
  align-items: center;
  display: flex;
  span {
    color: ${props => props.theme.color.gray8};
    font-style: italic;
    padding-right: 12px;
  }
`

const ShareRating = ({ onClick, trackingLabel, className, type }) => {
  return (
    <StyledShareRating
      id="share-rating"
      className={className}
      type={type}
      trackingLabel={trackingLabel}
      data-tooltip
      data-tip="Share this rating"
      data-for={TOOLTIPS.GLOBAL_TOOLTIP}
      data-testid={SHARERATING_TEST_ID}
      onClick={onClick}
    >
      <ShareWrapper>{Share({ color: 'black' })}</ShareWrapper>
    </StyledShareRating>
  )
}

ShareRating.propTypes = {
  className: PropTypes.string,
  trackingLabel: TrackingEventTypes,
  type: PropTypes.string,
  onClick: PropTypes.func
}

export default ShareRating
