import styled from 'styled-components'

const VerticalSeparator = styled.div`
  background: ${props => props.theme.color.default};
  margin: 0 5px;
  max-height: 100%;
  min-height: 100%;
  width: 1px;
`

export default VerticalSeparator
