/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type NumRatingsLink_teacher$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type RatingValue_teacher$ref: FragmentReference;
declare export opaque type RatingValue_teacher$fragmentType: RatingValue_teacher$ref;
export type RatingValue_teacher = {|
  +avgRating: ?number,
  +numRatings: ?number,
  +$fragmentRefs: NumRatingsLink_teacher$ref,
  +$refType: RatingValue_teacher$ref,
|};
export type RatingValue_teacher$data = RatingValue_teacher;
export type RatingValue_teacher$key = {
  +$data?: RatingValue_teacher$data,
  +$fragmentRefs: RatingValue_teacher$ref,
};
*/


const node/*: ReaderFragment*/ = {
  "kind": "Fragment",
  "name": "RatingValue_teacher",
  "type": "Teacher",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "avgRating",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "numRatings",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "FragmentSpread",
      "name": "NumRatingsLink_teacher",
      "args": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '9121d2b2dfce8a4706a24ad1edd92594';
module.exports = node;
