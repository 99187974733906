import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import GradeSquare from '@components/StyledComponents/GradeSquare'
import { helveticaBold, helveticaLight } from '@components/StyledComponents/theme/typesets'
import CompareSchoolTypes from '@types/CompareSchool.types'
import Link, { LINK_TYPE } from '@components/Link'
import roundToOneDecimal from '@utils/roundToOneDecimal'
import ROUTE from '@enums/ROUTE'
import TeacherTypes from '@types/Teacher.types'
import SchoolTypes from '@types/School.types'
import parseAndSanitize from '@utils/parseAndSanitize'
import { StyledDepartmentLink } from '@components/TeacherInfo/TeacherDepartment'

const HeaderBoxContainer = styled.div`
  height: 100%;
  position: relative;
`

const ProfessorName = styled.div`
  font-size: ${({ theme }) => theme.fontSize.teacherCardName};
  ${helveticaBold}
`

const ProfessorRatingsDetails = styled.div`
  color: ${props => props.theme.color.gray17};
  font-size: 14px;
  margin-bottom: 8px;
  margin-top: 8px;
  ${helveticaLight}
`

const SchoolName = styled.div`
  font-size: 14px;
  line-height: 20px;

  ${props =>
    props.underline &&
    css`
      &:hover {
        cursor: pointer;
        text-decoration: underline;
      }
    `}
  ${helveticaBold}
`

const ProfessorRatingSquareContainer = styled.div`
  display: inline-block;
  ${props => (props.includeMargin ? `margin-top: 16px;` : null)}
`

const StyledLink = styled(Link)``

const SchoolLink = ({ children, school }) => {
  return (
    <StyledLink
      type={LINK_TYPE.INTERNAL}
      to={ROUTE.NEW_CAMPUS_RATINGS.replace(':sid', school.legacyId)}
    >
      {children}
    </StyledLink>
  )
}

SchoolLink.propTypes = {
  children: PropTypes.node,
  school: CompareSchoolTypes
}

const CompareProfessorsHeaderBox = ({ teacher, school }) => {
  return (
    <HeaderBoxContainer>
      <ProfessorName>{parseAndSanitize(`${teacher.firstName} ${teacher.lastName}`)}</ProfessorName>
      <ProfessorRatingSquareContainer includeMargin>
        <GradeSquare large grade={teacher.numRatings > 0 && roundToOneDecimal(teacher.avgRating)} />
      </ProfessorRatingSquareContainer>
      <ProfessorRatingsDetails overall>Overall Quality</ProfessorRatingsDetails>
      <StyledDepartmentLink
        to={`${ROUTE.TEACHER_SEARCH.replace(':sid?', teacher.school.legacyId)}?q=*&did=${
          teacher.departmentId
        }`}
      >
        <b>{teacher.department}</b>
      </StyledDepartmentLink>{' '}
      at{' '}
      <SchoolLink school={school}>
        <SchoolName underline>{school.name}</SchoolName>
      </SchoolLink>
    </HeaderBoxContainer>
  )
}

CompareProfessorsHeaderBox.propTypes = {
  teacher: TeacherTypes,
  school: SchoolTypes
}

export default CompareProfessorsHeaderBox
