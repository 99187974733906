import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import FieldErrors, { StyledFieldError } from './FieldErrors'

export const StyledFieldWrapper = styled.div`
  position: relative;
  text-align: left;

  ${StyledFieldError} {
    line-height: 24px;
  }
`
export const StyledErrorContainer = styled.div`
  position: relative;
`

/**
 * FieldWrapper is a wrapper for form fields which also renders their errors
 * which are pulled from the FormContext
 */
const FieldWrapper = ({ fieldName, children, className, helperText, setHasErrors = () => {} }) => {
  return (
    <StyledFieldWrapper className={className}>
      {children}
      <StyledErrorContainer>
        <FieldErrors fieldName={fieldName} setHasErrors={setHasErrors} helperText={helperText} />
      </StyledErrorContainer>
    </StyledFieldWrapper>
  )
}

FieldWrapper.propTypes = {
  fieldName: PropTypes.string.isRequired,
  className: PropTypes.string,
  children: PropTypes.node,
  setHasErrors: PropTypes.func,
  helperText: PropTypes.string
}

export default FieldWrapper
