import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import CheckboxSwitch from '@StyledComponents/CheckboxSwitch'
import FieldWrapper from './FieldWrapper'

export const FormSwitchWrapper = styled(FieldWrapper)`
  align-items: center;
  display: flex;
  height: 70px;
  justify-content: space-between;
`

export const FormSwitchLabel = styled.label`
  font-family: ${props => props.theme.fontFamily.avenirHeavy};
  font-size: ${props => props.theme.fontSize.medium};
  text-align: left;
`

export const SwitchWrapper = styled.div`
  align-items: center;
  display: flex;
  max-width: 455px;
  width: 100%;
`

export const SwitchText = styled.span`
  font-size: ${props => props.theme.fontSize.medium};
  margin-left: 15px;
  text-align: left;
`

const FormCheckboxSwitch = ({ name, label, text, checked, ...props }) => {
  return (
    <FormSwitchWrapper fieldName={name}>
      <FormSwitchLabel htmlFor={name}>{label}</FormSwitchLabel>
      <SwitchWrapper>
        <CheckboxSwitch name={name} {...props} checked={checked} />
        <SwitchText>{text}</SwitchText>
      </SwitchWrapper>
    </FormSwitchWrapper>
  )
}

FormCheckboxSwitch.propTypes = {
  name: PropTypes.string.isRequired,
  text: PropTypes.string,
  label: PropTypes.string.isRequired,
  checked: PropTypes.bool
}

export default FormCheckboxSwitch
