import React from 'react'
import styled, { css } from 'styled-components'
import { createFragmentContainer, graphql } from 'react-relay'

import { ButtonAttributes } from '@StyledComponents/Buttons'
import {
  breakpointSmallPlusAndBelow,
  breakpointMediumAndBelow
} from '@StyledComponents/theme/helpers/breakpoints'
import TeacherTypes from '@types/Teacher.types'
import TRACKING_EVENT from '@enums/TRACKING_EVENT'
import parseAndSanitize from '@utils/parseAndSanitize'
import RateTeacherLink from '@components/RateTeacherLink'
import useUserInfo from '@hooks/useUserInfo'
import USERTYPES from '@enums/USERTYPES'
import useCompareProfessors from '@utils/useCompareProfessors'
import CompareProfessorLink from '@components/CompareProfessorLink'
import IconButton from '@components/StyledComponents/IconButton'
import RatingValue from './RatingValue'
import NameTitle from './NameTitle'
import TeacherTags from './TeacherTags'
import NameLink from './NameLink'
import TeacherFeedback from './TeacherFeedback'

export const RateButton = styled(RateTeacherLink)`
  text-decoration: none;
  width: fit-content;

  ${ButtonAttributes};

  &:not(:disabled)&:hover {
    background: ${props => props.theme.color.darkMainBlue};
    transform: none;
  }
`

export const StyledTeacher = styled.div`
  flex: 1 0 40%;
  padding: 22px 0;
  text-align: left;

  ${breakpointMediumAndBelow(
    css`
      padding-right: 22px;
      padding-bottom: 25px;
    `
  )}

  ${RateButton} {
    margin-bottom: 24px;
  }
`

export const CompareButton = styled(CompareProfessorLink)`
  margin-bottom: 24px;
  margin-left: 12px;
  text-decoration: none;
  width: fit-content;

  ${ButtonAttributes};

  ${breakpointSmallPlusAndBelow(css`
    margin-left: 12px;
  `)}

  &:not(:disabled)&:hover {
    background: ${props => props.theme.color.darkMainBlue};
    transform: none;
  }
`

const RateIconButton = styled(IconButton.ArrowRight)`
  color: white;
`

const InfoButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`

export function TeacherInfo({ teacher }) {
  if (!teacher) return null
  const isCompareProfessorOn = useCompareProfessors()

  const user = useUserInfo()

  const isUserProf = user?.Type === USERTYPES.TEACHER

  const trackingLabel =
    teacher.numRatings === 0 ? TRACKING_EVENT.PROF_RATE_BUTTON_TOP : TRACKING_EVENT.PROF_RATE_BUTTON

  return (
    <StyledTeacher>
      <RatingValue teacher={teacher} />
      <NameTitle teacher={teacher} />
      <TeacherFeedback teacher={teacher} />
      {!isUserProf && isCompareProfessorOn && (
        <InfoButtonContainer>
          <RateButton id="rate-professor-btn" teacher={teacher} trackingLabel={trackingLabel} blue>
            <RateIconButton position="right">Rate</RateIconButton>
          </RateButton>
          <CompareButton id="compare-professor-btn" teacher={teacher} white secondary>
            Compare
          </CompareButton>
        </InfoButtonContainer>
      )}
      {!isUserProf && !isCompareProfessorOn && (
        <RateButton id="rate-professor-btn" teacher={teacher} trackingLabel={trackingLabel} blue>
          Rate Professor {parseAndSanitize(teacher.lastName)}
        </RateButton>
      )}
      <NameLink teacher={teacher} />
      <TeacherTags teacher={teacher} />
    </StyledTeacher>
  )
}

export default createFragmentContainer(TeacherInfo, {
  teacher: graphql`
    fragment TeacherInfo_teacher on Teacher {
      id
      lastName
      numRatings

      ...RatingValue_teacher
      ...NameTitle_teacher
      ...TeacherTags_teacher
      ...NameLink_teacher
      ...TeacherFeedback_teacher
      ...RateTeacherLink_teacher
      ...CompareProfessorLink_teacher
    }
  `
})

TeacherInfo.propTypes = {
  teacher: TeacherTypes
}
