import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'react-relay'
import Button from '@components/Button'
import styled from 'styled-components'
import QueryWrapper from '@components/utils/QueryWrapper'
import { GoogleButtonAttributes } from './Buttons'

const googleIconSrc = require('../../assets/images/google-logo.svg')

const oauthUrlQuery = graphql`
  query OauthButtonUrlQuery {
    oauthURL(provider: "google")
  }
`

export const OauthButton = styled(Button)`
  justify-content: center;
  margin-top: 24px;
  width: 100%;
  ${GoogleButtonAttributes}
`

const OauthContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 24px;
`

const OauthDivider = styled.div`
  border-bottom: 1px solid #d1d1d1;
  height: 18px;
  width: 100%;
`

const OauthText = styled.div`
  font-size: 16px;
  margin: 8px;
  min-width: 156px;
`

const GoogleIcon = styled.div`
  height: 24px;
  padding-right: 8px;
`

export function GoogleButton({ oauthURL, login }) {
  const displayText = `${login ? 'Login' : 'Sign up'} with Google`
  return (
    <OauthButton type="submit" onClick={() => window.open(oauthURL, '_self')}>
      <GoogleIcon>
        <img src={googleIconSrc} alt="Google" />
      </GoogleIcon>
      {displayText}
    </OauthButton>
  )
}

GoogleButton.propTypes = {
  oauthURL: PropTypes.string,
  login: PropTypes.bool
}

export const OauthButtonWrapper = ({ login }) => {
  return (
    <QueryWrapper
      query={oauthUrlQuery}
      component={node => <GoogleButton oauthURL={node.oauthURL} login={login} />}
    />
  )
}

OauthButtonWrapper.propTypes = {
  login: PropTypes.bool
}

export const OauthSection = ({ login }) => {
  const displayText = `Or ${login ? 'login' : 'sign up'} with email`
  return (
    <React.Fragment>
      <OauthButtonWrapper login={login} />
      <OauthContainer>
        <OauthDivider />
        <OauthText>{displayText}</OauthText>
        <OauthDivider />
      </OauthContainer>
    </React.Fragment>
  )
}

OauthSection.propTypes = {
  login: PropTypes.bool
}

export default OauthSection
