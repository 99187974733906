import PropTypes from 'prop-types'
import ProfessorNoteTypes from './ProfessorNote.types'

export const RatingAttributes = {
  id: PropTypes.string,
  legacyId: PropTypes.number,
  tid: PropTypes.string,
  sid: PropTypes.string,
  date: PropTypes.string,
  comment: PropTypes.string,
  helpfulRating: PropTypes.number,
  clarityRating: PropTypes.number,
  thumbsUpTotal: PropTypes.number,
  thumbsDownTotal: PropTypes.number,
  class: PropTypes.string,
  difficultyRating: PropTypes.number,
  wouldTakeAgain: PropTypes.number,
  attendanceMandatory: PropTypes.string,
  grade: PropTypes.string,
  note: ProfessorNoteTypes,
  isForCredit: PropTypes.bool,
  isOnlineClass: PropTypes.bool,
  teacherRatingTags: PropTypes.arrayOf(
    PropTypes.shape({
      legacyId: PropTypes.number,
      tagName: PropTypes.string,
      count: PropTypes.number
    })
  )
}

const RatingTypes = PropTypes.shape(RatingAttributes)

export const RatingList = PropTypes.shape({
  edges: PropTypes.arrayOf(
    PropTypes.shape({
      cursor: PropTypes.string,
      node: RatingTypes
    })
  ),
  pageInfo: PropTypes.shape({
    hasNextPage: PropTypes.bool
  })
})

export default RatingTypes
