import React from 'react'
import PropTypes from 'prop-types'
import { TOAST_EVENTS } from '@utils/toast'
import Toast, { ToastHeader, ToastDescription } from './Toast'

const EmailConfirmationReminder = ({ closeToast }) => {
  return (
    <Toast canDismiss toastEvent={TOAST_EVENTS.EMAIL_CONFIRMATION_REMINDER} closeToast={closeToast}>
      <ToastHeader>Confirm Email Address</ToastHeader>
      <ToastDescription>Don’t forget to Confirm your email address!</ToastDescription>
    </Toast>
  )
}

EmailConfirmationReminder.propTypes = {
  closeToast: PropTypes.func.isRequired
}

export default EmailConfirmationReminder
