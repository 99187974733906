const sanitizeQuery = query => {
  if (query === null || query === undefined) return ''
  let sanitizedQuery = query

  /**
   * If html is present, remove everything after the first html tag, blank queries sent to hermione are fine
   */
  const htmlRegex = /<[^>]*>/
  if (htmlRegex.test(query)) {
    // eslint-disable-next-line
    sanitizedQuery = query.split('<')[0]
  }

  /**
   * Remove all characters that aren't letters or spaces
   */
  const cleanedQuery = sanitizedQuery.replace(/[^a-zA-Z\s]/g, '').trim()

  return cleanedQuery
}

export default sanitizeQuery
