const rateTeacherModel = {
  courseCode: {
    name: 'class',
    label: 'Select Course Code'
  },
  onlineCourse: {
    name: 'online',
    label: 'This is an online course'
  },
  sliders: [
    {
      name: 'rating',
      label: 'Rate your professor',
      isRequired: true,
      type: 'QUALITY'
    },
    {
      name: 'difficulty',
      label: 'How difficult was this professor?',
      isRequired: true,
      type: 'DIFFICULTY'
    }
  ],
  radioButtons: [
    {
      name: 'wouldTakeAgain',
      label: 'Would you take this professor again?',
      isRequired: true
    },
    {
      name: 'forCredit',
      label: 'Was this class taken for credit?',
      isRequired: false
    },
    {
      name: 'usesTextbooks',
      label: 'Did this professor use textbooks?',
      isRequired: false
    },
    {
      name: 'attendanceMandatory',
      label: 'Was attendance mandatory?',
      isRequired: false
    }
  ],
  gradeDropdown: {
    label: 'Select grade received',
    name: 'grade',
    placeholder: 'Select grade',
    options: [
      { label: 'A+', value: 'A+' },
      { label: 'A', value: 'A' },
      { label: 'A-', value: 'A-' },
      { label: 'B+', value: 'B+' },
      { label: 'B', value: 'B' },
      { label: 'B-', value: 'B-' },
      { label: 'C+', value: 'C+' },
      { label: 'C', value: 'C' },
      { label: 'C-', value: 'C-' },
      { label: 'D+', value: 'D+' },
      { label: 'D', value: 'D' },
      { label: 'D-', value: 'D-' },
      { label: 'F', value: 'F' },
      { label: 'Audit/No Grade', value: 'Audit/No Grade' },
      { label: 'Drop/Withdrawal', value: 'Drop/Withdrawal' },
      { label: 'Incomplete', value: 'Incomplete' },
      { label: 'Not sure yet', value: 'Not sure yet' },
      { label: 'Rather not say', value: 'Rather not say' }
    ]
  },
  allTeacherTags: {
    name: 'tags',
    label: 'Select up to 3 tags'
  },
  writeReview: {
    label: 'Write a Review',
    subTitle:
      "Discuss the professor's professional abilities including teaching style and ability to convey the material clearly",
    name: 'comment',
    placeholder: 'What do you want other students to know about this professor?',
    guidelines: [
      'Your rating could be removed if you use profanity or derogatory terms.',
      "Don't claim that the professor shows bias or favoritism for or against students.",
      'Don’t forget to proof read!'
    ]
  }
}

export default rateTeacherModel
