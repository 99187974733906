import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { ButtonAttributes } from '@StyledComponents/Buttons'
import { USER_ACCOUNT_ROUTE } from '@enums/ROUTE'
import MODALS from '@enums/MODALS'
import { poppinsBoldest3x } from '@StyledComponents/theme/typesets'
import ModalButtonLink, { StyledButton, StyledLink } from '@components/ModalButtonLink'
import useUserInfo from '@hooks/useUserInfo'
import { Link } from 'react-router-dom'
import CTAImagePrompts from '@StyledComponents/CTAImagePrompts'

const CTAButtonAttributes = css`
  margin: 0 auto;
  max-width: 196px;
  min-width: 196px;
  padding: 14px 22px;
  text-decoration: none;
`

const StyledHomepageCTA = styled.div`
  margin: 0 auto;
  max-width: 1280px;
  width: 100%;
  ${StyledButton},
  ${StyledLink} {
    ${CTAButtonAttributes}

    ${ButtonAttributes}
    &:hover {
      cursor: pointer;
    }
  }
`

const CTAHeader = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.poppins};
  margin-bottom: 88px;
  text-align: center;
`

const CTAMainHeader = styled.div`
  margin-bottom: 17px;

  ${poppinsBoldest3x};
`

const CTASubheader = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.poppins};
  font-size: ${({ theme }) => theme.fontSize.xlarge};
`

const CTALink = styled(Link)`
  ${ButtonAttributes}
  ${CTAButtonAttributes}

  width: fit-content;
`

const CTAButton = ({ isLoggedIn }) => {
  if (isLoggedIn) {
    return <CTALink to={USER_ACCOUNT_ROUTE.RATINGS}>My Ratings</CTALink>
  }

  return <ModalButtonLink modal={MODALS.SIGN_UP_FORM}>Sign up now!</ModalButtonLink>
}

export default function HomepageCTA() {
  const user = useUserInfo()

  const isLoggedIn = !!user?.Email
  const headerText = isLoggedIn ? 'Welcome back!' : 'Join the RMP Family'

  return (
    <StyledHomepageCTA>
      <CTAHeader>
        <CTAMainHeader>{headerText}</CTAMainHeader>
        {!isLoggedIn && <CTASubheader>Love RMP? Let&apos;s make it official.</CTASubheader>}
      </CTAHeader>
      <CTAImagePrompts />
      <CTAButton isLoggedIn={isLoggedIn} />
    </StyledHomepageCTA>
  )
}

CTAButton.propTypes = {
  isLoggedIn: PropTypes.bool
}
