import React from 'react'
import InfoPage from '../components/PageTemplates/InfoPage'

export default function SchoolNotFound() {
  return (
    <InfoPage
      header="School Not Found"
      subheader="We couldn't find the school you were looking for. Try searching for a different school!"
      pageTitle="School not found | Rate My Professors"
    />
  )
}
