import React from 'react'
import styled from 'styled-components'
import USERTYPES from '@enums/USERTYPES'
import PropTypes from 'prop-types'

const StyledDeleteAccountWarning = styled.div`
  font-size: ${props => props.theme.fontSize.medium};
  line-height: 24px;
  margin: 40px 0;
  text-align: left;
`

const WarningList = styled.ul`
  list-style-type: none;
`

const WarningItem = styled.li`
  display: list-item;

  &:before {
    content: '•';
    margin-right: 5px;
  }
`

export const WarningHeader = styled.div`
  font-size: ${props => props.theme.fontSize.medium};
  font-weight: ${props => props.theme.fontWeight.bold};
  margin-bottom: 25px;
`

export const StudentWarning = () => {
  return (
    <WarningList>
      <WarningItem>Deleting your account is permanent</WarningItem>
      <WarningItem>The ratings you&apos;ve already submitted will not be deleted</WarningItem>
      <WarningItem>You will no longer be able to edit your ratings</WarningItem>
    </WarningList>
  )
}

export const TeacherWarning = () => {
  return (
    <WarningList>
      <WarningItem>Deleting your account is permanent</WarningItem>
      <WarningItem>Your profile will not be deleted</WarningItem>
      <WarningItem>Students will still be able to rate you</WarningItem>
      <WarningItem>You will no longer be notified of new ratings</WarningItem>
      <WarningItem>You will no longer be able to reply to ratings</WarningItem>
    </WarningList>
  )
}

export default function DeleteAccountWarning({ userType }) {
  return (
    <StyledDeleteAccountWarning>
      <WarningHeader>Are you sure you want to delete your account?</WarningHeader>
      {userType === USERTYPES.STUDENT ? <StudentWarning /> : <TeacherWarning />}
    </StyledDeleteAccountWarning>
  )
}

DeleteAccountWarning.propTypes = {
  userType: PropTypes.oneOf(Object.values(USERTYPES))
}
