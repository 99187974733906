import React, { useContext, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import FormContext from '@contexts/FormContext'
import { StyledFieldWrapper } from '@components/Form/FieldWrapper'
import ErrorText from '@StyledComponents/ErrorText'
import FieldErrors, { StyledFieldError } from '@components/Form/FieldErrors'
import {
  breakpointSmallPlusAndBelow,
  breakpointLargeAndBelow
} from '@StyledComponents/theme/helpers/breakpoints'
import { MAX_COMMENT_LENGTH, MIN_COMMENT_LENGTH } from '@src/CONSTANTS'

export const FORMCARD_TESTID = 'FORMCARD_TESTID'

const FormCardContainer = styled.div`
  margin: 0px 40px 20px 0;
  min-width: 343px;
  text-align: left;

  ${breakpointLargeAndBelow(css`
    margin: 0px 0px 20px;
  `)}

  ${StyledFieldError} {
    color: ${({ theme }) => theme.color.error};
    font-size: ${({ theme }) => theme.fontSize.medium};
    font-weight: ${({ theme }) => theme.fontWeight.bold};
  }
`

export const StyledFormCard = styled.div`
  background: #ffffff;
  border: 1px solid ${({ hasErrors, theme }) => (hasErrors ? theme.color.error : theme.color.gray5)};
  border-radius: 6px;
  box-shadow: 0px 4px 4px rgba(126, 126, 126, 0.25);
  padding: 24px 28px 20px;
  text-align: left;

  ${breakpointSmallPlusAndBelow(css`
    padding: 20px 12px;
  `)}

  ${StyledFieldWrapper} {
    ${ErrorText} {
      display: none;
    }
  }
`

export const StyledFormCardTitle = styled.div`
  font-size: ${({ theme }) => theme.fontSize.medium};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  line-height: 20px;
  margin-bottom: 16px;
  span {
    color: ${({ theme }) => theme.color.errorRed};
    margin-left: 3px;
  }
`

export const StyledFormCardSubheader = styled.div`
  font-size: ${({ theme }) => theme.fontSize.medium};
  line-height: 22px;
  margin-bottom: 16px;
`

export const StyledFormCardContent = styled.div`
  text-align: center;
`

const FormCard = ({ children, fieldName, label, isRequired, subTitle }) => {
  const { model, validation } = useContext(FormContext)
  const formCardRef = useRef()

  const matchingFields =
    validation?.errors?.length > 0 && validation.errors
      ? validation.errors.filter(e => e.field.toLowerCase() === fieldName?.toLowerCase())
      : []

  const matchingErrors = matchingFields.reduce((combined, current) => {
    if (
      current.field.toLowerCase() === 'comment' &&
      model?.comment?.length >= MIN_COMMENT_LENGTH &&
      model?.comment?.length <= MAX_COMMENT_LENGTH &&
      model?.comment.trim().length > MIN_COMMENT_LENGTH
    ) {
      return combined
    }

    return combined.concat(current.messages)
  }, [])

  useEffect(() => {
    if (matchingErrors.length > 0) {
      formCardRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center'
      })
    }
  }, [validation?.errors])

  return (
    <FormCardContainer>
      <StyledFormCard
        ref={formCardRef}
        hasErrors={matchingErrors.length > 0}
        data-testid={FORMCARD_TESTID}
      >
        {label && (
          <StyledFormCardTitle>
            {label}
            <span>{isRequired && '*'}</span>
          </StyledFormCardTitle>
        )}
        {subTitle && <StyledFormCardSubheader>{subTitle}</StyledFormCardSubheader>}
        <StyledFormCardContent>{children}</StyledFormCardContent>
      </StyledFormCard>
      <FieldErrors fieldName={fieldName} />
    </FormCardContainer>
  )
}

FormCard.propTypes = {
  children: PropTypes.node,
  fieldName: PropTypes.string,
  label: PropTypes.string,
  isRequired: PropTypes.bool,
  subTitle: PropTypes.string
}

export default FormCard
