/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type RatingsFilter_teacher$ref: FragmentReference;
declare export opaque type RatingsFilter_teacher$fragmentType: RatingsFilter_teacher$ref;
export type RatingsFilter_teacher = {|
  +courseCodes: ?$ReadOnlyArray<?{|
    +courseCount: ?number,
    +courseName: ?string,
  |}>,
  +$refType: RatingsFilter_teacher$ref,
|};
export type RatingsFilter_teacher$data = RatingsFilter_teacher;
export type RatingsFilter_teacher$key = {
  +$data?: RatingsFilter_teacher$data,
  +$fragmentRefs: RatingsFilter_teacher$ref,
};
*/


const node/*: ReaderFragment*/ = {
  "kind": "Fragment",
  "name": "RatingsFilter_teacher",
  "type": "Teacher",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "courseCodes",
      "storageKey": null,
      "args": null,
      "concreteType": "Course",
      "plural": true,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "courseCount",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "courseName",
          "args": null,
          "storageKey": null
        }
      ]
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = 'd732b595198a883fc35adcc40ea3bfe8';
module.exports = node;
