import ROUTE from '@enums/ROUTE'
import { LINK_TYPE } from './FooterLink'

const FOOTER_LINKS = [
  { label: 'Help', to: ROUTE.HELP, type: LINK_TYPE.EXTERNAL },
  { label: 'Site Guidelines', to: ROUTE.LEGAL_SITE_GUIDELINES, type: LINK_TYPE.INTERNAL },
  { label: 'Terms & Conditions', to: ROUTE.LEGAL_TERMS, type: LINK_TYPE.INTERNAL },
  { label: 'Privacy Policy', to: ROUTE.LEGAL_PRIVACY, type: LINK_TYPE.INTERNAL },
  {
    label: 'Copyright Compliance Policy',
    to: ROUTE.LEGAL_COPYRIGHT,
    type: LINK_TYPE.INTERNAL
  },
  {
    label: 'CA Notice at Collection',
    to: `${ROUTE.LEGAL_PRIVACY}#sectionp10`,
    type: LINK_TYPE.INTERNAL
  },
  {
    label: 'CA Do Not Sell or Share My Personal Information',
    to: ROUTE.CCPA,
    type: LINK_TYPE.INTERNAL
  }
]

export default FOOTER_LINKS
