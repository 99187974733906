import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import Form from '@components/Form/Form'
import TeacherTypes from '@types/Teacher.types'
import CompareProfessorsSearch from './CompareProfessorsSearch'
import CompareProfessorsColumn from './CompareProfessorsColumn'

const CompareProfessorsSearchContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
`

const CompareProfessorsSearchBar = styled(Form)`
  margin-bottom: 16px;
  text-align: left;
  width: 100%;
`

const CompareProfessorsColumnContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`

const CompareProfessorsSearchResults = ({
  primaryProfessor,
  secondaryProfessor,
  handleOnItemSelected,
  handleClose
}) => {
  return (
    <CompareProfessorsSearchContainer>
      <CompareProfessorsSearchBar>
        <CompareProfessorsSearch
          isPrimaryProfessor={!primaryProfessor}
          handleOnItemSelected={handleOnItemSelected}
          primaryProfessor={primaryProfessor}
          secondaryProfessor={secondaryProfessor}
        />
      </CompareProfessorsSearchBar>

      <CompareProfessorsColumnContainer>
        <CompareProfessorsColumn
          teacher={primaryProfessor}
          column="primary"
          handleClose={handleClose}
        />
        <CompareProfessorsColumn
          teacher={secondaryProfessor}
          column="secondary"
          handleClose={handleClose}
        />
      </CompareProfessorsColumnContainer>
    </CompareProfessorsSearchContainer>
  )
}

CompareProfessorsSearchResults.propTypes = {
  primaryProfessor: TeacherTypes,
  secondaryProfessor: TeacherTypes,
  handleOnItemSelected: PropTypes.func,
  handleClose: PropTypes.func
}

export default CompareProfessorsSearchResults
