import React from 'react'
import styled, { css } from 'styled-components'

import {
  breakpointLargeAndBelow,
  breakpointSmallPlusAndBelow
} from '@StyledComponents/theme/helpers/breakpoints'
import cengageLogo from '@assets/images/logos/cengage_logo_summer22.svg'
import getEnv from '@utils/getEnv'
import { getCengageSRC, getCengageHREF } from '@utils/getCengageLinks'

export const LogoCengage = styled.a`
  background: url(${cengageLogo});
  background-repeat: no-repeat;
  background-size: contain;
  height: 26px;
  width: 218px;

  ${breakpointLargeAndBelow(css`
    background-image: url(${cengageLogo});
    position: absolute;
    right: 12px;
    margin-left: 0;
    height: 36px;
    width: 239px;
  `)}
  ${breakpointSmallPlusAndBelow(css`
    background-image: url(${cengageLogo});
    width: 124px;
    max-width: calc(50% - 55px);
    height: 38px;
    top: 10px;
  `)}
`

export default function CengagePromo() {
  if (getEnv('REACT_APP_SHOW_CENGAGE') !== 'true') return null

  return (
    <React.Fragment>
      <div id="cengage-promo-header">
        <LogoCengage href={getCengageHREF()} target="_blank" />
      </div>
      <img
        src={getCengageSRC()}
        border="0"
        height="1"
        width="1"
        aria-hidden
        // Image still loads in DOM but needs to be hidden to avoid white 1x1 pixel
        style={{ visibility: 'hidden' }}
        // Empty string to prevent alt text being rendered (ch16014)
        alt=""
        title="Cengage Advertisement"
      />
    </React.Fragment>
  )
}
