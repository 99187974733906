import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import * as Sentry from '@sentry/react'
import ROUTE from '@enums/ROUTE'
import App from './App'
import RelayStore from './components/RelayStore'

export default function AppWrapper() {
  return (
    <Sentry.ErrorBoundary
      fallback={() => {
        window.location.assign(`${ROUTE.ERROR}?type=app`)
      }}
      onError={error => {
        Sentry.captureException(error)
      }}
    >
      <RelayStore>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </RelayStore>
    </Sentry.ErrorBoundary>
  )
}
