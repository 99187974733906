import React from 'react'
import CCPAModal from '@components/Modals/CCPAModal'
import SignupPromptModal from './SignupPromptModal'
import LoginModal from './LoginModal'
import EmailConfirmationModal from './EmailConfirmationModal'
import EmailConfirmationPromptModal from './EmailConfirmationPromptModal'
import SignupFormModal from './SignupFormModal/SignupFormModal'
import ForgotPasswordModal from './ForgotPasswordModal'
import LogoutModal from './LogoutModal'
import ResetPasswordModal from './ResetPasswordModal'
import RegisterPromptModal from './RegisterPromptModal/RegisterPromptModal'
import ProfessorSignUpModal from './ProfessorSignUpModal/ProfessorSignUpModal'

// All modals should be added here, and use Modal with the modal prop which will
// conditionally show / hide them based on activeModal in the global state
export default function ModalRoot() {
  return (
    <React.Fragment>
      <SignupFormModal />
      <CCPAModal />
      <LoginModal />
      <EmailConfirmationModal />
      <EmailConfirmationPromptModal />
      <SignupPromptModal />
      <ForgotPasswordModal />
      <LogoutModal />
      <ResetPasswordModal />
      <RegisterPromptModal />
      <ProfessorSignUpModal />
    </React.Fragment>
  )
}
