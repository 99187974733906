function windowEnvDefined() {
  return typeof window !== 'undefined' && window.process && window.process.env
}

export default function getEnv(name) {
  if (process.env[name] !== undefined) {
    return process.env[name]
  }

  if (windowEnvDefined() && window.process.env[name]) {
    return window.process.env[name]
  }

  return null
}
