import React from 'react'
import PropTypes from 'prop-types'
import { TOAST_EVENTS } from '@utils/toast'
import Toast, { ToastHeader, ToastDescription } from './Toast'

const HardlockedTeacherToast = ({ closeToast, teacherName }) => {
  return (
    <Toast canDismiss toastEvent={TOAST_EVENTS.HARD_LOCKED_TEACHER} closeToast={closeToast}>
      <ToastHeader>Alert</ToastHeader>
      <ToastDescription>
        Professor {teacherName}&apos;s profile is currently locked. Please check back shortly to
        leave your review or visit our help center for more info.
      </ToastDescription>
    </Toast>
  )
}

HardlockedTeacherToast.propTypes = {
  closeToast: PropTypes.func.isRequired,
  teacherName: PropTypes.string
}

export default HardlockedTeacherToast
