/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type CompareProfessorLink_teacher$ref = any;
type NameLink_teacher$ref = any;
type NameTitle_teacher$ref = any;
type RateTeacherLink_teacher$ref = any;
type RatingValue_teacher$ref = any;
type TeacherFeedback_teacher$ref = any;
type TeacherTags_teacher$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type TeacherInfo_teacher$ref: FragmentReference;
declare export opaque type TeacherInfo_teacher$fragmentType: TeacherInfo_teacher$ref;
export type TeacherInfo_teacher = {|
  +id: ?string,
  +lastName: ?string,
  +numRatings: ?number,
  +$fragmentRefs: RatingValue_teacher$ref & NameTitle_teacher$ref & TeacherTags_teacher$ref & NameLink_teacher$ref & TeacherFeedback_teacher$ref & RateTeacherLink_teacher$ref & CompareProfessorLink_teacher$ref,
  +$refType: TeacherInfo_teacher$ref,
|};
export type TeacherInfo_teacher$data = TeacherInfo_teacher;
export type TeacherInfo_teacher$key = {
  +$data?: TeacherInfo_teacher$data,
  +$fragmentRefs: TeacherInfo_teacher$ref,
};
*/


const node/*: ReaderFragment*/ = {
  "kind": "Fragment",
  "name": "TeacherInfo_teacher",
  "type": "Teacher",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "id",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "lastName",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "numRatings",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "FragmentSpread",
      "name": "RatingValue_teacher",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "NameTitle_teacher",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "TeacherTags_teacher",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "NameLink_teacher",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "TeacherFeedback_teacher",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "RateTeacherLink_teacher",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "CompareProfessorLink_teacher",
      "args": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '32fa165546a05c12cc288e9bd646fdb9';
module.exports = node;
