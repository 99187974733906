/* eslint-disable no-param-reassign */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'

const isValidInput = input => {
  const invalidChars = /[<>"|\\/_$#]/
  return !invalidChars.test(input) && input.length <= 100
}

/**
 * DebouncedInput delays the input onChange event so that we don't send search query requests for every character
 * and instead group characters together. The SEARCH_DEBOUNCE_TIME is passed as a prop here as time. If the
 * debounce time is set too low it will cause excessive queries.
 */
function DebouncedInput({
  value,
  setValue,
  time,
  onChange,
  minLength = 0,
  inputRef,
  setError,
  setDisableEnterKey,
  ...props
}) {
  const [inputValue, setInputValue] = useState(value)
  const debounceTimerRef = useRef(null)

  // Update local state when prop value changes
  useEffect(() => {
    setInputValue(value)
  }, [value])

  // Start or reset the debounce timer on input change
  useEffect(() => {
    if (inputValue && !isValidInput(inputValue)) {
      setError(
        "Oops, that doesn't look like a name. Try again using alphanumeric characters only. "
      )
      setDisableEnterKey(true)
    } else if (inputValue && inputValue.length >= minLength) {
      setError(null)
      setDisableEnterKey(false)

      if (debounceTimerRef.current) clearTimeout(debounceTimerRef.current)

      debounceTimerRef.current = setTimeout(() => {
        onChange(inputValue)
        debounceTimerRef.current = null
      }, time)
    }

    // Clear the debounce timer to avoid leftover actions
    return () => {
      if (debounceTimerRef.current) clearTimeout(debounceTimerRef.current)
    }
  }, [inputValue, minLength, time, onChange])

  const handleInputChange = e => {
    const newValue = e.target.value

    // Trigger both local and prop state update if exists
    if (setValue) {
      setValue(newValue)
    }
    setInputValue(newValue)
  }

  return (
    <input type="text" value={inputValue} onChange={handleInputChange} ref={inputRef} {...props} />
  )
}

DebouncedInput.propTypes = {
  time: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
  minLength: PropTypes.number,
  setValue: PropTypes.func,
  inputRef: PropTypes.oneOfType([
    PropTypes.shape({
      current: PropTypes.node
    }),
    PropTypes.shape({
      current: null
    })
  ]),
  setError: PropTypes.func,
  setDisableEnterKey: PropTypes.func
}

export default DebouncedInput
