import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import ReactModal from 'react-modal'
import useGlobalContext from '@hooks/useGlobalContext'
import { ROOT_ID } from '@src/CONSTANTS'
import { ModalsTypes } from '@enums/MODALS'

// Fn and const below are needed to encapsulate modal styles
//  see https://github.com/reactjs/react-modal/issues/603
function ReactModalAdapter({ className, ...props }) {
  const contentClassName = `${className}__content`
  const overlayClassName = `${className}__overlay`
  return (
    <ReactModal
      portalClassName={className}
      className={{
        base: contentClassName,
        afterOpen: `${contentClassName}--after-open`,
        beforeClose: `${contentClassName}--before-close`
      }}
      overlayClassName={overlayClassName}
      {...props}
    />
  )
}

/** Modal is an unstyled React-modal which has been integrated into the lockhart
 * GlobalContext system, it will show/hide itself based on GlobalContexts activeModal
 * property.
 */
export default function Modal({
  className,
  modal,
  children,
  shouldCloseOnOverlayClick = false,
  closeTimeoutMS,
  onOpen = () => {},
  onClose = () => {}
}) {
  const { activeModal, closeActiveModal } = useGlobalContext()
  const onCloseActions = () => {
    onClose()
    closeActiveModal()
  }

  useEffect(() => {
    if (activeModal === modal) {
      onOpen()
    }
  }, [activeModal])

  // Modal bound to appElement for accessibility compliance see http://reactcommunity.org/react-modal/accessibility/
  const root = document.getElementById(ROOT_ID)
  if (root) ReactModal.setAppElement(root)

  return (
    <ReactModalAdapter
      onRequestClose={onCloseActions}
      isOpen={activeModal === modal}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      ariaHideApp={!global.isTest}
      className={className}
      closeTimeoutMS={closeTimeoutMS}
    >
      {children}
    </ReactModalAdapter>
  )
}

Modal.propTypes = {
  modal: ModalsTypes.isRequired,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  shouldCloseOnOverlayClick: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  closeTimeoutMS: PropTypes.number
}

ReactModalAdapter.propTypes = {
  className: PropTypes.string
}
