/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const TextAreaContainer = styled.div`
  align-items: flex-end;
  display: flex;
  flex-direction: column;
`

export const StyledTextArea = styled.textarea`
  border: solid 1px ${({ invalid, theme }) => (invalid ? theme.color.error : theme.color.gray4)};
  border-radius: 3px;
  font-family: ${props => props.theme.fontFamily.helvetica};
  font-size: ${props => props.theme.fontSize.medium};
  outline: none;
  padding: 16px;
  resize: none;
  width: 100%;

  ::placeholder {
    color: ${({ theme }) => theme.color.gray11};
  }
`

export const LengthCounter = styled.span`
  color: ${({ theme, invalid }) => (invalid ? theme.color.error : theme.color.gray11)};
  font-family: ${props => props.theme.fontFamily.helveticaNeue};
  font-weight: ${props => props.theme.fontWeight.bold};
  margin-top: 4px;
`

const TextArea = ({ value, maxlength = undefined, ...props }) => {
  const invalid = maxlength !== undefined && value.length > maxlength
  return (
    <TextAreaContainer>
      <StyledTextArea value={value} maxlength={maxlength} invalid={invalid} {...props} />
      {maxlength !== undefined && (
        <LengthCounter invalid={invalid}>
          {value.length}/{maxlength}
        </LengthCounter>
      )}
    </TextAreaContainer>
  )
}

TextArea.propTypes = {
  value: PropTypes.string.isRequired,
  maxlength: PropTypes.number
}

export default TextArea
