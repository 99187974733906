import React from 'react'
import styled from 'styled-components'
import LABEL_STYLE from '@enums/LABEL_STYLE'
import FormSelect, { StyledSelect } from '@components/Form/FormSelect'
import TextInput from '@components/Form/TextInput'
import FormContext from '@contexts/FormContext'
/**
 * The decision was made to hardcode the state and country
 * options for the form selection. These are pulled from the
 * "states" and "countries" table in the database and mapped
 * for the form selection
 */
import STATES from './constants/States'
import COUNTRIES from './constants/Countries'

const StyledFormSelect = styled(FormSelect)`
  max-width: 441px;
  ${StyledSelect} {
    width: 100%;
  }
`

const StyledTextInput = styled(TextInput)`
  max-width: 441px;
`

export function LocationSelection() {
  const [filteredStates, setFilteredStates] = React.useState([])
  const { model } = React.useContext(FormContext)
  React.useEffect(() => {
    if (model?.country?.value) {
      setFilteredStates(STATES.filter(option => option.countryId === model.country.value))
    }
  }, [model.country])

  return (
    <React.Fragment>
      <StyledFormSelect
        name="country"
        options={COUNTRIES}
        label="Country"
        labelStyle={LABEL_STYLE.TOP}
        fullWidth
      />
      <StyledFormSelect
        name="state"
        options={filteredStates}
        label="State/Province"
        labelStyle={LABEL_STYLE.TOP}
        fullWidth
      />
      <StyledTextInput name="city" label="City" labelStyle={LABEL_STYLE.TOP} />
    </React.Fragment>
  )
}

export default LocationSelection
