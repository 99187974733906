import React from 'react'
import styled from 'styled-components'
import MODALS from '@enums/MODALS'
import { poppinsBoldest3x } from '@StyledComponents/theme/typesets'
import CTAImagePrompts from '@StyledComponents/CTAImagePrompts'
import SignUpForm from './SignUpForm'
import SmallModal from '../Templates/SmallModal'

const ModalHeader = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.poppins};
  text-align: center;
`

const ModalMainHeader = styled.div`
  ${poppinsBoldest3x};
  font-size: 24px;
  line-height: 42px;
`

const ModalSubheader = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.poppins};
  font-size: 14px;
  line-height: 23px;
  margin: 5px auto 20px;
`
const SignUpContainer = styled.div`
  margin: auto;
  width: 311px;
`

export default function RegisterPromptModal() {
  const onClose = () => {}

  return (
    <SmallModal
      modal={MODALS.REGISTER_PROMPT}
      onClose={onClose}
      isSlidingModal
      padding="36px 48px 26px"
      maxWidth="736px"
    >
      <ModalHeader>
        <ModalMainHeader>Join the RMP family</ModalMainHeader>
        <ModalSubheader>Love RMP? Let&apos;s make it official.</ModalSubheader>
      </ModalHeader>
      <CTAImagePrompts small />
      <SignUpContainer>
        <SignUpForm />
      </SignUpContainer>
    </SmallModal>
  )
}
