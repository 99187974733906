/**
 * @flow
 * @relayHash a3313feebf5e5c0208bdeb399d9a5bfa
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type FormController_validation$ref = any;
export type LoginModalMutationVariables = {|
  email: string,
  password: string,
|};
export type LoginModalMutationResponse = {|
  +userLogin: ?{|
    +token: ?string,
    +validation: ?{|
      +$fragmentRefs: FormController_validation$ref
    |},
  |}
|};
export type LoginModalMutation = {|
  variables: LoginModalMutationVariables,
  response: LoginModalMutationResponse,
|};
*/


/*
mutation LoginModalMutation(
  $email: String!
  $password: String!
) {
  userLogin(email: $email, password: $password) {
    token
    validation {
      ...FormController_validation
      id
    }
  }
}

fragment FormController_validation on ValidationResult {
  id
  success
  errors {
    field
    messages
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "email",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "password",
    "type": "String!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "email",
    "variableName": "email"
  },
  {
    "kind": "Variable",
    "name": "password",
    "variableName": "password"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "token",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "LoginModalMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "userLogin",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "AuthenticationResponse",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "validation",
            "storageKey": null,
            "args": null,
            "concreteType": "ValidationResult",
            "plural": false,
            "selections": [
              {
                "kind": "FragmentSpread",
                "name": "FormController_validation",
                "args": null
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "LoginModalMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "userLogin",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "AuthenticationResponse",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "validation",
            "storageKey": null,
            "args": null,
            "concreteType": "ValidationResult",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "id",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "success",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "errors",
                "storageKey": null,
                "args": null,
                "concreteType": "FieldErrorType",
                "plural": true,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "field",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "messages",
                    "args": null,
                    "storageKey": null
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "mutation",
    "name": "LoginModalMutation",
    "id": null,
    "text": "mutation LoginModalMutation(\n  $email: String!\n  $password: String!\n) {\n  userLogin(email: $email, password: $password) {\n    token\n    validation {\n      ...FormController_validation\n      id\n    }\n  }\n}\n\nfragment FormController_validation on ValidationResult {\n  id\n  success\n  errors {\n    field\n    messages\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '07020616b5df7b1d6cb06f0fdf900fc5';
module.exports = node;
