/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type SchoolCardHeader_school$ref = any;
type SchoolCardLocation_school$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type SchoolCard_school$ref: FragmentReference;
declare export opaque type SchoolCard_school$fragmentType: SchoolCard_school$ref;
export type SchoolCard_school = {|
  +legacyId: ?number,
  +name: ?string,
  +numRatings: ?number,
  +avgRating: ?number,
  +avgRatingRounded: ?any,
  +$fragmentRefs: SchoolCardHeader_school$ref & SchoolCardLocation_school$ref,
  +$refType: SchoolCard_school$ref,
|};
export type SchoolCard_school$data = SchoolCard_school;
export type SchoolCard_school$key = {
  +$data?: SchoolCard_school$data,
  +$fragmentRefs: SchoolCard_school$ref,
};
*/


const node/*: ReaderFragment*/ = {
  "kind": "Fragment",
  "name": "SchoolCard_school",
  "type": "School",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "legacyId",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "name",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "numRatings",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "avgRating",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "avgRatingRounded",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "FragmentSpread",
      "name": "SchoolCardHeader_school",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "SchoolCardLocation_school",
      "args": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '58c89b54676e3b944f0083d0cdc237b1';
module.exports = node;
