import React, { useRef } from 'react'
import styled from 'styled-components'
import { graphql } from 'react-relay'
import useGlobalContext from '@hooks/useGlobalContext'
import TRACKING_EVENT from '@enums/TRACKING_EVENT'
import MODALS from '@enums/MODALS'
import Form from '@components/Form/Form'
import TextInput from '@components/Form/TextInput'
import FormButton from '@components/Form/FormButton'
import { ButtonAttributes } from '@StyledComponents/Buttons'
import ModalButtonLink from '@components/ModalButtonLink'

const userCreateAndLoginMutation = graphql`
  mutation SignUpFormMutation($user: UserInputObject!, $email: String!, $password: String!) {
    userCreate(user: $user) {
      user {
        id
        Email
      }

      validation {
        ...FormController_validation
      }
    }

    userLogin(email: $email, password: $password) {
      token

      validation {
        ...FormController_validation
      }
    }
  }
`

const StyledSignUpForm = styled(Form)`
  margin-top: 16px;
`

const FormDisclosure = styled.div`
  font-size: ${props => props.theme.fontSize.default};
  margin: 16px 0 0;
`

const SubmitButton = styled(FormButton)`
  margin-top: 20px;
  width: 100%;

  ${ButtonAttributes}
`

export const SigninSubHeader = styled.div`
  line-height: 22px;
  margin-top: 16px;
  text-align: center;

  button {
    font-weight: bold;
  }
`

export default function SignUpForm() {
  const { login, closeActiveModal } = useGlobalContext()
  const userToken = useRef(null)

  const onSignUpFormSuccess = async () => {
    if (userToken.current) {
      await login(userToken.current)
      closeActiveModal()
    }
  }

  return (
    <StyledSignUpForm
      getVariables={model => ({
        user: { email: model.email, password: model.password },
        email: model.email,
        password: model.password
      })}
      mutation={userCreateAndLoginMutation}
      updater={store => {
        userToken.current = store?.getRootField('userLogin')?.getValue('token')
      }}
      onSuccess={() => onSignUpFormSuccess()}
    >
      <TextInput name="email" label="Email" />
      <TextInput
        isPassword
        name="password"
        label="Password"
        autoComplete="new-password"
        setHelperTextToError="Minimum 8 characters"
      />
      <FormDisclosure>
        By clicking &apos;Continue&apos; you agree to the Terms of Use and our Privacy Policy.
        RateMyProfessors is designed for and targeted to U.S. audiences and is governed by and
        operated in accordance with U.S. laws.
      </FormDisclosure>
      <SubmitButton id="register-prompt-modal-sign-up-btn" type="submit">
        Sign Up
      </SubmitButton>
      <SigninSubHeader>
        I already have an account.{' '}
        <ModalButtonLink
          trackingLabel={TRACKING_EVENT.REGWALL_LOGIN_CLICK_PROF}
          modal={MODALS.LOGIN}
        >
          Log In
        </ModalButtonLink>
      </SigninSubHeader>
    </StyledSignUpForm>
  )
}
