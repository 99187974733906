import MODALS from '@enums/MODALS'
import Cookies from '@utils/Cookies'
import processLogout from '@utils/processLogout'

export const ACTIONS = {
  LOG_IN: 'LOG_IN',
  LOG_OUT: 'LOG_OUT',
  SET_ACTIVE_MODAL: 'SET_ACTIVE_MODAL',
  CLOSE_ACTIVE_MODAL: 'CLOSE_ACTIVE_MODAL',
  SET_REDIRECT_AFTER_CLOSE: 'SET_REDIRECT_AFTER_CLOSE'
}

export default function reducer(state, action) {
  switch (action.type) {
    case ACTIONS.LOG_IN: {
      Cookies.set('rmpAuth', action.token)
      /**
       * We are overriding the default redirect for login so that
       * we can allow redirection to account profile pages after login
       */
      return { ...state, authToken: action.token, shouldRedirect: action.shouldRedirect }
    }
    case ACTIONS.LOG_OUT:
      processLogout()
      return { ...state, authToken: null }
    case ACTIONS.SET_ACTIVE_MODAL:
      return { ...state, activeModal: action.modal }
    case ACTIONS.CLOSE_ACTIVE_MODAL:
      if (state.shouldRedirect) {
        window.location.assign('/')
      }
      return { ...state, activeModal: MODALS.NONE }
    case ACTIONS.SET_REDIRECT_AFTER_CLOSE:
      return { ...state, shouldRedirect: action.shouldRedirect }
    default:
      return state
  }
}
