import React from 'react'
import styled, { css } from 'styled-components'
import { createFragmentContainer, graphql } from 'react-relay'
import PropTypes from 'prop-types'
import SchoolRatingTypes from '@types/SchoolRating.types'
import SchoolTypes from '@types/School.types'
import parseAndSanitize from '@utils/parseAndSanitize'
import {
  breakpointSmediumAndBelow,
  breakpointSmallAndBelow
} from '@StyledComponents/theme/helpers/breakpoints'
import { emotionMap, determineEmotion } from '@componentUtils/emotionHelpers'
import EmotionLabel, { StyledEmotionLabel } from '@StyledComponents/EmotionLabel'
import GradeSquare from '@components/StyledComponents/GradeSquare'
import TimeStamp from '@components/TimeStamp'
import SchoolRatingSummary from './SchoolRatingSummary'
import calculateOverallGrade from './SchoolRating.utils'
import SchoolRatingSuperHeader from './SchoolRatingSuperHeader'
import SchoolRatingFooter from './SchoolRatingFooter'

const SchoolRatingContainer = styled.div`
  background-color: ${({ theme }) => theme.color.gray7};
  display: flex;
  flex-direction: column;
  font-family: ${props => props.theme.fontFamily.poppins};
  margin: 16px 0;
  min-width: 343px;
  width: 100%;
  ${breakpointSmediumAndBelow(css`
    padding: 18px 16px 24px;
  `)}

  ${StyledEmotionLabel} {
    text-align: center;
  }
`

const SchoolRatingBody = styled.div`
  display: flex;
  flex-direction: row;
  padding: 20px 24px 32px;
`

const OverallRatingContainer = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  padding: 10px 24px 0 0;
  ${breakpointSmallAndBelow(css`
    display: none;
  `)}
`

const OverallHeader = styled.div`
  margin-bottom: 8px;
`

const MainRatingContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 15px;
  width: 100%;
`

const RatingHeader = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: ${props => (props.showEmotionLabel ? 'space-between;' : 'flex-end;')};
`

const RatingComment = styled.div`
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  margin-top: 20px;
  text-align: left;
`

const StyledTimeStamp = styled(TimeStamp)`
  padding-bottom: 0;
`

export const StyledRating = styled.div`
  background-color: rgba(241, 241, 241, 0.4);
  margin: 16px 0;
`

export const defaultDisplayOptions = {
  showSuperHeader: false,
  showFooter: true,
  showEmotionLabel: false
}

export function SchoolRating({ rating, school, displayOptions = defaultDisplayOptions }) {
  const {
    clubsRating,
    comment,
    date,
    facilitiesRating,
    foodRating,
    happinessRating,
    internetRating,
    locationRating,
    opportunitiesRating,
    reputationRating,
    safetyRating,
    socialRating,
    createdByUser
  } = rating

  const ratingList = [
    { score: reputationRating, name: 'Reputation' },
    { score: locationRating, name: 'Location' },
    { score: opportunitiesRating, name: 'Opportunities' },
    { score: parseFloat(facilitiesRating), name: 'Facilities' },
    { score: internetRating, name: 'Internet' },
    { score: foodRating, name: 'Food' },
    { score: clubsRating, name: 'Clubs' },
    { score: socialRating, name: 'Social' },
    { score: happinessRating, name: 'Happiness' },
    { score: safetyRating, name: 'Safety' }
  ]

  const isCurrentUserRating = createdByUser ?? false

  const overallGrade = calculateOverallGrade(ratingList)
  const emotion = determineEmotion(overallGrade)

  return (
    <SchoolRatingContainer>
      {isCurrentUserRating && rating && school && (
        <SchoolRatingSuperHeader rating={rating} school={school} />
      )}
      <SchoolRatingBody>
        <OverallRatingContainer>
          <OverallHeader>Overall</OverallHeader>
          <GradeSquare grade={overallGrade} large />
        </OverallRatingContainer>
        <MainRatingContainer>
          <RatingHeader showEmotionLabel={displayOptions.showEmotionLabel}>
            {displayOptions.showEmotionLabel && (
              <EmotionLabel emotion={emotion}>{emotionMap[emotion].genericRating}</EmotionLabel>
            )}
            <StyledTimeStamp timeStamp={date} />
          </RatingHeader>
          <RatingComment>{parseAndSanitize(comment)}</RatingComment>
          <SchoolRatingSummary ratingList={ratingList} />
          {displayOptions.showFooter && <SchoolRatingFooter rating={rating} school={school} />}
        </MainRatingContainer>
      </SchoolRatingBody>
    </SchoolRatingContainer>
  )
}

export default createFragmentContainer(SchoolRating, {
  rating: graphql`
    fragment SchoolRating_rating on SchoolRating {
      clubsRating
      comment
      date
      facilitiesRating
      foodRating
      happinessRating
      internetRating
      locationRating
      opportunitiesRating
      reputationRating
      safetyRating
      socialRating
      legacyId
      flagStatus
      createdByUser
      ...SchoolRatingFooter_rating
    }
  `,
  school: graphql`
    fragment SchoolRating_school on School {
      ...SchoolRatingSuperHeader_school
      ...SchoolRatingFooter_school
    }
  `
})

SchoolRating.propTypes = {
  rating: SchoolRatingTypes.isRequired,
  school: SchoolTypes,
  displayOptions: PropTypes.objectOf(PropTypes.bool)
}
