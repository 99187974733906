import React from 'react'
import { createFragmentContainer, graphql } from 'react-relay'
import styled from 'styled-components'
import { poppinsBoldest, helveticaBold } from '@StyledComponents/theme/typesets'

import ProfessorNoteTypes from '@types/ProfessorNote.types'
import TeacherTypes from '@types/Teacher.types'
import { convertDBtoReadable } from '@utils/date'

const StyledProfessorNoteHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
`

export const Title = styled.span`
  ${poppinsBoldest};

  letter-spacing: 0.5px;
`

export const DateHolder = styled.div`
  align-items: flex-end;
  display: flex;
  flex-direction: column;
`

export const DateMajor = styled.div`
  ${helveticaBold};
`

export const DateMinor = styled.div`
  color: ${props => props.theme.color.gray8};
  font-family: ${props => props.theme.fontFamily.helveticaNeue};
  margin-top: 5px;
`

export const ProfessorNoteHeader = ({ note, teacher }) => {
  return (
    <StyledProfessorNoteHeader>
      <Title>Professor {teacher.lastName} says:</Title>
      <DateHolder>
        <DateMajor>Posted on: {convertDBtoReadable(note.createdAt)}</DateMajor>
        {note.updatedAt && (
          <DateMinor>Last Updated on: {convertDBtoReadable(note.updatedAt)}</DateMinor>
        )}
      </DateHolder>
    </StyledProfessorNoteHeader>
  )
}

ProfessorNoteHeader.propTypes = {
  note: ProfessorNoteTypes,
  teacher: TeacherTypes
}

export default createFragmentContainer(ProfessorNoteHeader, {
  teacher: graphql`
    fragment ProfessorNoteHeader_teacher on Teacher {
      lastName
    }
  `,
  note: graphql`
    fragment ProfessorNoteHeader_note on TeacherNotes {
      createdAt
      updatedAt
    }
  `
})
