import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import useCurrentRoute from '@hooks/useCurrentRoute'
import hamburgerLight from '@assets/images/hamburger-icon-light.svg'
import hamburgerDark from '@assets/images/hamburger-icon-dark.svg'
import closeIcon from '@assets/images/close-icon.svg'
import { poppinsBold3x } from '@StyledComponents/theme/typesets'
import useUserInfo from '@hooks/useUserInfo'
import Link, { LINK_TYPE } from '@components/Link'
import ROUTE, { USER_ACCOUNT_ROUTE } from '@enums/ROUTE'
import USERTYPES from '@enums/USERTYPES'
import TRACKING_EVENT from '@enums/TRACKING_EVENT'
import useGlobalContext from '@hooks/useGlobalContext'
import MODALS from '@enums/MODALS'
import SocialLinks, { SOCIAL_ICON_COLORS, StyledSocialLinks } from './SocialLinks'
import { SocialIcon } from './SocialIconLink'

const authMenuItemOnClick = config => {
  const { setActiveModal, setIsMobileMenuOpen, modal, event } = config

  event.preventDefault()

  setActiveModal(modal)
  setIsMobileMenuOpen(false)
}

export const LOGGED_OUT_MENU_ITEMS = [
  {
    label: 'Log In',
    to: ROUTE.LOGIN,
    type: LINK_TYPE.OLD_RMP,
    trackingLabel: TRACKING_EVENT.LOGIN_CLICK,
    onClick: config => authMenuItemOnClick({ ...config, modal: MODALS.LOGIN })
  },
  {
    label: 'Sign Up',
    to: ROUTE.SIGN_UP,
    type: LINK_TYPE.OLD_RMP,
    trackingLabel: TRACKING_EVENT.SIGN_UP_CLICK,
    onClick: config => authMenuItemOnClick({ ...config, modal: MODALS.SIGN_UP_FORM })
  }
]

export const STUDENT_MENU_ITEMS = [
  {
    label: 'Profile',
    to: USER_ACCOUNT_ROUTE.PROFILE,
    type: LINK_TYPE.INTERNAL,
    onClick: () => {}
  },
  {
    label: 'Account Settings',
    to: USER_ACCOUNT_ROUTE.SETTINGS,
    type: LINK_TYPE.INTERNAL
  },
  {
    label: 'Your Ratings',
    to: USER_ACCOUNT_ROUTE.RATINGS,
    type: LINK_TYPE.INTERNAL
  },
  {
    label: 'My Saved Professors',
    to: USER_ACCOUNT_ROUTE.SAVED_PROFESSORS,
    type: LINK_TYPE.INTERNAL
  }
]

export const TEACHER_MENU_ITEMS = [
  {
    label: 'Profile',
    to: USER_ACCOUNT_ROUTE.PROFILE,
    type: LINK_TYPE.INTERNAL
  }
]

export const MobileMenuWrapper = styled.div`
  display: none;
`

export const MenuBacking = styled.div`
  background: rgba(0, 0, 0, 0.4);
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: -1;
`

const getMenuButton = ({ menuShown, isLightButton }) => {
  if (menuShown) return closeIcon
  return isLightButton ? hamburgerLight : hamburgerDark
}

export const MenuButton = styled.button`
  background: none;
  background-image: url(${({ menuShown, isLightButton }) =>
    getMenuButton({ menuShown, isLightButton })});
  background-repeat: no-repeat;
  background-size: contain;
  border: 0;
  flex: 0 0 auto;
  height: 14px;
  margin: 8px;
  outline: none;
  width: 19px;
`

export const Menu = styled.div`
  background: white;
  bottom: 0;
  box-shadow: 0px 9px 12px rgba(0, 0, 0, 0.25);
  color: ${({ theme }) => theme.color.default};
  display: flex;
  flex-direction: column;
  height: fit-content;
  justify-content: flex-start;
  left: 0;
  margin-top: 53px;
  padding: 0 24px;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 4;

  ${StyledSocialLinks} {
    padding-bottom: 30px;

    ${SocialIcon} {
      height: unset;
      width: unset;
    }
  }
`

export const MenuLink = styled(Link)`
  color: ${props => props.theme.color.default};
  display: block;
  flex: 0 0 auto;
  font-size: ${props => props.theme.fontSize.large};
  font-weight: bold;
  padding-bottom: 36px;
  text-align: left;
  text-decoration: none;
`

export const AccountLink = styled(Link)`
  color: white;
  display: block;
  flex: 0 0 auto;
  font-weight: bold;
  padding: 16px;
  text-decoration: none;
  text-transform: uppercase;
`
export const MenuHeader = styled.div`
  color: black;
  margin: 39px 0 52px 0;
  text-align: left;

  ${poppinsBold3x};
`

export const LogOutLink = styled(Link)`
  font-family: ${props => props.theme.fontFamily.poppins};
  font-size: ${props => props.theme.fontSize.medium};
  line-height: 20px;
  padding-bottom: 42px;
  padding-top: 33px;
  text-align: center;
  text-decoration: none;
`

const getMobileMenuItems = (isLoggedIn, userInfo) => {
  if (!isLoggedIn || !userInfo?.Type) return LOGGED_OUT_MENU_ITEMS

  if (userInfo.Type === USERTYPES.STUDENT) {
    return STUDENT_MENU_ITEMS
  }

  return TEACHER_MENU_ITEMS
}

const getWelcomeText = (isLoggedIn, userInfo) => {
  if (!isLoggedIn) return 'Howdy'

  if (!userInfo?.firstName) return 'My Account'

  return `Hey, ${userInfo.firstName}`
}

export default function MobileMenu({
  isMobileMenuOpen,
  setIsMobileMenuOpen,
  isLightButton = true
}) {
  const userInfo = useUserInfo()
  const isLoggedIn = userInfo?.Email

  const menuHeaderText = getWelcomeText(isLoggedIn, userInfo)
  const menuLinks = getMobileMenuItems(isLoggedIn, userInfo)

  const { setActiveModal, logout } = useGlobalContext()
  const history = useHistory()
  const currentRoute = useCurrentRoute()

  const authMenuItemConfig = {
    setActiveModal,
    setIsMobileMenuOpen,
    logout
  }

  return (
    <MobileMenuWrapper isLoggedIn={isLoggedIn}>
      <MenuButton
        title="Mobile Menu Button"
        menuShown={isMobileMenuOpen}
        onClick={() => {
          setIsMobileMenuOpen(!isMobileMenuOpen)
        }}
        isLightButton={isLightButton}
      />
      {isMobileMenuOpen && (
        <React.Fragment>
          <MenuBacking onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)} />
          <Menu>
            <MenuHeader>
              {menuHeaderText}{' '}
              <span role="img" aria-label="waving emoji">
                👋
              </span>
            </MenuHeader>

            {menuLinks.map((link, i) => (
              <MenuLink
                key={`${link.label}-${i.toString()}`}
                to={link.to}
                type={link.type}
                trackingLabel={link.trackingLabel}
                onClick={event => link?.onClick?.({ ...authMenuItemConfig, event })}
              >
                {link.label}
              </MenuLink>
            ))}

            {isLoggedIn && (
              <LogOutLink
                to={ROUTE.LOGOUT}
                onClick={event => {
                  event.preventDefault()
                  logout()
                  if (currentRoute !== ROUTE.HOME) {
                    history.push({ pathname: ROUTE.HOME, state: { toast: null } })
                  }
                }}
              >
                Log Out
              </LogOutLink>
            )}

            <div>
              <SocialLinks iconColor={SOCIAL_ICON_COLORS.BLACK} />
            </div>
          </Menu>
        </React.Fragment>
      )}
    </MobileMenuWrapper>
  )
}

MobileMenu.propTypes = {
  isMobileMenuOpen: PropTypes.bool,
  setIsMobileMenuOpen: PropTypes.func,
  isLightButton: PropTypes.bool
}
