/**
 * @flow
 * @relayHash 36dbbd3b3d24b2fccccf73dfb4997862
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type GlobalContextProviderQueryVariables = {||};
export type GlobalContextProviderQueryResponse = {|
  +user: ?{|
    +firstName: ?string,
    +id: ?string,
    +legacyId: ?number,
    +Email: ?string,
    +Type: ?string,
    +graduationYear: ?number,
    +emailConfirmed: ?boolean,
    +major: ?string,
    +school: ?{|
      +id: ?string,
      +legacyId: ?number,
      +name: ?string,
      +numRatings: ?number,
      +avgRatingRounded: ?any,
      +summary: ?{|
        +campusCondition: ?number,
        +campusLocation: ?number,
        +careerOpportunities: ?number,
        +clubAndEventActivities: ?number,
        +foodQuality: ?number,
        +internetSpeed: ?number,
        +libraryCondition: ?number,
        +schoolReputation: ?number,
        +schoolSafety: ?number,
        +schoolSatisfaction: ?number,
        +socialActivities: ?number,
      |},
    |},
  |}
|};
export type GlobalContextProviderQuery = {|
  variables: GlobalContextProviderQueryVariables,
  response: GlobalContextProviderQueryResponse,
|};
*/


/*
query GlobalContextProviderQuery {
  user {
    firstName
    id
    legacyId
    Email
    Type
    graduationYear
    emailConfirmed
    major
    school {
      id
      legacyId
      name
      numRatings
      avgRatingRounded
      summary {
        campusCondition
        campusLocation
        careerOpportunities
        clubAndEventActivities
        foodQuality
        internetSpeed
        libraryCondition
        schoolReputation
        schoolSafety
        schoolSatisfaction
        socialActivities
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "legacyId",
  "args": null,
  "storageKey": null
},
v2 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "user",
    "storageKey": null,
    "args": null,
    "concreteType": "User",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "firstName",
        "args": null,
        "storageKey": null
      },
      (v0/*: any*/),
      (v1/*: any*/),
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "Email",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "Type",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "graduationYear",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "emailConfirmed",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "major",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "school",
        "storageKey": null,
        "args": null,
        "concreteType": "School",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "name",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "numRatings",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "avgRatingRounded",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "summary",
            "storageKey": null,
            "args": null,
            "concreteType": "SchoolSummary",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "campusCondition",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "campusLocation",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "careerOpportunities",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "clubAndEventActivities",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "foodQuality",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "internetSpeed",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "libraryCondition",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "schoolReputation",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "schoolSafety",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "schoolSatisfaction",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "socialActivities",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "GlobalContextProviderQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": (v2/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "GlobalContextProviderQuery",
    "argumentDefinitions": [],
    "selections": (v2/*: any*/)
  },
  "params": {
    "operationKind": "query",
    "name": "GlobalContextProviderQuery",
    "id": null,
    "text": "query GlobalContextProviderQuery {\n  user {\n    firstName\n    id\n    legacyId\n    Email\n    Type\n    graduationYear\n    emailConfirmed\n    major\n    school {\n      id\n      legacyId\n      name\n      numRatings\n      avgRatingRounded\n      summary {\n        campusCondition\n        campusLocation\n        careerOpportunities\n        clubAndEventActivities\n        foodQuality\n        internetSpeed\n        libraryCondition\n        schoolReputation\n        schoolSafety\n        schoolSatisfaction\n        socialActivities\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'c798d89fa6e860b5a2562255891b2568';
module.exports = node;
