import React from 'react'
import { withRouter, useLocation, Redirect } from 'react-router-dom'
import styled, { css } from 'styled-components'
import { graphql } from 'react-relay'
import PropTypes from 'prop-types'
import USERTYPES from '@enums/USERTYPES'
import UserTypes from '@types/User.types'
import {
  breakpointMediumAndBelow,
  breakpointSmediumAndBelow,
  breakpointSmallAndBelow
} from '@StyledComponents/theme/helpers/breakpoints'
import QueryWrapper from '@componentUtils/QueryWrapper'
import parseAndSanitize from '@utils/parseAndSanitize'
import UserPagesTabs from '@components/UserPagesTabs'
import generateRouteList from '@src/config/userAccountRouteConfig'
import PageWrapper from '@components/PageWrapper'
import { poppinsBoldest3x } from '@StyledComponents/theme/typesets'
import ROUTE from '@enums/ROUTE'

const PagesIndexWrapper = styled(PageWrapper)``

export const PagesIndex = styled.div`
  min-height: inherit;
  padding: 40px;
  width: 100%;
`

const WelcomeTextArea = styled.div`
  display: flex;
  margin: auto;
  padding: 32px 0 20px;
  width: 755px;

  ${breakpointMediumAndBelow(css`
    padding: 32px 40px 20px;
    width: 100%;
  `)}
`

export const WelcomeText = styled.div`
  display: inline-flex;
  height: 45px;

  ${poppinsBoldest3x};

  ${breakpointSmediumAndBelow(css`
    width: 100%;
    max-width: 688px;
  `)};
  ${breakpointSmallAndBelow(css`
    max-width: 375px;
    font-size: 22px;
  `)}
`

const query = graphql`
  query UserPagesIndexQuery {
    user {
      firstName
      lastName
      Type
      Email
      ...UserPagesTabs_user
    }
  }
`

const getWelcomeArea = user => {
  let welcomeName
  if (user.Type === USERTYPES.STUDENT && user.firstName) {
    welcomeName = parseAndSanitize(user.firstName)
  } else if (user.Type === USERTYPES.TEACHER && user.lastName) {
    welcomeName = `Professor ${parseAndSanitize(user.lastName)}`
  }

  const welcomeText = welcomeName ? `Hey, ${welcomeName}` : 'Hey there'

  return (
    <WelcomeTextArea>
      <WelcomeText>{welcomeText}</WelcomeText>
    </WelcomeTextArea>
  )
}

export function UserPages({ user, loading }) {
  const location = useLocation()
  if (loading) {
    return <PageWrapper>Loading...</PageWrapper>
  }
  const isLoggedIn = !!user?.Email

  /**
   * If a user is not logged in we need to redirect to the client side and
   * set a cookie in the browser to save the redirect path
   */
  if (!isLoggedIn) {
    const postLoginRedirect = `${ROUTE.NEW_LOGIN}?redirect=${location.pathname}`
    return <Redirect to={postLoginRedirect} />
  }

  return (
    <PagesIndexWrapper>
      {/* TODO: Title */}
      {getWelcomeArea(user)}
      <PagesIndex>
        <UserPagesTabs user={user} />
        {generateRouteList()}
      </PagesIndex>
    </PagesIndexWrapper>
  )
}

export const UserPagesIndex = () => {
  return <QueryWrapper query={query} component={UserPages} />
}

UserPages.propTypes = {
  user: UserTypes,
  loading: PropTypes.bool
}

const withRouterUserPagesIndex = withRouter(UserPagesIndex)
withRouterUserPagesIndex.serverQueries = [
  {
    query
  }
]

export default withRouterUserPagesIndex
