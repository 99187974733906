import React from 'react'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'
import CloseButton from '@components/StyledComponents/CloseButton'
import { breakpointSmallAndBelow } from '@StyledComponents/theme/helpers/breakpoints'
import { TOAST_TYPES, TOAST_EVENTS, toastConfigMap } from '@utils/toast'

const StyledToast = styled.div`
  background: white;
  border: 1px solid ${({ theme }) => theme.color.gray4};
  border-radius: 6px;
  box-shadow: 0px 4px 4px rgba(82, 82, 82, 0.3);
  display: flex;
  font-size: ${({ theme }) => theme.fontSize.medium};
  padding: 20px 42px 20px 16px;
  text-align: left;
  z-index: 2;

  ${({ toastType }) => {
    if (toastType === TOAST_TYPES.SUCCESS) {
      return css`
        border-left: 8px solid ${({ theme }) => theme.color.successGreen};
      `
    }

    if (toastType === TOAST_TYPES.ALERT) {
      return css`
        border-left: 8px solid ${({ theme }) => theme.color.errorRed};
      `
    }

    return css`
      border-left: 8px solid ${({ theme }) => theme.color.cyanBlue};
    `
  }}
`

const StyledCloseButton = styled(CloseButton)`
  position: absolute;
  right: 18px;
  top: 16px;
`

export const ToastHeader = styled.div`
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  line-height: 19px;
  padding-bottom: 4px;
`

export const ToastDescription = styled.div`
  line-height: 22px;
  width: 358px;

  ${breakpointSmallAndBelow(css`
    width: 249px;
  `)}
`

const ToastHeaderDescWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const ToastEmoji = styled.div`
  font-size: ${({ theme }) => theme.fontSize.xxlarge};
  padding-right: 16px;
`

const Toast = ({ closeToast, canDismiss = false, toastEvent, children }) => {
  const toastConfig = toastConfigMap[toastEvent]

  return (
    <StyledToast toastType={toastConfig.type}>
      <ToastEmoji role="img" aria-label={toastConfig.label}>
        {toastConfig.emoji}
      </ToastEmoji>
      <ToastHeaderDescWrapper>{children}</ToastHeaderDescWrapper>
      {canDismiss && <StyledCloseButton onClick={closeToast} />}
    </StyledToast>
  )
}

Toast.propTypes = {
  closeToast: PropTypes.func.isRequired,
  canDismiss: PropTypes.bool,
  toastEvent: PropTypes.oneOf(Object.values(TOAST_EVENTS)).isRequired,
  children: PropTypes.node
}

export default Toast
