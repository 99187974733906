import React from 'react'
import styled, { css } from 'styled-components'
import searchBrokenIcon from '@assets/images/search-broken.svg'
import PageWrapper from './PageWrapper'
import AdRail from './Ads/AdRail'
import {
  breakpointLargePlusAndBelow,
  breakpointSmediumAndBelow
} from './StyledComponents/theme/helpers/breakpoints'

const StyledSearchError = styled.div`
  align-items: center;
  background: #eeeeee;
  border: 1px solid #cccccc;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  height: auto;
  justify-content: flex-start;
  max-width: 775px;
  padding: 24px;
  top: 234px;

  ${breakpointLargePlusAndBelow(css`
    margin: 24px;
  `)}
  ${breakpointSmediumAndBelow(css`
    max-width: 334px;
    padding: 12px;
  `)}
`

export const SearchIcon = styled.img`
  box-sizing: content-box;
  height: 24px;
  left: 0px;
  margin-left: 14px;
  margin-right: 14px;
  width: 24px;
  z-index: ${({ theme }) => theme.zIndex.search};
`

const SearchError = () => {
  return (
    <PageWrapper>
      <StyledSearchError>
        <SearchIcon src={searchBrokenIcon} alt="search icon" aria-hidden="true" />
        <div>
          Our search function is currently down and our team is actively working on this issue
        </div>
      </StyledSearchError>
      <AdRail />
    </PageWrapper>
  )
}

export default SearchError
