import React from 'react'
import { createFragmentContainer, graphql } from 'react-relay'
import styled, { css } from 'styled-components'
import SchoolTypes from '@types/School.types'
import { breakpointSmallAndBelow } from '@StyledComponents/theme/helpers/breakpoints'

export const SCHOOL_CARD_LOCATION_TEST_ID = `school_card_location_test_id`

const StyledSchoolCardLocation = styled.div`
  position: absolute;
  right: 18px;
  text-align: right;
  top: 18px;

  ${breakpointSmallAndBelow(
    css`
      margin: 12px 0;
      position: static;
      text-align: left;
    `
  )}
`

const getLocation = school => {
  const { city, state } = school

  let result = city || ''
  if (city && state) result += ', '
  if (state) result += state
  return result
}

export const SchoolCardLocation = ({ school }) => {
  if (!school) return null

  return (
    <StyledSchoolCardLocation data-testid={SCHOOL_CARD_LOCATION_TEST_ID}>
      {getLocation(school)}
    </StyledSchoolCardLocation>
  )
}

export default createFragmentContainer(SchoolCardLocation, {
  school: graphql`
    fragment SchoolCardLocation_school on School {
      city
      state
    }
  `
})

SchoolCardLocation.propTypes = {
  school: SchoolTypes
}
