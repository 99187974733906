/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/button-has-type */
// Eslint won't allow a dynamic button type value, but proptypes will ensure
// the type is valid

import React from 'react'
import PropTypes from 'prop-types'

import LoadingIcon from '@StyledComponents/LoadingIcon'
import { TrackingEventTypes } from '@enums/TRACKING_EVENT'

const BUTTON_TYPES = ['button', 'submit', 'reset']

export default function Button(props) {
  const {
    trackingLabel,
    children,
    onClick,
    isLoading,
    disabled,
    type,
    isLoggedIn,
    isMobileInputFullWidth,
    id,
    ...rest
  } = props
  const trackingOnClick = event => {
    if (typeof onClick === 'function') onClick(event)
  }

  return (
    <button
      {...rest}
      type={type || 'button'}
      disabled={isLoading || disabled}
      onClick={trackingOnClick}
      id={id}
    >
      {isLoading ? <LoadingIcon /> : children}
    </button>
  )
}

Button.propTypes = {
  trackingLabel: TrackingEventTypes,
  children: PropTypes.node,
  type: PropTypes.oneOf(BUTTON_TYPES),
  isLoading: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  isLoggedIn: PropTypes.bool,
  isMobileInputFullWidth: PropTypes.bool,
  id: PropTypes.string
}
