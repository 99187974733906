import React, { useEffect } from 'react'
import styled, { css } from 'styled-components'
import ROUTE from '@enums/ROUTE'
import { hotjar } from 'react-hotjar'
import { breakpointSmediumAndBelow } from '@StyledComponents/theme/helpers/breakpoints'
import TRACKING_EVENT from '@enums/TRACKING_EVENT'
import MODALS from '@enums/MODALS'
import getEnv from '@utils/getEnv'
import { Button } from '@StyledComponents/Buttons'
import ModalButtonLink, { StyledButton, StyledLink } from '@components/ModalButtonLink'
import { poppinsBoldest3x } from '@StyledComponents/theme/typesets'
import SignupInstructionalSlides from '@components/SignupInstructionalSlides'
import FullPageModalContainer from './Templates/FullPageModal'

export const StyledSignup = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  padding: 43px 0 48px 0;
  width: 756px;

  ${breakpointSmediumAndBelow(
    css`
      width: 345px;
    `
  )}

  ${StyledButton},
  ${StyledLink} {
    cursor: pointer;
    font-size: ${props => props.theme.fontSize.medium};
    font-weight: ${props => props.theme.fontWeight.bold};
    text-decoration: underline;
  }

  a {
    font-weight: ${props => props.theme.fontWeight.bold};
  }
`

export const SignupHeaderArea = styled.div`
  margin-bottom: 18px;
  max-width: 100%;
  width: 440px;

  ${breakpointSmediumAndBelow(
    css`
      margin-bottom: 8px;
      width: 300px;
    `
  )}
`
export const SignupHeader = styled.h1`
  margin-bottom: 12px;
  text-align: center;

  ${poppinsBoldest3x};

  ${breakpointSmediumAndBelow(
    css`
      font-size: ${props => props.theme.fontSize.xlarge};
    `
  )}
`
export const SignupSubHeader = styled.h2`
  font-family: ${props => props.theme.fontFamily.helveticaNeue};
  font-size: ${props => props.theme.fontSize.medium};
  text-align: center;

  ${breakpointSmediumAndBelow(
    css`
      font-size: ${props => props.theme.fontSize.default};
      line-height: 22px;
    `
  )}
`
export const SigninSubHeader = styled.h2`
  font-family: ${props => props.theme.fontFamily.helveticaNeue};
  font-size: ${props => props.theme.fontSize.medium};
  text-align: center;

  ${breakpointSmediumAndBelow(
    css`
      line-height: 22px;
    `
  )}

  a {
    text-decoration: none;
  }
`
const SignupFormArea = styled.div`
  margin: 0 auto;
  max-width: 100%;
  width: 310px;
`
export const SignupFormTextFields = styled.div`
  align-content: space-between;
  display: flex;
  flex-direction: column;
`
export const TermsCheckLabel = styled.label`
  align-items: center;
  display: flex;
  height: 27px;
  justify-content: space-between;
  left: -7px;
  max-width: 100%;
  position: relative;
  width: 325px;
`

export const SignupSubmitButton = styled(Button)`
  font-size: ${props => props.theme.fontSize.medium};
  height: 48px;
  margin: 14px auto 18px;
  max-width: calc(100% - 40px);
  width: 305px;
`

export default function SignupPromptModal() {
  const hotjarID = getEnv(getEnv('REACT_APP_HOTJAR_KEY'))

  useEffect(() => {
    hotjar.initialize(hotjarID)
    hotjar.event('sign-up-prompt')
  }, [])

  return (
    <FullPageModalContainer modal={MODALS.SIGN_UP_PROMPT}>
      <StyledSignup>
        <SignupHeaderArea>
          <SignupHeader> Create an Account</SignupHeader>
          <SignupSubHeader>
            You&#39;ll need an account to do this. Please{' '}
            <ModalButtonLink
              trackingLabel={TRACKING_EVENT.REGWALL_LOGIN_CLICK_PROF}
              modal={MODALS.LOGIN}
              to={ROUTE.LOGIN}
            >
              log in
            </ModalButtonLink>{' '}
            or create an account.
          </SignupSubHeader>
        </SignupHeaderArea>
        <SignupInstructionalSlides />
        <SignupFormArea>
          <ModalButtonLink
            trackingLabel={TRACKING_EVENT.REGWALL_SIGNUP_CLICK_PROF}
            modal={MODALS.SIGN_UP_FORM}
            to={ROUTE.SIGN_UP}
          >
            <SignupSubmitButton>Sign Up</SignupSubmitButton>
          </ModalButtonLink>
          <SigninSubHeader>
            I already have an account.{' '}
            <ModalButtonLink
              trackingLabel={TRACKING_EVENT.REGWALL_LOGIN_CLICK_PROF}
              modal={MODALS.LOGIN}
              to={ROUTE.LOGIN}
            >
              Log In
            </ModalButtonLink>
          </SigninSubHeader>
        </SignupFormArea>
      </StyledSignup>
    </FullPageModalContainer>
  )
}
