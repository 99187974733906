import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { breakpointMediumAndBelow } from '@StyledComponents/theme/helpers/breakpoints'

export const StyledFormButtonsArea = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-right: 100px;
  width: 100%;
  ${breakpointMediumAndBelow(css`
    justify-content: center;
    padding-right: 0;
  `)}
`
const FormButtons = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  width: ${({ theme }) => theme.mainColumnWidth.form};
`

const FormButtonsArea = ({ children }) => {
  return (
    <StyledFormButtonsArea>
      <FormButtons>{children}</FormButtons>
    </StyledFormButtonsArea>
  )
}

FormButtonsArea.propTypes = {
  children: PropTypes.node
}

export default FormButtonsArea
