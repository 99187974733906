import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import Cookies from '@utils/Cookies'
import Checkbox from '@StyledComponents/Checkbox'
import {
  breakpointSmallAndBelow,
  breakpointMediumAndBelow,
  getPageContainerMaxWidth
} from '@StyledComponents/theme/helpers/breakpoints'
import { poppinsBoldest, poppinsBold } from '@StyledComponents/theme/typesets'
import COOKIE from '@enums/COOKIE'
import SEOMetaInfo from '@components/SEOMetaInfo'

export const Heading = styled.h1`
  font-size: ${props => props.theme.fontSize.large4x};

  ${poppinsBoldest};
`

const StyledCCPAPage = styled.div`
  margin: 0 auto;
  max-width: 100%;
  min-height: calc(
    100vh - ${props => props.theme.header.height + props.theme.leaderboard.height}px
  );
  padding: 24px 64px;
  text-align: left;
  width: 1152px;

  ${({ theme }) => getPageContainerMaxWidth(theme)};

  ${breakpointMediumAndBelow(css`
    padding: 24px 32px;
  `)}
  ${breakpointSmallAndBelow(css`
    padding: 24px 24px;
  `)}

  span,
  p {
    line-height: 1.5;
  }
`

const FormContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 25px 0;
  width: 100%;
`

const StyledCCPAForm = styled.form`
  border: solid 1px ${props => props.theme.color.gray4};
  display: block;
  max-width: 100%;
  width: 564px;
`

const CheckBoxArea = styled.div`
  display: flex;
  padding: 24px 32px 32px;
  user-select: none;
`

export const FormHeading = styled.h2`
  background: ${({ optOut, theme }) => (optOut ? theme.color.red : theme.color.green)};
  padding: 16px 24px;
  width: 100%;

  ${poppinsBold};
`

export const LabelHeading = styled.span`
  display: inline-block;
  margin-bottom: 5px;

  ${poppinsBold};
`

const CheckboxContainer = styled.div`
  display: flex;
`

const Label = styled.label`
  padding: 5px 15px;
`

export default function CCPAPage() {
  return (
    <StyledCCPAPage>
      <SEOMetaInfo title="CCPA | RateMyProfessors" />
      <Heading>CCPA</Heading>
      <p>
        As described in the Privacy Policy, we share with and allow third-party service providers
        and advertisers to collect certain personal information about Site visitors (such as IP
        address or device identifiers) for Customized Advertising purposes.
      </p>
      <br />
      <p>
        If you do not want us to sell or share your personal information with third parties, you can
        select the opt-out button below.
      </p>
      <FormContainer>
        <CCPAForm />
      </FormContainer>
      <p>
        Your opt-out is tied to a specific device/browser. If you access this site from other
        devices or browsers, make a separate opt-out request from those devices or browsers to
        ensure your choice applies to the data collected when you use those devices or browsers.
      </p>
    </StyledCCPAPage>
  )
}

const onCheckboxChange = ({ target }, setOptOut) => {
  const out = target.checked
  setOptOut(out)
  if (out) {
    Cookies.set(COOKIE.CCPA_OPT_OUT, 'true')
  } else {
    Cookies.remove(COOKIE.CCPA_OPT_OUT)
  }

  // TODO: Update privacy settings with Nobid functionality for OneTrust
}

export function CCPAForm() {
  const [optOut, setOptOut] = useState(Cookies.get(COOKIE.CCPA_OPT_OUT) !== undefined)
  return (
    <StyledCCPAForm>
      <FormHeading optOut={optOut}>Your Status: Opted {optOut ? 'Out' : 'In'}</FormHeading>
      <CheckBoxArea>
        <CheckboxContainer>
          <Checkbox
            id="opt-in-out"
            checked={optOut}
            onChange={e => onCheckboxChange(e, setOptOut)}
          />
          <Label htmlFor="opt-in-out">
            <LabelHeading>Opt Out</LabelHeading>
            <br />
            <span>
              Opting out of the sale or sharing of this information will not stop the delivery of
              all ads on this Site. In addition, we will continue to share Site visitor personal
              information with our service providers following an opt-out so that they can perform
              advertising-related functions on our behalf, such as measuring ad effectiveness,
              managing how many times an ad is shown, reporting on ad performance, ensuring services
              are working correctly and securely, providing aggregate statistics and analytics,
              improving when and where you may see ads, and/or reducing ad fraud.
            </span>
          </Label>
        </CheckboxContainer>
      </CheckBoxArea>
    </StyledCCPAForm>
  )
}
