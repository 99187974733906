import React from 'react'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'
import { applyBreakpointMin } from '@StyledComponents/theme/helpers/breakpoints'
import { poppinsBold } from '@StyledComponents/theme/typesets'
import { Helmet } from 'react-helmet'

export const StyledInfoPage = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: calc(
    100vh - ${props => props.theme.header.height + props.theme.leaderboard.height}px
  );

  ${applyBreakpointMin(
    'large',
    css`
      max-width: calc(100% - ${props => props.theme.adRail.width}px);
    `
  )}
`

const Header = styled.h1`
  font-size: ${props => props.theme.fontSize[props.headerSize]};

  ${poppinsBold};
`

const Subhead = styled.h2`
  font-size: ${props => props.theme.fontSize.large3x};
  font-weight: ${props => props.theme.fontWeight.bold};
`

export default function InfoPage({ header, subheader, pageTitle, headerSize = 'large4x' }) {
  return (
    <StyledInfoPage>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <Header headerSize={headerSize}>{header}</Header>
      <Subhead>{subheader}</Subhead>
    </StyledInfoPage>
  )
}
InfoPage.propTypes = {
  header: PropTypes.string,
  subheader: PropTypes.string,
  pageTitle: PropTypes.string,
  headerSize: PropTypes.string
}
