const STATES = [
  {
    value: 'AK',
    countryId: '0',
    label: 'Alaska'
  },
  {
    value: 'AL',
    countryId: '0',
    label: 'Alabama'
  },
  {
    value: 'AR',
    countryId: '0',
    label: 'Arkansas'
  },
  {
    value: 'AZ',
    countryId: '0',
    label: 'Arizona'
  },
  {
    value: 'CA',
    countryId: '0',
    label: 'California'
  },
  {
    value: 'CO',
    countryId: '0',
    label: 'Colorado'
  },
  {
    value: 'CT',
    countryId: '0',
    label: 'Connecticut'
  },
  {
    value: 'DC',
    countryId: '0',
    label: 'Washington DC'
  },
  {
    value: 'DE',
    countryId: '0',
    label: 'Delaware'
  },
  {
    value: 'FL',
    countryId: '0',
    label: 'Florida'
  },
  {
    value: 'GA',
    countryId: '0',
    label: 'Georgia'
  },
  {
    value: 'HI',
    countryId: '0',
    label: 'Hawaii'
  },
  {
    value: 'IA',
    countryId: '0',
    label: 'Iowa'
  },
  {
    value: 'ID',
    countryId: '0',
    label: 'Idaho'
  },
  {
    value: 'IL',
    countryId: '0',
    label: 'Illinois'
  },
  {
    value: 'IN',
    countryId: '0',
    label: 'Indiana'
  },
  {
    value: 'KS',
    countryId: '0',
    label: 'Kansas'
  },
  {
    value: 'KY',
    countryId: '0',
    label: 'Kentucky'
  },
  {
    value: 'LA',
    countryId: '0',
    label: 'Louisiana'
  },
  {
    value: 'MA',
    countryId: '0',
    label: 'Massachusetts'
  },
  {
    value: 'MD',
    countryId: '0',
    label: 'Maryland'
  },
  {
    value: 'ME',
    countryId: '0',
    label: 'Maine'
  },
  {
    value: 'MI',
    countryId: '0',
    label: 'Michigan'
  },
  {
    value: 'MN',
    countryId: '0',
    label: 'Minnesota'
  },
  {
    value: 'MO',
    countryId: '0',
    label: 'Missouri'
  },
  {
    value: 'MS',
    countryId: '0',
    label: 'Mississippi'
  },
  {
    value: 'MT',
    countryId: '0',
    label: 'Montana'
  },
  {
    value: 'NC',
    countryId: '0',
    label: 'North Carolina'
  },
  {
    value: 'ND',
    countryId: '0',
    label: 'North Dakota'
  },
  {
    value: 'NE',
    countryId: '0',
    label: 'Nebraska'
  },
  {
    value: 'NH',
    countryId: '0',
    label: 'New Hampshire'
  },
  {
    value: 'NJ',
    countryId: '0',
    label: 'New Jersey'
  },
  {
    value: 'NM',
    countryId: '0',
    label: 'New Mexico'
  },
  {
    value: 'NV',
    countryId: '0',
    label: 'Nevada'
  },
  {
    value: 'NY',
    countryId: '0',
    label: 'New York'
  },
  {
    value: 'OH',
    countryId: '0',
    label: 'Ohio'
  },
  {
    value: 'OK',
    countryId: '0',
    label: 'Oklahoma'
  },
  {
    value: 'OR',
    countryId: '0',
    label: 'Oregon'
  },
  {
    value: 'PA',
    countryId: '0',
    label: 'Pennsylvania'
  },
  {
    value: 'RI',
    countryId: '0',
    label: 'Rhode Island'
  },
  {
    value: 'SC',
    countryId: '0',
    label: 'South Carolina'
  },
  {
    value: 'SD',
    countryId: '0',
    label: 'South Dakota'
  },
  {
    value: 'TN',
    countryId: '0',
    label: 'Tennessee'
  },
  {
    value: 'TX',
    countryId: '0',
    label: 'Texas'
  },
  {
    value: 'UT',
    countryId: '0',
    label: 'Utah'
  },
  {
    value: 'VA',
    countryId: '0',
    label: 'Virginia'
  },
  {
    value: 'VT',
    countryId: '0',
    label: 'Vermont'
  },
  {
    value: 'WA',
    countryId: '0',
    label: 'Washington'
  },
  {
    value: 'WI',
    countryId: '0',
    label: 'Wisconsin'
  },
  {
    value: 'WV',
    countryId: '0',
    label: 'West Virginia'
  },
  {
    value: 'WY',
    countryId: '0',
    label: 'Wyoming'
  },
  {
    value: 'AB',
    countryId: '1',
    label: 'Alberta'
  },
  {
    value: 'BC',
    countryId: '1',
    label: 'British Columbia'
  },
  {
    value: 'MB',
    countryId: '1',
    label: 'Manitoba'
  },
  {
    value: 'NB',
    countryId: '1',
    label: 'New Brunswick'
  },
  {
    value: 'NL',
    countryId: '1',
    label: 'Newfoundland and Labrador'
  },
  {
    value: 'NS',
    countryId: '1',
    label: 'Nova Scotia'
  },
  {
    value: 'NU',
    countryId: '1',
    label: 'Nunavut'
  },
  {
    value: 'ON',
    countryId: '1',
    label: 'Ontario'
  },
  {
    value: 'PE',
    countryId: '1',
    label: 'Prince Edward Island'
  },
  {
    value: 'QC',
    countryId: '1',
    label: 'Quebec'
  },
  {
    value: 'SK',
    countryId: '1',
    label: 'Saskatchewan'
  },
  {
    value: 'YT',
    countryId: '1',
    label: 'Yukon Territory'
  },
  {
    value: 'BEDFORDSHIRE',
    countryId: '2',
    label: 'Bedfordshire'
  },
  {
    value: 'BERKSHIRE',
    countryId: '2',
    label: 'Bershire'
  },
  {
    value: 'BIRMINGHAM',
    countryId: '2',
    label: 'Birmingham'
  },
  {
    value: 'BRISTOL',
    countryId: '2',
    label: 'Bristol'
  },
  {
    value: 'BUCKINGHAMSHIRE',
    countryId: '2',
    label: 'Buckinghamshire'
  },
  {
    value: 'CAMBRIDGESHIRE',
    countryId: '2',
    label: 'Cambridgeshire'
  },
  {
    value: 'CHESHIRE',
    countryId: '2',
    label: 'Cheshire'
  },
  {
    value: 'CORNWALL',
    countryId: '2',
    label: 'Cornwall'
  },
  {
    value: 'CUMBRIA',
    countryId: '2',
    label: 'Cumbria'
  },
  {
    value: 'DERBYSHIRE',
    countryId: '2',
    label: 'Derbyshire'
  },
  {
    value: 'DEVON',
    countryId: '2',
    label: 'Devon'
  },
  {
    value: 'DORSET',
    countryId: '2',
    label: 'Dorset'
  },
  {
    value: 'DUMBARTON',
    countryId: '2',
    label: 'Dumbarton'
  },
  {
    value: 'DURHAM',
    countryId: '2',
    label: 'Durham'
  },
  {
    value: 'EAST SUSSEX',
    countryId: '2',
    label: 'East Sussex'
  },
  {
    value: 'EAST YORKSHIRE',
    countryId: '2',
    label: 'East Yorkshire'
  },
  {
    value: 'ESSEX',
    countryId: '2',
    label: 'Essex'
  },
  {
    value: 'GLOUCESTERSHIRE',
    countryId: '2',
    label: 'Gloucestershire'
  },
  {
    value: 'HAMPSHIRE',
    countryId: '2',
    label: 'Hampshire'
  },
  {
    value: 'HEREFORDSHIRE',
    countryId: '2',
    label: 'Herefordshire'
  },
  {
    value: 'HERTFORDSHIRE',
    countryId: '2',
    label: 'Hertfordshire'
  },
  {
    value: 'KENT',
    countryId: '2',
    label: 'Kent'
  },
  {
    value: 'LANARKSHIRE',
    countryId: '2',
    label: 'Lanarkshire'
  },
  {
    value: 'LANCASHIRE',
    countryId: '2',
    label: 'Lancashire'
  },
  {
    value: 'LEICESTERSHIRE',
    countryId: '2',
    label: 'Leicestershire'
  },
  {
    value: 'LINCOLNSHIRE',
    countryId: '2',
    label: 'Lincolnshire'
  },
  {
    value: 'LONDON',
    countryId: '2',
    label: 'London'
  },
  {
    value: 'MANCHESTER',
    countryId: '2',
    label: 'Manchester'
  },
  {
    value: 'MIDDLESEX',
    countryId: '2',
    label: 'Middlesex'
  },
  {
    value: 'NORFOLK',
    countryId: '2',
    label: 'Norfolk'
  },
  {
    value: 'NORTH YORKSHIRE',
    countryId: '2',
    label: 'North Yorkshire'
  },
  {
    value: 'NORTHAMTONSHIRE',
    countryId: '2',
    label: 'Northamtonshire'
  },
  {
    value: 'NORTHUMBERLAND',
    countryId: '2',
    label: 'Northumberland'
  },
  {
    value: 'NOTTINGHAMSHIRE',
    countryId: '2',
    label: 'Nottinghamshire'
  },
  {
    value: 'OXFORDSHIRE',
    countryId: '2',
    label: 'Oxfordshire'
  },
  {
    value: 'PLYMOUTH',
    countryId: '2',
    label: 'Plymouth'
  },
  {
    value: 'SHROPSHIRE',
    countryId: '2',
    label: 'Shropshire'
  },
  {
    value: 'SOMERSET',
    countryId: '2',
    label: 'Somerset'
  },
  {
    value: 'SOUTH DEVON',
    countryId: '2',
    label: 'South Devon'
  },
  {
    value: 'SOUTH YORKSHIRE',
    countryId: '2',
    label: 'South Yorkshire'
  },
  {
    value: 'STAFFORDSHIRE',
    countryId: '2',
    label: 'Staffordshire'
  },
  {
    value: 'SURREY',
    countryId: '2',
    label: 'Surrey'
  },
  {
    value: 'TYNE AND WEAR',
    countryId: '2',
    label: 'Tyne and Wear'
  },
  {
    value: 'WARWICKSHIRE',
    countryId: '2',
    label: 'Warwickshire'
  },
  {
    value: 'WEST MIDLANDS',
    countryId: '2',
    label: 'West Midlands'
  },
  {
    value: 'WEST SUSSEX',
    countryId: '2',
    label: 'West Sussex'
  },
  {
    value: 'WEST YORKSHIRE',
    countryId: '2',
    label: 'West Yorkshire'
  },
  {
    value: 'WILTSHIRE',
    countryId: '2',
    label: 'Wiltshire'
  },
  {
    value: 'WORCESTERSHIRE',
    countryId: '2',
    label: 'Worcestershire'
  },
  {
    value: 'YORKSHIRE',
    countryId: '2',
    label: 'Yorkshire'
  },
  {
    value: 'ABERDEENSHIRE',
    countryId: '5',
    label: 'Aberdeenshire'
  },
  {
    value: 'ANGUS',
    countryId: '5',
    label: 'Angus'
  },
  {
    value: 'ARGYLL',
    countryId: '5',
    label: 'Argyll'
  },
  {
    value: 'AYRSHIRE',
    countryId: '5',
    label: 'Ayrshire'
  },
  {
    value: 'CLACKMANNANSHIR',
    countryId: '5',
    label: 'Clackmannanshir'
  },
  {
    value: 'DERBYSHIRE',
    countryId: '5',
    label: 'Derbyshire'
  },
  {
    value: 'DUMBARTONSHIRE',
    countryId: '5',
    label: 'Dumbartonshire'
  },
  {
    value: 'DUMFRIESHIRE',
    countryId: '5',
    label: 'Dumfrieshire'
  },
  {
    value: 'EDINBURGH',
    countryId: '5',
    label: 'Edinburgh'
  },
  {
    value: 'FIFE',
    countryId: '5',
    label: 'Fife'
  },
  {
    value: 'GLASGOW',
    countryId: '5',
    label: 'Glasgow'
  },
  {
    value: 'LANARKSHIRE',
    countryId: '5',
    label: 'Lanarkshire'
  },
  {
    value: 'LAUDERDALE',
    countryId: '5',
    label: 'Lauderdale'
  },
  {
    value: 'LOTHIAN',
    countryId: '5',
    label: 'Lothian'
  },
  {
    value: 'ORKNEY ISLANDS',
    countryId: '5',
    label: 'Orkney Islands'
  },
  {
    value: 'RENFREWSHIRE',
    countryId: '5',
    label: 'Renfrewshire'
  },
  {
    value: 'STIRLING',
    countryId: '5',
    label: 'Stirling'
  },
  {
    value: 'STIRLINGSHIRE',
    countryId: '5',
    label: 'Stirlingshire'
  },
  {
    value: 'ANTRIM',
    countryId: '6',
    label: 'Antrim'
  },
  {
    value: 'DOWN',
    countryId: '6',
    label: 'Down'
  },
  {
    value: 'DURHAM',
    countryId: '6',
    label: 'Durham'
  },
  {
    value: 'FERMANAGH',
    countryId: '6',
    label: 'Fermanagh'
  },
  {
    value: 'LONDONDERRY',
    countryId: '6',
    label: 'Londonderry'
  },
  {
    value: 'TYRONE',
    countryId: '6',
    label: 'Tyrone'
  },
  {
    value: 'CARDIFF',
    countryId: '7',
    label: 'Cardiff'
  },
  {
    value: 'CEREDIGION',
    countryId: '7',
    label: 'Ceredigion'
  },
  {
    value: 'CONWY',
    countryId: '7',
    label: 'Conwy'
  },
  {
    value: 'DENBIGHSHIRE',
    countryId: '7',
    label: 'Denbighshire'
  },
  {
    value: 'DYFED',
    countryId: '7',
    label: 'Dyfed'
  },
  {
    value: 'FLINTSHIRE',
    countryId: '7',
    label: 'Flintshire'
  },
  {
    value: 'GLAMORGAN',
    countryId: '7',
    label: 'Glamorgan'
  },
  {
    value: 'GWENT',
    countryId: '7',
    label: 'Gwent'
  },
  {
    value: 'Gwynedd',
    countryId: '7',
    label: 'Gwynedd'
  },
  {
    value: 'NORTH WALES',
    countryId: '7',
    label: 'North Wales'
  },
  {
    value: 'POWYS',
    countryId: '7',
    label: 'Powys'
  },
  {
    value: 'SOUTH GLAMORGAN',
    countryId: '7',
    label: 'South Glamorgan'
  },
  {
    value: 'SWANSEA',
    countryId: '7',
    label: 'Swansea'
  },
  {
    value: 'WEST GLAMORGAN',
    countryId: '7',
    label: 'West Glamorgan'
  },
  {
    value: 'WREXHAM',
    countryId: '7',
    label: 'Wrexham'
  },
  {
    value: 'NT',
    countryId: '1',
    label: 'Northwest Territories'
  },
  {
    value: 'ISLE OF WIGHT',
    countryId: '2',
    label: 'Isle of Wight'
  },
  {
    value: 'SUFFOLK',
    countryId: '2',
    label: 'Suffolk'
  },
  {
    value: 'AMER. SAMOA',
    countryId: '0',
    label: 'American Samoa'
  },
  {
    value: 'GUAM',
    countryId: '0',
    label: 'Guam'
  },
  {
    value: 'N. MARIANA IS',
    countryId: '0',
    label: 'Northern Mariana Islands'
  },
  {
    value: 'PUERTO RICO',
    countryId: '0',
    label: 'Puerto Rico'
  },
  {
    value: 'US VI',
    countryId: '0',
    label: 'U.S. Virgin Islands'
  }
]

export default STATES
