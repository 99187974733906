/**
 * @flow
 * @relayHash 1eac20ffa8ea8ea3cada68c4db1329ac
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type AddSchoolFormMutationVariables = {|
  name: string,
  nickname?: ?string,
  city: string,
  state: string,
  country: string,
  website: string,
  email: string,
  recaptcha: string,
|};
export type AddSchoolFormMutationResponse = {|
  +schoolCreate: ?{|
    +validation: ?{|
      +id: ?string,
      +success: ?boolean,
      +errors: ?$ReadOnlyArray<?{|
        +field: ?string,
        +messages: ?$ReadOnlyArray<?string>,
      |}>,
    |}
  |}
|};
export type AddSchoolFormMutation = {|
  variables: AddSchoolFormMutationVariables,
  response: AddSchoolFormMutationResponse,
|};
*/


/*
mutation AddSchoolFormMutation(
  $name: String!
  $nickname: String
  $city: String!
  $state: String!
  $country: String!
  $website: String!
  $email: String!
  $recaptcha: String!
) {
  schoolCreate(name: $name, nickname: $nickname, city: $city, state: $state, country: $country, website: $website, email: $email, recaptcha: $recaptcha) {
    validation {
      id
      success
      errors {
        field
        messages
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "name",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "nickname",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "city",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "state",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "country",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "website",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "email",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "recaptcha",
    "type": "String!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "schoolCreate",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "city",
        "variableName": "city"
      },
      {
        "kind": "Variable",
        "name": "country",
        "variableName": "country"
      },
      {
        "kind": "Variable",
        "name": "email",
        "variableName": "email"
      },
      {
        "kind": "Variable",
        "name": "name",
        "variableName": "name"
      },
      {
        "kind": "Variable",
        "name": "nickname",
        "variableName": "nickname"
      },
      {
        "kind": "Variable",
        "name": "recaptcha",
        "variableName": "recaptcha"
      },
      {
        "kind": "Variable",
        "name": "state",
        "variableName": "state"
      },
      {
        "kind": "Variable",
        "name": "website",
        "variableName": "website"
      }
    ],
    "concreteType": "ValidationResponse",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "validation",
        "storageKey": null,
        "args": null,
        "concreteType": "ValidationResult",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "success",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "errors",
            "storageKey": null,
            "args": null,
            "concreteType": "FieldErrorType",
            "plural": true,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "field",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "messages",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "AddSchoolFormMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "AddSchoolFormMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "AddSchoolFormMutation",
    "id": null,
    "text": "mutation AddSchoolFormMutation(\n  $name: String!\n  $nickname: String\n  $city: String!\n  $state: String!\n  $country: String!\n  $website: String!\n  $email: String!\n  $recaptcha: String!\n) {\n  schoolCreate(name: $name, nickname: $nickname, city: $city, state: $state, country: $country, website: $website, email: $email, recaptcha: $recaptcha) {\n    validation {\n      id\n      success\n      errors {\n        field\n        messages\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'bb3cf52c535ee2fda0f5e667c44bd33f';
module.exports = node;
