import React, { useContext, useState } from 'react'
import PropTypes from 'prop-types'

import TextField from '@StyledComponents/TextField'
import FormContext from '@contexts/FormContext'
import FieldWrapper from './FieldWrapper'

const TextInput = ({
  disabled,
  name,
  className,
  helperText,
  noErrState,
  setHelperTextToError,
  ...props
}) => {
  const [hasErrors, setHasErrors] = useState(false)
  const { locked, model, onChange } = useContext(FormContext)
  return (
    <FieldWrapper
      fieldName={name}
      className={className}
      setHasErrors={noErrState ? () => {} : setHasErrors}
      helperText={helperText || (setHelperTextToError && hasErrors && setHelperTextToError)}
    >
      <TextField
        hasError={hasErrors}
        name={name}
        disabled={locked || disabled}
        onChange={onChange}
        value={model?.[name] || ''}
        {...props}
      />
    </FieldWrapper>
  )
}

TextInput.propTypes = {
  disabled: PropTypes.bool,
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  helperText: PropTypes.string,
  noErrState: PropTypes.bool,
  setHelperTextToError: PropTypes.string
}

export default TextInput
