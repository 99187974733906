/* eslint-disable no-unused-vars */
/* stylelint-disable comment-empty-line-before */
/* stylelint-disable  */
/* stylelint-disable order/properties-alphabetical-order */
/* istanbul ignore file */
import React, { useState } from 'react'
import * as Sentry from '@sentry/react'
import styled from 'styled-components'
import UserTypes from '@types/User.types'
import useGlobalContext from '@hooks/useGlobalContext'
import MODALS from '@enums/MODALS'
import Button from '@components/Button'

const Sandbox = styled.div`
  min-height: 70vh;
  min-width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
`
const Title = styled.div`
  display: inline-block;
  margin-bottom: 60px;
`
const TitleText = styled.div`
  margin-bottom: 60px;
  font-size: 40px;
  line-height: 80px;
  float: left;
`
const TitleBadge = styled.div`
  font-size: 80px;
  float: left;
`

const buildOnItemSelected = onItemSelectedConfig => newSearchConfig => {
  const { setSchoolName, setIsSchoolSearch, isSchoolSearch, setValue } = onItemSelectedConfig

  const { history, event, itemType, item, setIsOpen } = newSearchConfig

  event.preventDefault()
  setSchoolName(item.name)
  setIsSchoolSearch(false)
  setValue('')
}

export default function GUISandboxPage({ user }) {
  const { setActiveModal } = useGlobalContext()
  const [schoolName, setSchoolName] = useState('')
  const [isSchoolSearch, setIsSchoolSearch] = useState(false)
  const [value, setValue] = useState(null)

  const onItemSelected = buildOnItemSelected({
    setSchoolName,
    setIsSchoolSearch,
    isSchoolSearch,
    setValue
  })

  return (
    <Sandbox>
      <Title>
        <TitleText>UI Sandbox</TitleText>
        <TitleBadge>🏜</TitleBadge>
      </Title>

      <Button
        onClick={() => {
          try {
            throw new Error('Sentry Test Error')
          } catch (error) {
            Sentry.captureException(error)
          }
        }}
      >
        Throw test error
      </Button>

      <Button onClick={() => setActiveModal(MODALS.PROFESSOR_SIGN_UP)}>
        Open professor sign up
      </Button>
    </Sandbox>
  )
}

GUISandboxPage.propTypes = {
  user: UserTypes
}
