import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import FormContext from '@contexts/FormContext'
import { MAX_COMMENT_LENGTH } from '@src/CONSTANTS'
import FieldWrapper from './FieldWrapper'

const TextAreaContainer = styled.div`
  align-items: flex-end;
  display: flex;
  flex-direction: column;
`

export const StyledTextArea = styled.textarea`
  border: solid 1px ${({ invalid, theme }) => (invalid ? theme.color.error : theme.color.gray4)};
  border-radius: 3px;
  font-family: ${props => props.theme.fontFamily.helvetica};
  font-size: ${props => props.theme.fontSize.medium};
  outline: none;
  padding: 16px;
  resize: none;
  width: 100%;

  &:focus,
  &:hover {
    border: 1px solid
      ${({ theme, invalid }) => (invalid ? theme.color.error : theme.color.inputSelect)};
  }

  ::placeholder {
    color: ${({ theme }) => theme.color.gray11};
  }
`

export const LengthCounter = styled.span`
  color: ${({ theme, invalid }) => (invalid ? theme.color.error : theme.color.gray11)};
  font-family: ${props => props.theme.fontFamily.helveticaNeue};
  font-weight: ${props => props.theme.fontWeight.bold};
  margin-top: 4px;
`

const FormTextArea = ({
  maxLength = MAX_COMMENT_LENGTH,
  name,
  placeholder,
  disabled,
  ...props
}) => {
  const { locked, model, onChange } = useContext(FormContext)
  const invalid = model[name]?.length > maxLength
  return (
    <FieldWrapper fieldName={name}>
      <TextAreaContainer>
        <StyledTextArea
          disabled={locked || disabled}
          onChange={onChange}
          value={(model && model[name]) || ''}
          invalid={invalid}
          name={name}
          placeholder={placeholder}
          rows={9}
          {...props}
        />
        {maxLength !== undefined && (
          <LengthCounter invalid={invalid}>
            {model[name]?.length || 0}/{maxLength}
          </LengthCounter>
        )}
      </TextAreaContainer>
    </FieldWrapper>
  )
}

FormTextArea.propTypes = {
  maxLength: PropTypes.number,
  minLength: PropTypes.number,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool
}

export default FormTextArea
