import getEnv from './getEnv'

/**
 * getRMPRoot returns the root URL for RMP which will either be '' or be
 * defined in REACT_APP_RMP_ROOT. This is needed because when linking to an old
 * RMP page
 */
export default function getRMPRoot() {
  const REACT_APP_RMP_ROOT = getEnv('REACT_APP_RMP_ROOT')
  return REACT_APP_RMP_ROOT || ''
}
