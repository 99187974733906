import React, { useEffect } from 'react'
import styled from 'styled-components'
import MODALS from '@enums/MODALS'
import useGlobalContext from '@hooks/useGlobalContext'
import useUserInfo from '@hooks/useUserInfo'
import { poppinsBoldest } from '@StyledComponents/theme/typesets'
import AuthModal from './Templates/SmallModal'

const LogoutModalHeader = styled.div`
  font-size: ${({ theme }) => theme.fontSize.xxlarge};
  margin-bottom: 16px;
  text-align: center;

  ${poppinsBoldest};
`

const LogoutModalBody = styled.div`
  font-size: ${({ theme }) => theme.fontSize.medium};
  text-align: center;
`

export default function LogoutModal() {
  const userInfo = useUserInfo()
  const { activeModal } = useGlobalContext()
  const { logout } = useGlobalContext()

  const isLoggedIn = !!userInfo?.Email

  useEffect(() => {
    if (activeModal === MODALS.LOGOUT && isLoggedIn) {
      logout()
    }
  }, [isLoggedIn])

  return (
    <AuthModal modal={MODALS.LOGOUT} isSlidingModal>
      <LogoutModalHeader>Logout</LogoutModalHeader>
      <LogoutModalBody>You&apos;ve been logged out.</LogoutModalBody>
    </AuthModal>
  )
}
