import React from 'react'
import { graphql, createFragmentContainer } from 'react-relay'
import UserTypes from '@types/User.types'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import LABEL_STYLE from '@enums/LABEL_STYLE'
import Form from '@components/Form/Form'
import TextInput from '@components/Form/TextInput'
import FormSchoolSelector from '@components/Form/FormSchoolSelector'
import FormSelect from '@components/Form/FormSelect'
import SubmitButton from '@components/Form/Buttons/SubmitButton'
import CancelButton from '@components/Form/Buttons/CancelButton'
import FormButtonsArea from '@components/Form/FormButtonsArea'
import ProfileEditButton from '@components/ProfileEditButton'
import DepartmentSelection from './AddProfessor/DepartmentSelection'

const StyledTextInput = styled(TextInput)`
  margin-bottom: 22px;
`

const StyledFormSchoolSelector = styled(FormSchoolSelector)`
  margin-bottom: 22px;
  label {
    min-width: 300px;
  }
`

const StyledDepartmentSelector = styled(DepartmentSelection)`
  margin-bottom: 22px;
  label {
    min-width: 300px;
  }
`

export const ProfileFormContainer = styled.div`
  margin-top: 36px;
  text-align: left;
  width: 100%;
`

export const ProfileEditContainer = styled.div`
  position: relative;
  right: 0;
  width: 0;
`

const mutation = graphql`
  mutation StudentProfileFieldsMutation($user: UserInputObject!) {
    userUpdate(user: $user) {
      user {
        ...StudentProfileFields_user
      }

      validation {
        ...FormController_validation
      }
    }
  }
`

const CURRENT_YEAR = new Date().getFullYear()
export const MIN_GRAD_YEAR = CURRENT_YEAR - 10
export const MAX_GRAD_YEAR = CURRENT_YEAR + 10

const generateGradYearOptions = currentValue => {
  const options = []
  if (currentValue && (currentValue < MIN_GRAD_YEAR || currentValue > MAX_GRAD_YEAR)) {
    options.push({ label: `${currentValue}`, value: currentValue })
  }
  for (let i = MAX_GRAD_YEAR; i >= MIN_GRAD_YEAR; i -= 1) {
    options.push({ label: `${i}`, value: i })
  }
  return options
}

export const studentProfileVariables = (model, user) => {
  return {
    user: {
      ...(model?.firstName && { firstName: model.firstName }),
      ...(model?.lastName && { lastName: model.lastName }),
      ...(model?.graduationYear && { graduationYear: model.graduationYear }),
      ...(model?.school && { schoolId: model.school }),
      ...(model?.major && { major: model.major }),
      email: user?.email
    }
  }
}

export const StudentProfileFields = ({ user, edit, setEdit }) => {
  return (
    <React.Fragment>
      <ProfileFormContainer>
        <Form
          mutation={mutation}
          getVariables={model => studentProfileVariables(model, user)}
          existingData={user}
          onSuccess={() => setEdit(false)}
        >
          <StyledTextInput
            disabled={!edit}
            labelStyle={LABEL_STYLE.LEFT}
            name="firstName"
            label="First Name"
          />
          <StyledTextInput
            disabled={!edit}
            labelStyle={LABEL_STYLE.LEFT}
            name="lastName"
            label="Last Name"
          />
          <StyledFormSchoolSelector disabled={!edit} name="school" label="School" />
          <StyledDepartmentSelector
            disabled={!edit}
            name="major"
            label="Field of Study"
            placeholder="Field of Study"
            labelStyle={LABEL_STYLE.LEFT}
            isSearchable
          />
          <FormSelect
            disabled={!edit}
            name="graduationYear"
            label="Expected Year of Graduation"
            options={generateGradYearOptions(user?.graduationYear)}
          />
          {edit && (
            <FormButtonsArea>
              <SubmitButton type="submit">Save Changes</SubmitButton>
              <CancelButton onClick={() => setEdit(false)}>Cancel</CancelButton>
            </FormButtonsArea>
          )}
        </Form>
      </ProfileFormContainer>
      <ProfileEditContainer>
        {!edit && (
          <ProfileEditButton
            to=""
            onClick={e => {
              e.preventDefault()
              setEdit(!edit)
            }}
          >
            Edit
          </ProfileEditButton>
        )}
      </ProfileEditContainer>
    </React.Fragment>
  )
}

StudentProfileFields.propTypes = {
  user: UserTypes,
  edit: PropTypes.bool,
  setEdit: PropTypes.func
}

export default createFragmentContainer(StudentProfileFields, {
  user: graphql`
    fragment StudentProfileFields_user on User {
      id
      firstName
      lastName
      graduationYear
      school {
        id
        name
        departments {
          id
          name
        }
      }
      major
      legacyId
      email
    }
  `
})
