import React, { useState } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import svgCheckmark from '@assets/images/icons-checkmark-white.svg'

export const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  /*
	 Hide checkbox visually but remain accessible to screen readers.
   Source: https://polished.js.org/docs/#hidevisually
	 */
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`

const checkboxBorderRadius = (rounded, isFocused) => {
  if (rounded) {
    return '50%'
  }
  if (isFocused) {
    return '0'
  }
  return '1.5px'
}

export const StyledCheckbox = styled.div`
  background: ${({ checked, theme }) => (checked ? theme.color.default : 'white')};
  border: 1.5px solid ${({ theme }) => theme.color.default};
  border-radius: ${({ rounded, isFocused }) => checkboxBorderRadius(rounded, isFocused)};
  color: white;
  display: inline-block;
  height: 25px;
  user-select: none;
  width: 25px;
`

export const CheckBoxContainer = styled.div`
  border-color: ${({ isFocused, theme }) => (isFocused ? theme.color.lightBlue3 : 'white')};
  border-radius: 1.5px;
  border-style: solid;
  border-width: 1.5px;
  display: inline-block;
  height: 28px;
  vertical-align: middle;
  width: 28px;
`

export const CheckMark = styled.img.attrs({ src: svgCheckmark, alt: 'check mark' })`
  height: 100%;
  width: 100%;
`

export default function Checkbox({
  className,
  checked,
  disabled,
  onChange,
  name,
  rounded,
  ...props
}) {
  const [isFocused, setIsFocused] = useState(false)

  const toggle = () => {
    onChange({ target: { name, type: 'checkbox', checked: !checked } })
  }
  return (
    <CheckBoxContainer className={className} isFocused={isFocused}>
      <HiddenCheckbox
        checked={checked}
        disabled={disabled}
        onChange={onChange}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        name={name}
        {...props}
      />
      <StyledCheckbox
        checked={checked}
        aria-hidden
        disabled={disabled}
        value={checked}
        onClick={toggle}
        isFocused={isFocused}
        rounded={rounded}
      >
        {checked && <CheckMark />}
      </StyledCheckbox>
    </CheckBoxContainer>
  )
}

Checkbox.propTypes = {
  className: PropTypes.string,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  name: PropTypes.string,
  rounded: PropTypes.bool
}
