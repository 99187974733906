import React, { useEffect, useRef, useState } from 'react'
import styled, { css } from 'styled-components'
import { graphql } from 'react-relay'
import { useHistory, useLocation } from 'react-router-dom'
import { TOAST_EVENTS } from '@utils/toast'
import MODALS from '@enums/MODALS'
import Form from '@components/Form/Form'
import TextInput from '@components/Form/TextInput'
import useGlobalContext from '@hooks/useGlobalContext'
import { ButtonAttributes } from '@StyledComponents/Buttons'
import Button from '@components/Button'
import { poppinsBoldest3x } from '@StyledComponents/theme/typesets'
import ModalButtonLink from '@components/ModalButtonLink'
import useOAuth from '@utils/useOAuth'
import OauthSection from '@components/StyledComponents/OauthButton'
import FormRecaptcha3Button from '@components/Form/FormReCaptcha3Button'
import ReCAPTCHA from '@lib/ReCAPTCHA'
import AuthModal from './Templates/SmallModal'

const mutation = graphql`
  mutation LoginModalMutation($email: String!, $password: String!) {
    userLogin(email: $email, password: $password) {
      token

      validation {
        ...FormController_validation
      }
    }
  }
`

const linkButtonStyles = css`
  color: ${({ theme }) => theme.color.mainBlue};
  font-size: ${({ theme }) => theme.fontSize.medium};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  text-decoration: none;

  &:hover {
    cursor: pointer;
  }
`
const LoginModalButtonLink = styled(Button)`
  background: transparent;
  border: 0;
  cursor: pointer;
  outline: none;

  ${linkButtonStyles}
`

const ForgotPasswordLinkWrapper = styled.div`
  margin-bottom: 16px;
  margin-top: 16px;
`

const LoginPrompt = styled.div`
  font-size: 12px;
  line-height: 16px;
  margin: 16px 0;
  max-width: 343px;
  width: 100%;
`
const LoginModalHeader = styled.div`
  margin-top: 5px;
  text-align: center;

  ${poppinsBoldest3x};
`

const StyledHelperTextFooter = styled.div`
  font-size: ${props => props.theme.fontSize.medium};
  left: 0px;
  margin: 26px 0px 0px;
  width: 100%;
  button {
    color: ${props => props.theme.color.mainBlue};
    font-weight: ${props => props.theme.fontWeight.bold};
    line-height: inherit;
    margin-left: 3px;
    text-decoration: none;
  }
`
const StyledStudentSignUpContainer = styled.div`
  height: 100%;
  text-align: center;
  width: 100%;
`

const SubmitButton = styled(FormRecaptcha3Button)`
  width: 100%;

  ${ButtonAttributes}
`

const LoginLegalBody = () => (
  <LoginPrompt>
    Rate My Professors is designed for and targeted to U.S. audiences and is governed by and
    operated in accordance with U.S. laws.
  </LoginPrompt>
)

const LoginModalForm = () => {
  const { closeActiveModal, setActiveModal, login } = useGlobalContext()
  const tokenRef = useRef(null)
  const history = useHistory()
  const location = useLocation()
  const [redirect, setRedirect] = useState(null)

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    const redirectParam = searchParams.get('redirect')
    setRedirect(redirectParam)
  }, [location])

  return (
    <Form
      mutation={mutation}
      onSuccess={() => {
        if (tokenRef.current) {
          login({ token: tokenRef.current, shouldRedirect: false })
          closeActiveModal()
          history.push(redirect ?? '/', { toast: TOAST_EVENTS.EMAIL_CONFIRMATION_REMINDER })
        }
      }}
      updater={store => {
        tokenRef.current = store?.getRootField('userLogin')?.getValue('token')
      }}
    >
      <TextInput name="email" label="Email" noErrState />
      <TextInput name="password" label="Password" isPassword noErrState />
      <ForgotPasswordLinkWrapper>
        <LoginModalButtonLink
          onClick={() => setActiveModal(MODALS.FORGOT_PASSWORD)}
          role="button"
          type="button"
        >
          Forgot Password?
        </LoginModalButtonLink>
      </ForgotPasswordLinkWrapper>
      <SubmitButton action={ReCAPTCHA.ACTIONS.VALIDATE_EMAIL}>Continue</SubmitButton>
    </Form>
  )
}

export default function LoginModal() {
  const onClose = () => {}

  const isNewOauth = useOAuth()

  return (
    <AuthModal modal={MODALS.LOGIN} onClose={onClose} isSlidingModal>
      <LoginModalHeader>Login</LoginModalHeader>
      {isNewOauth && <OauthSection login />}
      <StyledStudentSignUpContainer>
        <LoginModalForm />
        <LoginLegalBody />
        <StyledHelperTextFooter>
          Don&apos;t have an account?
          <ModalButtonLink modal={MODALS.SIGN_UP_FORM}>Sign Up</ModalButtonLink>
        </StyledHelperTextFooter>
      </StyledStudentSignUpContainer>
    </AuthModal>
  )
}
