import React, { useEffect } from 'react'
import styled, { css } from 'styled-components'
import { applyBreakpointMin } from '@StyledComponents/theme/helpers/breakpoints'
import PageWrapper from '@components/PageWrapper'

const StyledOutbrainAd = styled.aside`
  ${applyBreakpointMin(
    'large',
    css`
      max-width: calc(
        ${props => props.theme.pageWrapper.maxWidth}px - ${props => props.theme.adRail.width}px
      );
    `
  )}
`

export default function OutbrainAd() {
  useEffect(() => {
    const script = document.createElement('script')
    script.src = 'https://widgets.outbrain.com/outbrain.js'
    script.type = 'text/javascript'
    script.async = true
    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
    }
  }, [])

  return (
    <PageWrapper>
      <StyledOutbrainAd>
        <div
          data-website-id="rmp"
          className="OUTBRAIN"
          data-src="https://www.ratemyprofessors.com"
          data-widget-id="AR_1"
        />
      </StyledOutbrainAd>
    </PageWrapper>
  )
}

OutbrainAd.propTypes = {}
