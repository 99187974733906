import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { graphql } from 'react-relay'
import MODALS from '@enums/MODALS'
import commitMutation from '@relay/commitMutation'
import ROUTE from '@enums/ROUTE'
import PropTypes from 'prop-types'
import ModalButtonLink from '@components/ModalButtonLink'
import { Link } from 'react-router-dom'
import { poppinsBoldest3x } from '@StyledComponents/theme/typesets'
import useGlobalContext from '@hooks/useGlobalContext'
import useUserInfo from '@hooks/useUserInfo'
import AuthModal from './Templates/SmallModal'

const confirmEmailMutation = graphql`
  mutation EmailConfirmationModalMutation($token: String!) {
    confirmEmail(token: $token) {
      validation {
        ...FormController_validation
      }
    }
  }
`
const StyledEmailConfirmationModalContent = styled.div`
  padding: 50px 80px;
`
const EmailConfirmHeader = styled.div`
  text-align: center;

  ${poppinsBoldest3x};
`
const EmailConfirmBody = styled.div`
  font-size: ${({ theme }) => theme.fontSize.medium};
  margin-top: 32px;
  text-align: center;
  button {
    color: ${({ theme }) => theme.color.mainBlue};
    font-weight: ${({ theme }) => theme.fontWeight.bold};
  }
`

export const CONFIRMATION_STATUSES = {
  LOADING: 'Loading',
  SUCCESS: 'Success',
  ERROR: 'Error'
}
export function EmailConfirmationModalContent({ confirmationStatus }) {
  return (
    <StyledEmailConfirmationModalContent>
      {confirmationStatus === CONFIRMATION_STATUSES.LOADING && <EmailConfirmLoading />}
      {confirmationStatus === CONFIRMATION_STATUSES.SUCCESS && <EmailConfirmSuccess />}
      {confirmationStatus === CONFIRMATION_STATUSES.ERROR && <EmailConfirmError />}
    </StyledEmailConfirmationModalContent>
  )
}

function EmailConfirmLoading() {
  return <EmailConfirmBody>Loading...</EmailConfirmBody>
}

function EmailConfirmSuccess() {
  const userInfo = useUserInfo()
  const isLoggedIn = userInfo?.Email
  return (
    <React.Fragment>
      <EmailConfirmHeader>Welcome to Rate My Professors!</EmailConfirmHeader>
      <EmailConfirmBody>
        You&apos;re good to go.{' '}
        {isLoggedIn ? (
          <Link to={ROUTE.HOME}>Log In</Link>
        ) : (
          <ModalButtonLink modal={MODALS.LOGIN}>Log In</ModalButtonLink>
        )}{' '}
        to get started.
      </EmailConfirmBody>
    </React.Fragment>
  )
}

function EmailConfirmError() {
  return <EmailConfirmHeader>Something went wrong</EmailConfirmHeader>
}
EmailConfirmationModalContent.propTypes = {
  confirmationStatus: PropTypes.oneOf(Object.values(CONFIRMATION_STATUSES))
}

export default function EmailConfirmationModal() {
  const [confirmationStatus, setConfirmationStatus] = useState(CONFIRMATION_STATUSES.LOADING)
  const { activeModal } = useGlobalContext()

  const params = new URLSearchParams(window.location.search)
  const token = params.get('token')

  useEffect(() => {
    if (activeModal === MODALS.CONFIRM_EMAIL) {
      commitMutation({
        mutation: confirmEmailMutation,
        variables: { token },
        onCompleted: (_, errors) => {
          if (errors) {
            console.error(errors)
            setConfirmationStatus(CONFIRMATION_STATUSES.ERROR)
          } else {
            setConfirmationStatus(CONFIRMATION_STATUSES.SUCCESS)
          }
        }
      })
    }
  }, [activeModal])

  return (
    <AuthModal modal={MODALS.CONFIRM_EMAIL}>
      <EmailConfirmationModalContent confirmationStatus={confirmationStatus} />
    </AuthModal>
  )
}
