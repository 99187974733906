import React from 'react'
import styled, { css } from 'styled-components'
import {
  breakpointLargeAndBelow,
  breakpointSmallPlusAndBelow
} from '@StyledComponents/theme/helpers/breakpoints'
import { getMcgrawhillHREF, getMcgrawhillSRC } from '@utils/getMcgrawhillLinks'
import mcgrawhillLogo from '@assets/images/logos/mcgrawhill-logo-2023.svg'
import getEnv from '@utils/getEnv'

export const LogoMcgrawhill = styled.a`
  background: url(${mcgrawhillLogo});
  background-repeat: no-repeat;
  background-size: contain;
  height: 26px;
  min-width: 196px;

  ${breakpointLargeAndBelow(css`
    background-image: url(${mcgrawhillLogo});
    position: absolute;
    right: 12px;
    margin-left: 0;
    height: 36px;
    min-width: 196px;
  `)}
  ${breakpointSmallPlusAndBelow(css`
    background-image: url(${mcgrawhillLogo});
    min-width: 124px;
    max-width: calc(50% - 55px);
    height: 38px;
    top: 10px;
  `)}
`

export default function McgrawhillPromo() {
  if (getEnv('REACT_APP_SHOW_MCGRAWHILL') !== 'true') return null
  return (
    <React.Fragment>
      <div id="mcgrawhill-promo-header">
        <LogoMcgrawhill href={getMcgrawhillHREF()} target="_blank" />
      </div>
      <img
        src={getMcgrawhillSRC()}
        border="0"
        height="1"
        width="1"
        aria-hidden
        // Image still loads in DOM but needs to be hidden to avoid white 1x1 pixel
        style={{ visibility: 'hidden' }}
        // Empty string to prevent alt text being rendered (ch16014)
        alt=""
        title="McGrawhill Advertisement"
      />
    </React.Fragment>
  )
}
