import ROUTE from '@enums/ROUTE'
import { graphql } from 'react-relay'
import { matchPath } from 'react-router'

export const getVariables = location => {
  const match = matchPath(location.pathname, {
    path: [ROUTE.NEW_FLAG_CAMPUS_RATING]
  })

  if (!match?.params?.sid) return { id: '' }

  return {
    id: btoa(`School-${match.params.sid}`),
    ratingId: match?.params?.rid ? btoa(`SchoolRating-${match.params.rid}`) : null
  }
}

export const flagCampusRatingMutation = graphql`
  mutation FlagCampusRatingMutation($ratingID: ID!, $flagComment: UserComment!) {
    flagCampusRating(ratingID: $ratingID, flagComment: $flagComment) {
      validation {
        ...FormController_validation
      }
    }
  }
`
