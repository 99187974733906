/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type FormController_validation$ref: FragmentReference;
declare export opaque type FormController_validation$fragmentType: FormController_validation$ref;
export type FormController_validation = {|
  +id: ?string,
  +success: ?boolean,
  +errors: ?$ReadOnlyArray<?{|
    +field: ?string,
    +messages: ?$ReadOnlyArray<?string>,
  |}>,
  +$refType: FormController_validation$ref,
|};
export type FormController_validation$data = FormController_validation;
export type FormController_validation$key = {
  +$data?: FormController_validation$data,
  +$fragmentRefs: FormController_validation$ref,
};
*/


const node/*: ReaderFragment*/ = {
  "kind": "Fragment",
  "name": "FormController_validation",
  "type": "ValidationResult",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "id",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "success",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "errors",
      "storageKey": null,
      "args": null,
      "concreteType": "FieldErrorType",
      "plural": true,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "field",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "messages",
          "args": null,
          "storageKey": null
        }
      ]
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '64ab12edc81e58e344bfc15b3546acd9';
module.exports = node;
