import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import OldRMPLink from '../OldRMPLink'

export const LINK_TYPE = {
  EXTERNAL: 'external',
  INTERNAL: 'internal',
  OLD_RMP: 'old_rmp'
}

export default function FooterLink({ link, className }) {
  if (!link) return null

  switch (link.type) {
    case LINK_TYPE.INTERNAL:
      return (
        <Link className={className} to={link.to}>
          {link.label}
        </Link>
      )
    case LINK_TYPE.OLD_RMP:
      return (
        <OldRMPLink className={className} to={link.to}>
          {link.label}
        </OldRMPLink>
      )
    case LINK_TYPE.EXTERNAL: /* Intentional Fall-Through */
    default:
      return (
        <a className={className} href={link.to}>
          {link.label}
        </a>
      )
  }
}

export const FooterLinkTypes = PropTypes.shape({
  to: PropTypes.string,
  label: PropTypes.string,
  type: PropTypes.oneOf(Object.values(LINK_TYPE))
})

FooterLink.propTypes = {
  link: FooterLinkTypes,
  className: PropTypes.string
}
