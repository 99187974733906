import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { helveticaBold } from '@components/StyledComponents/theme/typesets'
import IconButton from '@components/StyledComponents/IconButton'
import toast, { TOAST_EVENTS } from '@utils/toast'
import ShareComparisonToast from '@components/Toast/ShareComparisonToast'
import { breakpointXMediumAndBelow } from '@components/StyledComponents/theme/helpers/breakpoints'

export const SharedButtonStyles = css`
  border: inherit;
  border-radius: 33px;
  cursor: pointer;
  font-size: 16px;
  margin-right: 16px;
  padding: 8px 16px 8px 16px;
`

export const CompareProfessorsHeaderContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  ${breakpointXMediumAndBelow(css`
    align-items: start;
    flex-direction: column;
    justify-content: space-between;
  `)}
`

const CompareProfessorsTitle = styled.div`
  display: flex;
  font-size: 24px;

  ${breakpointXMediumAndBelow(css`
    margin-bottom: 12px;
  `)}

  ${helveticaBold};
`

const CompareProfessorsButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;

  ${breakpointXMediumAndBelow(css`
    margin-bottom: 8px;
  `)}
`

const ShareComparisonDisabledButton = styled(IconButton.ShareGray)`
  ${SharedButtonStyles}
  background-color: ${({ theme }) => theme.color.gray5};
  color: ${({ theme }) => theme.color.gray9};
`

const ShareComparisonButton = styled(IconButton.Share)`
  ${SharedButtonStyles}
  background-color: ${({ theme }) => theme.color.black};
  color: ${({ theme }) => theme.color.white};
  
  &:hover {
    background-color: ${({ theme }) => theme.color.gray8};
    cursor: pointer;
  }
`

const CompareProfessorsHeader = ({ disabled }) => {
  const handleOnShareClicked = async () => {
    const currentUrlWithUTMs = `${window.location.href}?utm_source=share&utm_medium=web&utm_campaign=compare_professors`
    await navigator?.clipboard?.writeText(currentUrlWithUTMs)
    toast(ShareComparisonToast, TOAST_EVENTS.SHARE_COMPARISON)
  }

  const getShareComparisonButton = () => {
    if (disabled) {
      return (
        <ShareComparisonDisabledButton
          id="share-comparison-button"
          header
          disabled
          onClick={handleOnShareClicked}
        >
          Share
        </ShareComparisonDisabledButton>
      )
    }
    return (
      <ShareComparisonButton id="share-comparison-button" header onClick={handleOnShareClicked}>
        Share
      </ShareComparisonButton>
    )
  }

  return (
    <CompareProfessorsHeaderContainer>
      <CompareProfessorsTitle>Compare Professors</CompareProfessorsTitle>
      <CompareProfessorsButtonWrapper>{getShareComparisonButton()}</CompareProfessorsButtonWrapper>
    </CompareProfessorsHeaderContainer>
  )
}

CompareProfessorsHeader.propTypes = {
  disabled: PropTypes.bool
}

export default CompareProfessorsHeader
