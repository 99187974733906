import PropTypes from 'prop-types'

const SEARCH_CATEGORY = {
  ALL: 'all',
  TEACHERS: 'teachers',
  SCHOOLS: 'schools'
}

export const SearchCategoryType = PropTypes.oneOf(Object.values(SEARCH_CATEGORY))

export default SEARCH_CATEGORY
