/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type ProfessorNoteEditor_rating$ref = any;
type ProfessorNote_note$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type ProfessorNoteSection_rating$ref: FragmentReference;
declare export opaque type ProfessorNoteSection_rating$fragmentType: ProfessorNoteSection_rating$ref;
export type ProfessorNoteSection_rating = {|
  +teacherNote: ?{|
    +$fragmentRefs: ProfessorNote_note$ref
  |},
  +$fragmentRefs: ProfessorNoteEditor_rating$ref,
  +$refType: ProfessorNoteSection_rating$ref,
|};
export type ProfessorNoteSection_rating$data = ProfessorNoteSection_rating;
export type ProfessorNoteSection_rating$key = {
  +$data?: ProfessorNoteSection_rating$data,
  +$fragmentRefs: ProfessorNoteSection_rating$ref,
};
*/


const node/*: ReaderFragment*/ = {
  "kind": "Fragment",
  "name": "ProfessorNoteSection_rating",
  "type": "Rating",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "teacherNote",
      "storageKey": null,
      "args": null,
      "concreteType": "TeacherNotes",
      "plural": false,
      "selections": [
        {
          "kind": "FragmentSpread",
          "name": "ProfessorNote_note",
          "args": null
        }
      ]
    },
    {
      "kind": "FragmentSpread",
      "name": "ProfessorNoteEditor_rating",
      "args": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '989dca8de6771a091770c274fdd21ea1';
module.exports = node;
