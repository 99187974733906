import { useContext } from 'react'
import cookie from 'cookie'
import Cookies from 'js-cookie'
import SSRCookieContext from '../contexts/SSRCookieContext'
import isSSR from './isSSR'
import isLocal from './isLocal'

const getCookieOptions = (overrides = {}) => {
  const o = {}

  if (!isLocal()) {
    o.secure = true
    o.sameSite = 'none'
  }

  if (!isSSR()) {
    let host = window.location.hostname
    host = host.replace('www', '').replace('staging', '')
    o.domain = host
  }

  return { ...o, ...overrides }
}

const get = name => {
  if (!isSSR()) {
    return Cookies.get(name)
  }
  try {
    const ssrCookieContext = useContext(SSRCookieContext)
    if (!ssrCookieContext) return undefined
    return ssrCookieContext[name]
  } catch (e) {
    return undefined
  }
}

const set = (name, value, additionalOptions = {}) => {
  if (!isSSR()) {
    const options = getCookieOptions(additionalOptions)
    Cookies.set(name, value, options)
  }
}

const remove = (name, options = {}) => {
  if (!isSSR()) {
    // Fall backs for various cookie options, as all that were present when the
    // cookie was set must be present to delete the cookie.
    Cookies.remove(name)
    Cookies.remove(name, options)
    Cookies.remove(name, { ...options, ...getCookieOptions() })
  }
}

const parse = cookieString => {
  return cookie.parse(cookieString)
}

export default { get, set, remove, parse, getCookieOptions }
