import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { createFragmentContainer, graphql } from 'react-relay'
import { ButtonAttributes } from '@StyledComponents/Buttons'
import {
  breakpointSmallAndBelow,
  breakpointMediumAndBelow
} from '@StyledComponents/theme/helpers/breakpoints'
import { TrackingEventTypes } from '@enums/TRACKING_EVENT'
import TeacherTypes from '@types/Teacher.types'
import SchoolTypes from '@types/School.types'
import RateTeacherLink from '@components/RateTeacherLink'
import RateSchoolLink from '@components/RateSchoolLink'

export const StyledNoRatingsArea = styled.div`
  background: ${props => (props.gray ? props.theme.color.gray7 : 'none')};
  font-family: ${props => props.theme.fontFamily.poppins};
  font-size: ${props => props.theme.fontSize.large};
  padding: 123px 72px;
  text-align: center;

  ${breakpointMediumAndBelow(
    css`
      font-size: ${props => props.theme.fontSize.medium};
      padding: 75px 50px;
    `
  )}
  ${breakpointSmallAndBelow(
    css`
      font-size: ${props => props.theme.fontSize.medium};
      padding: 48px 24px;
    `
  )}

  span {
    padding-left: 5px;
  }
`
const ButtonStyles = css`
  margin: 20px auto 0 auto;
  ${ButtonAttributes}
  text-decoration: none;
  width: fit-content;
`

export const RateTeacherButton = styled(RateTeacherLink)`
  ${ButtonStyles}
`
export const RateSchoolButton = styled(RateSchoolLink)`
  ${ButtonStyles}
`

export const NoRatingsArea = ({ teacher, trackingLabel, gray, school }) => {
  if (!teacher && !school) return null
  return (
    <StyledNoRatingsArea gray={gray}>
      {teacher ? (
        <div>
          Professor {teacher.lastName} doesn’t have any ratings yet
          <span role="img" aria-label="sobbing emoji">
            😭
          </span>
          <RateTeacherButton teacher={teacher} trackingLabel={trackingLabel}>
            Be the first to rate Professor {teacher.lastName}
          </RateTeacherButton>
        </div>
      ) : (
        <div>
          {school.name} doesn’t have any ratings yet
          <span role="img" aria-label="sobbing emoji">
            😭
          </span>
          <RateSchoolButton school={school} trackingLabel={trackingLabel}>
            Be the first to rate {school.name}
          </RateSchoolButton>
        </div>
      )}
    </StyledNoRatingsArea>
  )
}

export default createFragmentContainer(NoRatingsArea, {
  teacher: graphql`
    fragment NoRatingsArea_teacher on Teacher {
      lastName
      ...RateTeacherLink_teacher
    }
  `,
  school: graphql`
    fragment NoRatingsArea_school on School {
      name
      ...RateSchoolLink_school
    }
  `
})

NoRatingsArea.propTypes = {
  teacher: TeacherTypes,
  school: SchoolTypes,
  trackingLabel: TrackingEventTypes,
  gray: PropTypes.bool
}
