/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type RatingValues_rating$ref: FragmentReference;
declare export opaque type RatingValues_rating$fragmentType: RatingValues_rating$ref;
export type RatingValues_rating = {|
  +helpfulRating: ?number,
  +clarityRating: ?number,
  +difficultyRating: ?number,
  +$refType: RatingValues_rating$ref,
|};
export type RatingValues_rating$data = RatingValues_rating;
export type RatingValues_rating$key = {
  +$data?: RatingValues_rating$data,
  +$fragmentRefs: RatingValues_rating$ref,
};
*/


const node/*: ReaderFragment*/ = {
  "kind": "Fragment",
  "name": "RatingValues_rating",
  "type": "Rating",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "helpfulRating",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "clarityRating",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "difficultyRating",
      "args": null,
      "storageKey": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '3c71e3b29740744e015016a53287a7aa';
module.exports = node;
