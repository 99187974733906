/**
 * @flow
 * @relayHash 3607ffc707ebd5a3eac17065e25b45c6
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type FormController_validation$ref = any;
export type FormQueryVariables = {|
  validationId: string
|};
export type FormQueryResponse = {|
  +validation: ?{|
    +id: ?string,
    +$fragmentRefs: FormController_validation$ref,
  |}
|};
export type FormQuery = {|
  variables: FormQueryVariables,
  response: FormQueryResponse,
|};
*/


/*
query FormQuery(
  $validationId: ID!
) {
  validation: node(id: $validationId) {
    __typename
    id
    ...FormController_validation
  }
}

fragment FormController_validation on ValidationResult {
  id
  success
  errors {
    field
    messages
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "validationId",
    "type": "ID!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "validationId"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "FormQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": "validation",
        "name": "node",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "FragmentSpread",
            "name": "FormController_validation",
            "args": null
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "FormQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": "validation",
        "name": "node",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "__typename",
            "args": null,
            "storageKey": null
          },
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "type": "ValidationResult",
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "success",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "errors",
                "storageKey": null,
                "args": null,
                "concreteType": "FieldErrorType",
                "plural": true,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "field",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "messages",
                    "args": null,
                    "storageKey": null
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "FormQuery",
    "id": null,
    "text": "query FormQuery(\n  $validationId: ID!\n) {\n  validation: node(id: $validationId) {\n    __typename\n    id\n    ...FormController_validation\n  }\n}\n\nfragment FormController_validation on ValidationResult {\n  id\n  success\n  errors {\n    field\n    messages\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '4e46577b87622b28230f6064112935a4';
module.exports = node;
