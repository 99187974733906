/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type CourseMeta_rating$ref = any;
type ProfessorNoteSection_rating$ref = any;
type RatingFooter_rating$ref = any;
type RatingHeader_rating$ref = any;
type RatingSuperHeader_rating$ref = any;
type RatingTags_rating$ref = any;
type RatingValues_rating$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type Rating_rating$ref: FragmentReference;
declare export opaque type Rating_rating$fragmentType: Rating_rating$ref;
export type Rating_rating = {|
  +comment: ?any,
  +flagStatus: ?string,
  +createdByUser: ?boolean,
  +teacherNote: ?{|
    +id: ?string
  |},
  +$fragmentRefs: RatingHeader_rating$ref & RatingSuperHeader_rating$ref & RatingValues_rating$ref & CourseMeta_rating$ref & RatingTags_rating$ref & RatingFooter_rating$ref & ProfessorNoteSection_rating$ref,
  +$refType: Rating_rating$ref,
|};
export type Rating_rating$data = Rating_rating;
export type Rating_rating$key = {
  +$data?: Rating_rating$data,
  +$fragmentRefs: Rating_rating$ref,
};
*/


const node/*: ReaderFragment*/ = {
  "kind": "Fragment",
  "name": "Rating_rating",
  "type": "Rating",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "comment",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "flagStatus",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "createdByUser",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "teacherNote",
      "storageKey": null,
      "args": null,
      "concreteType": "TeacherNotes",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "id",
          "args": null,
          "storageKey": null
        }
      ]
    },
    {
      "kind": "FragmentSpread",
      "name": "RatingHeader_rating",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "RatingSuperHeader_rating",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "RatingValues_rating",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "CourseMeta_rating",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "RatingTags_rating",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "RatingFooter_rating",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "ProfessorNoteSection_rating",
      "args": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '086451567cc3d6808f10f5de8040405f';
module.exports = node;
