/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type StudentProfileFields_user$ref: FragmentReference;
declare export opaque type StudentProfileFields_user$fragmentType: StudentProfileFields_user$ref;
export type StudentProfileFields_user = {|
  +id: ?string,
  +firstName: ?string,
  +lastName: ?string,
  +graduationYear: ?number,
  +school: ?{|
    +id: ?string,
    +name: ?string,
    +departments: ?$ReadOnlyArray<?{|
      +id: ?string,
      +name: ?string,
    |}>,
  |},
  +major: ?string,
  +legacyId: ?number,
  +email: ?string,
  +$refType: StudentProfileFields_user$ref,
|};
export type StudentProfileFields_user$data = StudentProfileFields_user;
export type StudentProfileFields_user$key = {
  +$data?: StudentProfileFields_user$data,
  +$fragmentRefs: StudentProfileFields_user$ref,
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "StudentProfileFields_user",
  "type": "User",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    (v0/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "firstName",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "lastName",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "graduationYear",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "school",
      "storageKey": null,
      "args": null,
      "concreteType": "School",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "departments",
          "storageKey": null,
          "args": null,
          "concreteType": "Department",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/)
          ]
        }
      ]
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "major",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "legacyId",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "email",
      "args": null,
      "storageKey": null
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '33ae1f49015d5a35245c52a6d371f79d';
module.exports = node;
