import React from 'react'
import styled from 'styled-components'
import { createFragmentContainer, graphql } from 'react-relay'

import { getPageContentMaxWidth } from '@StyledComponents/theme/helpers/breakpoints'
import SchoolTypes from '@types/School.types'
import SchoolRatingsList from './SchoolRatingsList'

const RatingsContainer = styled.div`
  ${({ theme }) => getPageContentMaxWidth(theme)};
`

const SchoolRatingsCount = styled.div`
  font-family: ${props => props.theme.fontFamily.poppins};
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  margin: 0 24px;
  text-align: left;
`

export function SchoolRatingsContainer({ school }) {
  if (!school || school.numRatings === null) return null

  return (
    <RatingsContainer data-ad-target="ratings-list">
      <SchoolRatingsCount>
        {`${school.numRatings ? school.numRatings : 0} Ratings`}
      </SchoolRatingsCount>
      <SchoolRatingsList school={school} />
    </RatingsContainer>
  )
}

SchoolRatingsContainer.propTypes = {
  school: SchoolTypes
}

export default createFragmentContainer(SchoolRatingsContainer, {
  school: graphql`
    fragment SchoolRatingsContainer_school on School {
      numRatings
      ...SchoolRatingsList_school
    }
  `
})
