import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import useSiteTakeover from '@utils/useSiteTakeover'
import AD_TIMING from '@enums/AD_TIMING'

const StyledPlaceholder = styled.div`
  ${props =>
    props.isHidden
      ? css`
          margin: ${props.margin}px;
          padding: ${props.padding}px;
        `
      : null}
`

const AdController = ({ children, margin, padding }) => {
  const isSiteTakeover = useSiteTakeover()
  const [isHidden, setIsHidden] = useState(isSiteTakeover)

  useEffect(() => {
    if (isSiteTakeover) {
      setTimeout(() => {
        setIsHidden(false)
      }, AD_TIMING.ONE_MINUTE)
    }
  })

  return (
    <StyledPlaceholder id="ad-controller" isHidden={isHidden} margin={margin} padding={padding}>
      {!isHidden && children}
    </StyledPlaceholder>
  )
}

AdController.propTypes = {
  children: PropTypes.node,
  margin: PropTypes.number,
  padding: PropTypes.number
}

export default AdController
