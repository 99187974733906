import ROUTE from '@enums/ROUTE'
import commitMutation from '@relay/commitMutation'
import { graphql } from 'relay-runtime'

const addProfessorFormMutation = graphql`
  mutation AddProfessorFormMutation(
    $firstName: String!
    $middleName: String
    $lastName: String!
    $department: String!
    $directoryUrl: String
    $schoolName: String!
    $schoolID: ID!
    $recaptcha: String!
  ) {
    addTeacher(
      firstName: $firstName
      middleName: $middleName
      lastName: $lastName
      department: $department
      directoryUrl: $directoryUrl
      schoolName: $schoolName
      schoolID: $schoolID
      recaptcha: $recaptcha
    ) {
      teacher {
        id
        legacyId
      }
      validation {
        id
        success
        errors {
          field
          messages
        }
      }
    }
  }
`

const submitProfessorForm = ({ values, history, setSubmissionError }) => {
  commitMutation({
    mutation: addProfessorFormMutation,
    variables: values,
    onCompleted: response => {
      if (response?.addTeacher?.validation?.success) {
        /**
         * Handle successful submission
         */
        history.push({
          pathname: ROUTE.ADD_PROFESSOR_SUCCESS,
          search: `?sid=${values.schoolID}`,
          state: {
            profLastName: values.lastName,
            profLegacyId: response.addTeacher?.teacher?.legacyId
          }
        })
      } else {
        const validationErrors = response?.addTeacher?.validation?.errors?.[0]?.messages
        setSubmissionError(validationErrors?.[0] ?? 'Server error')
      }
    },
    onError: err => {
      setSubmissionError(err.message)
    }
  })
}

export default submitProfessorForm
