import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import socialIconInstagramWhite from '@assets/images/socialIcons/instagram-white.svg'
import socialIconInstagramBlack from '@assets/images/socialIcons/instagram-black.svg'
import socialIconXWhite from '@assets/images/socialIcons/x-white.svg'
import socialIconXBlack from '@assets/images/socialIcons/x-black.svg'
import socialIconTikTokWhite from '@assets/images/socialIcons/tiktok-white.svg'
import socialIconTikTokBlack from '@assets/images/socialIcons/tiktok-black.svg'

import SocialIconLink from './SocialIconLink'

export const SOCIAL_ICON_COLORS = {
  WHITE: 'white',
  BLACK: 'black'
}

export const socialIconOptions = {
  [SOCIAL_ICON_COLORS.WHITE]: {
    instagram: socialIconInstagramWhite,
    x: socialIconXWhite,
    tiktok: socialIconTikTokWhite
  },
  [SOCIAL_ICON_COLORS.BLACK]: {
    instagram: socialIconInstagramBlack,
    x: socialIconXBlack,
    tiktok: socialIconTikTokBlack
  }
}

export const getSocialLinks = iconColor => {
  return [
    {
      platform: 'Instagram',
      icon: socialIconOptions[iconColor].instagram,
      url: 'https://www.instagram.com/ratemyprofessors/'
    },
    {
      platform: 'X',
      icon: socialIconOptions[iconColor].x,
      url: 'https://x.com/ratemyprofessor'
    },
    {
      platform: 'TikTok',
      icon: socialIconOptions[iconColor].tiktok,
      url: 'https://tiktok.com/@ratemyprofessors'
    }
  ]
}

export const StyledSocialLinks = styled.div`
  display: flex;
  margin-bottom: 16px;
  position: relative;

  @media (min-width: 768px) {
    display: flex;
    justify-content: center;
  }
`

export default function SocialLinks({ iconColor }) {
  if (!iconColor) return null

  const iconMap = getSocialLinks(iconColor)

  return (
    <StyledSocialLinks>
      {iconMap.map(link => (
        <SocialIconLink
          key={link.platform}
          platform={link.platform}
          src={link.icon}
          href={link.url}
        />
      ))}
    </StyledSocialLinks>
  )
}

SocialLinks.propTypes = {
  iconColor: PropTypes.oneOf(Object.values(SOCIAL_ICON_COLORS))
}

SocialLinks.displayName = 'SocialLinks'
