import { useEffect, useRef } from 'react'

// This hook can be used if you want useEffect functionality but NOT on initial render
const useDidMountEffect = (func, deps) => {
  const didMount = useRef(false)
  useEffect(() => {
    if (didMount.current) func()
    else didMount.current = true
  }, deps)
}

export default useDidMountEffect
