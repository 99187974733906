import React from 'react'
import styled, { css } from 'styled-components'
import { createFragmentContainer, graphql } from 'react-relay'
import { breakpointMediumAndBelow } from '@StyledComponents/theme/helpers/breakpoints'
import SchoolTypes from '@types/School.types'
import { poppinsBoldest } from '@StyledComponents/theme/typesets'

const OverallRatingContainer = styled.div`
  padding-left: 24px;
`

export const Rating = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
`

const RatingWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const Number = styled.div`
  font-size: ${props => props.theme.fontSize.jumbo};
  line-height: ${props => props.theme.fontSize.jumbo};
  margin-bottom: ${props => (props.presentRating ? '2px' : '8px')};
  ${poppinsBoldest};

  ${breakpointMediumAndBelow(
    css`
      font-size: ${props => props.theme.fontSize.huge};
      line-height: ${props => props.theme.fontSize.huge};
    `
  )}
`

const HelperText = styled.div`
  color: ${({ theme }) => theme.color.gray11};
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
`

export function OverallRating({ school }) {
  const { avgRatingRounded, avgRating, numRatings } = school
  if ((avgRating === null && avgRatingRounded === null) || numRatings === null) return null
  const presentRating = numRatings !== 0
  return (
    <OverallRatingContainer>
      <Rating>
        <RatingWrapper>
          <Number presentRating={presentRating}>
            {presentRating
              ? (avgRatingRounded && avgRatingRounded.toFixed(1)) ||
                (avgRating && avgRating.toFixed(1))
              : 'N/A'}
          </Number>
          <HelperText>Overall Quality</HelperText>
        </RatingWrapper>
      </Rating>
    </OverallRatingContainer>
  )
}

OverallRating.propTypes = {
  school: SchoolTypes
}

export default createFragmentContainer(OverallRating, {
  school: graphql`
    fragment OverallRating_school on School {
      avgRatingRounded
      avgRating
      numRatings
    }
  `
})
