/**
 * @flow
 * @relayHash 2bc5f64259036a59c0a2e2b9df016e67
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type SchoolRatingsList_school$ref = any;
export type SchoolRatingsListQueryVariables = {|
  count: number,
  id: string,
  cursor?: ?string,
|};
export type SchoolRatingsListQueryResponse = {|
  +node: ?{|
    +$fragmentRefs: SchoolRatingsList_school$ref
  |}
|};
export type SchoolRatingsListQuery = {|
  variables: SchoolRatingsListQueryVariables,
  response: SchoolRatingsListQueryResponse,
|};
*/


/*
query SchoolRatingsListQuery(
  $count: Int!
  $id: ID!
  $cursor: String
) {
  node(id: $id) {
    __typename
    ... on School {
      ...SchoolRatingsList_school_1G22uz
    }
    id
  }
}

fragment SchoolRatingsList_school_1G22uz on School {
  id
  name
  city
  state
  country
  legacyId
  ratings(first: $count, after: $cursor) {
    edges {
      cursor
      node {
        ...SchoolRating_rating
        id
        __typename
      }
    }
    pageInfo {
      hasNextPage
      endCursor
    }
  }
  ...SchoolRating_school
}

fragment SchoolRating_rating on SchoolRating {
  clubsRating
  comment
  date
  facilitiesRating
  foodRating
  happinessRating
  internetRating
  locationRating
  opportunitiesRating
  reputationRating
  safetyRating
  socialRating
  legacyId
  flagStatus
  createdByUser
  ...SchoolRatingFooter_rating
}

fragment SchoolRating_school on School {
  ...SchoolRatingSuperHeader_school
  ...SchoolRatingFooter_school
}

fragment SchoolRatingSuperHeader_school on School {
  name
  legacyId
}

fragment SchoolRatingFooter_school on School {
  id
  legacyId
  ...Thumbs_school
}

fragment Thumbs_school on School {
  id
  legacyId
}

fragment SchoolRatingFooter_rating on SchoolRating {
  id
  comment
  flagStatus
  legacyId
  ...Thumbs_schoolRating
}

fragment Thumbs_schoolRating on SchoolRating {
  id
  legacyId
  thumbsDownTotal
  thumbsUpTotal
  userThumbs {
    computerId
    thumbsUp
    thumbsDown
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "count",
    "type": "Int!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "id",
    "type": "ID!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "cursor",
    "type": "String",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "__typename",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "legacyId",
  "args": null,
  "storageKey": null
},
v5 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "cursor"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "count"
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "SchoolRatingsListQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "node",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "type": "School",
            "selections": [
              {
                "kind": "FragmentSpread",
                "name": "SchoolRatingsList_school",
                "args": [
                  {
                    "kind": "Variable",
                    "name": "count",
                    "variableName": "count"
                  },
                  {
                    "kind": "Variable",
                    "name": "cursor",
                    "variableName": "cursor"
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "SchoolRatingsListQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "node",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "kind": "InlineFragment",
            "type": "School",
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "name",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "city",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "state",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "country",
                "args": null,
                "storageKey": null
              },
              (v4/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "ratings",
                "storageKey": null,
                "args": (v5/*: any*/),
                "concreteType": "CampusRatingConnection",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "edges",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "CampusRatingEdge",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "cursor",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "node",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "SchoolRating",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "clubsRating",
                            "args": null,
                            "storageKey": null
                          },
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "comment",
                            "args": null,
                            "storageKey": null
                          },
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "date",
                            "args": null,
                            "storageKey": null
                          },
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "facilitiesRating",
                            "args": null,
                            "storageKey": null
                          },
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "foodRating",
                            "args": null,
                            "storageKey": null
                          },
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "happinessRating",
                            "args": null,
                            "storageKey": null
                          },
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "internetRating",
                            "args": null,
                            "storageKey": null
                          },
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "locationRating",
                            "args": null,
                            "storageKey": null
                          },
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "opportunitiesRating",
                            "args": null,
                            "storageKey": null
                          },
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "reputationRating",
                            "args": null,
                            "storageKey": null
                          },
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "safetyRating",
                            "args": null,
                            "storageKey": null
                          },
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "socialRating",
                            "args": null,
                            "storageKey": null
                          },
                          (v4/*: any*/),
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "flagStatus",
                            "args": null,
                            "storageKey": null
                          },
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "createdByUser",
                            "args": null,
                            "storageKey": null
                          },
                          (v3/*: any*/),
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "thumbsDownTotal",
                            "args": null,
                            "storageKey": null
                          },
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "thumbsUpTotal",
                            "args": null,
                            "storageKey": null
                          },
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "userThumbs",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "CampusRatingThumb",
                            "plural": true,
                            "selections": [
                              {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "computerId",
                                "args": null,
                                "storageKey": null
                              },
                              {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "thumbsUp",
                                "args": null,
                                "storageKey": null
                              },
                              {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "thumbsDown",
                                "args": null,
                                "storageKey": null
                              },
                              (v3/*: any*/)
                            ]
                          },
                          (v2/*: any*/)
                        ]
                      }
                    ]
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "pageInfo",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "hasNextPage",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "endCursor",
                        "args": null,
                        "storageKey": null
                      }
                    ]
                  }
                ]
              },
              {
                "kind": "LinkedHandle",
                "alias": null,
                "name": "ratings",
                "args": (v5/*: any*/),
                "handle": "connection",
                "key": "SchoolRatingsList_ratings",
                "filters": null
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "SchoolRatingsListQuery",
    "id": null,
    "text": "query SchoolRatingsListQuery(\n  $count: Int!\n  $id: ID!\n  $cursor: String\n) {\n  node(id: $id) {\n    __typename\n    ... on School {\n      ...SchoolRatingsList_school_1G22uz\n    }\n    id\n  }\n}\n\nfragment SchoolRatingsList_school_1G22uz on School {\n  id\n  name\n  city\n  state\n  country\n  legacyId\n  ratings(first: $count, after: $cursor) {\n    edges {\n      cursor\n      node {\n        ...SchoolRating_rating\n        id\n        __typename\n      }\n    }\n    pageInfo {\n      hasNextPage\n      endCursor\n    }\n  }\n  ...SchoolRating_school\n}\n\nfragment SchoolRating_rating on SchoolRating {\n  clubsRating\n  comment\n  date\n  facilitiesRating\n  foodRating\n  happinessRating\n  internetRating\n  locationRating\n  opportunitiesRating\n  reputationRating\n  safetyRating\n  socialRating\n  legacyId\n  flagStatus\n  createdByUser\n  ...SchoolRatingFooter_rating\n}\n\nfragment SchoolRating_school on School {\n  ...SchoolRatingSuperHeader_school\n  ...SchoolRatingFooter_school\n}\n\nfragment SchoolRatingSuperHeader_school on School {\n  name\n  legacyId\n}\n\nfragment SchoolRatingFooter_school on School {\n  id\n  legacyId\n  ...Thumbs_school\n}\n\nfragment Thumbs_school on School {\n  id\n  legacyId\n}\n\nfragment SchoolRatingFooter_rating on SchoolRating {\n  id\n  comment\n  flagStatus\n  legacyId\n  ...Thumbs_schoolRating\n}\n\nfragment Thumbs_schoolRating on SchoolRating {\n  id\n  legacyId\n  thumbsDownTotal\n  thumbsUpTotal\n  userThumbs {\n    computerId\n    thumbsUp\n    thumbsDown\n    id\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '778552ef83737b864ba2753d1069d20d';
module.exports = node;
