import React, { useState } from 'react'
import { graphql } from 'react-relay'
import MODALS from '@enums/MODALS'
import AuthModal from '@components/Modals/Templates/SmallModal'
import styled from 'styled-components'
import ModalButtonLink from '@components/ModalButtonLink'
import Form from '@components/Form/Form'
import FormTeacherSelector from '@components/Form/FormTeacherSelector'
import LABEL_STYLE from '@enums/LABEL_STYLE'
import TextInput from '@components/Form/TextInput'
import FormButton from '@components/Form/FormButton'
import { ButtonAttributes } from '@components/StyledComponents/Buttons'
import useGlobalContext from '@hooks/useGlobalContext'
import ProfessorSelectHeader from './ProfessorSelectHeader'
import ProfessorSignUpSuccess from './ProfessorSignUpSuccess'
import SignupModalHeader from '../SignupFormModal/SignupModalHeader'

const professorSignUpMutation = graphql`
  mutation ProfessorSignUpModalMutation($professor: ProfessorInputObject) {
    createProfessor(professor: $professor) {
      validation {
        ...FormController_validation
      }
    }
  }
`

export const ProfSignUpModalHeader = styled(SignupModalHeader)`
  display: inline-flex;
  justify-content: center;
  margin-top: 5px;
`

export const ProfSignUpModalHeaderBold = styled(SignupModalHeader)`
  font-weight: ${props => props.theme.fontWeight.boldest};
  margin-right: 8px;
`

const StyledProfessorSignUpContainer = styled.div`
  height: 100%;
  text-align: center;
  width: 100%;
`

const StyledHelperTextFooter = styled.div`
  bottom: 0px;
  font-size: ${props => props.theme.fontSize.medium};
  left: 0px;
  margin-bottom: 16px;
  position: absolute;
  width: 100%;
  button {
    color: ${props => props.theme.color.mainBlue};
    font-weight: ${props => props.theme.fontWeight.bold};
    line-height: inherit;
    margin-left: 3px;
    text-decoration: none;
  }
`

export const StyledProfessorSignUpForm = styled(Form)`
  margin-top: 24px;
`

const SubmitButton = styled(FormButton)`
  width: 100%;

  ${ButtonAttributes}
`

export const OptInText = styled.div`
  margin: 16px;
`

export default function ProfessorSignUpModal() {
  const { globalRef } = useGlobalContext()
  const [selectedTeacher, setSelectedTeacher] = useState()
  const [showSuccess, setShowSuccess] = useState(false)

  const onClose = () => {
    setSelectedTeacher(null)
    setShowSuccess(false)
    globalRef.current = null
  }

  React.useEffect(() => {
    if (globalRef?.current && globalRef?.current.legacyId) {
      setSelectedTeacher(globalRef.current)
    }
  }, [globalRef?.current])

  if (showSuccess) {
    return (
      <AuthModal modal={MODALS.PROFESSOR_SIGN_UP} isSlidingModal minHeight="424px" maxWidth="575px">
        <ProfSignUpModalHeader>
          <ProfSignUpModalHeaderBold>Professor</ProfSignUpModalHeaderBold>Sign Up
        </ProfSignUpModalHeader>
        <ProfessorSignUpSuccess email={selectedTeacher.email} />
      </AuthModal>
    )
  }

  return (
    <AuthModal modal={MODALS.PROFESSOR_SIGN_UP} isSlidingModal minHeight="424px" onClose={onClose}>
      <ProfSignUpModalHeader>
        <ProfSignUpModalHeaderBold>Professor</ProfSignUpModalHeaderBold>Sign Up
      </ProfSignUpModalHeader>

      <StyledProfessorSignUpContainer>
        <StyledProfessorSignUpForm
          getVariables={model => {
            setSelectedTeacher({ ...selectedTeacher, email: model.email })
            return {
              professor: {
                email: model.email,
                password: model.password,
                firstName: selectedTeacher.firstName,
                lastName: selectedTeacher.lastName,
                teacherId: selectedTeacher.id
              }
            }
          }}
          mutation={professorSignUpMutation}
          onSuccess={() => {
            setShowSuccess(true)
          }}
        >
          {selectedTeacher ? (
            <ProfessorSelectHeader teacher={selectedTeacher} />
          ) : (
            <FormTeacherSelector
              inModal
              name="teacher"
              label="Professor"
              labelStyle={LABEL_STYLE.HIDDEN}
              setSelectedTeacher={setSelectedTeacher}
            />
          )}
          {selectedTeacher && (
            <React.Fragment>
              <TextInput
                name="email"
                label="University/College Email Address"
                labelStyle={LABEL_STYLE.TOP}
              />
              <TextInput
                isPassword
                name="password"
                label="Password"
                autoComplete="new-password"
                setHelperTextToError="Minimum 8 characters"
                labelStyle={LABEL_STYLE.TOP}
              />
              <OptInText>
                By clicking &quot;Continue&quot; you agree to the Terms of Use and our Privacy
                RateMyProfessors is designed for and targeted to U.S. audiences and is governed by
                and operated in accordance with U.S. laws.
              </OptInText>
              <SubmitButton type="submit">Continue</SubmitButton>
            </React.Fragment>
          )}
        </StyledProfessorSignUpForm>
        <StyledHelperTextFooter>
          Already have an account? <ModalButtonLink modal={MODALS.LOGIN}>Login</ModalButtonLink>
        </StyledHelperTextFooter>
      </StyledProfessorSignUpContainer>
    </AuthModal>
  )
}
