import React from 'react'
import { Helmet } from 'react-helmet'
import getRMPRoot from '@utils/getRMPRoot'
import metaLogo from '@assets/images/logos/meta-no-thumbs.png'

export const faviconMap = [
  { rel: 'icon', href: '/favicons/favicon-16.png' },
  { rel: 'icon', href: '/favicons/favicon-32.png', sizes: '32×32' },
  { rel: 'apple-touch-icon', href: '/favicons/favicon-57.png' },
  { rel: 'apple-touch-icon', href: '/favicons/favicon-48.png', sizes: '48×48' },
  { rel: 'apple-touch-icon', href: '/favicons/favicon-72.png', sizes: '72×72' },
  { rel: 'apple-touch-icon', href: '/favicons/favicon-114.png', sizes: '114×114' },
  { rel: 'apple-touch-icon', href: '/favicons/favicon-152.png', sizes: '152×152' },
  { rel: 'apple-touch-icon-precomposed', href: '/favicons/favicon-196.png' }
]

export default function AppMeta() {
  const rootURL = getRMPRoot()

  const title = 'Rate My Professors'
  const description = 'Find and rate your professor or school'

  return (
    <Helmet>
      {faviconMap.map((favicon, index) => (
        <link rel={favicon.rel} href={favicon.href} sizes={favicon.sizes} key={index.toString()} />
      ))}
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta property="og:title" content={`${title} | ${description}`} />
      <meta property="og:description" content={description} />
      {/* Should be less than 1MB and 1200×630 or larger at ~1.91:1 aspect ratio */}
      <meta property="og:image" content={`${rootURL}${metaLogo}`} />
      <meta property="og:type" content="website" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="fo-verify" content="0ab72f55-ad7d-4d15-9196-2791be44b44c" />
    </Helmet>
  )
}
