/**
 * @flow
 * @relayHash ef0027b1012bf02275f4f02941a1322d
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type FormController_validation$ref = any;
export type AddCampusPageMutationVariables = {|
  name: string,
  nickname?: ?string,
  city: string,
  state: string,
  country: string,
  website: string,
  email: string,
  recaptcha: string,
|};
export type AddCampusPageMutationResponse = {|
  +schoolCreate: ?{|
    +validation: ?{|
      +$fragmentRefs: FormController_validation$ref
    |}
  |}
|};
export type AddCampusPageMutation = {|
  variables: AddCampusPageMutationVariables,
  response: AddCampusPageMutationResponse,
|};
*/


/*
mutation AddCampusPageMutation(
  $name: String!
  $nickname: String
  $city: String!
  $state: String!
  $country: String!
  $website: String!
  $email: String!
  $recaptcha: String!
) {
  schoolCreate(name: $name, nickname: $nickname, city: $city, state: $state, country: $country, website: $website, email: $email, recaptcha: $recaptcha) {
    validation {
      ...FormController_validation
      id
    }
  }
}

fragment FormController_validation on ValidationResult {
  id
  success
  errors {
    field
    messages
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "name",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "nickname",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "city",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "state",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "country",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "website",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "email",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "recaptcha",
    "type": "String!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "city",
    "variableName": "city"
  },
  {
    "kind": "Variable",
    "name": "country",
    "variableName": "country"
  },
  {
    "kind": "Variable",
    "name": "email",
    "variableName": "email"
  },
  {
    "kind": "Variable",
    "name": "name",
    "variableName": "name"
  },
  {
    "kind": "Variable",
    "name": "nickname",
    "variableName": "nickname"
  },
  {
    "kind": "Variable",
    "name": "recaptcha",
    "variableName": "recaptcha"
  },
  {
    "kind": "Variable",
    "name": "state",
    "variableName": "state"
  },
  {
    "kind": "Variable",
    "name": "website",
    "variableName": "website"
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "AddCampusPageMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "schoolCreate",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "ValidationResponse",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "validation",
            "storageKey": null,
            "args": null,
            "concreteType": "ValidationResult",
            "plural": false,
            "selections": [
              {
                "kind": "FragmentSpread",
                "name": "FormController_validation",
                "args": null
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "AddCampusPageMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "schoolCreate",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "ValidationResponse",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "validation",
            "storageKey": null,
            "args": null,
            "concreteType": "ValidationResult",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "id",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "success",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "errors",
                "storageKey": null,
                "args": null,
                "concreteType": "FieldErrorType",
                "plural": true,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "field",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "messages",
                    "args": null,
                    "storageKey": null
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "mutation",
    "name": "AddCampusPageMutation",
    "id": null,
    "text": "mutation AddCampusPageMutation(\n  $name: String!\n  $nickname: String\n  $city: String!\n  $state: String!\n  $country: String!\n  $website: String!\n  $email: String!\n  $recaptcha: String!\n) {\n  schoolCreate(name: $name, nickname: $nickname, city: $city, state: $state, country: $country, website: $website, email: $email, recaptcha: $recaptcha) {\n    validation {\n      ...FormController_validation\n      id\n    }\n  }\n}\n\nfragment FormController_validation on ValidationResult {\n  id\n  success\n  errors {\n    field\n    messages\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'a323fd8b4c402e78f71e0a1a673075c6';
module.exports = node;
