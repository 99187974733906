/**
 * @flow
 * @relayHash 97927f54a492c130a0da4f02a4dc5aa8
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type FlagCampusReportingBox_rating$ref = any;
export type FlagCampusRatingPageQueryVariables = {|
  id: string,
  ratingId?: ?string,
|};
export type FlagCampusRatingPageQueryResponse = {|
  +school: ?{|
    +id?: ?string,
    +legacyId?: ?number,
    +name?: ?string,
  |},
  +rating: ?{|
    +id?: ?string,
    +$fragmentRefs: FlagCampusReportingBox_rating$ref,
  |},
|};
export type FlagCampusRatingPageQuery = {|
  variables: FlagCampusRatingPageQueryVariables,
  response: FlagCampusRatingPageQueryResponse,
|};
*/


/*
query FlagCampusRatingPageQuery(
  $id: ID!
  $ratingId: ID
) {
  school: node(id: $id) {
    __typename
    ... on School {
      id
      legacyId
      name
    }
    id
  }
  rating: node(id: $ratingId) {
    __typename
    ... on SchoolRating {
      id
      ...FlagCampusReportingBox_rating
    }
    id
  }
}

fragment FlagCampusReportingBox_rating on SchoolRating {
  comment
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "id",
    "type": "ID!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "ratingId",
    "type": "ID",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "legacyId",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v5 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "ratingId"
  }
],
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "__typename",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "FlagCampusRatingPageQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": "school",
        "name": "node",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "type": "School",
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/)
            ]
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": "rating",
        "name": "node",
        "storageKey": null,
        "args": (v5/*: any*/),
        "concreteType": null,
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "type": "SchoolRating",
            "selections": [
              (v2/*: any*/),
              {
                "kind": "FragmentSpread",
                "name": "FlagCampusReportingBox_rating",
                "args": null
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "FlagCampusRatingPageQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": "school",
        "name": "node",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "plural": false,
        "selections": [
          (v6/*: any*/),
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "type": "School",
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/)
            ]
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": "rating",
        "name": "node",
        "storageKey": null,
        "args": (v5/*: any*/),
        "concreteType": null,
        "plural": false,
        "selections": [
          (v6/*: any*/),
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "type": "SchoolRating",
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "comment",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "FlagCampusRatingPageQuery",
    "id": null,
    "text": "query FlagCampusRatingPageQuery(\n  $id: ID!\n  $ratingId: ID\n) {\n  school: node(id: $id) {\n    __typename\n    ... on School {\n      id\n      legacyId\n      name\n    }\n    id\n  }\n  rating: node(id: $ratingId) {\n    __typename\n    ... on SchoolRating {\n      id\n      ...FlagCampusReportingBox_rating\n    }\n    id\n  }\n}\n\nfragment FlagCampusReportingBox_rating on SchoolRating {\n  comment\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '997912754ce4c50ea20e9b32dfdf7a12';
module.exports = node;
