import React from 'react'
import PropTypes from 'prop-types'

const Share = ({ color }) => {
  return (
    <svg width="36" height="30" viewBox="0 0 36 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M21.4522 6.71525L17.8516 2.99833L14.2509 6.71525L13.2998 5.73342L17.8516 1.03467L22.4033 5.73342L21.4522 6.71525Z"
        fill={color}
        stroke={color}
        strokeWidth="0.5"
      />
      <path
        d="M17.1792 2.01489H18.5379V16.7423H17.1792V2.01489Z"
        fill={color}
        stroke={color}
        strokeWidth="0.5"
      />
      <path
        d="M24.6454 25.1614H11.0581C9.90319 25.1614 9.02002 24.2497 9.02002 23.0575V10.434C9.02002 9.24178 9.90319 8.33008 11.0581 8.33008H15.8137V9.73269H11.0581C10.6505 9.73269 10.3787 10.0132 10.3787 10.434V23.0575C10.3787 23.4783 10.6505 23.7588 11.0581 23.7588H24.6454C25.053 23.7588 25.3248 23.4783 25.3248 23.0575V10.434C25.3248 10.0132 25.053 9.73269 24.6454 9.73269H19.8899V8.33008H24.6454C25.8003 8.33008 26.6835 9.24178 26.6835 10.434V23.0575C26.6835 24.2497 25.8003 25.1614 24.6454 25.1614Z"
        fill={color}
        stroke={color}
        strokeWidth="0.5"
      />
    </svg>
  )
}

Share.propTypes = {
  color: PropTypes.string
}

export default Share
