/**
 * @flow
 * @relayHash 30eb9612520802025c105cc790edb452
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type FormController_validation$ref = any;
export type RateSchoolEditMutationVariables = {|
  social: number,
  safety: number,
  comment: any,
  schoolID: string,
  internet: number,
  opportunities: number,
  facilities: number,
  food: number,
  clubs: number,
  happiness: number,
  reputation: number,
  location: number,
  ID: string,
|};
export type RateSchoolEditMutationResponse = {|
  +editSchoolRating: ?{|
    +validation: ?{|
      +$fragmentRefs: FormController_validation$ref
    |}
  |}
|};
export type RateSchoolEditMutation = {|
  variables: RateSchoolEditMutationVariables,
  response: RateSchoolEditMutationResponse,
|};
*/


/*
mutation RateSchoolEditMutation(
  $social: Int!
  $safety: Int!
  $comment: UserComment!
  $schoolID: ID!
  $internet: Int!
  $opportunities: Int!
  $facilities: Int!
  $food: Int!
  $clubs: Int!
  $happiness: Int!
  $reputation: Int!
  $location: Int!
  $ID: ID!
) {
  editSchoolRating(social: $social, safety: $safety, comment: $comment, schoolID: $schoolID, internet: $internet, opportunities: $opportunities, facilities: $facilities, food: $food, clubs: $clubs, happiness: $happiness, reputation: $reputation, location: $location, ID: $ID) {
    validation {
      ...FormController_validation
      id
    }
  }
}

fragment FormController_validation on ValidationResult {
  id
  success
  errors {
    field
    messages
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "social",
    "type": "Int!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "safety",
    "type": "Int!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "comment",
    "type": "UserComment!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "schoolID",
    "type": "ID!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "internet",
    "type": "Int!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "opportunities",
    "type": "Int!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "facilities",
    "type": "Int!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "food",
    "type": "Int!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "clubs",
    "type": "Int!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "happiness",
    "type": "Int!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "reputation",
    "type": "Int!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "location",
    "type": "Int!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "ID",
    "type": "ID!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "ID",
    "variableName": "ID"
  },
  {
    "kind": "Variable",
    "name": "clubs",
    "variableName": "clubs"
  },
  {
    "kind": "Variable",
    "name": "comment",
    "variableName": "comment"
  },
  {
    "kind": "Variable",
    "name": "facilities",
    "variableName": "facilities"
  },
  {
    "kind": "Variable",
    "name": "food",
    "variableName": "food"
  },
  {
    "kind": "Variable",
    "name": "happiness",
    "variableName": "happiness"
  },
  {
    "kind": "Variable",
    "name": "internet",
    "variableName": "internet"
  },
  {
    "kind": "Variable",
    "name": "location",
    "variableName": "location"
  },
  {
    "kind": "Variable",
    "name": "opportunities",
    "variableName": "opportunities"
  },
  {
    "kind": "Variable",
    "name": "reputation",
    "variableName": "reputation"
  },
  {
    "kind": "Variable",
    "name": "safety",
    "variableName": "safety"
  },
  {
    "kind": "Variable",
    "name": "schoolID",
    "variableName": "schoolID"
  },
  {
    "kind": "Variable",
    "name": "social",
    "variableName": "social"
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "RateSchoolEditMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "editSchoolRating",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "EditSchoolRatingMutationResponse",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "validation",
            "storageKey": null,
            "args": null,
            "concreteType": "ValidationResult",
            "plural": false,
            "selections": [
              {
                "kind": "FragmentSpread",
                "name": "FormController_validation",
                "args": null
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "RateSchoolEditMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "editSchoolRating",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "EditSchoolRatingMutationResponse",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "validation",
            "storageKey": null,
            "args": null,
            "concreteType": "ValidationResult",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "id",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "success",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "errors",
                "storageKey": null,
                "args": null,
                "concreteType": "FieldErrorType",
                "plural": true,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "field",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "messages",
                    "args": null,
                    "storageKey": null
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "mutation",
    "name": "RateSchoolEditMutation",
    "id": null,
    "text": "mutation RateSchoolEditMutation(\n  $social: Int!\n  $safety: Int!\n  $comment: UserComment!\n  $schoolID: ID!\n  $internet: Int!\n  $opportunities: Int!\n  $facilities: Int!\n  $food: Int!\n  $clubs: Int!\n  $happiness: Int!\n  $reputation: Int!\n  $location: Int!\n  $ID: ID!\n) {\n  editSchoolRating(social: $social, safety: $safety, comment: $comment, schoolID: $schoolID, internet: $internet, opportunities: $opportunities, facilities: $facilities, food: $food, clubs: $clubs, happiness: $happiness, reputation: $reputation, location: $location, ID: $ID) {\n    validation {\n      ...FormController_validation\n      id\n    }\n  }\n}\n\nfragment FormController_validation on ValidationResult {\n  id\n  success\n  errors {\n    field\n    messages\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'd973aa9440c998e7292445d9dfb72408';
module.exports = node;
