import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import getRMPRoot from '@utils/getRMPRoot'
import { useHistory } from 'react-router-dom'

const StyledSchoolPageLink = styled.button`
  background: transparent;
  border: 0;
  margin-top: -3px;

  &:hover {
    cursor: pointer;
  }
`

export default function OldRMPSchoolPageLink({ to, children, ...props }) {
  const history = useHistory()

  const handleClick = () => {
    const path = `${getRMPRoot()}${to}`
    history.push(path)
  }

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <StyledSchoolPageLink onClick={handleClick} type="button" {...props}>
      {children}
    </StyledSchoolPageLink>
  )
}

OldRMPSchoolPageLink.propTypes = {
  to: PropTypes.string,
  children: PropTypes.node
}
