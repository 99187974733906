import React, { useState, useContext } from 'react'
import styled from 'styled-components'
import FormContext from '@contexts/FormContext'
import { RATING_LEVEL_LIMITS } from '@enums/RATING_SCALE_ENUMS'
import PropTypes from 'prop-types'
import FormSlider from '@components/Form/FormSlider'
import RatingSliderBox from './RatingSliderBox'
import RatingSliderStatus from './RatingSliderStatus'

export const StyledRatingSlider = styled.div`
  display: inline-flex;
  flex-direction: column;
  width: 100%;
`
export const SliderBoxContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  &:hover {
    cursor: pointer;
  }
`

const HiddenFormSlider = styled(FormSlider)`
  display: none;
`

export default function RatingSlider({ type, label, name, ...props }) {
  const { model } = useContext(FormContext)
  const [ratingValue, setRatingValue] = useState(0)
  const [hoverValue, setHoverValue] = useState(0)
  const SliderBoxes = []

  for (let i = RATING_LEVEL_LIMITS.MIN; i < RATING_LEVEL_LIMITS.MAX + 1; i += 1) {
    SliderBoxes.push(
      <RatingSliderBox
        key={`slider-box-${i}`}
        boxValue={i}
        ratingValue={ratingValue || model[name] || 0}
        setRatingValue={setRatingValue}
        hoverValue={hoverValue}
        setHoverValue={setHoverValue}
        aria-selected={ratingValue === i}
        data-testid="SliderBox"
        type={type}
      />
    )
  }
  const importedRatingValue = ratingValue || model[name] || 0
  const displayedValue = hoverValue > 0 ? hoverValue : importedRatingValue

  function isLit() {
    if (hoverValue > 0 && hoverValue !== importedRatingValue) {
      return false
    }
    return importedRatingValue > 0
  }

  return (
    <StyledRatingSlider>
      <HiddenFormSlider
        disabled={false}
        value={ratingValue}
        valueMax={RATING_LEVEL_LIMITS.MAX}
        label={label}
        name={name}
        {...props}
      />
      <SliderBoxContainer>{SliderBoxes}</SliderBoxContainer>
      <RatingSliderStatus type={type} value={displayedValue} isLit={isLit()} {...props} />
    </StyledRatingSlider>
  )
}

RatingSlider.propTypes = {
  label: PropTypes.string,
  type: PropTypes.string,
  name: PropTypes.string
}
