import ROUTE from '@enums/ROUTE'
import { graphql } from 'react-relay'
import { matchPath } from 'react-router'

export const getVariables = location => {
  const match = matchPath(location.pathname, {
    path: [ROUTE.NEW_FLAG_PROF_RATING]
  })

  if (!match?.params?.tid) return { id: '' }

  return {
    id: btoa(`Teacher-${match.params.tid}`),
    ratingId: match?.params?.rid ? btoa(`Rating-${match.params.rid}`) : null
  }
}

export const flagTeacherRatingMutation = graphql`
  mutation FlagTeacherRatingMutation($ratingID: ID!, $flagComment: UserComment!) {
    flagProfessorRating(ratingID: $ratingID, flagComment: $flagComment) {
      validation {
        ...FormController_validation
      }
    }
  }
`
