/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type SchoolCardLocation_school$ref: FragmentReference;
declare export opaque type SchoolCardLocation_school$fragmentType: SchoolCardLocation_school$ref;
export type SchoolCardLocation_school = {|
  +city: ?string,
  +state: ?string,
  +$refType: SchoolCardLocation_school$ref,
|};
export type SchoolCardLocation_school$data = SchoolCardLocation_school;
export type SchoolCardLocation_school$key = {
  +$data?: SchoolCardLocation_school$data,
  +$fragmentRefs: SchoolCardLocation_school$ref,
};
*/


const node/*: ReaderFragment*/ = {
  "kind": "Fragment",
  "name": "SchoolCardLocation_school",
  "type": "School",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "city",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "state",
      "args": null,
      "storageKey": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '50c167ccc3875dc22d2d93a88df26257';
module.exports = node;
