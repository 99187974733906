import React from 'react'
import { graphql } from 'react-relay'
import ReactRouterPropTypes from 'react-router-prop-types'
import ROUTE from '@enums/ROUTE'
import { withRouter, Redirect } from 'react-router-dom'
import QueryWrapper from '@componentUtils/QueryWrapper'
import RateSchool from './RateSchool'
import { getVariables } from './RateSchool.utils'

export const AddRatingSchoolPageQuery = graphql`
  query AddRatingSchoolPageQuery($id: ID!) {
    school: node(id: $id) {
      ... on School {
        name
        state
        city
        id
        legacyId
        ...StickyHeaderContent_school
      }
    }
  }
`

export const AddRatingSchoolPage = ({ location }) => {
  const variables = getVariables(location)

  if (!variables.id) {
    return <Redirect to={ROUTE.TEACHER_NOT_FOUND} />
  }

  return (
    <QueryWrapper query={AddRatingSchoolPageQuery} component={RateSchool} variables={variables} />
  )
}

AddRatingSchoolPage.propTypes = {
  location: ReactRouterPropTypes.location
}

const withRouterAddRatingSchoolPage = withRouter(AddRatingSchoolPage)
withRouterAddRatingSchoolPage.serverQueries = [
  {
    query: AddRatingSchoolPageQuery,
    getVariables
  }
]

export default withRouterAddRatingSchoolPage
