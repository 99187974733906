/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type CourseMeta_rating$ref: FragmentReference;
declare export opaque type CourseMeta_rating$fragmentType: CourseMeta_rating$ref;
export type CourseMeta_rating = {|
  +attendanceMandatory: ?string,
  +wouldTakeAgain: ?number,
  +grade: ?string,
  +textbookUse: ?number,
  +isForOnlineClass: ?boolean,
  +isForCredit: ?boolean,
  +$refType: CourseMeta_rating$ref,
|};
export type CourseMeta_rating$data = CourseMeta_rating;
export type CourseMeta_rating$key = {
  +$data?: CourseMeta_rating$data,
  +$fragmentRefs: CourseMeta_rating$ref,
};
*/


const node/*: ReaderFragment*/ = {
  "kind": "Fragment",
  "name": "CourseMeta_rating",
  "type": "Rating",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "attendanceMandatory",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "wouldTakeAgain",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "grade",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "textbookUse",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "isForOnlineClass",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "isForCredit",
      "args": null,
      "storageKey": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '08714a48a7ec6441f935708a88e41b28';
module.exports = node;
