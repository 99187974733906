/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type Thumbs_rating$ref: FragmentReference;
declare export opaque type Thumbs_rating$fragmentType: Thumbs_rating$ref;
export type Thumbs_rating = {|
  +id: ?string,
  +comment: ?any,
  +adminReviewedAt: ?string,
  +flagStatus: ?string,
  +legacyId: ?number,
  +thumbsUpTotal: ?number,
  +thumbsDownTotal: ?number,
  +thumbs: ?$ReadOnlyArray<?{|
    +computerId: ?string,
    +thumbsUp: ?number,
    +thumbsDown: ?number,
  |}>,
  +teacherNote: ?{|
    +id: ?string
  |},
  +$refType: Thumbs_rating$ref,
|};
export type Thumbs_rating$data = Thumbs_rating;
export type Thumbs_rating$key = {
  +$data?: Thumbs_rating$data,
  +$fragmentRefs: Thumbs_rating$ref,
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "Thumbs_rating",
  "type": "Rating",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    (v0/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "comment",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "adminReviewedAt",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "flagStatus",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "legacyId",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "thumbsUpTotal",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "thumbsDownTotal",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "thumbs",
      "storageKey": null,
      "args": null,
      "concreteType": "Thumb",
      "plural": true,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "computerId",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "thumbsUp",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "thumbsDown",
          "args": null,
          "storageKey": null
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "teacherNote",
      "storageKey": null,
      "args": null,
      "concreteType": "TeacherNotes",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '8daf7eb444d8f8d5bef37ce88bb4f75a';
module.exports = node;
