import React from 'react'
import ReactRouterPropTypes from 'react-router-prop-types'
import { Redirect, withRouter } from 'react-router-dom'
import { graphql } from 'react-relay'
import ROUTE from '@enums/ROUTE'
import QueryWrapper from '@componentUtils/QueryWrapper'
import SchoolRatings from './SchoolRatings'
import { getVariables } from './SchoolRatings.utils'

const query = graphql`
  query SchoolRatingsPageQuery($id: ID!) {
    school: node(id: $id) {
      ... on School {
        id
        legacyId
        name
        city
        state
        country
        numRatings
        ...StickyHeaderContent_school
        ...OverallRating_school
        ...SchoolSummary_school
        ...SchoolRatingsContainer_school
      }
    }
  }
`

export const SchoolRatingsPage = ({ location }) => {
  const variables = getVariables(location)
  if (!variables.id) {
    return <Redirect to={ROUTE.CAMPUS_NOT_FOUND} />
  }

  return <QueryWrapper query={query} component={SchoolRatings} variables={variables} />
}

SchoolRatingsPage.propTypes = {
  location: ReactRouterPropTypes.location
}

const withRouterSchoolRatingsPage = withRouter(SchoolRatingsPage)
withRouterSchoolRatingsPage.serverQueries = [
  {
    query,
    getVariables
  }
]

export default withRouterSchoolRatingsPage
