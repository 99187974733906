import React, { useEffect } from 'react'
import styled from 'styled-components'
import { graphql } from 'react-relay'
import { LINK_TYPE } from '@components/Link'
import PropTypes from 'prop-types'
import { hotjar } from 'react-hotjar'
import Form from '@components/Form/Form'
import TextInput from '@components/Form/TextInput'
import { ButtonAttributes } from '@StyledComponents/Buttons'
import MODALS from '@enums/MODALS'
import getEnv from '@utils/getEnv'
import FormRecaptcha3Button from '@components/Form/FormReCaptcha3Button'
import ReCAPTCHA from '@lib/ReCAPTCHA'
import ModalButtonLink from '@components/ModalButtonLink'
import SIGNUP_STAGE from '@enums/SIGNUP_STAGE'
import OauthSection from '@components/StyledComponents/OauthButton'
import useOAuth from '@utils/useOAuth'
import SignupModalHeader from './SignupModalHeader'

const EmailFormHeader = styled(SignupModalHeader)`
  display: inline-flex;
  justify-content: center;
  margin-top: 5px;
`

const EmailFormHeaderBold = styled(SignupModalHeader)`
  font-weight: ${props => props.theme.fontWeight.boldest};
  margin-right: 8px;
`

const StyledEmailForm = styled(Form)``

const FormCTA = styled.div`
  display: flex;
  font-size: ${props => props.theme.fontSize.medium};
  justify-content: center;
  line-height: 18px;
  margin-top: 26px;

  a,
  button {
    color: ${props => props.theme.color.mainBlue};
    font-size: ${props => props.theme.fontSize.medium};
    font-weight: ${props => props.theme.fontWeight.bold};
    line-height: inherit;
    margin-left: 3px;
    text-decoration: none;
  }
`

const EmailButton = styled(FormRecaptcha3Button)`
  margin-top: 46px;
  width: 100%;

  ${ButtonAttributes}
`

const SignUpModalLink = styled(ModalButtonLink)``

const emailValidationMutation = graphql`
  mutation EmailFormMutation($email: String!, $recaptcha: String!) {
    emailValidation(email: $email, recaptcha: $recaptcha) {
      validation {
        ...FormController_validation
      }
    }
  }
`

const LegalTextBody = styled.div`
  font-size: 12px;
  line-height: 16px;
  margin: 16px 0;
  max-width: 343px;
  width: 100%;
`

const LegalText = () => (
  <LegalTextBody>
    Rate My Professors is designed for and targeted to U.S. audiences and is governed by and
    operated in accordance with U.S. laws.
  </LegalTextBody>
)

export default function EmailForm({ setStage, setNewEmail, newEmail }) {
  const hotjarID = getEnv(getEnv('REACT_APP_HOTJAR_KEY'))
  useEffect(() => {
    hotjar.initialize(hotjarID)
    hotjar.event('sign-up')
  }, [])

  const isNewOauth = useOAuth()

  return (
    <React.Fragment>
      <EmailFormHeader>
        <EmailFormHeaderBold>Student</EmailFormHeaderBold>Sign Up
      </EmailFormHeader>
      <FormCTA>
        Are you a professor?
        <SignUpModalLink modal={MODALS.PROFESSOR_SIGN_UP} type={LINK_TYPE.INTERNAL}>
          Sign up here
        </SignUpModalLink>
      </FormCTA>
      {isNewOauth && <OauthSection login={false} />}
      <StyledEmailForm
        getVariables={model => {
          setNewEmail(model.email)
          return model
        }}
        existingData={{ email: newEmail }}
        mutation={emailValidationMutation}
        onSuccess={() => setStage(SIGNUP_STAGE.PASSWORD)}
      >
        <TextInput name="email" label="Email" />
        <EmailButton action={ReCAPTCHA.ACTIONS.VALIDATE_EMAIL}>Continue</EmailButton>
      </StyledEmailForm>
      <LegalText />
      <FormCTA>
        Already have an account?
        <ModalButtonLink modal={MODALS.LOGIN}>Login</ModalButtonLink>
      </FormCTA>
    </React.Fragment>
  )
}

EmailForm.propTypes = {
  setStage: PropTypes.func.isRequired,
  setNewEmail: PropTypes.func.isRequired,
  newEmail: PropTypes.string.isRequired
}
