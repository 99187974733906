import React from 'react'
import styled, { css } from 'styled-components'
import getEnv from '@utils/getEnv'
import { breakpointSmediumAndBelow } from '@components/StyledComponents/theme/helpers/breakpoints'
import CengageBannerLogo from '@assets/images/logos/banner_cengage_logo_summer22.svg'
import { getCengageSRC, getCengageHREF } from '@utils/getCengageLinks'

const StyledHeaderCengageBanner = styled.a`
  background: ${({ theme }) => theme.color.gray11};
  padding: 6px 12px;
  width: 100%;
`

const BannerLogo = styled.div`
  background-image: url(${CengageBannerLogo});
  background-repeat: no-repeat;
  height: 26px;
  margin-left: 128px;

  ${breakpointSmediumAndBelow(css`
    background-image: url(${CengageBannerLogo});
    height: 28px;
    margin-top: 6px;
    margin-left: 0px;
  `)}
`

const HeaderCengageBanner = () => {
  if (getEnv('REACT_APP_SHOW_CENGAGE') !== 'true') return null

  return (
    <React.Fragment>
      <StyledHeaderCengageBanner href={getCengageHREF()} target="_blank">
        <div id="cengage-promo-banner">
          <BannerLogo role="img" />
        </div>
      </StyledHeaderCengageBanner>
      <img
        src={getCengageSRC()}
        border="0"
        height="1"
        width="1"
        aria-hidden
        // Image still loads in DOM but needs to be hidden to avoid white 1x1 pixel
        style={{ visibility: 'hidden' }}
        // Empty string to prevent alt text being rendered (ch16014)
        alt=""
        title="Cengage Advertisement"
      />
    </React.Fragment>
  )
}

export default HeaderCengageBanner
