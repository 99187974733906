import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import TeacherDepartmentFilter from './TeacherDepartmentFilter'

const StyledTeacherFilterSet = styled.div`
  display: flex;
  margin-bottom: 24px;
  max-width: 680px;
  width: 100%;
`

const TeacherFilterSet = ({ setFilter, filter, filters }) => {
  return (
    <StyledTeacherFilterSet>
      <TeacherDepartmentFilter
        value={filter}
        filterOptions={filters[0]?.options}
        onChange={setFilter}
      />
    </StyledTeacherFilterSet>
  )
}

const filterPropTypes = PropTypes.shape({
  field: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape({ count: PropTypes.number, value: PropTypes.string }))
})

TeacherFilterSet.propTypes = {
  setFilter: PropTypes.func,
  filter: filterPropTypes,
  filters: PropTypes.arrayOf(filterPropTypes)
}

export default TeacherFilterSet
