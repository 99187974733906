/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type RatingTags_rating$ref: FragmentReference;
declare export opaque type RatingTags_rating$fragmentType: RatingTags_rating$ref;
export type RatingTags_rating = {|
  +ratingTags: ?string,
  +$refType: RatingTags_rating$ref,
|};
export type RatingTags_rating$data = RatingTags_rating;
export type RatingTags_rating$key = {
  +$data?: RatingTags_rating$data,
  +$fragmentRefs: RatingTags_rating$ref,
};
*/


const node/*: ReaderFragment*/ = {
  "kind": "Fragment",
  "name": "RatingTags_rating",
  "type": "Rating",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "ratingTags",
      "args": null,
      "storageKey": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '3d9b312b62eeb781af2f4c72d9f713d9';
module.exports = node;
