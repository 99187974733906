import { graphql } from 'react-relay'

const addProfessorPageMutation = graphql`
  mutation AddProfessorPageMutation(
    $firstName: String!
    $middleName: String
    $lastName: String!
    $department: String!
    $directoryUrl: String
    $schoolName: String!
    $schoolID: ID!
    $recaptcha: String!
  ) {
    addTeacher(
      firstName: $firstName
      middleName: $middleName
      lastName: $lastName
      department: $department
      directoryUrl: $directoryUrl
      schoolName: $schoolName
      schoolID: $schoolID
      recaptcha: $recaptcha
    ) {
      teacher {
        id
        legacyId
      }
      validation {
        ...FormController_validation
      }
    }
  }
`

export default addProfessorPageMutation
