import PropTypes from 'prop-types'

const SchoolAttributes = {
  id: PropTypes.string,
  legacyId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  name: PropTypes.string,
  country: PropTypes.string,
  city: PropTypes.string
}

const SchoolTypes = PropTypes.shape(SchoolAttributes)

export default SchoolTypes
