/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type SchoolRatingFooter_school$ref = any;
type SchoolRatingSuperHeader_school$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type SchoolRating_school$ref: FragmentReference;
declare export opaque type SchoolRating_school$fragmentType: SchoolRating_school$ref;
export type SchoolRating_school = {|
  +$fragmentRefs: SchoolRatingSuperHeader_school$ref & SchoolRatingFooter_school$ref,
  +$refType: SchoolRating_school$ref,
|};
export type SchoolRating_school$data = SchoolRating_school;
export type SchoolRating_school$key = {
  +$data?: SchoolRating_school$data,
  +$fragmentRefs: SchoolRating_school$ref,
};
*/


const node/*: ReaderFragment*/ = {
  "kind": "Fragment",
  "name": "SchoolRating_school",
  "type": "School",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "SchoolRatingSuperHeader_school",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "SchoolRatingFooter_school",
      "args": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = 'f3e059ae2a741e4bf3cc8656d8eaa807';
module.exports = node;
