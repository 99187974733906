import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import { createFragmentContainer, graphql } from 'react-relay'
import getEnv from '@utils/getEnv'
import { breakpointSmallPlusAndBelow } from '@StyledComponents/theme/helpers/breakpoints'
import PropTypes from 'prop-types'
import RatingTypes from '@types/Rating.types'
import TeacherTypes from '@types/Teacher.types'
import FLAG_STATUS from '@enums/FLAG_STATUS'
import Comments from '@components/Comments'
import ProfessorNoteSection from '@components/ProfessorNotes/ProfessorNoteSection'
import RatingRemoved from '@components/Rating/RatingRemoved'
import RatingHeader from './RatingHeader'
import RatingValues from './RatingValues'
import CourseMeta from './CourseMeta'
import RatingTags from './RatingTags'
import RatingFooter from './RatingFooter'
import RatingSuperHeader from './RatingSuperHeader'

export const RatingBody = styled.div`
  display: flex;
  padding: 21px 24px 0;

  ${breakpointSmallPlusAndBelow(
    css`
      flex-direction: column;
    `
  )};
`

export const StyledRating = styled.div`
  background-color: rgba(241, 241, 241, 0.4);
  margin: 16px 0;
`

export const RatingComments = styled(Comments)`
  font-size: ${props => props.theme.fontSize.medium};
  line-height: 1.5;
  margin-bottom: 30px;
`

export const RatingInfo = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 48px;
  text-align: left;
  width: 100%;

  ${breakpointSmallPlusAndBelow(
    css`
      margin: 0;
    `
  )}
`

export const defaultDisplayOptions = {
  showSuperHeader: false,
  showEmotionLabel: false,
  showFooter: true,
  showRemoved: false
}
export function Rating({ rating, teacher, displayOptions = defaultDisplayOptions }) {
  const [replyModeActive, setReplyModeActive] = useState(false)
  const isCurrentUserRating = !displayOptions.showSuperHeader && rating?.createdByUser
  const hideRating =
    !rating || rating.flagStatus === FLAG_STATUS.HIDDEN || rating.flagStatus === FLAG_STATUS.DELETED

  const enableConfirmEmailCTA = getEnv('REACT_APP_CONFIRM_EMAIL_CTA') === 'true'

  // Ratings that are hidden or deleted shouldn't be returned, but this is still ensured on the front end
  if (hideRating && !displayOptions.showRemoved) return null

  if (displayOptions.showRemoved) {
    return <RatingRemoved />
  }

  return (
    <StyledRating>
      {(displayOptions.showSuperHeader || isCurrentUserRating) && rating && teacher && (
        <RatingSuperHeader hideTitle={isCurrentUserRating} rating={rating} teacher={teacher} />
      )}
      <RatingBody>
        <RatingHeader rating={rating} showEmotionLabel={displayOptions.showEmotionLabel} mobile />
        <RatingValues rating={rating} />
        <RatingInfo>
          <RatingHeader rating={rating} showEmotionLabel={displayOptions.showEmotionLabel} />
          <CourseMeta rating={rating} />
          <RatingComments content={rating.comment} />
          <RatingTags rating={rating} />
          {displayOptions.showFooter && (
            <RatingFooter
              confirmEmailFeature={enableConfirmEmailCTA}
              rating={rating}
              teacher={teacher}
              onReplyClicked={() => setReplyModeActive(true)}
            />
          )}
          {(rating.teacherNote || replyModeActive) && (
            <ProfessorNoteSection
              teacher={teacher}
              rating={rating}
              replyModeActive={replyModeActive}
              setReplyModeActive={setReplyModeActive}
            />
          )}
        </RatingInfo>
      </RatingBody>
    </StyledRating>
  )
}

export default createFragmentContainer(Rating, {
  teacher: graphql`
    fragment Rating_teacher on Teacher {
      ...RatingFooter_teacher
      ...RatingSuperHeader_teacher
      ...ProfessorNoteSection_teacher
    }
  `,
  rating: graphql`
    fragment Rating_rating on Rating {
      comment
      flagStatus
      createdByUser
      teacherNote {
        id
      }
      ...RatingHeader_rating
      ...RatingSuperHeader_rating
      ...RatingValues_rating
      ...CourseMeta_rating
      ...RatingTags_rating
      ...RatingFooter_rating
      ...ProfessorNoteSection_rating
    }
  `
})

Rating.propTypes = {
  rating: RatingTypes.isRequired,
  teacher: TeacherTypes,
  displayOptions: PropTypes.objectOf(PropTypes.bool)
}
