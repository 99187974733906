import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Error, SearchTextInput } from 'storybook-rmp'
import SEARCH_CATEGORY from '@enums/SEARCH_CATEGORY'
import { useFormikContext } from 'formik'
import SearchContainer from '@components/Search/SearchContainer'

const FormSearch = styled(SearchContainer)`
  font-family: HelveticaNeue;
  font-size: 16px;
  max-width: 360px;
  width: 100%;
`

export const onSchoolSelected = ({ selection, formik }) => {
  selection.event.preventDefault()

  const formattedDepartments = selection.item.departments.map(dep => {
    return {
      value: dep.id,
      label: dep.name
    }
  })

  formik.setValues({
    departments: formattedDepartments,
    schoolName: selection.item.name,
    schoolID: selection.item.id
  })

  selection.setIsOpen(false)
}

const SearchSchoolsInput = ({ id, name, label, ariaLabel, value, error }) => {
  const formik = useFormikContext()

  return (
    <SearchTextInput id={id} name={name} label={label} ariaLabel={ariaLabel} data-testid="school">
      <FormSearch
        searchCategory={SEARCH_CATEGORY.SCHOOLS}
        allowRedirect={false}
        onItemSelected={selection => onSchoolSelected({ selection, formik })}
        value={value}
        hidePlaceholder
        useErrorBorder
      />
      {error && <Error error={error} />}
    </SearchTextInput>
  )
}

SearchSchoolsInput.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  ariaLabel: PropTypes.string,
  value: PropTypes.string,
  error: PropTypes.string
}

export default SearchSchoolsInput
