/**
 * @flow
 * @relayHash 0388f73736182b11b71c9dbdb12e56d1
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UserAccountSettingsPageQueryVariables = {||};
export type UserAccountSettingsPageQueryResponse = {|
  +user: ?{|
    +oauth: ?boolean,
    +Email: ?string,
  |}
|};
export type UserAccountSettingsPageQuery = {|
  variables: UserAccountSettingsPageQueryVariables,
  response: UserAccountSettingsPageQueryResponse,
|};
*/


/*
query UserAccountSettingsPageQuery {
  user {
    oauth
    Email
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "oauth",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "Email",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "UserAccountSettingsPageQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "user",
        "storageKey": null,
        "args": null,
        "concreteType": "User",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/)
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "UserAccountSettingsPageQuery",
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "user",
        "storageKey": null,
        "args": null,
        "concreteType": "User",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "UserAccountSettingsPageQuery",
    "id": null,
    "text": "query UserAccountSettingsPageQuery {\n  user {\n    oauth\n    Email\n    id\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '1f8dc933a230c21e0a5e35bb91a27e2b';
module.exports = node;
