import React from 'react'
import { createFragmentContainer, graphql } from 'react-relay'
import styled, { css } from 'styled-components'
import TeacherTypes from '@types/Teacher.types'
import parseAndSanitize from '@utils/parseAndSanitize'
import { breakpointMediumAndBelow } from '@StyledComponents/theme/helpers/breakpoints'
import useGlobalContext from '@hooks/useGlobalContext'
import MODALS from '@enums/MODALS'
import Link from '../Link'

export const StyledNameLink = styled.div`
  display: flex;

  & > :nth-child(1) {
    padding-right: 12px;
  }

  & > :nth-child(2) {
    border-left: 1px solid ${props => props.theme.color.default};
    padding-left: 12px;
  }
`

export const StyledLink = styled(Link)`
  display: inline-block;
  font-weight: ${props => props.theme.fontWeight.bold};
  margin-bottom: 24px;

  ${breakpointMediumAndBelow(
    css`
      margin-bottom: 0;
    `
  )}
`

export const StyledModalLink = styled.div`
  cursor: pointer;
  display: inline-block;
  font-weight: ${props => props.theme.fontWeight.bold};
  margin-bottom: 24px;
  text-decoration: underline;
`

export const NameLink = ({ teacher }) => {
  const { globalRef, setActiveModal } = useGlobalContext()
  if (!teacher || !teacher.legacyId || !teacher.lastName) return null

  const handleProfSignUp = () => {
    globalRef.current = teacher
    setActiveModal(MODALS.PROFESSOR_SIGN_UP)
  }

  return (
    <StyledNameLink>
      {!teacher.isProfCurrentUser && (
        <React.Fragment>
          <StyledModalLink onClick={handleProfSignUp}>
            I&apos;m Professor {parseAndSanitize(teacher.lastName)}
          </StyledModalLink>
        </React.Fragment>
      )}
    </StyledNameLink>
  )
}

NameLink.propTypes = {
  teacher: TeacherTypes
}

export default createFragmentContainer(NameLink, {
  teacher: graphql`
    fragment NameLink_teacher on Teacher {
      isProfCurrentUser
      id
      legacyId
      firstName
      lastName
      school {
        name
      }
    }
  `
})
