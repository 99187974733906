/* eslint-disable import/prefer-default-export */
import PropTypes from 'prop-types'

export const getPaginationTypes = nodeTypes => {
  return PropTypes.shape({
    edges: PropTypes.arrayOf(
      PropTypes.shape({
        cursor: PropTypes.string,
        node: nodeTypes
      })
    ),
    pageInfo: PropTypes.shape({
      hasNextPage: PropTypes.bool
    })
  })
}
