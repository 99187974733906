import PropTypes from 'prop-types'

const MODALS = {
  NONE: null,
  CCPA: 'ccpa-modal',
  SIGN_UP_PROMPT: 'sign-up-prompt-modal',
  SIGN_UP_FORM: 'sign-up-form-modal',
  LOGIN: 'login-modal',
  LOGOUT: 'logout-modal',
  RESET_PASSWORD: 'reset-password',
  FORGOT_PASSWORD: 'forgot-password',
  CONFIRM_EMAIL: 'confirm-email',
  CONFIRM_EMAIL_PROMPT: 'confirm-email-prompt',
  REGISTER_PROMPT: 'register-prompt',
  PROFESSOR_SIGN_UP: 'professor-sign-up-modal'
}

export const ModalsTypes = PropTypes.oneOf(Object.values(MODALS))

export default MODALS
