import React from 'react'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'
import {
  breakpointMediumAndBelow,
  breakpointSmediumAndBelow
} from '@StyledComponents/theme/helpers/breakpoints'
import Carousel from '@components/Carousel/Carousel'
import CarouselItem from '@components/Carousel/CarouselItem'
import CTACard, { StyledCTACard, IMAGES, cardImagesConfig } from './CTACard'

const CardContainer = styled.div`
  display: flex;
  justify-content: space-around;
  margin-bottom: ${({ small }) => (small ? '0' : '72px')};

  ${props =>
    props.small
      ? breakpointSmediumAndBelow(css`
          display: none;
        `)
      : breakpointMediumAndBelow(css`
          flex-direction: column;
          margin: 0 auto;
          width: fit-content;
          justify-content: flex-start;

          ${StyledCTACard} {
            margin: 0 auto;
            width: fit-content;
            margin-bottom: 50px;
            font-size: 14px;
          }
        `)}
`

const StyledCarousel = styled(Carousel)`
  display: none;
  ${breakpointSmediumAndBelow(css`
    display: flex;
  `)}
`

export default function CTAImagePrompts({ small }) {
  return (
    <React.Fragment>
      <CardContainer small={small}>
        <CTACard imageConfig={cardImagesConfig[IMAGES.PENCIL_LADY]} small={small}>
          Manage and edit your ratings
        </CTACard>
        <CTACard imageConfig={cardImagesConfig[IMAGES.MYSTERY_LADY]} small={small}>
          Your ratings are always anonymous
        </CTACard>
        <CTACard imageConfig={cardImagesConfig[IMAGES.THUMB_WAR]} small={small}>
          Like or dislike ratings
        </CTACard>
      </CardContainer>
      {small && (
        <StyledCarousel small={small}>
          <CarouselItem>
            <CTACard imageConfig={cardImagesConfig[IMAGES.PENCIL_LADY]} small={small}>
              Manage and edit your ratings
            </CTACard>
          </CarouselItem>
          <CarouselItem>
            <CTACard imageConfig={cardImagesConfig[IMAGES.MYSTERY_LADY]} small={small}>
              Your ratings are always anonymous
            </CTACard>
          </CarouselItem>
          <CarouselItem>
            <CTACard imageConfig={cardImagesConfig[IMAGES.THUMB_WAR]} small={small}>
              Like or dislike ratings
            </CTACard>
          </CarouselItem>
        </StyledCarousel>
      )}
    </React.Fragment>
  )
}

CTAImagePrompts.propTypes = {
  small: PropTypes.bool
}
