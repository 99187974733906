import React from 'react'
import styled, { css } from 'styled-components'
import TeacherTypes from '@types/Teacher.types'
import { createFragmentContainer, graphql } from 'react-relay'
import PropTypes from 'prop-types'
import ROUTE from '@enums/ROUTE'
import Link from '@components/Link'
import TRACKING_EVENT from '@enums/TRACKING_EVENT'

const linkStyles = css`
  color: ${({ theme }) => theme.color.default};
  font-weight: bold;
`

export const StyledDepartmentLink = styled(Link)`
  ${linkStyles}
`

export const TeacherDepartment = ({ teacher, noLink }) => {
  if (!teacher || !teacher.school || !teacher.school.name || !teacher.school.legacyId) {
    return null
  }

  const TeacherSchoolLink = () => {
    return (
      <Link to={ROUTE.NEW_CAMPUS_RATINGS.replace(':sid', teacher.school.legacyId)}>
        {teacher.school.name}
      </Link>
    )
  }

  if (!teacher.department) {
    return (
      <React.Fragment>
        <span>Professor at </span> <TeacherSchoolLink />
      </React.Fragment>
    )
  }

  return (
    <React.Fragment>
      <span>
        Professor in the{' '}
        <StyledDepartmentLink
          to={`${ROUTE.TEACHER_SEARCH.replace(':sid?', teacher.school.legacyId)}?q=*&did=${
            teacher.departmentId
          }`}
          trackingLabel={TRACKING_EVENT.PROF_DEPARTMENT_LINK}
        >
          <b>{teacher.department} department</b>
        </StyledDepartmentLink>{' '}
        at{' '}
      </span>
      {noLink ? `${teacher.school.name}` : <TeacherSchoolLink />}
    </React.Fragment>
  )
}

export default createFragmentContainer(TeacherDepartment, {
  teacher: graphql`
    fragment TeacherDepartment_teacher on Teacher {
      department
      departmentId
      school {
        legacyId
        name
      }
    }
  `
})

TeacherDepartment.propTypes = {
  teacher: TeacherTypes,
  noLink: PropTypes.bool
}
