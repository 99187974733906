/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type ProfessorNoteEditor_teacher$ref = any;
type ProfessorNote_teacher$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type ProfessorNoteSection_teacher$ref: FragmentReference;
declare export opaque type ProfessorNoteSection_teacher$fragmentType: ProfessorNoteSection_teacher$ref;
export type ProfessorNoteSection_teacher = {|
  +$fragmentRefs: ProfessorNote_teacher$ref & ProfessorNoteEditor_teacher$ref,
  +$refType: ProfessorNoteSection_teacher$ref,
|};
export type ProfessorNoteSection_teacher$data = ProfessorNoteSection_teacher;
export type ProfessorNoteSection_teacher$key = {
  +$data?: ProfessorNoteSection_teacher$data,
  +$fragmentRefs: ProfessorNoteSection_teacher$ref,
};
*/


const node/*: ReaderFragment*/ = {
  "kind": "Fragment",
  "name": "ProfessorNoteSection_teacher",
  "type": "Teacher",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "ProfessorNote_teacher",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "ProfessorNoteEditor_teacher",
      "args": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = 'de862a1232e4e39f818bf7ca0267de63';
module.exports = node;
