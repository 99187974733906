import React from 'react'
import styled from 'styled-components'
import { createFragmentContainer, graphql } from 'react-relay'
import TeacherTypes from '@types/Teacher.types'
import FeedbackItem from './FeedbackItem'

const StyledTeacherFeedback = styled.div`
  display: flex;
  margin-bottom: 25px;

  & > :nth-child(1) {
    padding: 0 12px 16px 0;
  }

  & > :nth-child(2) {
    border-left: 1px solid ${props => props.theme.color.default};
    padding: 0 0 16px 12px;
  }
`

export function TeacherFeedback({ teacher }) {
  if (!teacher) return null

  const wouldTakeAgain =
    teacher.wouldTakeAgainPercent < 0 ? 'N/A' : `${Math.ceil(teacher.wouldTakeAgainPercent)}%`

  return (
    <StyledTeacherFeedback>
      <FeedbackItem
        number={wouldTakeAgain}
        numRatings={teacher.numRatings}
        label="Would take again"
      />
      <FeedbackItem
        number={teacher.avgDifficulty}
        numRatings={teacher.numRatings}
        label="Level of Difficulty"
      />
    </StyledTeacherFeedback>
  )
}

TeacherFeedback.propTypes = {
  teacher: TeacherTypes
}

export default createFragmentContainer(TeacherFeedback, {
  teacher: graphql`
    fragment TeacherFeedback_teacher on Teacher {
      numRatings
      avgDifficulty
      wouldTakeAgainPercent
    }
  `
})
