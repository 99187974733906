/**
 * @flow
 * @relayHash 5c3844fa73a255f4107e354c8ee00fb7
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type UserPagesTabs_user$ref = any;
export type UserPagesIndexQueryVariables = {||};
export type UserPagesIndexQueryResponse = {|
  +user: ?{|
    +firstName: ?string,
    +lastName: ?string,
    +Type: ?string,
    +Email: ?string,
    +$fragmentRefs: UserPagesTabs_user$ref,
  |}
|};
export type UserPagesIndexQuery = {|
  variables: UserPagesIndexQueryVariables,
  response: UserPagesIndexQueryResponse,
|};
*/


/*
query UserPagesIndexQuery {
  user {
    firstName
    lastName
    Type
    Email
    ...UserPagesTabs_user
    id
  }
}

fragment UserPagesTabs_user on User {
  Type
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "firstName",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "lastName",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "Type",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "Email",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "UserPagesIndexQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "user",
        "storageKey": null,
        "args": null,
        "concreteType": "User",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "kind": "FragmentSpread",
            "name": "UserPagesTabs_user",
            "args": null
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "UserPagesIndexQuery",
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "user",
        "storageKey": null,
        "args": null,
        "concreteType": "User",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "UserPagesIndexQuery",
    "id": null,
    "text": "query UserPagesIndexQuery {\n  user {\n    firstName\n    lastName\n    Type\n    Email\n    ...UserPagesTabs_user\n    id\n  }\n}\n\nfragment UserPagesTabs_user on User {\n  Type\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '4d414a5bfdaf0ade97fad2ed87b269e8';
module.exports = node;
