/* eslint-disable default-case */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import PropTypes from 'prop-types'
import hashLinkScroll from '@utils/hashLinkScroll'
import { Link as RouterDomLink } from 'react-router-dom'
import { TrackingEventTypes } from '@enums/TRACKING_EVENT'
import OldRMPLink from './OldRMPLink'
import OldRMPSchoolPageLink from './OldRMPSchoolPageLink'

export const HASH_PATTERN = /^#.+$/
export const ROUTE_AND_HASH_PATTERN = /.*#.+/

export const LINK_TYPE = {
  EXTERNAL: 'external',
  INTERNAL: 'internal',
  OLD_RMP: 'old_rmp',
  OLD_RMP_SCHOOL_PAGE: 'old_rmp_school_page'
}

const isCurrentRouteAndOrHash = href => {
  if (href.match(HASH_PATTERN)) return true
  const isCurrentRoute = href.includes(window.location.pathname)
  const isRouteAndHash = href.match(ROUTE_AND_HASH_PATTERN)
  return isCurrentRoute && isRouteAndHash
}

const internalHandleClick = (event, to, click) => {
  const hash = to.split('#')[1]
  if (isCurrentRouteAndOrHash(to) || hash) {
    event.preventDefault()
    hashLinkScroll(`#${hash}`)
  }

  click(event)
}

export default function Link({
  to,
  href,
  trackingLabel,
  children,
  onClick,
  disabled,
  type = LINK_TYPE.INTERNAL,
  id,
  ...props
}) {
  // eslint-disable-next-line no-param-reassign
  onClick = onClick || (() => {})

  const click = event => {
    if (disabled) {
      event.preventDefault()
      return
    }

    if (type === LINK_TYPE.INTERNAL && to) {
      internalHandleClick(event, to, onClick)
    } else onClick(event)
  }

  switch (type) {
    case LINK_TYPE.EXTERNAL:
      return (
        <a href={to} {...props} onClick={click} aria-disabled={disabled}>
          {children}
        </a>
      )
    case LINK_TYPE.OLD_RMP:
      return (
        <OldRMPLink to={to} {...props} onClick={click} aria-disabled={disabled}>
          {children}
        </OldRMPLink>
      )
    case LINK_TYPE.OLD_RMP_SCHOOL_PAGE:
      return (
        <OldRMPSchoolPageLink to={to} {...props} onClick={click} aria-disabled={disabled}>
          {children}
        </OldRMPSchoolPageLink>
      )
    case LINK_TYPE.INTERNAL:
      return (
        <RouterDomLink to={to || href} {...props} id={id} onClick={click} aria-disabled={disabled}>
          {children}
        </RouterDomLink>
      )
  }
}

Link.propTypes = {
  to: PropTypes.string,
  href: PropTypes.string,
  trackingLabel: TrackingEventTypes,
  children: PropTypes.node,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  type: PropTypes.oneOf(Object.values(LINK_TYPE)),
  id: PropTypes.string
}
