import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const StyledSignupHeader = styled.div`
  font-family: ${props => props.theme.fontFamily.poppins};
  font-size: ${props => props.theme.fontSize.large3x};
`

const SignupModalHeader = ({ children, ...props }) => {
  return <StyledSignupHeader {...props}>{children}</StyledSignupHeader>
}

SignupModalHeader.propTypes = {
  children: PropTypes.node
}
export default SignupModalHeader
