/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type HeaderDescription_school$ref = any;
type HeaderRateButton_school$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type StickyHeaderContent_school$ref: FragmentReference;
declare export opaque type StickyHeaderContent_school$fragmentType: StickyHeaderContent_school$ref;
export type StickyHeaderContent_school = {|
  +$fragmentRefs: HeaderDescription_school$ref & HeaderRateButton_school$ref,
  +$refType: StickyHeaderContent_school$ref,
|};
export type StickyHeaderContent_school$data = StickyHeaderContent_school;
export type StickyHeaderContent_school$key = {
  +$data?: StickyHeaderContent_school$data,
  +$fragmentRefs: StickyHeaderContent_school$ref,
};
*/


const node/*: ReaderFragment*/ = {
  "kind": "Fragment",
  "name": "StickyHeaderContent_school",
  "type": "School",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "HeaderDescription_school",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "HeaderRateButton_school",
      "args": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = 'fe60220be28774b379483c5ace6d7c35';
module.exports = node;
