/* eslint-disable react/forbid-prop-types */
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { breakpointMediumAndBelow } from '@components/StyledComponents/theme/helpers/breakpoints'

const AdNoBid = ({ code, className, pixel, targeting }) => {
  const [isClientSide, setIsClientSide] = useState(false)
  const id = `${code}_${Math.ceil(Math.random() * 9999)}`

  useEffect(() => {
    setIsClientSide(true)

    if (isClientSide && typeof window !== 'undefined' && typeof window.wrapup !== 'undefined') {
      window.wrapup.cmd.push(() => {
        window.wrapup.defineSlot(id, code, targeting)
      })

      return () => {
        window.wrapup.cmd.push(() => {
          window.wrapup.destroySlot(id)
        })
      }
    }
    return () => {}
  }, [id])

  if (!isClientSide) {
    return null
  }

  return (
    <div id={`ctr_${id}`} className={className} data-testid={`ctr_${code}`}>
      {!pixel && <div id={`spr_${id}`} />}
      <div id={id} />
    </div>
  )
}

AdNoBid.propTypes = {
  code: PropTypes.string,
  /**
   * We pass the className here so that we can apply styles using the styled-components library
   */
  className: PropTypes.string,
  /**
   * We won't include the advertisement label for pixels
   */
  pixel: PropTypes.bool,
  /**
   * Dynamic key-value pairs for ad targeting, i.e. { targeting: { position: "atf" }}
   */
  targeting: PropTypes.object
}

/**
 * Reusable styles for sitewide use
 */
export const AdNoBidLeaderboardStyled = styled(AdNoBid)`
  display: grid;
  justify-content: left;
  margin-bottom: 16px;

  ${breakpointMediumAndBelow(
    css`
      justify-content: center;
    `
  )}
`

export default AdNoBid
