import React from 'react'
import styled from 'styled-components'
import FormButton from '@components/Form/FormButton'
import ROUTE from '@enums/ROUTE'
import { useHistory } from 'react-router-dom'
import { TextButton, ButtonAttributes } from '@StyledComponents/Buttons'
import { TOAST_EVENTS } from '@utils/toast'
import { graphql } from 'react-relay'
import PropTypes from 'prop-types'
import LABEL_STYLE from '@enums/LABEL_STYLE'
import SETTINGS_FORM_MODE from '@enums/SETTINGS_FORM_MODE'
import useGlobalContext from '@hooks/useGlobalContext'
import UserPagesTextInput from '@components/UserPagesTextInput'
import { InputContainer } from '@StyledComponents/TextField'
import Form from './Form/Form'
import FormButtonsArea from './Form/FormButtonsArea'

const mutationQuery = graphql`
  mutation DeleteAccountFormMutation($password: String!) {
    userDelete(password: $password) {
      validation {
        success
        errors {
          field
          messages
        }
      }
    }
  }
`

const DeleteAccountFieldContainer = styled(UserPagesTextInput)`
  ${InputContainer} {
    justify-content: center;
  }
`

export const SubmitButton = styled(FormButton)`
  font-size: ${props => props.theme.fontSize.medium};
  margin: 56px 0 8px;
  min-width: 241px;

  ${ButtonAttributes};
`

export const CancelButton = styled(TextButton)`
  font-size: ${props => props.theme.fontSize.medium};
`

const DeleteAccountForm = ({ userEmail, setFormMode }) => {
  const { logout } = useGlobalContext()
  const history = useHistory()

  return (
    <Form
      mutation={mutationQuery}
      getVariables={model => ({
        password: model.password
      })}
      existingData={{ email: userEmail }}
      onSuccess={() => {
        logout()
        history.push({ pathname: ROUTE.HOME, state: { toast: TOAST_EVENTS.ACCOUNT_DELETED } })
      }}
    >
      <DeleteAccountFieldContainer
        label="Delete this account:"
        name="email"
        labelStyle={LABEL_STYLE.LEFT}
        disabled
      />

      <DeleteAccountFieldContainer
        isPassword
        label="Enter your password to confirm:"
        name="password"
        labelStyle={LABEL_STYLE.LEFT}
      />

      <FormButtonsArea>
        <SubmitButton type="submit" pink>
          Delete Account
        </SubmitButton>
        <CancelButton onClick={() => setFormMode(SETTINGS_FORM_MODE.EDITING)}>Cancel</CancelButton>
      </FormButtonsArea>
    </Form>
  )
}

export default DeleteAccountForm

DeleteAccountForm.propTypes = {
  userEmail: PropTypes.string,
  setFormMode: PropTypes.func
}
