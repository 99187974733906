import React from 'react'
import { graphql } from 'react-relay'
import ReactRouterPropTypes from 'react-router-prop-types'
import { withRouter } from 'react-router-dom'
import QueryWrapper from '@componentUtils/QueryWrapper'
import FlagCampusRating from './FlagCampusRating'
import { getVariables } from './FlagCampusRating.utils'

export const FlagCampusRatingPageQuery = graphql`
  query FlagCampusRatingPageQuery($id: ID!, $ratingId: ID) {
    school: node(id: $id) {
      ... on School {
        id
        legacyId
        name
      }
    }
    rating: node(id: $ratingId) {
      ... on SchoolRating {
        id
        ...FlagCampusReportingBox_rating
      }
    }
  }
`

export const FlagCampusRatingPage = ({ location }) => {
  const variables = getVariables(location)

  return (
    <QueryWrapper
      query={FlagCampusRatingPageQuery}
      component={FlagCampusRating}
      variables={variables}
    />
  )
}

FlagCampusRatingPage.propTypes = {
  location: ReactRouterPropTypes.location
}

const withRouterFlagCampusRatingPage = withRouter(FlagCampusRatingPage)
withRouterFlagCampusRatingPage.serverQueries = [
  {
    query: FlagCampusRatingPageQuery,
    getVariables
  }
]

export default withRouterFlagCampusRatingPage
