import { useState, useEffect } from 'react'

const useRelayIsLoading = (relay, dependancies = [relay]) => {
  const [isLoading, setIsLoading] = useState(false)
  useEffect(() => {
    const relayIsLoading = relay.isLoading()
    if (isLoading !== relayIsLoading) setIsLoading(relayIsLoading)
  }, dependancies)
  return [isLoading, setIsLoading]
}

export default useRelayIsLoading
