import React, { useState, useRef } from 'react'
import MODALS from '@enums/MODALS'
import AuthModal from '@components/Modals/Templates/SmallModal'
import SIGNUP_STAGE from '@enums/SIGNUP_STAGE'
import { TOAST_EVENTS } from '@utils/toast'
import { useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'
import EmailForm from './EmailForm'
import PasswordForm from './PasswordForm'
import DetailsForm from './DetailsForm'

export default function SignupFormModal({ firstStage }) {
  const initialStage = firstStage || SIGNUP_STAGE.EMAIL
  const [stage, setStage] = useState(initialStage)
  const history = useHistory()

  const newEmail = useRef('')
  const setNewEmail = val => {
    newEmail.current = val
  }

  const onClose = () => {
    if (stage === SIGNUP_STAGE.DETAILS) {
      history.push({ state: { toast: TOAST_EVENTS.EMAIL_CONFIRMATION_REMINDER } })
    }
    setStage(initialStage)
    newEmail.current = ''
  }

  const ActiveStageComponent =
    {
      [SIGNUP_STAGE.EMAIL]: (
        <EmailForm setStage={setStage} setNewEmail={setNewEmail} newEmail={newEmail.current} />
      ),
      [SIGNUP_STAGE.PASSWORD]: <PasswordForm setStage={setStage} newEmail={newEmail.current} />,
      [SIGNUP_STAGE.DETAILS]: <DetailsForm onClose={onClose} newEmail={newEmail.current} />
    }[stage] || null

  return (
    <AuthModal modal={MODALS.SIGN_UP_FORM} onClose={onClose} isSlidingModal>
      {ActiveStageComponent}
    </AuthModal>
  )
}

SignupFormModal.propTypes = {
  firstStage: PropTypes.oneOf(Object.values(SIGNUP_STAGE))
}
