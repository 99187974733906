import React, { useContext, useState } from 'react'
import PropTypes from 'prop-types'
import styled, { ThemeContext } from 'styled-components'
import FormContext from '@contexts/FormContext'
import { components as ReactSelectComponents } from 'react-select'
import Creatable from 'react-select/creatable'
import { getStyles } from '@StyledComponents/Select'
import { getFilterStyles } from '@components/Form/FormSelect'
import FieldWrapper from './FieldWrapper'

const StyledCreatable = styled(Creatable)`
  min-width: 311px;
`

const getDropdownIndicator = open => styled(ReactSelectComponents.DropdownIndicator)`
  color: black !important;
  transform: ${open ? 'scale(1, -1)' : ''};
`

const FormCreatableSelect = ({ disabled, name, options, placeholder = '', fullWidth = false }) => {
  const [open, setOpen] = useState(false)
  const theme = useContext(ThemeContext)
  const { locked, onChange, model } = useContext(FormContext)
  const selectComponents = {
    DropdownIndicator: getDropdownIndicator(open),
    ClearIndicator: () => null,
    IndicatorSeparator: () => null
  }

  const filterStyles = getFilterStyles(theme, disabled, fullWidth)
  const styles = getStyles(theme, filterStyles)
  return (
    <FieldWrapper fieldName={name}>
      <StyledCreatable
        disabled={disabled || locked}
        components={selectComponents}
        name={name}
        inputId={name}
        onChange={value => onChange({ target: { name, value } })}
        options={options}
        placeholder={placeholder}
        styles={styles}
        onMenuOpen={() => setOpen(true)}
        onMenuClose={() => setOpen(false)}
        defaultValue={model && model[name] && { value: model[name], label: model[name] }}
      />
    </FieldWrapper>
  )
}

FormCreatableSelect.propTypes = {
  disabled: PropTypes.bool,
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.string
    })
  ),
  fullWidth: PropTypes.bool,
  placeholder: PropTypes.string
}

export default FormCreatableSelect
