/**
 * @flow
 * @relayHash 6ab4f54db5412704b4d5f23c5ae2bde4
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type AddProfessorFormMutationVariables = {|
  firstName: string,
  middleName?: ?string,
  lastName: string,
  department: string,
  directoryUrl?: ?string,
  schoolName: string,
  schoolID: string,
  recaptcha: string,
|};
export type AddProfessorFormMutationResponse = {|
  +addTeacher: ?{|
    +teacher: ?{|
      +id: ?string,
      +legacyId: ?number,
    |},
    +validation: ?{|
      +id: ?string,
      +success: ?boolean,
      +errors: ?$ReadOnlyArray<?{|
        +field: ?string,
        +messages: ?$ReadOnlyArray<?string>,
      |}>,
    |},
  |}
|};
export type AddProfessorFormMutation = {|
  variables: AddProfessorFormMutationVariables,
  response: AddProfessorFormMutationResponse,
|};
*/


/*
mutation AddProfessorFormMutation(
  $firstName: String!
  $middleName: String
  $lastName: String!
  $department: String!
  $directoryUrl: String
  $schoolName: String!
  $schoolID: ID!
  $recaptcha: String!
) {
  addTeacher(firstName: $firstName, middleName: $middleName, lastName: $lastName, department: $department, directoryUrl: $directoryUrl, schoolName: $schoolName, schoolID: $schoolID, recaptcha: $recaptcha) {
    teacher {
      id
      legacyId
    }
    validation {
      id
      success
      errors {
        field
        messages
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "firstName",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "middleName",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "lastName",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "department",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "directoryUrl",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "schoolName",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "schoolID",
    "type": "ID!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "recaptcha",
    "type": "String!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "addTeacher",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "department",
        "variableName": "department"
      },
      {
        "kind": "Variable",
        "name": "directoryUrl",
        "variableName": "directoryUrl"
      },
      {
        "kind": "Variable",
        "name": "firstName",
        "variableName": "firstName"
      },
      {
        "kind": "Variable",
        "name": "lastName",
        "variableName": "lastName"
      },
      {
        "kind": "Variable",
        "name": "middleName",
        "variableName": "middleName"
      },
      {
        "kind": "Variable",
        "name": "recaptcha",
        "variableName": "recaptcha"
      },
      {
        "kind": "Variable",
        "name": "schoolID",
        "variableName": "schoolID"
      },
      {
        "kind": "Variable",
        "name": "schoolName",
        "variableName": "schoolName"
      }
    ],
    "concreteType": "teacherMutationResponse",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "teacher",
        "storageKey": null,
        "args": null,
        "concreteType": "Teacher",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "legacyId",
            "args": null,
            "storageKey": null
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "validation",
        "storageKey": null,
        "args": null,
        "concreteType": "ValidationResult",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "success",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "errors",
            "storageKey": null,
            "args": null,
            "concreteType": "FieldErrorType",
            "plural": true,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "field",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "messages",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "AddProfessorFormMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v2/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "AddProfessorFormMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v2/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "AddProfessorFormMutation",
    "id": null,
    "text": "mutation AddProfessorFormMutation(\n  $firstName: String!\n  $middleName: String\n  $lastName: String!\n  $department: String!\n  $directoryUrl: String\n  $schoolName: String!\n  $schoolID: ID!\n  $recaptcha: String!\n) {\n  addTeacher(firstName: $firstName, middleName: $middleName, lastName: $lastName, department: $department, directoryUrl: $directoryUrl, schoolName: $schoolName, schoolID: $schoolID, recaptcha: $recaptcha) {\n    teacher {\n      id\n      legacyId\n    }\n    validation {\n      id\n      success\n      errors {\n        field\n        messages\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'f36dfb85403752751c4a767e0a8ec325';
module.exports = node;
