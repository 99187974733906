import React, { useEffect, useRef, useContext } from 'react'
import PropTypes from 'prop-types'
import Parse from 'html-react-parser'
import styled, { ThemeContext } from 'styled-components'
import {
  getPageContentMaxWidth,
  getPageContainerMaxWidth
} from '@StyledComponents/theme/helpers/breakpoints'
import PrivacyPolicy from '@assets/html/PrivacyPolicy.html'
import Copyright from '@assets/html/Copyright.html'
import SiteGuidelines from '@assets/html/SiteGuidelines.html'
import TermsOfUse from '@assets/html/TermsOfUse.html'
import PageWrapper from '@components/PageWrapper'
import HelpScoutLoader from '@lib/HelpScoutLoader'
import { poppinsBold3x } from '@StyledComponents/theme/typesets'
import { Helmet } from 'react-helmet'

const LegalPageWrapper = styled(PageWrapper)`
  padding: 20px;
  text-align: left;

  ${({ theme }) => getPageContainerMaxWidth(theme)}
`

export const LegalPageTitle = styled.h1`
  ${poppinsBold3x};
  ${({ theme }) => getPageContentMaxWidth(theme)}
`

export const LegalPageContent = styled.div`
  padding-top: 20px;

  ${({ theme }) => getPageContentMaxWidth(theme)}

  p,
  li,
  section {
    padding-bottom: 5px;
    padding-top: 5px;
  }

  table {
    margin: 10px;
  }

  section > * :not(strong) {
    padding-left: 10px;
  }

  b,
  strong {
    font-weight: ${({ theme }) => theme.fontWeight.bold};
  }

  ul {
    list-style: disc outside;
  }

  ol {
    list-style: decimal outside;
  }

  li {
    margin-left: 2rem;
  }

  th,
  td {
    border: 1px solid ${({ theme }) => theme.color.default};
    padding: 3px;
  }
`

function scrollTo(hash, theme) {
  const id = hash.replace(/#/, '')
  const element = document.getElementById(id)
  if (!element) return false

  window.history.pushState({}, '', hash)
  window.scrollTo(0, element.offsetTop - (theme.header.height + theme.leaderboard.height))
  return true
}

export function onAnchorLinkClicked(event, theme) {
  const { pathname, hash } = event.target
  if (!pathname || pathname !== window.location.pathname) return
  if (!hash || hash.indexOf('#') === -1) return

  const didScroll = scrollTo(hash, theme)
  if (didScroll) event.preventDefault()
}

export default function LegalPage({ title, html }) {
  const legalContent = useRef()
  const theme = useContext(ThemeContext)
  useEffect(() => {
    const links = legalContent.current.querySelectorAll("a[href^='#']")
    const callback = e => onAnchorLinkClicked(e, theme)
    links.forEach(l => l.addEventListener('click', callback))

    // If a link from outside the page had a hash, make sure we scroll to it
    if (window.location.hash) {
      scrollTo(window.location.hash, theme)
    }

    return () => {
      links.forEach(l => l.removeEventListener('click', callback))
    }
  })

  return (
    <LegalPageWrapper>
      <Helmet>
        <title>{title} | RateMyProfessors</title>
      </Helmet>
      <LegalPageTitle>{title}</LegalPageTitle>
      <LegalPageContent ref={legalContent}>{Parse(html)}</LegalPageContent>
    </LegalPageWrapper>
  )
}

LegalPage.propTypes = {
  title: PropTypes.string,
  html: PropTypes.string
}

export const CCPA_BUTTONS = [
  {
    id: 'ccpa-rtk',
    body:
      'I am a California resident and a registered user of RateMyProfessors. I request to know the specific pieces of CA Personal Information collected about myself. The e-mail provided in this request matches the e-mail address associated with my RateMyProfessors account.'
  },
  {
    id: 'ccpa-delete',
    body:
      'I am a California resident and a registered user of RateMyProfessors. I request that RateMyProfessors delete my account and all specific pieces of CA Personal Information collected. The e-mail provided in this request matches the e-mail address associated with my RateMyProfessors account.'
  }
]

function addCCPAEventListeners() {
  CCPA_BUTTONS.forEach(buttonConfig => {
    const button = document.getElementById(buttonConfig.id)
    if (!button) return
    button.addEventListener('click', e => {
      e.preventDefault()
      HelpScoutLoader.showCCPAForm(buttonConfig.body)
    })
  })
}

export function LegalPrivacy() {
  useEffect(addCCPAEventListeners)
  return <LegalPage title="Privacy Policy" html={PrivacyPolicy} />
}

export function LegalTerms() {
  return <LegalPage title="Terms of use" html={TermsOfUse} />
}

export function LegalCopyright() {
  return <LegalPage title="Copyright Compliance Policy" html={Copyright} />
}

export function LegalGuidelines() {
  return <LegalPage title="Guidelines" html={SiteGuidelines} />
}
