import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'

const SEOMetaInfo = ({ title, description, url }) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="title" content={title} />
      <meta name="description" content={description} />
      <meta property="og:url" content={url} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <link rel="canonical" href={url} />
    </Helmet>
  )
}

SEOMetaInfo.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  url: PropTypes.string
}

export default SEOMetaInfo
