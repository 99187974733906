import React from 'react'
import PropTypes from 'prop-types'
import { createFragmentContainer, graphql } from 'react-relay'
import TeacherTypes from '@types/Teacher.types'
import ROUTE from '@enums/ROUTE'
import Link from './Link'

export function CompareProfessorLink({ teacher, className, children, id }) {
  return (
    <Link
      className={className}
      to={ROUTE.COMPARE_PROFESSORS.replace(':primaryId?', teacher.legacyId).replace(
        '/:secondaryId?',
        ''
      )}
      id={id}
    >
      {children}
    </Link>
  )
}

export default createFragmentContainer(CompareProfessorLink, {
  teacher: graphql`
    fragment CompareProfessorLink_teacher on Teacher {
      legacyId
    }
  `
})

CompareProfessorLink.propTypes = {
  teacher: TeacherTypes,
  children: PropTypes.node,
  className: PropTypes.string,
  id: PropTypes.string
}
