/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type CompareSchoolLink_school$ref = any;
type RateSchoolLink_school$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type HeaderRateButton_school$ref: FragmentReference;
declare export opaque type HeaderRateButton_school$fragmentType: HeaderRateButton_school$ref;
export type HeaderRateButton_school = {|
  +$fragmentRefs: RateSchoolLink_school$ref & CompareSchoolLink_school$ref,
  +$refType: HeaderRateButton_school$ref,
|};
export type HeaderRateButton_school$data = HeaderRateButton_school;
export type HeaderRateButton_school$key = {
  +$data?: HeaderRateButton_school$data,
  +$fragmentRefs: HeaderRateButton_school$ref,
};
*/


const node/*: ReaderFragment*/ = {
  "kind": "Fragment",
  "name": "HeaderRateButton_school",
  "type": "School",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "RateSchoolLink_school",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "CompareSchoolLink_school",
      "args": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = 'aa5e96c00643a29df7a0af04902d43dc';
module.exports = node;
