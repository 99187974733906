import styled from 'styled-components'
import { ButtonAttributes } from '@StyledComponents/Buttons'
import FormButton from '@components/Form/FormButton'

const SubmitButton = styled(FormButton)`
  font-size: ${props => props.theme.fontSize.medium};
  height: 48px;
  margin: 56px 0 18px;
  width: 240px;

  ${ButtonAttributes}
`

export default SubmitButton
