import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import Select from '@StyledComponents/Select'
import { ThemeContext } from 'styled-components'
import TeacherTypes from '@types/Teacher.types'
import { createFragmentContainer, graphql } from 'react-relay'

export const FILTER_TYPES = {
  TAG: 'tag',
  COURSE: 'course',
  RATING: 'rating'
}

export const filterMap = {
  [FILTER_TYPES.COURSE]: {
    defaultValue: 'All courses',
    listKey: 'courseCodes',
    itemKey: 'courseName',
    itemCount: 'courseCount'
  }
}

const buildSelectOptions = (teacher, filter) => {
  const { itemKey, itemCount } = filter

  const options = teacher[filter.listKey].map(item => ({
    label: `${item[itemKey]} (${item[itemCount]})`,
    value: item[itemKey]
  }))

  // Unshift default option which allows the user to reset the filter
  options.unshift({ label: filter.defaultValue, value: '' })

  return options
}

const getFilterStyles = theme => {
  return {
    control: {
      fontWeight: theme.fontWeight.bold,
      width: '130px'
    },
    menu: {
      width: '205px'
    },
    option: {
      textAlign: 'left',
      fontWeight: 'bold'
    },
    placeholder: {
      color: theme.color.darkDefault
    }
  }
}

const getFilterTheme = theme => {
  return {
    fontWeight: theme.fontWeight.bold,
    fontSize: theme.fontSize.medium
  }
}

const handleChange = (selected, filterType, setValue) => {
  setValue(selected.value)
}

export const RatingsFilter = ({ teacher, value, setValue, filterType }) => {
  const theme = useContext(ThemeContext)

  if (!teacher || !teacher[filterMap[filterType].listKey] || !filterType || !setValue) return null

  const filter = filterMap[filterType]

  const options = buildSelectOptions(teacher, filter)

  return (
    <Select
      value={value}
      onChange={selected => handleChange(selected, filterType, setValue)}
      onMenuOpen={() => {}}
      placeholder={value || filter.defaultValue}
      options={options}
      additionalStyles={getFilterStyles(theme)}
      additionalTheming={getFilterTheme(theme)}
    />
  )
}

export default createFragmentContainer(RatingsFilter, {
  teacher: graphql`
    fragment RatingsFilter_teacher on Teacher {
      courseCodes {
        courseCount
        courseName
      }
    }
  `
})

RatingsFilter.propTypes = {
  teacher: TeacherTypes,
  setValue: PropTypes.func.isRequired,
  value: PropTypes.string,
  filterType: PropTypes.oneOf(Object.values(FILTER_TYPES)).isRequired
}
