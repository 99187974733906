import React from 'react'
import PropTypes from 'prop-types'
import FieldWrapper from './FieldWrapper'

const FormErrors = ({ className, children }) => {
  return (
    <FieldWrapper fieldName="form" className={className}>
      {children}
    </FieldWrapper>
  )
}

FormErrors.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node
}

export default FormErrors
