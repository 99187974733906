import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import useGlobalContext from '@hooks/useGlobalContext'
import MODALS from '@enums/MODALS'
import TeacherTypes from '@types/Teacher.types'
import SchoolTypes from '@types/School.types'
import ROUTE from '@enums/ROUTE'
import Link from '@components/Link'
import RatingSuccessImage from '@assets/images/ratingsuccess.svg'
import RatingSuccessImagePink from '@assets/images/ratingsuccess-pink.svg'
import { ButtonAttributes } from '@StyledComponents/Buttons'
import { breakpointSmallPlusAndBelow } from '@components/StyledComponents/theme/helpers/breakpoints'
import useUserInfo from '@hooks/useUserInfo'

const SuccessContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`

const ImageComponent = styled.div`
  background-image: ${props =>
    props.isSchoolRating ? `url(${RatingSuccessImagePink})` : `url(${RatingSuccessImage})`};
  background-position: center;
  background-repeat: no-repeat;
  min-width: 402px;
  width: 100%;
`

const SuccessContentContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: auto;
  padding: 160px 82px 100px;
  width: 662px;
  ${breakpointSmallPlusAndBelow(css`
    padding: 110px 15px 60px;
    width: 402px;
  `)}
`

const SuccessHeading = styled.div`
  font-size: 20px;
  font-weight: ${({ theme }) => theme.fontWeight.bolder};
  line-height: 24px;
  margin-bottom: 16px;
  ${breakpointSmallPlusAndBelow(css`
    margin-bottom: 12px;
  `)}
`

const SuccessSubHeading = styled.div`
  font-size: ${({ theme }) => theme.fontSize.medium};
  line-height: 22px;
  margin-bottom: 16px;
  ${breakpointSmallPlusAndBelow(css`
    margin-bottom: 12px;
    font-size: ${({ theme }) => theme.fontSize.default};
  `)}
`

const StyledLegalLink = styled(Link).attrs(props => ({ ...props, target: '_blank' }))`
  font-weight: ${({ theme }) => theme.fontWeight.bold};
`

const ViewRatingButton = styled(Link)`
  ${ButtonAttributes}
  padding-left: 64px;
  padding-right: 64px;
  text-decoration: none;
`

const RatingSuccessHeader = ({ successPageData }) => {
  const userInfo = useUserInfo()
  const { setActiveModal, activeModal } = useGlobalContext()
  const { teacher, school } = successPageData
  useEffect(() => {
    if (!userInfo && !activeModal) {
      setActiveModal(MODALS.REGISTER_PROMPT)
    }
  }, [userInfo])

  return (
    <SuccessContainer>
      <ImageComponent isSchoolRating={!!school}>
        <SuccessContentContainer>
          <SuccessHeading>
            Thanks for rating{' '}
            {school
              ? school.name
              : `Professor ${teacher?.lastName ? teacher.lastName : teacher?.firstName}!`}
          </SuccessHeading>
          <SuccessSubHeading>
            We&apos;ve posted your rating but it is still under review to ensure that it meets our{' '}
            <StyledLegalLink to={ROUTE.LEGAL_SITE_GUIDELINES}>Site Guidelines.</StyledLegalLink>
          </SuccessSubHeading>
          <ViewRatingButton
            to={
              school
                ? ROUTE.NEW_CAMPUS_RATINGS.replace(':sid', school?.legacyId)
                : ROUTE.SHOW_RATINGS.replace(':tid', teacher?.legacyId)
            }
            id={school ? 'view-my-school-rating-btn' : 'view-my-rating-btn'}
          >
            View my rating
          </ViewRatingButton>
        </SuccessContentContainer>
      </ImageComponent>
    </SuccessContainer>
  )
}

RatingSuccessHeader.propTypes = {
  successPageData: PropTypes.oneOfType([
    PropTypes.shape({
      teacher: TeacherTypes
    }),
    PropTypes.shape({
      school: SchoolTypes
    })
  ])
}

export default RatingSuccessHeader
