import React from 'react'
import styled from 'styled-components'
import { createPaginationContainer, graphql } from 'react-relay'
import RelayTypes from '@types/Relay.types'
import UserTypes from '@types/User.types'
import PaginationButton from '@StyledComponents/PaginationButton'
import useRelayIsLoading from '@hooks/useRelayIsLoading'
import Rating from '@components/Rating/Rating'
import SchoolRating from '@components/SchoolRating/SchoolRating'
import { helveticaBoldXLarge } from '@StyledComponents/theme/typesets'

export const RatingsList = styled.ul`
  list-style-type: none;
`

const RatingCardArea = styled.div`
  margin-bottom: 24px;
`

export const NoRatingsText = styled.div`
  margin-top: 85px;

  ${helveticaBoldXLarge};
`

export const NoRatingsSubText = styled.div`
  font-family: ${props => props.theme.fontFamily.helveticaNeue};
  font-size: ${props => props.theme.fontSize.medium};
`

export function MyRatingsList({ user, relay }) {
  const [isLoading, setIsLoading] = useRelayIsLoading(relay, [user, relay])

  if (!user) {
    return null
  }

  const noRatings =
    (!user.ratings || !user.ratings.edges || user.ratings.edges.length < 1) &&
    (!user.campusRatings || !user.campusRatings.edges || user.campusRatings.edges.length < 1)

  if (noRatings) {
    return <NoRatings />
  }

  const loadMore = () => {
    relay.loadMore(10, error => {
      if (error) {
        console.error(error)
      }
      setIsLoading(false)
    })
    setIsLoading(true)
  }

  const displayOptions = {
    showSuperHeader: true,
    showFooter: false,
    showEmotionLabel: false
  }

  return (
    <React.Fragment>
      <RatingsList>
        {(user.ratings.edges ?? []).map(item => (
          <RatingCardArea key={`rca-${item.cursor}`}>
            <Rating
              rating={item.rating}
              key={item.cursor}
              teacher={item.rating?.teacher}
              displayOptions={displayOptions}
            />
          </RatingCardArea>
        ))}
        {(user.campusRatings.edges ?? []).map(item => (
          <RatingCardArea key={`crca-${item.cursor}`}>
            <SchoolRating
              rating={item.campusRating}
              school={item.campusRating?.school}
              key={item.cursor}
              displayOptions={displayOptions}
            />
          </RatingCardArea>
        ))}
      </RatingsList>
      <PaginationButton isLoading={isLoading} hasMore={relay.hasMore()} onClick={loadMore}>
        Load more
      </PaginationButton>
    </React.Fragment>
  )
}

export function NoRatings() {
  return (
    <React.Fragment>
      <NoRatingsText>You haven’t rated any professors yet.</NoRatingsText>
      <NoRatingsSubText>
        Find your favorite professor and let them know what a difference they’ve made!
      </NoRatingsSubText>
    </React.Fragment>
  )
}

export default createPaginationContainer(
  MyRatingsList,
  {
    user: graphql`
      fragment MyRatingsList_user on User
        @argumentDefinitions(count: { type: "Int", defaultValue: 10 }, cursor: { type: "String" }) {
        id
        ratings(first: $count, after: $cursor) @connection(key: "MyRatingsList_ratings") {
          edges {
            cursor
            rating: node {
              legacyId
              teacher {
                ...Rating_teacher
              }
              ...Rating_rating
            }
          }
          pageInfo {
            hasNextPage
            endCursor
          }
        }
        campusRatings(first: $count, after: $cursor) {
          edges {
            cursor
            campusRating: node {
              ...SchoolRating_rating
              school {
                ...SchoolRating_school
              }
            }
          }
          pageInfo {
            hasNextPage
            endCursor
          }
        }
      }
    `
  },
  {
    direction: 'forward',
    getConnectionFromProps(props) {
      return props.user && props.user.ratings && props.user.campusRatings
    },
    getVariables(props, { count, cursor }) {
      return {
        count,
        cursor,
        id: props.user.id
      }
    },
    query: graphql`
      query MyRatingsListQuery($count: Int!, $cursor: String!, $id: ID!) {
        node(id: $id) {
          ... on User {
            ...MyRatingsList_user @arguments(count: $count, cursor: $cursor)
          }
        }
      }
    `
  }
)

MyRatingsList.propTypes = {
  user: UserTypes,
  relay: RelayTypes
}
