import React from 'react'
import styled, { css } from 'styled-components'
import SchoolTypes from '@types/School.types'
import PropTypes from 'prop-types'
import ROUTE from '@enums/ROUTE'
import { breakpointSmediumAndBelow } from '@StyledComponents/theme/helpers/breakpoints'
import Link from '@components/Link'

export const StyledTitle = styled.div`
  display: flex;
  flex-direction: row;
  white-space: nowrap;
  align-items: flex-start;

  ${breakpointSmediumAndBelow(css`
    flex-direction: ${props => (props.large ? 'column' : 'row')};
    padding-bottom: ${props => (props.large ? '10px' : '0')};
  `)}
`

const LinkContainer = styled.div`
  margin-left: 0px;
  margin-top: 5px;
`

const linkStyles = css`
  color: ${({ theme }) => theme.color.default};
  font-weight: bold;
  ${breakpointSmediumAndBelow(css`
    font-size: 12px;
  `)}
`
const StyledProfLink = styled(Link)`
  ${linkStyles}
`

export const SchoolTitles = ({ school, large }) => {
  if (!school) {
    return null
  }

  return (
    <StyledTitle large={large}>
      {large && (
        <LinkContainer>
          <StyledProfLink to={`${ROUTE.TEACHER_SEARCH.replace(':sid?', school.legacyId)}?q=*`}>
            View all Professors
          </StyledProfLink>
        </LinkContainer>
      )}
    </StyledTitle>
  )
}

export default SchoolTitles

SchoolTitles.propTypes = {
  school: SchoolTypes,
  large: PropTypes.bool
}
